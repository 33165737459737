import React, { useEffect } from "react";
import "../../styles/components/addLessDiv.css";
import { LessBtn, MoreBtn } from "../../images/icons/JsxIcons";
export default function AddLessDiv({
  classname = "",
  title = "",
  setData,
  data,
  max = 10000,
  min = 0,
  baseData = null,
  checkCallBefore = null,
  required = false,
  mineId = "mine-just-add-less-div",
}) {
  useEffect(() => {
    if (baseData){
      let newData = baseData;
      if (baseData > max) newData = max;
      setData(newData);
    } 
  }, [baseData]);
  // console.log("data", max);
  const handleAdd = () => {
    if (checkCallBefore) checkCallBefore();
    if (data < max) {
      setData(data + 1);
    }
  };
  const handleLess = () => {
    if (data > min) {
      setData(data - 1);
    }
  };
  return (
    <div
      id={mineId}
      className={`nbr-config ${classname} | flrow center btw rlve`}
    >
      {required && data <= min && <span className="required">* Réquis</span>}
      <label>{title}</label>
      <div className="nbrs | flrow center">
        <button
          onClick={handleLess}
          disabled={data <= min}
          className="btn-nbr | btn"
        >
          <LessBtn stroke={data <= min ? "#9CA3AF" : "#1E1A21"} />
        </button>
        <span className={`${data > 0 && "on-true"}`}>
          {data < 10 ? "0" + data : data}
        </span>
        <button
          onClick={handleAdd}
          disabled={data >= max}
          className="btn-nbr | btn"
        >
          <MoreBtn stroke={data >= max ? "#9CA3AF" : "#1E1A21"} />
        </button>
      </div>
    </div>
  );
}
