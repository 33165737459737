import React, { useEffect, useState } from "react";
import "../../styles/components/addResidencePart/step17.css";
import { Check, Warning, XClose } from "../../images/icons/JsxIcons";
import checkIcon from "../../images/icons/check.svg";
import dataRemboursement from "../../data/dataRemboursement.json";
import { CheckCircle } from "../../images/icons/JsxDesign";
import { useStoreShareFunction } from "../../store/StoreShareFunction";

const currentStep = 17;
export default function Step17Remboursement({
  step,
  handleBlockBtn,
  tableRemboursement,
  setTableRemboursement,
}) {
  useEffect(() => {
    let newTabSocial = [];
    dataRemboursement.data.map((remboursement) => {
      let newSelectables = [];
      remboursement.selectables.map((selectable) => {
        let newSelectable = { ...selectable, select: false };
        newSelectables.push(newSelectable);
      });
      let newRemboursement = { ...remboursement, selectables: newSelectables };
      newTabSocial.push(newRemboursement);
    });
    setTableRemboursement(newTabSocial);
  }, []);
  useEffect(() => {
    if (currentStep == step)
      if (tableRemboursement.length == 0) {
        handleBlockBtn(true);
        return;
      }
    let valgo = false;
    for (let index = 0; index < tableRemboursement.length; index++) {
      const element = tableRemboursement[index];
      let find = false;
      for (
        let select = 0;
        select < tableRemboursement[index].selectables.length;
        select++
      ) {
        const element = tableRemboursement[index].selectables[select];
        if (tableRemboursement[index].selectables[select].select == true) {
          find = true;
          break;
        }
      }
      if (find == false) {
        valgo = true;
        break;
      }
    }
    if (currentStep == step) handleBlockBtn(valgo);
  }, [step, tableRemboursement]);

  const handleUpdateSelected = (key, idteam) => {
    let socialTabUpdated = [];
    tableRemboursement.map((remboursement) => {
      let newSelectables = [];
      remboursement.selectables.map((selectable) => {
        let newSelectable = selectable;
        if (idteam == remboursement.id)
          if (key == selectable.key) {
            newSelectable = { ...newSelectable, select: true };
          } else newSelectable = { ...newSelectable, select: false };

        newSelectables.push(newSelectable);
      });
      let newRemboursement = { ...remboursement, selectables: newSelectables };
      socialTabUpdated.push(newRemboursement);
    });
    setTableRemboursement(socialTabUpdated);
  };

  const checkIfBlock = () => {
    let valgo = false;
    for (let index = 0; index < tableRemboursement.length; index++) {
      const element = tableRemboursement[index];
      let find = false;
      for (
        let select = 0;
        select < tableRemboursement[index].selectables.length;
        select++
      ) {
        const element = tableRemboursement[index].selectables[select];
        if (tableRemboursement[index].selectables[select].select == true) {
          find = true;
          break;
        }
      }
      if (find == false) {
        valgo = true;
        break;
      }
    }
    return valgo;
  };

  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step && !checkIfBlock()) {
      document.activeElement.blur();
      useStoreShareFunction.getState().handleGoOpenForAdd();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [checkIfBlock, step, tableRemboursement]);
  return (
    <section
      className={`step-1-annonce step-17 ${
        currentStep == step ? "visible" : ""
      } 
      ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input input-resi | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            <label>Conditions d’annulation</label>
            <p>
              Choisissez les conditions d'annulation qui s'appliqueront à vos
              clients. Ces conditions concernent les montants remboursés aux
              voyageurs, lorsqu'ils annulent avant le début de leurs séjours
            </p>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm jcenter liner">
          <div className="contains-input | flcolm jcenter liner">
            <div className="contains-selects | flcolm">
              <div
                style={{
                  overflowY: "scroll",
                }}
                className="remboursement-type | flcolm"
              >
                {tableRemboursement.map((remboursement, index) => {
                  return (
                    <div
                      key={"remboursement-" + index}
                      className="contains-zone | flcolm"
                    >
                      <label>{remboursement.label}</label>
                      {remboursement.selectables.map((selectable) => {
                        return (
                          <button
                            onClick={() =>
                              handleUpdateSelected(
                                selectable.key,
                                remboursement.id
                              )
                            }
                            key={"selectable-" + selectable.key}
                            className="btn | flrow"
                          >
                            <div className="left-side | flrow liner">
                              {selectable.percent == 0 ? (
                                <span className="no-montant">
                                  Pas de remboursement
                                </span>
                              ) : (
                                <>
                                  {" "}
                                  <span>{selectable.percent}%</span>
                                  <span> du montant total</span>
                                </>
                              )}
                            </div>
                            <div className="case-cocher | flex jcenter liner rlve">
                              {selectable.select && <CheckCircle />}
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
