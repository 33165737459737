import React, { useEffect, useState } from "react";
import "../../styles/components/addResidencePart/step7.css";
import {
  BuildingTypeIcon,
  ChaletTypeIcon,
  HomeTypeIcon,
} from "../../images/icons/JsxIcons";
import { dataFilters } from "../../data/dataFilters";
import { useStoreComodities } from "../../store/dataComodities";
import { UserPreferences } from "../../store/UserPreferences";
import { useTranslation } from "react-i18next";
import { useStoreShareFunction } from "../../store/StoreShareFunction";
const currentStep = 7;
export default function Step7Comodities({
  step,
  handleBlockBtn,
  currentCommodities,
  setCurrentCommodities,
}) {
  const { t } = useTranslation();
  const language = UserPreferences((state) => state.language);
  const comodities = useStoreComodities((state) => state.comodities);
  const allComodities = comodities;
  useEffect(() => {
    if (allComodities.length == 0) return;
    let filters = [];
    allComodities.map((filter) => {
      filters.push({ ...filter, selected: false });
    });
    setCurrentCommodities(filters);
  }, [allComodities]);
  const [_selectedCommodities, setSelectectCommodities] = useState([]);
  const handleCheckAmSelect = (filter) => {
    let checkMyValue = false;
    for (let index = 0; index < currentCommodities.length; index++) {
      const element = currentCommodities[index];
      if (filter.id == element.id) {
        if (filter.selected == true) checkMyValue = true;
        break;
      }
    }
    return checkMyValue;
  };
  const handleUpdateFilterComodities = (mineFilter) => {
    setCurrentCommodities((prev) => {
      const newFilters = prev.map((filter) => {
        if (filter.id === mineFilter.id) {
          return { ...filter, selected: !filter.selected };
        }
        return filter;
      });
      let newCommodites = [];
      newFilters.map((filterComoditie) => {
        if (filterComoditie.selected) {
          newCommodites.push(filterComoditie.idApi);
        }
      });
      setSelectectCommodities(newCommodites);
      return newFilters;
    });
  };
  useEffect(() => {
    if (currentStep == step) handleBlockBtn(_selectedCommodities.length == 0);
  }, [step, _selectedCommodities]);

  const checkIfBlock = () => {
    let check = false;
    currentCommodities.map((filter) => {
      if (filter.selected) check = true;
    });
    return check;
  };
  const handleKeyDown = (e) => {
    //blur de la page

    if (e.key == "Enter" && currentStep == step && checkIfBlock()) {
      document.activeElement.blur();
      useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [checkIfBlock, step, _selectedCommodities]);
  return (
    <section
      className={`step-1-annonce step-7 ${currentStep == step ? "visible" : ""} 
      ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input input-resi | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            <label>{t("step7Title")}</label>
            <p>{t("step7SubTitle")}</p>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm ">
          <div className="contains-input | flcolm">
            <div className="filter-selected-panel | flcolm">
              <div className="residence-type commodities-select | flrow">
                {currentCommodities &&
                  currentCommodities.map((filter) => (
                    <button
                      key={filter.id}
                      onClick={() => {
                        handleUpdateFilterComodities(filter);
                      }}
                      className={`${
                        handleCheckAmSelect(filter) == true &&
                        "selected-type | "
                      } btn flcolm`}
                    >
                      <img src={filter.secondThumb} alt="comoditie-icon" />
                      <span className="-l">
                        {language == "fr-FR" ? filter.title : filter.titleEng}
                      </span>
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
