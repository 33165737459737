import React from "react";
import "../../styles/components/tileResidence.css";
import { Star } from "../../images/icons/JsxIcons";
export default function TileResidence({ residence, pader }) {
  return (
    <div className={`tile-residence ${pader ? "pader" : ""} | flrow liner`}>
      <img
        src={
          residence.medias
            ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${residence.medias[0]?.filename}`
            : residence.thumb
        }
        alt="residence"
      />
      <div className="right-side | flcolm">
        <label>{residence.name}</label>
        <div className="loc-star | flcolm">
          <span>{residence.address}</span>
          <div className="star | flrow liner">
            <Star color={residence.stars ? "#FACC15" : "var(--shade-black)"} />
            <span>
              {residence.stars ? parseFloat(residence.stars).toFixed(1) : "N/A"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
