/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import fadeFace from "../../images/icons/fadeFace.svg";
import bookNoneIcon from "../../images/icons/file-02.svg";
import CardReservation from "../cells/CardReservation";
import SkeletonCardReservation from "../cells/SkeletonCardReservation";
export default function ListReservation({
  handleOpenReservationPanel,
  data = [],
  loading,
  skeletonNumber = 2,
  currentSelected,
  mineSelect,
  showNodata,
  hoteMode = false,
  setShowRemboursement,
}) {
  const [myResponse, setMyResponse] = useState([]);
  const { t } = useTranslation();
  const mineRef = useRef(null);
  useEffect(() => {
    //"all status"
    //waiting = le client a fait sa demande et elle est en attente de validation par l'hote
    //accepted = l'hote a validé la demande et le client on doit maintenant demandé au client de faire le paiement
    //planified = le client a payé et sa reservation est complètement validé. On attend donc le jour de la reservation
    //progressing = le client a pris possession du bien (l'hote a saisi son code de validation de reservation)
    //rejected = l'hote a rejeté la demande de reservation. Il peut le faire uniquement quand la demande est en attente (waiting)
    //cancelled = l'hote ou le client ont annulé la reservation.
    // done = le client a validé que la reservation est terminée (ou le systeme a passé sa reservation en terminé après que la date de fin soit passée
    if (loading) return;
    if (data.length == 0) return;
    let newTab = [];
    data.map((reservation) => {
      if (mineSelect == 0 && reservation.status != "external") {
        newTab.push(reservation);
      } else
        switch (mineSelect) {
          case 1:
            if (reservation.status == "waiting") {
              newTab.push(reservation);
            }
            break;
          case 2:
            if (reservation.status == "accepted") {
              newTab.push(reservation);
            }
            break;
          case 3:
            if (
              (reservation.status == "planified" ||
                reservation.status == "payment_processing") &&
              reservation.refundAskedAt == null
            ) {
              newTab.push(reservation);
            }
            break;
          case 4:
            if (
              reservation.status == "cancelled" ||
              (hoteMode &&
                reservation.refundAskedAt &&
                reservation.status != "external")
            ) {
              newTab.push(reservation);
            }
            break;
          case 5:
            if (reservation.status == "rejected") {
              newTab.push(reservation);
            }
            break;
          case 6:
            if (
              reservation.refundAskedAt &&
              !reservation.refundedAt &&
              reservation.status != "external"
            ) {
              newTab.push(reservation);
            }
            break;
          case 7:
            if (
              reservation.refundedAt &&
              reservation.status != "external" &&
              hoteMode == false
            ) {
              setShowRemboursement(true);
              newTab.push(reservation);
            }
            break;
          case 8:
            if (reservation.status == "done" && hoteMode == true) {
              setShowRemboursement(true);
              newTab.push(reservation);
            }
            break;
          case 9:
            if (
              reservation.status == "progressing" &&
              reservation.refundAskedAt == null
            ) {
              setShowRemboursement(true);
              newTab.push(reservation);
            }
            break;
          default:
            break;
        }
    });
    setMyResponse(newTab);
  }, [data, loading]);
  useEffect(() => {
    if (!mineRef.current) return;
    const newScrollValue = 0;

    mineRef.current.scrollTo({
      top: newScrollValue,
      behavior: "smooth",
    });
  }, [currentSelected]);
  return (
    <div>
      <div
        ref={mineRef}
        className={`${currentSelected == mineSelect ? "visible" : ""} 
    ${mineSelect < currentSelected ? "go-left" : ""} ${
          mineSelect > currentSelected ? "go-right" : ""
        } content-list all-list | flrow`}
      >
        {loading &&
          Array.from({ length: skeletonNumber }).map((_, index) => (
            <React.Fragment key={"skeleton-" + index}>
              <SkeletonCardReservation />
            </React.Fragment>
          ))}
        {!loading &&
          myResponse.map((reservation) => {
            return (
              <React.Fragment key={reservation.id}>
                <CardReservation
                  hoteMode={hoteMode}
                  handleOpenReservationPanel={handleOpenReservationPanel}
                  cardReservationInfo={reservation}
                />
              </React.Fragment>
            );
          })}
        {!loading && myResponse.length == 0 && (
          <div className="">
            <div className="no-data | flcolm jcenter liner center-absolute">
              <img
                src={hoteMode ? fadeFace : bookNoneIcon}
                alt="current-book-icon"
              />
              <div className="text | flcolm jcenter liner">
                <p>{t("noReservationTxt")}</p>
                <span>{showNodata}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
