import React from "react";

export default function SkeletonCardReservation() {
  return (
    <div style={{ padding: 16 }} className="load-skeleton-card | flrow">
      <div
        style={{ borderRadius: 16 }}
        className="load-img | skeleton rlve"
      ></div>
      <div className="txt-part | flcolm | btw">
        <div className="top-title | flcolm">
          <span
            style={{ width: "100%", height: 26, marginBottom: 8 }}
            className="skeleton span-skeleton"
          ></span>
          <span
            style={{
              width: "100%",
              height: 16,
              marginBottom: 8,
              width: "80%",
            }}
            className="skeleton span-skeleton"
          ></span>
        </div>
        <div className="middle-part | flcolm">
          <span
            style={{
              height: 26,
              marginBottom: 8,
              width: "70%",
            }}
            className="skeleton"
          ></span>
          <span
            style={{
              height: 26,
              marginBottom: 8,
              width: "80%",
            }}
            className="skeleton "
          ></span>
        </div>
        <span
          style={{
            borderRadius: 1000,
            height: 60,
            width: "100%",
          }}
          className="skeleton span-skeleton"
        ></span>
      </div>
    </div>
  );
}
