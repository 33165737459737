import { useEffect, useRef, useState } from "react";
import { ChevronLeft, Mail } from "../../images/icons/JsxIcons";
import { useTranslation } from "react-i18next";
import { post } from "../../services/useFetchAction";
import { useStorePageManager } from "../../store/PageManage";
import { NotificationService } from "../../services/Notification.service";

export const ForgetPasssPanelConnexion = ({
  currentState = 1,
  panelState,
  setPanelState,
  dataInscription,
  setDataInscription,
  setRequestId,
  setToken,
  requestId,
  token,
}) => {
  const { t } = useTranslation();
  const handleUpdateConnexionPanelState = useStorePageManager(
    (state) => state.handleUpdateConnexionPanelState
  );
  const handleUpdateBlockScreen = useStorePageManager(
    (state) => state.handleUpdateBlockScreen
  );
  const [stateMail, setStateMail] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [stateCode, setStateCode] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [passReceive, setPassReceive] = useState("");
  const [passAlreadySend, setPassAlreadySend] = useState(false);
  const [resendPass, canResendPass] = useState(false);
  const refCode = useRef(null);
  useEffect(() => {
    if (dataInscription.addressMail.length > 0) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(dataInscription.addressMail)) {
        setStateMail({ ...stateMail, error: false, good: true });
      } else {
        setStateMail({ ...stateMail, error: true, good: false });
      }
    } else {
      setStateMail({ ...stateMail, error: false, good: false });
    }
  }, [dataInscription.addressMail]);

  const handleUpdatePass = (e) => {
    let newCode = e.target.value.replace(/[^0-9+]/g, "");
    setDataInscription({ ...dataInscription, telephone: newCode });
    setPassReceive(newCode);
    if (newCode.length > 0) {
      if (newCode.length >= 4) {
        setStateCode({ ...stateCode, error: false, good: true });
      } else {
        setStateCode({ ...stateCode, error: true, good: false });
      }
    } else {
      setStateCode({ ...stateCode, error: false, good: false });
    }
    e.target.value = newCode;
  };
  const handleGoCheckMail = () => {
    handleUpdateBlockScreen(true);

    post("/users/request_password_recovery", {
      email: dataInscription.addressMail,
    })
      .then((res) => {
        setRequestId(res.data.id);
        setPassAlreadySend(true);
        canResendPass(false);
        setSeconds(20);
        NotificationService.addToQueue(0, t("codeSendTxt"));
      })
      .catch((err) => {
        console.log(err);
        NotificationService.addToQueue(2, t("userNoExistTxt"));
      })
      .finally(() => {
        handleUpdateBlockScreen(false);
      });
  };

  const handleGoCheckCode = () => {
    handleUpdateBlockScreen(true);
    post("/users/check_password_recovery_code", {
      requestId: requestId,
      code: passReceive,
    })
      .then((res) => {
        setToken(res.data.token);
        setPanelState({
          ...panelState,
          passwordForgotten: 0,
          newPassword: 1,
        });
      })
      .catch((err) => {
        NotificationService.addToQueue(2, t("codeNoValidTxt"));
      })
      .finally(() => {
        handleUpdateBlockScreen(false);
      });
  };

  const handleGoConnexion = () => {
    setPassReceive("");
    setStateCode({ ...stateCode, error: false, good: false });

    setDataInscription({
      ...dataInscription,
      addressMail: "",
    });
    setPassAlreadySend(false);
    setPanelState({
      ...panelState,
      connexion: 1,
      passwordForgotten: 2,
    });
    try {
      if (refCode !== null) refCode.current.value = "";
    } catch {}
  };

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(20);

  useEffect(() => {
    let interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes === 0) {
          clearInterval(interval);
          canResendPass(true);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  const sendCodePassFunction = () => {};
  const resendPassCode = () => {
    refCode.current.value = "";
    setStateCode({ ...stateCode, error: false, good: false });
    canResendPass(false);
    setSeconds(20);
    handleGoCheckMail();
  };
  return (
    <div
      className={`contains inscription-part ${
        currentState == 0
          ? "go-left-side"
          : currentState == 1
          ? "come-center"
          : "go-right-side"
      } | flcolm`}
    >
      <button onClick={handleGoConnexion} className="back-btn | btn">
        <ChevronLeft />
      </button>
      <label>{t("forgotPassTxt")}</label>
      <div className="separator label-separator">
        <div className="line"></div>
      </div>
      {passAlreadySend ? (
        <>
          <p className="label-second">
            {t("codeReceiveTxt")}{" "}
            <span style={{ fontWeight: 600 }}>
              {dataInscription.addressMail}
            </span>
            ,{t("validityCodeTxt")}
          </p>
          <div className="input-field | flcolm">
            <div className="input code | flrow rlve addgap8">
              <input
                onBlur={() => setStateCode({ ...stateCode, focus: false })}
                onFocus={() => setStateCode({ ...stateCode, focus: true })}
                onChange={handleUpdatePass}
                placeholder={t("codePlaceholder")}
                type="tel"
                name="code"
                ref={refCode}
              />
              <button
                onClick={resendPassCode}
                disabled={!resendPass}
                className="resend-btn | btn opt-btn"
              >
                {t("reSendTxt")}{" "}
                {!resendPass && (minutes < 10 ? `0${minutes}` : minutes)}
                {!resendPass && ":"}
                {!resendPass && (+seconds < 10 ? `0${seconds}` : seconds)}
              </button>

              {stateCode.focus == false && stateCode.error && (
                <div className="detect-issue-input">
                  <div className="chevron"></div>
                  <span>{t("enterCode")}</span>
                </div>
              )}
            </div>
          </div>
          <button
            onClick={handleGoCheckCode}
            className="next-btn | btn flex"
            disabled={!stateCode.good}
          >
            {t("checkCode")}
          </button>
        </>
      ) : (
        <>
          <p className="label-second">{t("newAdresseTxtInfo")}</p>
          <div className="input-field | flcolm">
            <div className="input | rlve">
              <Mail color={stateMail.focus ? "var(--gray-600)" : "none"} />
              <input
                on
                onBlur={() => setStateMail({ ...stateMail, focus: false })}
                onFocus={() => setStateMail({ ...stateMail, focus: true })}
                onChange={(e) =>
                  setDataInscription({
                    ...dataInscription,
                    addressMail: e.target.value,
                  })
                }
                autoCorrect="false"
                autoCapitalize="false"
                autoComplete="false"
                placeholder={t("placeHolderMail")}
                type="email"
              />
              {stateMail.focus == false && stateMail.error && (
                <div className="detect-issue-input">
                  <div className="chevron"></div>
                  <span>{t("issueEmailType")}</span>
                </div>
              )}
            </div>
          </div>
          <button
            onClick={handleGoCheckMail}
            className="next-btn | btn flex"
            disabled={!stateMail.good}
          >
            {t("sendLinkTxt")}
          </button>
        </>
      )}
    </div>
  );
};
