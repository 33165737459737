class Residence {
  constructor(id, title, price, thumb, stars, location) {
    this.id = id;
    this.title = title;
    this.price = price;
    this.thumb = thumb;
    this.stars = stars;
    this.location = location;
  }
}
export default Residence;
