/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Trash } from "../../images/icons/JsxIcons";
import imgPlus from "../../images/icons/plus.svg";
import { NotificationService } from "../../services/Notification.service";
import "../../styles/components/addResidencePart/step9.css";
import { DragOverlayItem } from "../DragDrop/placeholder";
import {
  DELAY_TIME_DND,
  DISTANCE_DETECT_DND,
  TOLERANCE_TIME_DND,
  handleDeleteThisPick,
  handleDragEnd,
  handleDragOver,
  handleDragStart,
  handlePutCover,
} from "../DragDrop/utils";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import PhotosStep9 from "../cells/PhotosStep9";
import Tooltip from "../molecules/Tooltip";

const currentStep = 9;
export default function Step9Photos({
  step,
  handleBlockBtn,
  currentPhotosSelected,
  setPhotosSelected,
  setCurrentStep,
  saveImagesToIndexedDB,
  onDropFunction,
}) {
  const [activeId, setActiveId] = useState(null);
  const [indexFirstSelected, setIndexFirstSelected] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [hideDeleteBtn, setHideDeleteBtn] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => onDropFunction(files),
    accept: {
      "image/*": [".jpeg", ".png"],
    },
    multiple: true,
  });
  useEffect(() => {
    if (currentStep == step) handleBlockBtn(currentPhotosSelected.length < 5);
    if (currentStep == step && currentPhotosSelected.length == 0)
      setCurrentStep(currentStep - 1);
  }, [step, currentPhotosSelected]);

  const sensorsMob = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: DELAY_TIME_DND,
        tolerance: TOLERANCE_TIME_DND,
      },
    }),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: DISTANCE_DETECT_DND,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const refScroll = useRef();

  const handleDeleteAllPhotos = () => {
    setPhotosSelected([]);
  };

  return (
    <section
      style={{
        overflow: "hidden",
      }}
      className={` step-9 ${currentStep == step ? "visible" : ""} 
      ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input input-resi | relve`}
    >
      <ModalDeletePhotos
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteAllPhotos={handleDeleteAllPhotos}
      />
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            {/* {platform} */}
            <label>Ajoutez quelques photos de votre résidence</label>
            <p>
              Pour commencer, vous aurez besoin de 5 photos. Vous pourrez en
              ajouter d'autres ou faire des modifications plus tard.
            </p>
          </div>
        </div>
        <div className={`right-part-add-resi | flcolm jcenter liner`}>
          <div className="contains-input | flcolm liner rlve">
            <div className="title-show | flrow liner maxwidth w-full">
              <div
                style={{
                  marginRight: "auto",
                  width: "fit-content",
                }}
                className="flex flex-col gap-2"
              >
                <label
                  style={{
                    width: "fit-content",
                  }}
                >
                  Ta-da! Vérifiez vos photos
                </label>
                {currentPhotosSelected.length > 1 && (
                  <span
                    className="flash-me -l"
                    style={{
                      fontSize: 11,
                      maxWidth: "95%",
                    }}
                  >
                    Pour changer l'ordre des photos, maintenez et déplacez les
                    photos
                  </span>
                )}
              </div>
              <div className={`photos-adder | flrow liner`} {...getRootProps()}>
                <input {...getInputProps()} />
                <img src={imgPlus} alt="plus-icon" />
                <span
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  Ajouter plus
                </span>
              </div>
            </div>
            <DndContext
              sensors={sensorsMob}
              onDragStart={(e) => {
                setHideDeleteBtn(true);
                handleDragStart({
                  active: e.active,
                  setIndexFirstSelected,
                  setActiveId,
                  currentPhotos: currentPhotosSelected,
                });
              }}
              onDragOver={(e) =>
                handleDragOver({
                  active: e.active,
                  over: e.over,
                  currentPhotos: currentPhotosSelected,
                  setCurrentPhotos: setPhotosSelected,
                })
              }
              onDragEnd={() => {
                handleDragEnd({ setActiveId });
                setHideDeleteBtn(false);
              }}
              onDragCancel={() => setActiveId(null)}
              autoScroll={true}
              measuring={({ active }) =>
                active?.id === activeId || activeId === null
              }
            >
              <SortableContext
                items={currentPhotosSelected}
                strategy={() => null}
                id="scrollableDivStep9"
                disabled={currentPhotosSelected.length < 2}
              >
                <div ref={refScroll} className="contains-photos | flrow">
                  {/* <div className="contains-photos | flrow"> */}
                  {currentPhotosSelected.map((photo, index) => (
                    <PhotosStep9
                      id={photo.id}
                      index={index}
                      photo={photo}
                      activeId={activeId}
                      setHideDeleteBtn={setHideDeleteBtn}
                      handleDeleteThisPick={() =>
                        handleDeleteThisPick(
                          index,
                          currentPhotosSelected,
                          setPhotosSelected
                        )
                      }
                      handlePutCover={() =>
                        handlePutCover(
                          index,
                          currentPhotosSelected,
                          setPhotosSelected
                        )
                      }
                    />
                  ))}
                  {/* </div> */}
                </div>
              </SortableContext>
              <DragOverlay>
                {activeId ? (
                  <DragOverlayItem
                    id={activeId}
                    currentPhotos={currentPhotosSelected}
                    indexFirstSelected={indexFirstSelected}
                    hotePage={false}
                  />
                ) : null}
              </DragOverlay>
            </DndContext>
            {currentPhotosSelected.length > 1 && (
              <button
                className="btn-delete-all hv-tooltip"
                onClick={() => {
                  setOpenDeleteModal(true);
                }}
                style={
                  openDeleteModal || hideDeleteBtn
                    ? {
                        pointerEvents: "none",
                        userSelect: "none",
                        opacity: "0",
                        touchAction: "none",
                      }
                    : {}
                }
              >
                <Trash color="var(--destructive-500)" />
                <Tooltip zIndex={2} minWidth={100} maxWidth={300}>
                  Supprimer toutes les photos
                </Tooltip>
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
const ModalDeletePhotos = ({
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteAllPhotos,
}) => {
  return (
    <ContainerPersoModal
      isOpen={openDeleteModal}
      toggle={setOpenDeleteModal}
      baseTop={true}
      className="modal-delete-all-photos"
      fitContent={true}
      title={"Confirmation"}
    >
      <div className="content | flcolm jcenter">
        <span className="p-just -c">
          Êtes-vous certain de vouloir supprimer toutes les photos ?
        </span>
        <div className="btns">
          <button
            onClick={() => {
              setOpenDeleteModal(false);
            }}
            className="btn btn-cancel"
          >
            Annuler
          </button>
          <button
            onClick={() => {
              handleDeleteAllPhotos();
              setOpenDeleteModal(false);
              NotificationService.addToQueue(
                0,
                "Photos supprimées avec succès"
              );
            }}
            className="btn btn-delete"
          >
            Supprimer
          </button>
        </div>
      </div>
    </ContainerPersoModal>
  );
};
