import { useEffect, useState } from "react";

const useCurrentHeight = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWindowHeight(window.innerHeight);
    // console.log("width", windowWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { windowHeight };
};

export default useCurrentHeight;
