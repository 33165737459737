import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronRightSimple, FlagIcon } from "../../images/icons/JsxIcons";
import { NotificationService } from "../../services/Notification.service";
import useCurrentWidth from "../../services/useCurrentWidth";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import { useStoreCurrencies } from "../../store/dataCurrencies";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreUserManager } from "../../store/UserManage";
import { UserPreferences } from "../../store/UserPreferences";
import "../../styles/components/mobileReservationPanel.css";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import ReservationMobilePanel from "../biggest/ReservationMobilePanel";
import SignalementModal from "./SignalementModal";
export default function MobileReservationPanel({
  residence = null,
  previewPrice,
  dateNotValid,
}) {
  const { windowWidth } = useCurrentWidth();
  const { t } = useTranslation();
  const [isOpen, toggle] = useState(false);
  const [toggeleReservation, setToggeleReservation] = useState(false);
  const language = UserPreferences((state) => state.language);
  const showDevise = UserPreferences((state) => state.showDevise);
  const sign = useStoreCurrencies((state) => state.sign);
  const userConnected = useStoreUserManager((state) => state.user.connected);
  const handleUpdateConnexionPanelState = useStorePageManager(
    (state) => state.handleUpdateConnexionPanelState
  );
  const handleUpdateDesactiveHeader = useStorePageManager(
    (state) => state.handleUpdateDesactiveHeader
  );
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );
  const handleOpenReservationPanel = () => {
    handleUpdateBodyOverflow(false);
    if (!userConnected) {
      handleUpdateConnexionPanelState(true);
      NotificationService.addToQueue(1, t("needConnectTxt"));
      return;
    }
    handleUpdateDesactiveHeader(true);
    setToggeleReservation(true);
  };
  const handleCloseReservationPanel = () => {
    handleUpdateBodyOverflow(true);
    handleUpdateDesactiveHeader(false);
    setToggeleReservation(false);
  };

  let averageP = 0;
  if (previewPrice?.averagePricePerNight) {
    averageP = previewPrice.averagePricePerNight;
  }
  if (previewPrice?.averagePricePerNight && dateNotValid) {
    averageP = previewPrice.averagePricePerNight + previewPrice.oneDayFee;
  }
  if (windowWidth > 550 || residence == null) return;
  return (
    <>
      <ContainerPersoModal
        className="signalement-modal-second"
        isOpen={isOpen}
        toggle={toggle}
      >
        <SignalementModal data={residence} />
      </ContainerPersoModal>

      <button onClick={() => toggle(true)} className="btn-signal | btn btw">
        <div className="left-side | flrow liner">
          <FlagIcon />
          <span>{t("signalAnnonceTxt")}</span>
        </div>
        <ChevronRightSimple />
      </button>

      <section className="mobile-reservation-panel">
        <div className="reservation-btn-bot | flrow liner btw">
          <div className="price-show | flcolm">
            {previewPrice ? (
              <>
                <span className="price">
                  {handleFormatToLocalString(averageP, language, sign)}{" "}
                  {showDevise}
                </span>
                <span className="price-note">{t("byNight")}</span>
              </>
            ) : (
              <>
                <span
                  style={{ width: "100px", height: "24px", marginBottom: 4 }}
                  className="skeleton span-skeleton"
                ></span>
                <span
                  style={{ width: "80px", height: "18px" }}
                  className="skeleton span-skeleton"
                ></span>
              </>
            )}
          </div>
          <button
            disabled={previewPrice == null}
            onClick={handleOpenReservationPanel}
            className="reservation-btn | btn pry-btn"
          >
            {t("btnAskReservationTxt")}
          </button>
        </div>
      </section>
      {toggeleReservation && (
        <ReservationMobilePanel
          residence={residence}
          handleCloseReservationPanel={handleCloseReservationPanel}
        />
      )}
    </>
  );
}
