import React, { useEffect, useState } from "react";
import { requestEndpoint } from "../../../data/requestEndpoint";
import pencilIcon from "../../../images/icons/edit-02.svg";
import { useGetDelayRequest } from "../../../services/useFetchAction";
import {
  findVersementMethod,
  handleHideSomeNumberInRib,
  handleSepareContact,
} from "../../../services/utils";
import { useStorePageManager } from "../../../store/PageManage";
import "../../../styles/components/versement-select.css";
import ContainerPersoModal from "../../biggest/ContainerPersoModal";
import ModifVersement from "./ModifVersement";
export default function ChangeVersementMethod({
  handleOutModal,
  handleUpdateUser,
  isReservationHote = false,
  t,
  setNoOpacity,
  reservation,
  currentPaiementInfo,
  directAccept,
}) {
  const [currentSelectedPaiementInfo, setCurrentSelectedPaiementInfo] =
    useState();
  const [userVersementMethodData, setUserVersementMethodData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [dataVersement, loadingVersement, errorVersement, fetchDataVersement] =
    useGetDelayRequest({
      route: requestEndpoint.getHoteVersement,
      delay: 0,
      loadingTime: 0,
    });
  useEffect(() => {
    fetchDataVersement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [tempVersementData, setTempVersementData] = useState([]);
  useEffect(() => {
    if (dataVersement?.data) {
      setUserVersementMethodData(dataVersement?.data);
    }
    if (!loadingVersement) {
      let tempVersement = JSON.parse(
        sessionStorage.getItem("temporaryVersement")
      );

      if (tempVersement) {
        setTempVersementData(tempVersement);
      }
    }
  }, [dataVersement, loadingVersement]);

  const [panelSelectOption, setPanelSelectOption] = useState(false);
  useEffect(() => {
    if (setNoOpacity) setNoOpacity(panelSelectOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelSelectOption]);
  const [forAddVersement, setForAddVersement] = useState(false);
  const [editPanelOpen, setEditPanelOpen] = useState(false);

  const [currentPaiementInfoSelect, setCurrentPaiementInfoSelect] = useState();

  const handleOpenEditPanel = (selected, force) => {
    setCurrentPaiementInfoSelect(
      findVersementMethod(selected?.payment_method_id)
    );
    setCurrentSelectedPaiementInfo(selected);

    // console.log(selected);
    setForAddVersement(false);
    if (isReservationHote && !force) {
      setPanelSelectOption(true);
      return;
    }
    setEditPanelOpen(!editPanelOpen);
  };
  const handleOpendEditPanelForAdd = () => {
    setForAddVersement(true);
    setCurrentSelectedPaiementInfo(null);
    setEditPanelOpen(!editPanelOpen);
  };
  return (
    <>
      {loadingVersement || errorVersement ? (
        <div className="maxwidth flcolm addgap8 center">
          <label>
            {" "}
            Actualisation de vos moyens de versement en cours, veuillez
            patienter...
          </label>
          <div class="lds-ripple-load">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : userVersementMethodData.length === 0 ? (
        <label>
          Vous ne disposez d'aucun moyen de versement, veuillez en ajouter en
          cliquant sur le bouton ci-dessous.
        </label>
      ) : isReservationHote ? (
        <div className="flcolm addgap8">
          {userVersementMethodData.length >= 1 && directAccept ? (
            <label>
              {/* Configurer un autre moyen de versement pour cette réservation ( */}
              <strong
                className="flash-me"
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                {/* {reservation?.residence?.name} */}
                Comment souhaitez vous recevoir votre argent ?
              </strong>
              {/* ) ? */}
            </label>
          ) : (
            <label>
              {/* Selectionner un moyen de versement pour cette réservation ( */}
              <strong
                className="flash-me"
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                {/* {reservation?.residence?.name} */}
                Comment souhaitez vous recevoir votre argent?
              </strong>
              {/* ) ? */}
            </label>
          )}
        </div>
      ) : userVersementMethodData.length === 1 ? (
        <label>Vous disposez d'un seul moyen de versement.</label>
      ) : (
        <label>
          Vous pouvez modifier le moyen de versement par défaut de vos
          réservations.
        </label>
      )}
      {!loadingVersement && !errorVersement && (
        <div className="maxwidth flcolm addgap8">
          {userVersementMethodData.map((item, index) => (
            <div className="maxwidth rlve">
              <button
                key={index + "paiement-btn"}
                style={{}}
                onClick={() => handleOpenEditPanel(item)}
                className={`btn flrow liner btn-select-defaut  ${
                  item.default ? "active" : ""
                }`}
              >
                <img
                  src={`${process.env.REACT_APP_API_URL}${
                    process.env.REACT_APP_LINK_PAIEMENT
                  }${findVersementMethod(item.payment_method_id)?.icon}`}
                  alt="paiement-method"
                />
                <div className="flcolm details">
                  <h3 className="flrow addgap8 liner">
                    {findVersementMethod(item.payment_method_id)?.label}
                    {item.temporary && (
                      <span
                        className="flash-me"
                        style={{
                          color: "var(--gray-500)",
                          fontSize: 12,
                          fontWeight: "600",
                        }}
                      >
                        (temporaire)
                      </span>
                    )}
                  </h3>
                  <p>
                    {item?.infos?.rib
                      ? handleHideSomeNumberInRib(item?.infos?.rib)
                      : `+(${item?.infos?.indicatif}) ${handleSepareContact(
                          item?.infos?.contact
                        )}`}
                  </p>
                </div>
                {item.default && !isReservationHote ? (
                  <span>Par défaut</span>
                ) : (
                  ""
                )}
              </button>
              {isReservationHote && (
                <button
                  style={{
                    padding: 8,
                    borderRadius: "50%",
                    position: "absolute",
                    top: "50%",
                    right: 20,
                    transform: "translateY(-50%)",
                    backgroundColor: "var(--gray-200)",
                  }}
                  onClick={() => handleOpenEditPanel(item, true)}
                  className="btn"
                >
                  <img src={pencilIcon} alt="pencil-icon" />
                </button>
              )}
            </div>
          ))}
          {tempVersementData.map((item, index) => (
            <div className="maxwidth rlve">
              <button
                key={index + "paiement-btn-temp"}
                style={{}}
                onClick={() => handleOpenEditPanel(item)}
                className={`btn flrow liner btn-select-defaut`}
              >
                <img
                  src={`${process.env.REACT_APP_API_URL}${
                    process.env.REACT_APP_LINK_PAIEMENT
                  }${findVersementMethod(item.payment_method_id)?.icon}`}
                  alt="paiement-method"
                />
                <div className="flcolm details">
                  <h3 className="flrow addgap8 liner">
                    {findVersementMethod(item.payment_method_id)?.label}
                    {item.temporary && (
                      <span
                        className="flash-me"
                        style={{
                          color: "var(--gray-500)",
                          fontSize: 12,
                          fontWeight: "600",
                        }}
                      >
                        (temporaire)
                      </span>
                    )}
                  </h3>
                  <p>
                    {item?.infos?.rib
                      ? handleHideSomeNumberInRib(item?.infos?.rib)
                      : `+(${item?.infos?.indicatif}) ${handleSepareContact(
                          item?.infos?.contact
                        )}`}
                  </p>
                </div>
              </button>
              {isReservationHote && (
                <button
                  style={{
                    padding: 8,
                    borderRadius: "50%",
                    position: "absolute",
                    top: "50%",
                    right: 20,
                    transform: "translateY(-50%)",
                    backgroundColor: "var(--gray-200)",
                  }}
                  onClick={() => handleOpenEditPanel(item, true)}
                  className="btn"
                >
                  <img src={pencilIcon} alt="pencil-icon" />
                </button>
              )}
            </div>
          ))}

          {!loadingVersement &&
            !errorVersement &&
            userVersementMethodData.length <= 9 && (
              <button
                onClick={handleOpendEditPanelForAdd}
                className="btn flrow liner btn-select-defaut btn-action"
              >
                <span
                  style={{
                    fontSize: 16,
                    margin: 0,
                    border: "none",
                  }}
                >
                  Ajouter un <strong style={{ margin: 0 }}>nouveau</strong>{" "}
                  moyen de versement
                </span>
              </button>
            )}
        </div>
      )}

      <ContainerPersoModal
        isOpen={editPanelOpen}
        toggle={setEditPanelOpen}
        title={
          forAddVersement
            ? "Ajouter un moyen de versement"
            : "Modifier le moyen de versement"
        }
        className="modal-edit-paiement"
        fullBlock={true}
        noOverflow={true}
        // bottomChildren={true}
        brum={true}
        overContent={true}
      >
        <ModifVersement
          t={t}
          data={currentSelectedPaiementInfo}
          userDataVersement={userVersementMethodData}
          setDataVersement={setUserVersementMethodData}
          setEditPanelOpen={setEditPanelOpen}
          fetchDataVersement={fetchDataVersement}
          typeEdit={!forAddVersement}
          inReservationPanel={isReservationHote}
          tempVersementData={tempVersementData}
          setTempVersementData={setTempVersementData}
        />
      </ContainerPersoModal>
      <ContainerPersoModal
        title="Confirmation du moyen de versement"
        isOpen={panelSelectOption}
        toggle={setPanelSelectOption}
        className="modal-edit-paiement confirmation just-modal"
        fullBlock={true}
        noOverflow={true}
        brum={true}
      >
        <div
          style={{
            width: 250,
          }}
          className="flcolm addgap8"
        >
          <span className="just-t">
            Après confirmation, vous ne pourrez plus modifier le moyen de
            versement pour cette réservation.
          </span>
          <span
            style={{
              fontWeight: 500,
            }}
            className="just-t"
          >
            {currentPaiementInfoSelect && (
              <strong>{currentPaiementInfoSelect.label} </strong>
            )}
            {"-> "}
            {currentPaiementInfoSelect &&
            currentPaiementInfoSelect?.id === 7 ? (
              <>
                gains versés entre{" "}
                <strong className="flash-me">7 et 14 jours</strong>{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    textUnderlineOffset: "2px",
                  }}
                >
                  {" "}
                  après le début du séjour du client{" "}
                </span>
              </>
            ) : (
              <>
                gains versés entre{" "}
                <strong className="flash-me">2 et 4 jours</strong>
                <span
                  style={{
                    textDecoration: "underline",
                    textUnderlineOffset: "2px",
                  }}
                >
                  {" "}
                  après le début du séjour du client
                </span>
              </>
            )}
            .
          </span>
          <button
            onClick={() => {
              useStorePageManager.getState().handleUpdateBlockScreen(true);
              handleOutModal(currentSelectedPaiementInfo, true);
            }}
            style={{
              borderRadius: 8,
            }}
            className="btn pry-btn"
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              Confirmer
            </span>
          </button>
        </div>
      </ContainerPersoModal>
    </>
  );
}
