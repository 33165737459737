/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  ArrayParam,
  BooleanParam,
  DateParam,
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { DetectVisibleRef } from "../../services/useCurrentScrollY";
import { useGetDelayRequestStore } from "../../services/useFetchAction";
import {
  checkDateJust,
  clearEmptyParams,
  smoothScrollUp,
} from "../../services/utils";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreManager } from "../../store/StoreManage";
import "../../styles/components/storeResidenceList.css";
import CardResidence from "../cells/CardResidence";
import CardResidenceSkeleton from "../cells/CardResidenceSkeleton";
import Footer from "./Footer";
import StoreMap from "./StoreMap";

const numberSkeleton = 9;
export default function StoreResidenceList() {
  const [query, setQuery] = useQueryParams({
    destination: withDefault(StringParam, null),
    arrivalDate: withDefault(DateParam, null),
    departureDate: withDefault(DateParam, null),
    numPeople: withDefault(NumberParam, null),
    minprice: withDefault(NumberParam, null),
    maxprice: withDefault(NumberParam, null),
    typeIds: withDefault(ArrayParam, []),
    assetId: withDefault(ArrayParam, []),
    roomsId: withDefault(ArrayParam, []),
    roomsNum: withDefault(ArrayParam, []),
    activitiesId: withDefault(ArrayParam, []),
    occupation: withDefault(StringParam, null),
    mapOpen: withDefault(BooleanParam, false),
    centerMap: withDefault(ObjectParam, null),
    mapZoom: withDefault(NumberParam, null),
    mapBounds: withDefault(ObjectParam, null),
    currentMarkerId: withDefault(NumberParam, null),
    page: withDefault(NumberParam, 1),
  });
  const [mapStore, setMapStore] = useState(null);
  const searchStoreParams = useStoreManager((state) => state.searchStoreParams);
  const refInfiniteLoad = useRef();
  const [waitBeforeDetectRef, setWaitBeforeDetect] = useState(false);
  const [currentStoreResidence, setStoreResidence] = useState([]);
  const [posSearch, setPosSearch] = useState(0);
  const [maxPage, setMaxPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [timeOutWaiter, setTimeOutWaiter] = useState(null);
  const [firstLoadPageCount, setFirstLoadPageCount] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [data, loading, error, fetchData] = useGetDelayRequestStore({
    log: false,
    delay: 0,
    page: currentPage,
    query: query,
    setQuery: setQuery,
  });
  const { isVisibleRef } = DetectVisibleRef(refInfiniteLoad, 35);
  const storeParameters = useStoreManager((state) => state.storeParameters);
  const handleUpdateStoreParameters = useStoreManager(
    (state) => state.handleUpdateStoreParameters
  );
  const lastTouchedResidenceId = useStorePageManager(
    (state) => state.lastResidenceTouched
  );
  const handleUpdateRefreshStore = useStoreManager(
    (state) => state.handleUpdateRefreshStore
  );

  const [firstLoad, setFirstLoad] = useState(true);
  //fonction pour clear tout les parametres de recherche de l'url qui sont null ou undefined ou vide

  const refreshStoreSearch = () => {
    const currentData = useStoreManager.getState().searchStoreParams;
    try {
      const roomsIds = currentData.roomIds.map((data) => data.roomId);
      const roomsNumbs = currentData.roomIds.map((data) => data.quantity);
      setQuery(
        {
          destination: currentData.location,
          arrivalDate: currentData.startDate,
          departureDate: currentData.endDate,
          numPeople: currentData.numPeople,
          minprice: currentData.minPrice <= 0 ? null : currentData.minPrice,
          maxprice:
            currentData.maxPrice >= 90000000 ? null : currentData.maxPrice,
          typeIds: currentData.typeIds,
          assetId: currentData.assetIds,
          roomsId: roomsIds,
          roomsNum: roomsNumbs,
          activitiesId: currentData.activitiesIds,
          occupation: currentData.occupation,
        },
        "replaceIn"
      );

      clearEmptyParams();
    } catch (error) {}

    handleUpdateFilterBeforeToGo();
    const isIosIphone = navigator.userAgent.match(/(iPod|iPhone|iPad)/);
    const navigatorIsSafari = navigator.userAgent.indexOf("Safari") != -1;
    if (
      isIosIphone &&
      navigatorIsSafari &&
      window.scrollY > 0 &&
      query.mapOpen &&
      window.innerWidth <= 550
    )
      return;
    setTimeout(() => {
      smoothScrollUp(true, posSearch);
    }, 75);
  };

  const handleFirstUpdateStore = async () => {
    // console.log("handleFirstUpdateStore");
    const rooms = query.roomsId.map((data, index) => {
      return {
        roomId: parseInt(data),
        quantity: parseInt(query.roomsNum[index]),
      };
    });
    const pageToGo = query.page ? (query.page < 1 ? 1 : query.page) : 1;

    await handleUpdateStoreParameters(
      {
        ...storeParameters,
        destination: query.destination,
        arriveeDate: query.arrivalDate ? new Date(query.arrivalDate) : null,
        departDate: query.departureDate ? new Date(query.departureDate) : null,
        personnes: query.numPeople
          ? query.numPeople < 0
            ? null
            : query.numPeople
          : null,
        minPrice: query.minprice
          ? query.minprice < 0
            ? 0
            : query.minprice
          : 0,
        maxPrice: query.maxprice ? query.maxprice : 90000000,
        chambre: null,
        salleDeBain: null,
        occupation: query.occupation
          ? ["Full", "Partial"].includes(query.occupation)
            ? query.occupation
            : null
          : null,
        commodites: query.assetId.map((data) => parseInt(data)),
        roomIds: rooms,
        typeIds: query.typeIds.map((data) => parseInt(data)),
        activitiesIds: query.activitiesId.map((data) => parseInt(data)),
        page: pageToGo,
        loadPreviousPage: pageToGo > 1 ? firstLoadPageCount : false,
      },
      "StoreResidenceList"
    );
    setFirstLoadPageCount(false);
    setCurrentPage(pageToGo);
    handleUpdateRefreshStore(refreshStoreSearch);
    setFirstLoad(false);
    if (timeOutWaiter) clearTimeout(timeOutWaiter);
    useStoreManager.getState().handleUpdateSearchStoreParameters();
    fetchData();
  };
  useEffect(() => {
    if (!searchStoreParams) return;
    if (!handleUpdateRefreshStore) return;
    if (!firstLoad) return;
    handleFirstUpdateStore();
  }, [handleUpdateStoreParameters, handleUpdateRefreshStore]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFetchDataLogic = () => {
    setWaitBeforeDetect(false);
    setPosSearch(window.scrollY);
    if (timeOutWaiter) clearTimeout(timeOutWaiter);
    fetchData();
  };

  const handleUpdatePageAndFetchData = () => {
    setCurrentPage(1);
    setStoreResidence([]);
    setWaitBeforeDetect(false);
  };

  const handleUpdateFilterBeforeToGo = async () => {
    await handleUpdatePageAndFetchData();
    handleFetchDataLogic();
  };
  const [firstFetch, setFirstFetch] = useState(true);
  const clearDuplicateResidence = (table) => {
    let newTable = [];
    table.map((residence) => {
      if (newTable.findIndex((data) => data.id == residence.id) == -1) {
        newTable.push(residence);
      }
    });
    return newTable;
  };
  useEffect(() => {
    if (loading) return;
    if (!data) return;
    // console.log("data", data);
    let dataRecieve = data.data;
    let nextPage = dataRecieve.nextPage;
    let residences = [];
    if (dataRecieve.residences?.length > 0) residences = dataRecieve.residences;
    if (Array.isArray(dataRecieve)) residences = dataRecieve;
    let newResidenceTable = [];
    newResidenceTable = currentStoreResidence.slice();
    residences.map((data) => newResidenceTable.push(data));
    setMaxPage(nextPage);
    if (nextPage) {
      setCurrentPage(currentPage + 1);
    }
    setStoreResidence(clearDuplicateResidence(newResidenceTable));
    if (!query.mapOpen) setLoadingPage(false);
  }, [loading, data]);

  const handleUpdateStoreBeforeToGo = async () => {
    // console.log("handleUpdateStoreBeforeToGo");
    // console.log("searchStoreParams", searchStoreParams);
    setQuery({ ...query, page: maxPage }, "replaceIn");
    let newParameters = {
      ...searchStoreParams,
      page: maxPage,
      loadPreviousPage: maxPage > 1 ? firstLoadPageCount : false,
    };
    await handleUpdateStoreParameters(newParameters, "StoreResidenceList 2");
    let newSearchStoreParams = {
      location: newParameters.location,
      startDate: checkDateJust(newParameters.startDate)
        ? newParameters.startDate
        : null,
      endDate: checkDateJust(newParameters.endDate)
        ? newParameters.endDate
        : null,
      numPeople: newParameters.numPeople == 0 ? null : newParameters.numPeople,
      minPrice: newParameters.minPrice,
      maxPrice: newParameters.maxPrice,
      typeIds: newParameters.typeIds,
      assetIds: newParameters.assetIds,
      roomIds: newParameters.roomIds,
      activitiesIds: newParameters.activitiesIds,
      chambre: null,
      salleDeBain: null,
      occupation: newParameters.occupation,
      page: newParameters.page,
      lng: newParameters.lng,
      lat: newParameters.lat,
      viewPort: newParameters.viewPort,
    };
    await useStoreManager
      .getState()
      .handleUpdateConfirmNewSearchStoreParameters(newSearchStoreParams);
    // console.log("newSearchStoreParams", newSearchStoreParams);
    if (timeOutWaiter) clearTimeout(timeOutWaiter);
    fetchData();
  };
  useEffect(() => {
    if (firstFetch) {
      setFirstFetch(false);
      return;
    }
    handleUpdateFilterBeforeToGo();
    setTimeout(() => {
      smoothScrollUp(true, posSearch);
    }, 75);
  }, []);
  useEffect(() => {
    if (loading) return;
    if (maxPage == 0 || maxPage == null) return;
    if (error) {
      console.log("error", error);
      return;
    }
    if (waitBeforeDetectRef == false) return;
  }, [isVisibleRef, currentPage, error, waitBeforeDetectRef]);

  const returnFooter = () => {
    if (loading == false && error == null && currentStoreResidence.length == 0)
      return <Footer extend={true} />;
    if (loading == false && !maxPage) return <Footer extend={true} />;
    if (
      loading == false &&
      maxPage &&
      error == null &&
      currentStoreResidence.length > 0
    )
      return <Footer extend={true} />;

    return <></>;
  };

  const [waitBeforeShowEndResult, setWaitBeforeShowEndResult] = useState(false);
  //clear all duplicate residence in the store with the same id

  useEffect(() => {
    if (
      loading == false &&
      !error &&
      currentStoreResidence.length == 0 &&
      !maxPage
    ) {
      if (timeOutWaiter) clearTimeout(timeOutWaiter);
      let timer = setTimeout(() => {
        setWaitBeforeShowEndResult(true);
      }, 100);
      setTimeOutWaiter(timer);
    } else {
      clearTimeout(timeOutWaiter);
      setWaitBeforeShowEndResult(false);
    }
    return () => {
      clearTimeout(timeOutWaiter);
    };
  }, [currentStoreResidence, loading, error, maxPage]);

  useEffect(() => {}, [loading]);
  // const storeMap = useStorePageManager((state) => state.storeMap);

  return (
    <>
      <section className="residence-store-list | max-container max-container-store flcolm rlve">
        <div className="content-residence | maxwidth flrow rlve">
          {currentStoreResidence.map((residence, index) => {
            return (
              <React.Fragment key={residence.id}>
                <CardResidence
                  key={residence.id}
                  residence={residence}
                  lastIdTouched={lastTouchedResidenceId}
                />
              </React.Fragment>
            );
          })}

          {(loading == true || loadingPage || error) &&
            Array.from({ length: numberSkeleton }).map((_, index) => {
              return (
                <React.Fragment key={"skeleton" + index}>
                  <CardResidenceSkeleton />
                </React.Fragment>
              );
            })}
          {!waitBeforeShowEndResult &&
            loading == false &&
            !error &&
            currentStoreResidence.length == 0 &&
            !maxPage &&
            Array.from({ length: numberSkeleton }).map((_, index) => {
              return (
                <React.Fragment key={"skeleton" + index}>
                  <CardResidenceSkeleton />
                </React.Fragment>
              );
            })}
          {currentStoreResidence.length > 0 &&
            loading == false &&
            maxPage == null && (
              <p style={{ width: "100%", flexShrink: 0 }} className="hh6bd-txt">
                {/* Fin des résultats ! */}
              </p>
            )}
          <div
            ref={refInfiniteLoad}
            style={{
              display: !loading && maxPage ? "block" : "none",
              height: "auto",
              width: "100%",
            }}
            className="detect-div"
          >
            {loading == false &&
              maxPage &&
              !error &&
              !waitBeforeShowEndResult && (
                <button
                  onClick={() => handleUpdateStoreBeforeToGo()}
                  style={{
                    margin: "0 auto",
                  }}
                  className="btn pry-btn"
                >
                  <span
                    style={{
                      fontWeight: 600,
                    }}
                    className="pmm-txt"
                  >
                    Voir plus
                  </span>
                </button>
              )}
          </div>
        </div>

        {waitBeforeShowEndResult &&
          currentStoreResidence.length == 0 &&
          loading == false &&
          loadingPage == false && (
            <div className="no-residence | flcolm">
              <p className="hh6bd-txt">Fin des résultats !</p>
              <span className="pmm-txt">
                N'hésitez pas à essayer d'autres combinaisons de filtres afin de
                trouver la meilleure résidence pour vous.
              </span>
              <span
                onClick={() => {
                  setQuery({ mapOpen: true }, "replaceIn");
                  handleUpdateStoreParameters({
                    ...storeParameters,
                    mapOpen: true,
                  });
                  useStorePageManager.getState().handleUdpateStoreMap(true);
                  useStorePageManager
                    .getState()
                    .handleUpdateBodyOverflow(false);
                }}
                style={{
                  textDecoration: "underline",
                  textUnderlineOffset: 2,
                  cursor: "pointer",
                }}
                className="pmm-txt"
              >
                ou essayer via la carte.
              </span>
            </div>
          )}
      </section>
      {returnFooter()}
      <StoreMap
        refreshStoreSearch={refreshStoreSearch}
        dataResidence={data}
        loading={loading}
        firstLoad={false}
        mapStore={mapStore}
        setMapStore={setMapStore}
        setLoadingPage={setLoadingPage}
      />
    </>
  );
}
