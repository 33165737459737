/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import iconTooltip from "../../images/icons/tooltip.png";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import { UserPreferences } from "../../store/UserPreferences";
import { useStoreCurrencies } from "../../store/dataCurrencies";
import "../../styles/components/tooltip-recap.css";
function TooltipRecap({ data, state, inverse = false }) {
  const showDevise = UserPreferences((state) => state.showDevise);
  const language = UserPreferences((state) => state.language);
  const sign = useStoreCurrencies((state) => state.sign);
  const [panelOpen, setPanelOpen] = useState(false);
  const [jsxToShow, setJsxToShow] = useState([]);
  const manageShowLines = () => {
    const rowLines = data.map((item) => {
      const total = item.count * item.price;
      if (item.label.includes("%")) {
        return (
          <div className="flrow line">
            <p className="flrow">
              <strong>{item.label}</strong>
            </p>
            <strong>
              -{handleFormatToLocalString(total, language, sign)}{" "}
              {showDevise ? showDevise : "XOF"}
            </strong>
          </div>
        );
      } else {
        return (
          <div className="flrow line">
            <p className="flrow">
              <strong>{item.count}</strong> X{" "}
              <strong>
                {handleFormatToLocalString(item.price, language, sign)}{" "}
                {showDevise ? showDevise : "XOF"}
              </strong>
              <span> ({item.label})</span>
            </p>
            <strong>
              {handleFormatToLocalString(total, language, sign)}{" "}
              {showDevise ? showDevise : "XOF"}
            </strong>
          </div>
        );
      }
    });
    setJsxToShow(rowLines);
  };
  useEffect(() => {
    data && typeof data === "object" && manageShowLines();
  }, [data]);

  const handleButtonSwitch = () => {
    //check if is pc, then do nothing
    const ismobile = window.navigator.userAgent.match(
      /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
    );
    if (!ismobile || window.innerWidth > 768) {
      return;
    }
    setPanelOpen(!panelOpen);
  };
  if (!data || state === "NORMAL") return null;
  return (
    <div
      onMouseEnter={() => setPanelOpen(true)}
      onMouseLeave={() => setPanelOpen(false)}
      onClick={handleButtonSwitch}
      className="tooltip-recap"
    >
      <img src={iconTooltip} alt="icon tooltip" />
      <AnimatePresence mode="sync">
        {panelOpen && data && (
          <motion.div
            initial={{ opacity: 0, y: inverse ? 5 : -5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: inverse ? 10 : -10 }}
            className={`recap-panel flcolm ${inverse ? "inverse" : ""}`}
          >
            {jsxToShow}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default TooltipRecap;
