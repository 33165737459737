import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../../data/requestEndpoint";
import {
  CashIcon,
  Mail,
  Marker,
  Pass,
  Tel,
} from "../../../images/icons/JsxIcons";
import pencilIcon from "../../../images/icons/edit-02.svg";
import { useGetDelayRequest } from "../../../services/useFetchAction";
import ContainerPersoModal from "../../biggest/ContainerPersoModal";
import ChangeHabitation from "./ChangeHabitation";
import ChangeMail from "./ChangeMail";
import ChangePassword from "./ChangePassword";
import ChangeTelephone from "./ChangeTelephone";
import ChangeVersementMethod from "./ChangeVersementMethod";

export default function PersonalInfo({
  handleUpdateUser,
  userData,
  handleUpdateBlockScreen,
  setSaveTokenWaiting,
  setTelNumberWaiting,
  telNumber,
  setSaveTokenWaitingMail,
  setMailNumberWaiting,
  emailNew,
  setIndicatifNumber,
  indicatifNumber,
}) {
  const [openModalPassModal, setOpenPassModal] = useState(false);
  const [openModalMailEdit, setOpenMailEdit] = useState(false);
  const [openModalTelModal, setOpenTelModal] = useState(false);
  const [openModalHabitation, setOpenHabitationModal] = useState(false);
  const { t } = useTranslation();

  const [chooseVersementMethod, setChooseVersementMethod] = useState(false);

  const [paiementIsVisible, setPaiementIsVisible] = useState(false);
  const [listPaiementMethod, setListPaiementMethod] = useState([]);
  const [dataPaiement, loadingPaiement, errorPaiement, fetchData] =
    useGetDelayRequest({
      route: requestEndpoint.payment_methods_versement,
      delay: 0,
      loadingTime: 0,
    });
  useEffect(() => {
    if (loadingPaiement) return;
    if (dataPaiement) {
      let list = [];
      dataPaiement.data.map((item) => {
        list.push({
          ...item,
          selected: false,
        });
      });
      setListPaiementMethod(list);
    }
  }, [dataPaiement, loadingPaiement]);
  useEffect(() => {
    fetchData();
  }, []);
  const findMypaiementMethod = () => {
    let myPaiement = null;
    listPaiementMethod.map((item) => {
      if (item.id == userData.payment_method_id) myPaiement = item;
    });
    return myPaiement;
  };
  let valueNumber = "";
  valueNumber = telNumber
    ? `${telNumber} ${userData.contactChecked ? "" : "(otp...)"}`
    : userData.contact
    ? userData.contact.length > 0
      ? userData.contact
      : t("noData")
    : t("noData");

  //verifier si valueNumber est un numero de telephone, si oui on verifie si + n'est present on l'ajoute
  if (valueNumber.length > 0) {
    //verifier si valueNumber est un numero de telephone
    let regex = new RegExp(/^[0-9]+$/);
    if (regex.test(valueNumber)) {
      // if (valueNumber[0] != "+") {
      //   valueNumber = "+" + valueNumber;
      // }
    }
  }
  return (
    <div className="info-personnelle | flcolm">
      <label>{t("informationPersonnelleTxt")}</label>
      <div className="separator"></div>
      <div className="info-perso | flcolm ">
        <div className="input-select mail-adress | flrow liner">
          <div className="circle-icone rlve">
            <Mail stroke="var(--primary-500)" />
          </div>
          <div className="right-content | flcolm">
            <label>{t("placeHolderMail")}</label>

            <span
              className={`${emailNew && !userData.emailVerified && "flash-me"}`}
            >
              {emailNew
                ? `${emailNew} ${userData.emailVerified ? "" : "(otp...)"}`
                : userData.mail
                ? userData.mail.length > 0
                  ? userData.mail
                  : t("noData")
                : t("noData")}
            </span>
            {userData.isSocial && (
              <label
                style={{
                  color: "var(--primary-500)",
                }}
              >
                compte ( {userData.typeSocial} ) lié
              </label>
            )}
            {/* <span>{userData.mail}</span> */}
          </div>
          <button
            style={
              userData.isSocial
                ? { background: "var(--gray-200)", opacity: 0.4 }
                : {}
            }
            onClick={() => setOpenMailEdit(true)}
            className="btn"
          >
            <img src={pencilIcon} alt="pencil-icon" />
          </button>
        </div>
        {userData.isSocial == false && (
          <div className="input-select mail-adress | flrow liner">
            <div className="circle-icone rlve">
              <Pass stroke="var(--primary-500)" />
            </div>
            <div className="right-content | flcolm">
              <label>{t("normalPasswordPlaceholder")}</label>
              <span>••••••••••••••••••••</span>
            </div>
            <button onClick={() => setOpenPassModal(true)} className="btn">
              <img src={pencilIcon} alt="pencil-icon" />
            </button>
          </div>
        )}

        <div className="input-select tel-number | flrow liner">
          <div className="circle-icone rlve">
            <Tel stroke="var(--primary-500)" />
          </div>
          <div className="right-content | flcolm">
            <label>{t("numberTel")}</label>
            <span
              className={`${
                telNumber && !userData.contactChecked && "flash-me"
              }`}
            >
              {valueNumber}
            </span>
          </div>
          <button
            style={
              telNumber && !userData.contactChecked
                ? { opacity: 0.5, pointerEvents: "none", userSelect: "none" }
                : {}
            }
            onClick={() => setOpenTelModal(true)}
            className="btn"
          >
            <img src={pencilIcon} alt="pencil-icon" />
          </button>
        </div>
        <div className="input-select tel-number | flrow liner">
          <div className="circle-icone rlve">
            <Marker stroke="var(--primary-500)" />
          </div>
          <div className="right-content | flcolm">
            <label>{t("habitationPlaceholderTxt")}</label>
            <span>
              {userData.location
                ? userData.location.length > 0
                  ? userData.location
                  : t("noData")
                : t("noData")}
            </span>
          </div>
          <button onClick={() => setOpenHabitationModal(true)} className="btn">
            <img src={pencilIcon} alt="pencil-icon" />
          </button>
        </div>
        {userData.enableHost && (
          <div className="input-select tel-number | flrow liner">
            <div className="circle-icone rlve">
              <CashIcon stroke="var(--primary-500)" />
            </div>
            <div className="right-content | flcolm">
              <label>{t("Moyen de versement (hote)")}</label>
              <div className="flrow liner addgap8">
                {findMypaiementMethod() ? (
                  <>
                    <img
                      style={{
                        width: 36,
                        height: "auto",
                        objectFit: "contain",
                      }}
                      src={`${process.env.REACT_APP_API_URL}${
                        process.env.REACT_APP_LINK_PAIEMENT
                      }${findMypaiementMethod().icon}`}
                      alt="paiement-method"
                    />
                    <span>{findMypaiementMethod().label}</span>
                  </>
                ) : (
                  <>
                    <span>Aucun moyen de versement choisi</span>
                  </>
                )}
              </div>
            </div>
            <button
              onClick={() => setChooseVersementMethod(true)}
              className="btn"
            >
              <img src={pencilIcon} alt="pencil-icon" />
            </button>
          </div>
        )}

        <ContainerPersoModal
          bottomChildren={true}
          baseTop={true}
          title={t("changementPassTitle")}
          isOpen={openModalPassModal}
          toggle={setOpenPassModal}
          className="piece-send-modal"
        >
          <div className="modal-change-edit | flcolm jcenter liner">
            <ChangePassword
              handleOutModal={setOpenPassModal}
              handleUpdateBlockScreen={handleUpdateBlockScreen}
            />
          </div>
        </ContainerPersoModal>
        <ContainerPersoModal
          bottomChildren={true}
          baseTop={true}
          title={t("changeNumeroTitle")}
          isOpen={openModalTelModal}
          toggle={setOpenTelModal}
          className="piece-send-modal just-modal visible-overflow-modal"
        >
          <div className="modal-change-edit | flcolm jcenter liner">
            <ChangeTelephone
              setSaveTokenWaiting={setSaveTokenWaiting}
              setTelNumberWaiting={setTelNumberWaiting}
              setIndicatifNumber={setIndicatifNumber}
              indicatifNumber={indicatifNumber}
              handleUpdateUser={handleUpdateUser}
              handleOutModal={setOpenTelModal}
              userData={userData}
              handleUpdateBlockScreen={handleUpdateBlockScreen}
            />
          </div>
        </ContainerPersoModal>
        <ContainerPersoModal
          bottomChildren={true}
          baseTop={true}
          title={t("localitySwitchTxt")}
          isOpen={openModalHabitation}
          toggle={setOpenHabitationModal}
          className="piece-send-modal just-modal"
        >
          <div className="modal-change-edit | flcolm jcenter liner">
            <ChangeHabitation
              handleUpdateUser={handleUpdateUser}
              handleOutModal={setOpenHabitationModal}
              userData={userData}
              handleUpdateBlockScreen={handleUpdateBlockScreen}
            />
          </div>
        </ContainerPersoModal>
        <ContainerPersoModal
          bottomChildren={true}
          baseTop={true}
          title={"Changer d'adresse mail"}
          isOpen={openModalMailEdit}
          toggle={setOpenMailEdit}
          className="piece-send-modal just-modal"
        >
          <div className="modal-change-edit | flcolm jcenter liner">
            <ChangeMail
              setSaveTokenWaitingMail={setSaveTokenWaitingMail}
              setMailNumberWaiting={setMailNumberWaiting}
              handleUpdateUser={handleUpdateUser}
              handleOutModal={setOpenMailEdit}
              userData={userData}
              handleUpdateBlockScreen={handleUpdateBlockScreen}
            />
          </div>
        </ContainerPersoModal>
        <ContainerPersoModal
          bottomChildren={true}
          baseTop={true}
          title={"Vos moyens de versement"}
          isOpen={chooseVersementMethod}
          toggle={setChooseVersementMethod}
          fullBlock={true}
          className="piece-send-modal just-modal"
        >
          <div className="modal-change-edit | flcolm jcenter liner">
            <ChangeVersementMethod
              isReservationHote={false}
              handleUpdateUser={handleUpdateUser}
              handleOutModal={setChooseVersementMethod}
              userData={userData}
              t={t}
            />
          </div>
        </ContainerPersoModal>
      </div>
    </div>
  );
}
