import greenCircle from "../images/icons/green-check_circle.png";
import greenHallow from "../images/icons/greenToast.svg";

import yellowCircle from "../images/icons/yellow-check_circle.png";
import yellowHallow from "../images/icons/yellowToast.svg";

import redCircle from "../images/icons/red-check_circle.png";
import redHallow from "../images/icons/redToast.svg";

export const NotificationService = (() => {
  let notificationQueue = [];
  let isNotifying = false;
  let currentMessage = "";
  let currentType = 0;
  const addToQueue = (type = 0, message) => {
    // if (notificationQueue.length > 0) {
    //   if (notificationQueue[notificationQueue.length - 1] !== message) {
    //     notificationQueue.push({ type, message });
    //   }
    // } else {
    //   notificationQueue.push({ type, message });
    // }

    // if (!isNotifying) {
    //   showNextNotification();
    // }
    let messageExists = notificationQueue.some(
      (item) => item.message === message
    );

    if (currentMessage === message && currentType === type) {
      messageExists = true;
    }

    // Si le message n'existe pas, l'ajoute à la file d'attente
    if (!messageExists) {
      currentType = type;
      currentMessage = message;
      notificationQueue.push({ type, message });

      // Démarre l'affichage des notifications si ce n'est pas déjà le cas
      if (!isNotifying) {
        showNextNotification();
      }
    }
  };

  const showNextNotification = () => {
    if (notificationQueue.length > 0) {
      const currNotif = notificationQueue.shift();
      isNotifying = true;

      const notificationDiv = document.createElement("div");
      notificationDiv.className = "notification-system";

      const innerDiv = document.createElement("div");
      innerDiv.className = "notification | flrow center rlve";

      const imgHallow = document.createElement("img");
      imgHallow.alt = "hallow-icon";
      imgHallow.src =
        currNotif.type == 0
          ? greenHallow
          : currNotif.type == 1
          ? yellowHallow
          : redHallow;
      imgHallow.className = "hallow";

      const imgCheck = document.createElement("img");
      imgCheck.alt = "check-icon";
      imgCheck.src =
        currNotif.type == 0
          ? greenCircle
          : currNotif.type == 1
          ? yellowCircle
          : redCircle;
      imgCheck.className = "check";

      const p = document.createElement("p");
      p.textContent = currNotif.message;

      innerDiv.appendChild(imgHallow);
      innerDiv.appendChild(imgCheck);
      innerDiv.appendChild(p);
      notificationDiv.appendChild(innerDiv);

      document.body.appendChild(notificationDiv);

      setTimeout(() => {
        document.body.removeChild(notificationDiv);
        isNotifying = false;
        currentMessage = "";
        currentType = 0;
        showNextNotification();
      }, 4500);
    }
  };

  return {
    addToQueue,
  };
})();
