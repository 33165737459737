import "../../styles/components/howWorkSection.css";
import { BankNote, Home, SearchLg } from "../../images/icons/JsxIcons";
import { VectorHowResidenceLeft } from "../../images/icons/JsxDesign";
import { useTranslation } from "react-i18next";

export default function HowWork() {
  const { t } = useTranslation();
  return (
    <section className="paiement second-how | flcolm liner max-container rlve">
      <div className="contains | flcolm liner">
        <div className="title | flcolm liner addgap8">
          <h2>{t("howWorkLabel")}</h2>
          <div>
            <p>{t("howWorkP")}</p>
          </div>
        </div>
        <div className="contents-how | flrow btw">
          <div className="card-how | flcolm">
            <span className="icon | flex liner">
              <SearchLg color="#A273FF" />
            </span>
            <div className="content | flcolm addgap16">
              <h3>{t("searchResidenceTitle")}</h3>
              <p>{t("searchResidenceP")}</p>
            </div>
          </div>
          <div className="card-how | flcolm">
            <span className="icon | flex liner">
              <BankNote color="#A273FF" />
            </span>
            <div className="content | flcolm addgap16">
              <h3>{t("comparePriceTitle")}</h3>
              <p>{t("comparePriceP")}</p>
            </div>
          </div>
          <div className="card-how | flcolm">
            <span className="icon | flex liner">
              <Home color="#A273FF" />
            </span>
            <div className="content | flcolm addgap16">
              <h3>{t("reserveResidenceTitle")}</h3>
              <p>{t("reserveResidenceP")}</p>
            </div>
          </div>
        </div>
      </div>
      <VectorHowResidenceLeft className="vector-left-how desktop-vector | design" />
    </section>
  );
}
