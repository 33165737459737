import React, { useEffect, useState } from "react";
import "../../styles/components/addResidencePart/step15.css";
import { Check, XClose } from "../../images/icons/JsxIcons";
import checkIcon from "../../images/icons/check.svg";
import { useTranslation } from "react-i18next";
import { useStoreShareFunction } from "../../store/StoreShareFunction";
const currentStep = 15;
export default function Step15Reglement({
  step,
  handleBlockBtn,
  events,
  setEvents,
  smoke,
  setSmoke,
  animals,
  setAnimals,
  childs,
  setChilds,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    if (currentStep == step) handleBlockBtn(false);
  }, [step, events, smoke, animals]);

  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step) {
      document.activeElement.blur();

      useStoreShareFunction.getState().nextPage();
      if (!events) useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [step, events, smoke, animals]);
  return (
    <section
      className={`step-1-annonce step-15 ${
        currentStep == step ? "visible" : ""
      } 
      ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input input-resi | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            <label>{t("step15Title")}</label>
            <p>{t("step15SubTitle")}</p>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm jcenter liner">
          <div className="contains-input | flcolm jcenter liner">
            <div className="evenement-type | flcolm">
              <div className="contains-select | liner flrow">
                <div className="left-side | flcolm">
                  <p>{t("titleLabel1")}</p>
                  <span>{t("paragraphL1")}</span>
                </div>
                <div className="btn-actions | flrow liner">
                  <button
                    style={{
                      background:
                        events == 0 ? "var(--destructive-400)" : "none",
                    }}
                    onClick={() => setEvents(0)}
                    className="btn"
                  >
                    <XClose
                      color={events == 0 ? "var(--shade-white)" : "#6B7280"}
                    />
                  </button>
                  <button
                    style={{
                      background: events == 1 ? "var(--primary-500)" : "none",
                    }}
                    onClick={() => setEvents(1)}
                    className="btn"
                  >
                    <Check
                      color={events == 1 ? "var(--shade-white)" : "#6B7280"}
                    />
                  </button>
                </div>
              </div>
              <div className="contains-select | liner flrow">
                <div className="left-side | flcolm">
                  <p>{t("titleLabel2")}</p>
                </div>
                <div className="btn-actions | flrow liner">
                  <button
                    style={{
                      background:
                        smoke == 0 ? "var(--destructive-400)" : "none",
                    }}
                    onClick={() => setSmoke(0)}
                    className="btn"
                  >
                    <XClose
                      color={smoke == 0 ? "var(--shade-white)" : "#6B7280"}
                    />
                  </button>
                  <button
                    style={{
                      background: smoke == 1 ? "var(--primary-500)" : "none",
                    }}
                    onClick={() => setSmoke(1)}
                    className="btn"
                  >
                    <Check
                      color={smoke == 1 ? "var(--shade-white)" : "#6B7280"}
                    />
                  </button>
                </div>
              </div>
              <div className="contains-select | liner flrow">
                <div className="left-side | flcolm">
                  <p>Animaux acceptés</p>
                </div>
                <div className="btn-actions | flrow liner">
                  <button
                    style={{
                      background:
                        animals == 0 ? "var(--destructive-400)" : "none",
                    }}
                    onClick={() => setAnimals(0)}
                    className="btn"
                  >
                    <XClose
                      color={animals == 0 ? "var(--shade-white)" : "#6B7280"}
                    />
                  </button>
                  <button
                    style={{
                      background: animals == 1 ? "var(--primary-500)" : "none",
                    }}
                    onClick={() => setAnimals(1)}
                    className="btn"
                  >
                    <Check
                      color={animals == 1 ? "var(--shade-white)" : "#6B7280"}
                    />
                  </button>
                </div>
              </div>

              <div className="contains-select | liner flrow">
                <div className="left-side | flcolm">
                  <p>Enfants acceptés</p>
                </div>
                <div className="btn-actions | flrow liner">
                  <button
                    style={{
                      background:
                        childs == 0 ? "var(--destructive-400)" : "none",
                    }}
                    onClick={() => setChilds(0)}
                    className="btn"
                  >
                    <XClose
                      color={childs == 0 ? "var(--shade-white)" : "#6B7280"}
                    />
                  </button>
                  <button
                    style={{
                      background: childs == 1 ? "var(--primary-500)" : "none",
                    }}
                    onClick={() => setChilds(1)}
                    className="btn"
                  >
                    <Check
                      color={childs == 1 ? "var(--shade-white)" : "#6B7280"}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
