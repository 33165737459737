import { useEffect, useState } from "react";

export const useCurrentScrollY = (observerPos) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [checkingPosY, setChecking] = useState(false);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (observerPos == undefined) return;
    if (checkingPosY == false && scrollPosition >= observerPos)
      setChecking(true);
    else if (checkingPosY == true && scrollPosition < observerPos)
      setChecking(false);
  }, [scrollPosition]);

  if (observerPos != undefined) {
    return { checkingPosY };
  } else return { scrollPosition };
};
export const useCurrentScrollYCheck = (observerPos) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [checkingPosY, setChecking] = useState(false);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (observerPos == undefined) return;
    if (checkingPosY == false && scrollPosition >= observerPos)
      setChecking(true);
    else if (checkingPosY == true && scrollPosition < observerPos)
      setChecking(false);
  }, [scrollPosition]);

  if (observerPos != undefined) {
    return [checkingPosY];
  } else return [scrollPosition];
};
export const useObserveToY = (ref, seuilMin, seuilMax) => {
  const [state, setState] = useState(false);
  const [bottomSeuil, setBottomSeuil] = useState(false);
  const [currentBottom, setCurrentBottom] = useState(0);
  const handleScroll = () => {
    if (ref.current) {
      const positionFromTop = ref.current.getBoundingClientRect().top;
      const positionFromBottom = ref.current.getBoundingClientRect().bottom;
      setCurrentBottom(positionFromBottom);
      if (positionFromTop <= seuilMin && !seuilMax) {
        setState(true);
        setBottomSeuil(false);
      } else if (
        positionFromTop <= seuilMin &&
        positionFromBottom >= seuilMax &&
        seuilMax
      ) {
        setState(true);
        setBottomSeuil(false);
      } else if (!seuilMax) {
        setState(false);
        setBottomSeuil(false);
      } else {
        setState(false);
        setBottomSeuil(true);
      }
    }
  };

  useEffect(() => {
    if (!ref) return;
    // Attacher l'événement de défilement
    window.addEventListener("scroll", handleScroll);

    // Nettoyer l'événement lors du démontage du composant
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ref]);
  return { state, bottomSeuil, currentBottom };
};
export const useObserveToYDirect = (ref, seuilMin, seuilMax) => {
  const [state, setState] = useState(false);
  const [bottomSeuil, setBottomSeuil] = useState(false);
  const [currentBottom, setCurrentBottom] = useState(0);
  const handleScroll = () => {
    const refSearch = document.getElementById(ref);
    if (ref) {
      const positionFromTop = ref.getBoundingClientRect().top;
      const positionFromBottom = ref.getBoundingClientRect().bottom;
      setCurrentBottom(positionFromBottom);
      if (positionFromTop <= seuilMin && !seuilMax) {
        setState(true);
        setBottomSeuil(false);
      } else if (
        positionFromTop <= seuilMin &&
        positionFromBottom >= seuilMax &&
        seuilMax
      ) {
        setState(true);
        setBottomSeuil(false);
      } else if (!seuilMax) {
        setState(false);
        setBottomSeuil(false);
      } else {
        setState(false);
        setBottomSeuil(true);
      }
    }
  };

  useEffect(() => {
    if (!ref) return;
    // Attacher l'événement de défilement
    window.addEventListener("scroll", handleScroll);

    // Nettoyer l'événement lors du démontage du composant
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ref]);
  return { state, bottomSeuil, currentBottom };
};
export const CheckCompareRef = (
  ref1,
  ref2,
  seuilCalcul = 1000,
  intervalCheck = 0
) => {
  const [stateCompare, setStateCompare] = useState(false);
  const [refFromTop1, setRefFromTop1] = useState(0);
  const [refFromTop2, setRefFromTop2] = useState(0);
  const refSearch = document.getElementById(ref1);
  const refSearch2 = document.getElementById(ref2);

  const handleScroll = () => {
    if (refSearch && refSearch2) {
      const positionFromTop = refSearch.getBoundingClientRect().top;
      const positionFromBottom = refSearch2.getBoundingClientRect().bottom;
      setRefFromTop1(refSearch.getBoundingClientRect().top);
      setRefFromTop2(refSearch2.getBoundingClientRect().top);
      if (positionFromTop + refSearch.clientHeight <= positionFromBottom)
        setStateCompare(true);
      else setStateCompare(false);
    }
  };

  useEffect(() => {
    if (!refSearch || !refSearch2) return;
    window.addEventListener("scroll", handleScroll);

    let interval = null;
    if (intervalCheck > 0) {
      interval = setInterval(() => {
        handleScroll();
      }, intervalCheck);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (interval) clearInterval(interval);
    };
  }, [refSearch, refSearch2]);
  return { stateCompare, refFromTop1, refFromTop2 };
};

export const GetWidthFromRef = (ref) => {
  try {
    const reference = document.getElementById(ref);
    const offsetWidth = reference.offsetWidth;
    return { offsetWidth };
  } catch (error) {
    return null;
  }
};
export const GetHeightFromRef = (ref) => {
  try {
    const reference = document.getElementById(ref);
    const offsetHeight = reference.offsetHeight;
    return { offsetHeight };
  } catch (error) {
    return null;
  }
};

export const DetectVisibleRef = (divRef, offset = 200) => {
  const [isVisibleRef, setIsVisible] = useState();
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            // console.log(
            //   `La div est visible à l'écran avec un offset de -${offset}px en y.`
            // );
          } else {
            setIsVisible(false);
          }
        });
      },
      {
        root: null,
        rootMargin: `${offset}px`,
        threshold: 0,
      }
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);

  return { isVisibleRef };
};
