/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../data/requestEndpoint";
import { Star } from "../../images/icons/JsxIcons";
import tempProfilPicture from "../../images/temps/user-02.svg";
import { NotificationService } from "../../services/Notification.service";
import useCurrentWidth from "../../services/useCurrentWidth";
import { get } from "../../services/useFetchAction";
import { UserPreferences } from "../../store/UserPreferences";
import "../../styles/components/commentaryResidence.css";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import CommentaryModal from "./CommentaryModal";
import NewCommentaryModal from "./NewCommentaryModal";

export default function CommentaryResidence({ residence = null }) {
  const { t } = useTranslation();
  const language = UserPreferences((state) => state.language);
  const [openCommentary, setOpenCommentary] = useState(false);
  const [proprieteCalcul, setProprieteCalcul] = useState(0);
  const [emplacementCalcul, setEmplacementCalcul] = useState(0);
  const [assetsCalcul, setAssetsCalcul] = useState(0);
  const [communicationCalcul, setCommunicationCalcul] = useState(0);
  const [rapportQualitePrixCalcul, setRapportQualitePrixCalcul] = useState(0);
  const [commentaryMoyenne, setCommentaryMoyenne] = useState(0.0);
  const [allCommentary, setAllCommentary] = useState([]);
  const [openNewComment, newCommentModal] = useState(false);

  const { windowWidth } = useCurrentWidth();
  const getCommentaryRequest = async () => {
    try {
      let response = await get(
        requestEndpoint.getResidenceCommentary + residence.id
      );
      let commentary = [];
      response.data.comments.map((comment) => {
        commentary.push({
          id: comment.id,
          message: comment.text,
          propriete: comment.quality,
          emplacement: comment.location,
          RapportQualitePrix: comment.globalExperience,
          communication: comment.communication,
          assets: comment.assets,
          stars: comment.stars,
          name: (
            comment.author.firstname +
            " " +
            comment.author.lastname
          ).replace(/\b\w/g, (l) => l.toUpperCase()),
          thumb: comment.author.avatar,
        });
      });
      let proprieteC = 0.0;
      let emplacementC = 0.0;
      let RqpC = 0.0;
      let assetsC = 0.0;
      let communicationC = 0.0;

      commentary.map((commentary) => {
        proprieteC += commentary.propriete;
        emplacementC += commentary.emplacement;
        RqpC += commentary.RapportQualitePrix;
        assetsC += commentary.assets;
        communicationC += commentary.communication;
      });
      if (commentary.length > 0) {
        let resultCalcul = 0.0;
        proprieteC = proprieteC / commentary.length;
        emplacementC = emplacementC / commentary.length;
        RqpC = RqpC / commentary.length;
        assetsC = assetsC / commentary.length;
        communicationC = communicationC / commentary.length;
        resultCalcul =
          proprieteC + emplacementC + RqpC + assetsC + communicationC;
        resultCalcul = resultCalcul / 5;
        setProprieteCalcul(proprieteC);
        setEmplacementCalcul(emplacementC);
        setRapportQualitePrixCalcul(RqpC);
        setAssetsCalcul(assetsC);
        setCommunicationCalcul(communicationC);
        setCommentaryMoyenne(resultCalcul);
      }
      setAllCommentary(commentary);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!residence) return;
    getCommentaryRequest();
  }, [residence]);

  const [canSendCommentary, setCanSendCommentary] = useState(false);

  useEffect(() => {
    if (residence) {
      setCanSendCommentary(residence.isAllowComment);
    }
  }, [residence]);

  const handleOpenNewComment = () => {
    if (canSendCommentary == false) {
      NotificationService.addToQueue(1, t("commentaryNotAllowTxt"));
      return;
    }
    newCommentModal(true);
  };
  if (!residence) return;

  return (
    <>
      <ContainerPersoModal
        className="commentary-modal"
        isOpen={openCommentary}
        toggle={setOpenCommentary}
        title={"Commentaire" + (language == "fr-FR" ? "s" : "")}
        fullBlock={true}
      >
        <CommentaryModal
          residence={residence}
          allCommentary={allCommentary}
          totalCalcul={commentaryMoyenne}
          propriete={proprieteCalcul}
          emplacement={emplacementCalcul}
          rapportQualite={rapportQualitePrixCalcul}
          assets={assetsCalcul}
          communication={communicationCalcul}
        />
      </ContainerPersoModal>
      <div className="commentary-residence | flcolm">
        <div className="title | flrow liner btw">
          <div className="title-comment | flrow addgap8 liner">
            <Star color={residence.stars ? "#FACC15" : "var(--shade-black)"} />
            <label>
              {residence.stars ? parseFloat(residence.stars).toFixed(1) : ""} (
              {allCommentary.length} {t("commentaryTxt")}
              {allCommentary.length > 1 && language == "fr-FR" ? "s" : ""})
            </label>
          </div>
          {allCommentary?.length > 0 ? (
            <button
              onClick={() => setOpenCommentary(true)}
              className="btn sd-btn"
            >
              <span>{t("showAllCommentaryTxt")}</span>
            </button>
          ) : (
            allCommentary?.length == 0 && (
              <button
                onClick={() => handleOpenNewComment()}
                className="btn sd-btn btn-no-commentary"
              >
                <span>Laissez un commentaire</span>
              </button>
            )
          )}
        </div>
        {allCommentary?.length > 0 && (
          <div className="contains-commentaires | flrow">
            {allCommentary.slice(0, 3).map((commentary) => {
              let totalName = "";
              if (residence) {
                totalName = commentary.name.toLocaleLowerCase();
                totalName = totalName.toLowerCase();
                totalName = totalName.replace(
                  /(^|\s)([a-zéèàêëîïôöûüç])/g,
                  function (m, p1, p2) {
                    return p1 + p2.toUpperCase();
                  }
                );
              }
              return (
                <div
                  key={"commentary-" + commentary.id}
                  className="commentary flcolm btw"
                >
                  <p className="comment">{commentary.message}</p>
                  <div className="comment-person | flrow liner">
                    <img
                      src={
                        commentary.thumb
                          ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_PROFIL_PICTURE}${commentary.thumb}`
                          : tempProfilPicture
                      }
                      alt="commentary-profil"
                    />
                    <div className="title-pers | flcolm">
                      <span>{totalName}</span>
                      <div className="flrow liner">
                        <Star />
                        <span>{parseFloat(commentary.stars).toFixed(1)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {allCommentary.length > 3 && windowWidth <= 600 && (
          <button
            onClick={() => setOpenCommentary(true)}
            className="btn ssd-btn"
          >
            <span>{t("showAllCommentaryTxt")}</span>
          </button>
        )}
        {allCommentary.length <= 3 &&
          allCommentary.length > 0 &&
          windowWidth <= 600 && (
            <button
              style={
                residence.isAllowComment
                  ? {}
                  : {
                      backgroundColor: "var(--gray-400)",
                      borderColor: "var(--gray-400)",
                    }
              }
              onClick={() => handleOpenNewComment()}
              className="btn opt-btn btn-no-commentary"
            >
              <span
                style={
                  residence.isAllowComment
                    ? { fontWeight: 600 }
                    : {
                        color: "var(--shade-white)",
                        fontWeight: 600,
                        cursor: "not-allowed",
                      }
                }
              >
                Laissez un commentaire
              </span>
            </button>
          )}
      </div>
      <ContainerPersoModal
        className="new-commentary-modal"
        title={t("redigerCommentaryTxt")}
        isOpen={openNewComment}
        toggle={newCommentModal}
      >
        <NewCommentaryModal residence={residence} noteCommentary={0} />
      </ContainerPersoModal>
    </>
  );
}
