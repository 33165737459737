/* eslint-disable react-hooks/exhaustive-deps */
import {
  APIProvider,
  AdvancedMarker,
  Map,
  useMap,
} from "@vis.gl/react-google-maps";
import React, { useCallback, useEffect, useState } from "react";
import { NotificationService } from "../services/Notification.service";
import { handleFormatToLocalString } from "../services/usePersoFormat";
import { UserPreferences } from "../store/UserPreferences";
import "../styles/test.css";
const markersData = [
  //données fake bro
  {
    title: "Cocody",
    position: { lat: 5.3599517, lng: -4.0082563 },
    price: 1000,
  },
  {
    title: "Yopougon",
    position: { lat: 5.3545, lng: -4.0182563 },
    price: 2000,
  },
  {
    title: "Adjame",
    position: { lat: 5.3745, lng: -4.0192563 },
    price: 2000,
  },
  {
    title: "Yopougon",
    position: { lat: 5.3245, lng: -4.0382563 },
    price: 2000,
  },
];
const delayRequest = 500;
const GetMapFunction = ({ setMapStore }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;
    setMapStore(map);
  }, [map]);

  return <></>;
};
export default function TestingPage() {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [loadingMap, setLoadingMap] = useState(true);
  const [mapStore, setMapStore] = useState({});
  const [delayTimeout, setDelayTimeout] = useState();
  const [userPosition, setUserPosition] = useState();

  const INITIAL_CAMERA = {
    //abidjan
    center: { lat: 5.3599517, lng: -4.0082563 },
    zoom: 12,
  };
  const [cameraProps, setCameraProps] = useState(INITIAL_CAMERA);

  const handleCameraChange = (ev) => {
    setLoadingMap(true);
    setCameraProps(ev.detail);
  };

  const handleSelectMarker = useCallback(
    (marker) => {
      if (selectedMarker === marker) return setSelectedMarker(null);
      setSelectedMarker(marker);
    },
    [selectedMarker]
  );

  const handleZoomIn = () => {
    const newZoom = mapStore.getZoom() + 1;
    mapStore.setZoom(newZoom);
  };

  const handleZoomOut = () => {
    const newZoom = mapStore.getZoom() - 1;
    mapStore.setZoom(newZoom);
  };

  const handleLocateUser = () => {
    //recuperer la position de l'utilisateur, si erreur, afficher un message d'erreur
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        if (!userPosition)
          NotificationService.addToQueue(0, "Ahmad localisé avec succès");
        setUserPosition(pos);
        mapStore.moveCamera({ center: pos, zoom: 15 });
      },
      () => {
        NotificationService.addToQueue(
          2,
          "Erreur lors de la récupération de votre position"
        );
      }
    );
  };

  useEffect(() => {
    if (delayTimeout) clearTimeout(delayTimeout);
    const timeout = setTimeout(() => {
      setLoadingMap(false);
      //garder le loading tant que l'api n'a pas fini de charger like airbnb, la je retire pour tester
      console.log("call api avec les données de la camera", cameraProps);
    }, delayRequest);
    setDelayTimeout(timeout);
    return () => clearTimeout(timeout);
  }, [cameraProps]);

  const mapOptions = {
    zoomControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    onBoundsChanged: handleCameraChange,
    mapId: "4676e62fc3d9bda7",
    minZoom: 5,
    maxZoom: 21,
    clickableIcons: false,
    draggableCursor: "default",
  };
  return (
    <div
      className="container"
      style={{
        position: "relative",
        width: "100vw",
        height: "95vh",
      }}
    >
      <div className="map-controls">
        <button onClick={handleZoomIn}>Dez +</button>
        <button onClick={handleZoomOut}>Melo -</button>
        <button onClick={handleLocateUser}>Ahmad localisation</button>
      </div>
      {loadingMap && (
        <p className="loading-map">call api après 0.5s after no action</p>
      )}
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_ADD} region="Fr">
        <Map
          style={{
            width: "100%",
            height: "100%",
          }}
          {...cameraProps}
          {...mapOptions}
        >
          {userPosition && (
            <AdvancedMarker
              className="marker-pos"
              position={userPosition}
              zIndex={1}
            >
              <></>
            </AdvancedMarker>
          )}
          <RenderMarker
            markers={markersData}
            handleSelectMarker={handleSelectMarker}
            currentMarker={selectedMarker}
          />
        </Map>
        <GetMapFunction setMapStore={setMapStore} />
      </APIProvider>
    </div>
  );
}

const RenderMarker = ({ markers, handleSelectMarker, currentMarker }) => {
  const language = UserPreferences((state) => state.language);
  const showDevise = UserPreferences((state) => state.showDevise);

  return (
    <>
      {markers.map((marker, index) => (
        <AdvancedMarker
          onClick={() => handleSelectMarker(marker)}
          key={index}
          position={marker.position}
          zIndex={currentMarker === marker ? 1 : 0}
        >
          <div
            className={`marker-map ${
              marker === currentMarker ? "selected" : ""
            }`}
          >
            <span className="marker-title">
              {handleFormatToLocalString(marker.price, language) +
                " " +
                showDevise}
            </span>
          </div>
        </AdvancedMarker>
      ))}
    </>
  );
};
