import React, { useEffect, useRef, useState } from "react";
import useCurrentWidth from "../../services/useCurrentWidth";
import "../../styles/components/inputHeader.css";
import "../../styles/components/inputHeaderSecond.css";
export const InputHeaderSecondPersonne = ({
  title = "",
  placeholder = "",
  codeUpdateInput = "",
  value,
  typeInput = "text",
  typer = "",
  baseFlex = 1,
  setHider,
  secondHeader = false,
  setBlurFilter,
  blurFilter,
  handleGoSearch,
  noLabel = false,
}) => {
  const [currentInput, setCurrentInput] = useState("");
  const [showValue, setShowValue] = useState("");
  const [currentBlur, setCurrentBlur] = useState(false);
  const [loadSearchBar, setLoadSearchBar] = useState(false);
  const refInput = useRef(null);

  const { windowWidth } = useCurrentWidth();

  let timeoutId;

  useEffect(() => {
    if (handleGoSearch) {
      handleGoSearch();
    }
  }, [showValue]);
  useEffect(() => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setLoadSearchBar(blurFilter);
    }, 200);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [blurFilter]);

  const handleClick = () => {
    setCurrentBlur(true);
    setBlurFilter(true);
  };

  const handleBlur = () => {
    setCurrentBlur(false);
    setBlurFilter(false);

    if (typeInput !== "number") return;
    if (currentInput === "0") {
      setCurrentInput("");
      refInput.current.value = "";
      return;
    }
    const numericValue = parseInt(currentInput);

    if (numericValue > 0) {
      if (numericValue === 1)
        setShowValue(numericValue + " " + typer + " minimum");
      else setShowValue(numericValue + " " + typer + "s minimum");
    }
  };
  const handleChange = (e) => {
    setCurrentInput(e.target.value);
    setShowValue(e.target.value);
  };
  const handleSelectChange = (value) => {
    refInput.current.value = value;
    setCurrentInput(value);
    setShowValue(value);
    setLoadSearchBar(false);
  };

  useEffect(() => {
    if (setHider) {
      setTimeout(() => {
        setHider(blurFilter);
      }, 10);
    }
  }, [setHider, blurFilter]);
  // console.log(value);
  useEffect(() => {
    if (value == 0) setCurrentInput(null);
    if (!refInput) return;
    if (!value) return;
    refInput.current.value = value;
    const numericValue = parseInt(value);
    if (numericValue > 0) {
      setCurrentInput(numericValue);
      if (numericValue === 1)
        setShowValue(numericValue + " " + typer + " minimum");
      else setShowValue(numericValue + " " + typer + "s minimum");
    } else {
      setCurrentInput(null);
    }
  }, [refInput, value]);

  return (
    <div
      style={{
        flex: baseFlex,
        transition: "all 0.15s ease",
        background: blurFilter ? "var(--shade-white)" : "none",
      }}
      className={"hd-inp hd-input-second | flcolm liner addgap8 rlve"}
    >
      {secondHeader
        ? !noLabel && (
            <>
              <label className="lbl-inp-second first-shower-lbl-inp">
                {title}
              </label>
              <label className="lbl-inp-second second-shower-lbl-inp">
                {secondHeader}
              </label>
            </>
          )
        : !noLabel && <label className="lbl-inp-second">{title}</label>}

      <button
        // style={{ opacity: !blurFilter && showValue && typeInput ? 0 : 1 }}
        onClick={handleClick}
        ref={refInput}
        type={typeInput}
        onChange={(e) => handleChange(e)}
        className="flrow"
      >
        {currentInput ? (
          <span
            style={noLabel ? { fontWeight: 600 } : {}}
            className={
              !blurFilter && showValue && typeInput
                ? "show-me spanner-show"
                : "spanner-show"
            }
          >
            {showValue}
          </span>
        ) : (
          <span
            style={noLabel ? { fontWeight: 600 } : {}}
            className="placeholder"
          >
            {windowWidth <= 600
              ? placeholder.slice(0, 12) + "..."
              : placeholder}
          </span>
        )}
      </button>
    </div>
  );
};
