/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import localforage from "localforage";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Cover from "../components/addResidencePart/Cover";
import Step10NameProperty from "../components/addResidencePart/Step10NameProperty";
import Step11Description from "../components/addResidencePart/Step11Description";
import Step13FinishPublish from "../components/addResidencePart/Step13FinishPublish";
import Step14Price from "../components/addResidencePart/Step14Price";
import Step15Reglement from "../components/addResidencePart/Step15Reglement";
import Step16SocialsActivities from "../components/addResidencePart/Step16SocialsActivities";
import Step17Remboursement from "../components/addResidencePart/Step17Remboursement";
import Step2TypeResidence from "../components/addResidencePart/Step2TypeResidence";
import Step3Accessibility from "../components/addResidencePart/Step3Accessibility";
import Step4BasicsLocation from "../components/addResidencePart/Step4BasicsLocation";
import Step4Location from "../components/addResidencePart/Step4Location";
import Step5InfoLocalite from "../components/addResidencePart/Step5InfoLocalite";
import Step7Comodities from "../components/addResidencePart/Step7Comodities";
import Step8Photos from "../components/addResidencePart/Step8Photos";
import Step9Photos from "../components/addResidencePart/Step9Photos";
import StepEndFinish from "../components/addResidencePart/StepEndFinish";
import StepOneAnnonce from "../components/addResidencePart/StepOneAnnonce";
import StepSixResidenceBig from "../components/addResidencePart/StepSixResidenceBig";
import StepTwelveFinish from "../components/addResidencePart/StepTwelveFinish";
import ContainerPersoModal from "../components/biggest/ContainerPersoModal";
import AddResidenceNextFooter from "../components/cells/AddResidenceNextFooter";
import AddResidenceTopHeader from "../components/cells/AddResidenceTopHeader";
import json from "../data/countries.json";
import { requestEndpoint } from "../data/requestEndpoint";
import { sendPhotosToDatabase } from "../request/addResidenceController";
import { NotificationService } from "../services/Notification.service";
import { decryptData, encryptData } from "../services/encryption.service";
import { get, post } from "../services/useFetchAction";
import { generateUniqueStringId, smoothScrollUp } from "../services/utils";
import { useStorePageManager } from "../store/PageManage";
import { useStoreUserManager } from "../store/UserManage";
import { UserPreferences } from "../store/UserPreferences";
import "../styles/pages_styles/addResidencePage.css";
const maxSizeInBytes = 8 * 1024 * 1024;

const objectStoreName = "images";

export default function AddResidencePage() {
  const { t } = useTranslation();
  const [currentResidenceId, setCurrentResidenceId] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  // console.log("currentStep", currentStep);
  const [blockNextBtn, setBlockNextBtn] = useState(false);
  const navigate = useNavigate();
  const language = UserPreferences((state) => state.language);
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );
  const handleUpdateBlockScreen = useStorePageManager(
    (state) => state.handleUpdateBlockScreen
  );
  const [modalSaveOpen, setModalSaveOpen] = useState(false);
  const userData = useStoreUserManager((state) => state.user);
  useEffect(() => {
    useStorePageManager.getState().handleUpdateDesactiveHeader(true);
    useStorePageManager.getState().handleUpdateStorePage(false);
    return () => {
      useStorePageManager.getState().handleUpdateDesactiveHeader(false);
    };
  }, []);
  const [directGoCheckLocation, setDirectGoCheckLocation] = useState(false);
  const [directGoEnd, setDirectGoEnd] = useState(false);
  const handleGoNextStep = () => {
    if (directGoEnd && currentStep != 8) {
      setCurrentStep(17);
      setDirectGoEnd(false);
      return;
    }
    if (directGoCheckLocation) {
      setCurrentStep(17);
      setDirectGoCheckLocation(false);
      return;
    }
    if (currentStep == 15 && events == 0) setCurrentStep((prev) => prev + 2);
    else setCurrentStep((prev) => prev + 1);
  };
  const handleGoBackStep = () => {
    setDirectGoEnd(false);
    if (currentStep == 9 || (currentStep == 17 && events == 0))
      setCurrentStep((prev) => prev - 2);
    else setCurrentStep((prev) => prev - 1);
  };

  const [currentPhotosSelected, setPhotosSelected] = useState([]);
  const updatePhotos = (images) => {
    setPhotosSelected(images);
  };
  const loadImagesFromLocalForage = async () => {
    try {
      const images = (await localforage.getItem(objectStoreName)) || [];
      let newImgagesList = [];
      for (let i = 0; i < images.length; i++) {
        newImgagesList.push({
          photo: images[i].photo,
          url: URL.createObjectURL(images[i].photo),
          id: generateUniqueStringId(),
        });
      }
      updatePhotos(newImgagesList);
    } catch (error) {
      console.error("Error loading images from localForage:", error);
    }
  };
  const saveImagesToLocalForage = async (images) => {
    try {
      await localforage.setItem(objectStoreName, images);
    } catch (error) {
      console.error("Error saving images to localForage:", error);
    }
  };
  const deleteAllImagesFromLocalForage = async () => {
    try {
      await localforage.removeItem(objectStoreName);
    } catch (error) {
      console.error("Error deleting images from localForage:", error);
    }
  };
  useEffect(() => {
    loadImagesFromLocalForage();
  }, []);

  const onDropFunction = useCallback(async (acceptedFiles, nexStep = null) => {
    let newFiles = [];

    useStorePageManager.getState().handleUpdateBlockScreen(true);
    await Promise.all(
      acceptedFiles.map(async (file) => {
        if (
          ["image/png", "image/jpeg", "image/jpg", "image/heic"].includes(
            file.type
          )
        ) {
          if (file.size <= maxSizeInBytes) {
            // const compressedFile = await compressImage(file);
            const compressedFile = file;
            const newId = generateUniqueStringId();
            newFiles.push({
              photo: compressedFile,
              url: URL.createObjectURL(compressedFile),
              id: newId,
            });
          } else {
            NotificationService.addToQueue(
              2,
              `Le fichier ${file.name} dépasse la taille maximale autorisée (8 Mo)`
            );
          }
        } else {
          NotificationService.addToQueue(
            2,
            "Uniquement les fichiers du type png, jpeg, heif sont acceptés"
          );
        }
      })
    );
    useStorePageManager.getState().handleUpdateBlockScreen(false);
    if (newFiles.length == 0) return;
    if (nexStep) setCurrentStep(nexStep);
    setPhotosSelected((prevPhotos) => {
      let newTab = [...prevPhotos, ...newFiles];
      if (newTab.length > 12) {
        NotificationService.addToQueue(
          1,
          "12 images au maximum peuvent etre upload"
        );
      }
      return newTab.slice(0, 12);
    });
  }, []);

  useEffect(() => {
    let newTimeout = setTimeout(() => {
      smoothScrollUp(true);
    }, 100);
    return () => {
      clearTimeout(newTimeout);
    };
  }, []);

  const [timeoutGoOut, setTimeoutGoOut] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutGoOut);
    };
  }, [timeoutGoOut]);

  const [openConfirmSendResidence, setOpenConfirmSendResidence] =
    useState(false);
  const [postInLoading, setPostInLoading] = useState(false);
  const [timeoutForGoNextNotif, setTimeoutForGoNextNotif] = useState();
  const messageList = [
    "Nous nous occupons de tout",
    "Un peu de patience",
    "Le temps de tout vérifier",
    "C'est presque fini",
    "Nous traitons vos magnifiques photos",
    "Nous vérifions quelques données sensibles",
    "Pas de panique",
    "Nous sommes bientôt prêts",
    "Nous sommes en train de tout vérifier",
    "Optimisation des images en cours",
    "Vérification de la qualité des photos",
    "Analyse des métadonnées en cours",
    "Nous ajustons les dimensions",
    "Sauvegarde des données en cours",
    "Finalisation de l'upload",
    "Nous y sommes presque, merci pour votre patience",
    "Nous sommes en train de tout vérifier",
    "Traitement en cours, merci de patienter",
    "Nous travaillons pour améliorer votre expérience",
    "Rien n'est impossible, même le chargement",
    "Nous faisons le nécessaire pour vous",
    "Chargement en cours, restez avec nous",
    "Votre patience est grandement appréciée",
    "Nous rendons chaque pixel parfait",
    "Une étape à la fois, nous y arrivons",
    "Nos ingénieurs s'assurent que tout fonctionne à merveille",
    "Nous mettons les petits détails en place",
    "La perfection prend du temps, merci pour votre compréhension",
    "Nous nous assurons que tout est conforme à vos attentes",
  ];
  const startNotifications = () => {
    let previousMessage = null;
    const getRandomDelay = () => Math.floor(Math.random() * 5000) + 6500;
    const intervalId = setInterval(() => {
      let randomMessage =
        messageList[Math.floor(Math.random() * messageList.length)];
      while (randomMessage === previousMessage) {
        randomMessage =
          messageList[Math.floor(Math.random() * messageList.length)];
      }
      NotificationService.addToQueue(0, randomMessage);
      previousMessage = randomMessage;
    }, getRandomDelay());
    setTimeoutForGoNextNotif(intervalId);
  };

  useEffect(() => {
    const stopNotifications = () => {
      if (timeoutForGoNextNotif) clearInterval(timeoutForGoNextNotif);
    };
    if (postInLoading) {
      startNotifications();
    } else {
      stopNotifications();
    }
    return () => {
      stopNotifications();
    };
  }, [postInLoading]);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [successCount, setSuccessCount] = useState(0);

  const handleAddResidenceOnServer = async (isConfirmed = false) => {
    // await handleSaveResidence();
    try {
      setSuccessCount(0);
      let tableRooms = [
        {
          roomId: 1,
          count: chambres,
        },
        {
          roomId: 2,
          count: salon,
        },
        {
          roomId: 3,
          count: douche,
        },
        {
          roomId: 4,
          count: toilet,
        },
        {
          roomId: 5,
          count: numberBathrooms,
        },
        {
          roomId: 6,
          count: balcon,
        },
        {
          roomId: 7,
          count: terrase,
        },
        {
          roomId: 8,
          count: jardin,
        },
        {
          roomId: 9,
          count: salleAManger,
        },
        {
          roomId: 10,
          count: garage,
        },
        {
          roomId: 11,
          count: bureau,
        },
        {
          roomId: 12,
          count: salleSport,
        },
      ];
      let currentOccupation = accessibilityResi == 1 ? "Full" : "Partial";
      let typeResi = currentTypeResi == 0 ? 1 : currentTypeResi;
      let commodities = [];
      currentCommodities.forEach((com) => {
        if (com.selected) commodities.push(com.id);
      });

      let activities = [];
      tableSocialsActivities.forEach((activitie) => {
        activitie.selectables.forEach((selectable) => {
          if (selectable.select) {
            activities.push(selectable.id);
          }
        });
      });

      let rules = [];
      if (smoke == 1) rules.push(1);
      if (animals == 1) rules.push(2);
      let activeEvents = events == 1 ? true : false;
      if (events == 1) {
        rules.push(3);
      }
      if (childs == 1) rules.push(4);

      let refundGrid = {
        "Moins de 48 heures avant le jour J": parseInt(
          tableRemboursement[0]?.selectables?.find(
            (selectable) => selectable.select
          )?.percent ?? 0
        ),
        "Entre 48h et 1 semaine avant le jour J": parseInt(
          tableRemboursement[1]?.selectables?.find(
            (selectable) => selectable.select
          )?.percent ?? 0
        ),
        "Entre 1 semaine et 1 mois avant le jour J": parseInt(
          tableRemboursement[2]?.selectables?.find(
            (selectable) => selectable.select
          )?.percent ?? 0
        ),
        "Entre 1 mois et 3 mois avant le jour J": parseInt(
          tableRemboursement[3]?.selectables?.find(
            (selectable) => selectable.select
          )?.percent ?? 0
        ),
        "Plus de 3 mois avant le jour J": parseInt(
          tableRemboursement[4]?.selectables?.find(
            (selectable) => selectable.select
          )?.percent ?? 0
        ),
      };
      let descriptionToGo = [];
      baseDescription.map((section) => {
        descriptionToGo.push({
          title: section.title,
          text: section.description,
        });
      });
      descriptionSections.map((section) => {
        descriptionToGo.push({
          title: section.title,
          text: section.description,
        });
      });
      let addressToGo = "";
      //format Ville, Quartier - Pays only first letter of in each word is uppercase
      if (currentCity != "") {
        addressToGo += currentCity.charAt(0).toUpperCase();
        addressToGo += currentCity.slice(1).toLowerCase();
      }
      if (currentWeighborhood != "") {
        addressToGo += ", " + currentWeighborhood.charAt(0).toUpperCase();
        addressToGo += currentWeighborhood.slice(1).toLowerCase();
      }
      if (currentCountrySelected != null) {
        addressToGo +=
          " - " + currentCountrySelected.name.charAt(0).toUpperCase();
        addressToGo += currentCountrySelected.name.slice(1).toLowerCase();
      }
      let normalPostData = {
        name: title == "" ? "NoTitle" + Date.now() : title,
        address: addressToGo == "" ? "NoLocation" : addressToGo,
        description: descriptionToGo,
        lng: markerPosition?.lng ?? 0,
        lat: markerPosition?.lat ?? 0,
        price: price == 0 ? 1 : price,
        maxPeople: numberMaxPers == 0 ? 1 : numberMaxPers,
        occupation: currentOccupation,
        typeId: typeResi ?? 1,
        assets: commodities,
        activities: activeEvents ? activities : [],
        rooms: tableRooms,
        rules: rules,
        countryId: currentCountrySelected?.id ?? 1,
        isConfirmed: isConfirmed,
        refundGrid: refundGrid,
      };

      const photos = [];
      currentPhotosSelected.forEach((photo) => {
        photos.push(photo.photo);
      });

      normalPostData =
        photos.length > 0
          ? { ...normalPostData, files: photos }
          : normalPostData;
      setPostInLoading(true);
      if (photos.length <= 0) {
        NotificationService.addToQueue(
          1,
          "Il y a eu un problème avec les images, veuillez réessayer de les rajouter"
        );
        setPostInLoading(false);
        deleteAllImagesFromLocalForage();
        setPhotosSelected([]);
        setDirectGoEnd(true);
        setOpenConfirmSendResidence(false);
        setCurrentStep(8);
        useStorePageManager.getState().handleUpdateBlockScreen(false);
        return null;
      }
      const photosBlob = normalPostData.files;
      const { photosURL, successCount, errorSend } = await sendPhotosToDatabase(
        photosBlob,
        setSuccessCount
      );
      if (errorSend) {
        setPostInLoading(false);
        setDirectGoEnd(true);
        setOpenConfirmSendResidence(false);
        setCurrentStep(8);
        useStorePageManager.getState().handleUpdateBlockScreen(false);
        return null;
      } else {
        normalPostData = { ...normalPostData, newImages: photosURL };
      }
      delete normalPostData.files;

      const res = await post(requestEndpoint.residenceAdd, normalPostData, {
        "Content-Type": "multipart/form-data",
      });
      useStoreUserManager.getState().handleUpdateUser({ enableHost: true });
      NotificationService.addToQueue(0, t("resiAdded"));
      setPostInLoading(false);
      const freeBrowserMemory = () => {
        // if (
        //   window &&
        //   window.performance &&
        //   window.performance.clearResourceTimings
        // ) {
        //   window.performance.clearResourceTimings();
        // }
      };
      freeBrowserMemory();

      if (!isConfirmed) {
        let returnData = res.data.id;
        return returnData;
      }

      setCurrentStep((prev) => prev + 1);
      setOpenConfirmSendResidence(false);
      localStorage.removeItem("residenceData");
      deleteAllImagesFromLocalForage();
      return res.data.id;
    } catch (err) {
      console.error(err);
      setPostInLoading(false);
      setOpenConfirmSendResidence(false);
      // NotificationService.addToQueue(2, t("resiErrorAddTxt"));
      if (err.response.status == 409) {
        NotificationService.addToQueue(
          1,
          "Le nom de la résidence est déjà pris"
        );
        setDirectGoEnd(true);
        setCurrentStep(10);
      } else {
        NotificationService.addToQueue(
          2,
          "Une erreur est survenue lors de l'ajout de la résidence, veuillez réessayer"
        );
      }
      useStorePageManager.getState().handleUpdateBlockScreen(false);

      return null;
    }
  };
  const handleAddResidence = async () => {
    //si latitude et longitude sont pas des nombres on retourne au step 4
    if (isNaN(longLat.lat) || isNaN(longLat.lng)) {
      setDirectGoCheckLocation(true);
      setOpenConfirmSendResidence(false);
      setCurrentStep(4);
      NotificationService.addToQueue(
        1,
        "Veuillez reconfirmer la localisation de votre résidence"
      );
      return;
    }
    handleUpdateBlockScreen(true);
    handleUpdateBodyOverflow(false);
    await handleAddResidenceOnServer(true);
    let timeout = setTimeout(() => {
      handleUpdateBodyOverflow(true);
      handleUpdateBlockScreen(false);
    }, 300);
    setTimeoutGoOut(timeout);
  };

  const handleGoToHostMode = () => {
    navigate("/hote", { replace: true, state: { menu: 1 } });
  };
  const [timeoutGoOutE, setTimeoutGoOutE] = useState();
  useEffect(() => {
    if (userData) {
      const handleCheckIfCan = async () => {
        if (userData.connected == false) {
          navigate("/", { replace: true });
          NotificationService.addToQueue(1, t("youNeedConnect"));
        }
        let goOut = false;
        if (!userData.emailVerified && !userData.isSocial) {
          NotificationService.addToQueue(
            1,
            "Vous devez vérifier votre email avant de pouvoir ajouter une résidence"
          );
          goOut = true;
        }
        if (!userData.identityDoc) {
          NotificationService.addToQueue(
            1,
            "Vous devez uploader votre pièce d'identité avant de pouvoir ajouter une résidence"
          );
          goOut = true;
        }
        if (!userData.contact) {
          NotificationService.addToQueue(
            1,
            "Vous devez ajouter un numéro de téléphone avant de pouvoir ajouter une résidence"
          );
          goOut = true;
        }
        if (!userData.contactChecked) {
          NotificationService.addToQueue(
            1,
            "Vous devez ajouter un numéro de téléphone valider avant de pouvoir ajouter une résidence"
          );
          goOut = true;
        }
        if (!goOut) {
          // console.log("userData",userData);
          // console.log("loadData");
          loadData();
        }
        if (goOut) {
          navigate("/profile", { replace: true });
        }
      };
      if (timeoutGoOutE) clearTimeout(timeoutGoOutE);
      let timer = setTimeout(() => {
        handleCheckIfCan();
      }, 500);
      setTimeoutGoOutE(timer);
      return () => {
        clearTimeout(timeoutGoOutE);
      };
    }
  }, [userData]);

  const [countries, setCountries] = useState([]);
  const [isSelectVisible, setIsSelectVisible] = useState(false);
  const [alreadyFetch, setAlreadyFetch] = useState(false);
  const [fetchFinish, setFetchFinish] = useState(false);

  useEffect(() => {
    // if (alreadyFetch || isSelectVisible == false) return;
    if (alreadyFetch) return;
    const fetchData = async () => {
      const countrieDb = await get(requestEndpoint.getCountries);
      setCountries(countrieDb.data);
      setAlreadyFetch(true);
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        if (response.ok) {
          const data = await response.json();
          const countryData = data.map((country) => ({
            countryCode: country.cca2,
            flagPath: country.flags.svg,
          }));
          let currentDataPays = [];
          for (let index = 0; index < json.length; index++) {
            for (let jindex = 0; jindex < countryData.length; jindex++) {
              if (
                json[index].code.toString().toUpperCase() ==
                countryData[jindex].countryCode.toString().toUpperCase()
              ) {
                currentDataPays.push({
                  ...json[index],
                  flag: countryData[jindex].flagPath,
                });
                // break;
              }
            }
          }
          // console.log("currentDataPays", currentDataPays);
          let newCurrentDataPays = [];
          countrieDb.data.forEach((element) => {
            let find = false;
            let currentElement = { ...element };
            let finded = {};
            for (let index = 0; index < currentDataPays.length; index++) {
              if (
                currentDataPays[index].code.toString().toUpperCase() ==
                currentElement.iso2.toString().toUpperCase()
              ) {
                finded = currentDataPays[index];
                find = true;
                break;
              }
            }
            if (!find) {
              newCurrentDataPays.push({
                ...currentElement,
                flag: null,
              });
            } else {
              newCurrentDataPays.push({
                ...finded,
                name: currentElement.name,
                id: currentElement.id,
                province: currentElement.province,
              });
            }
          });
          setCountries(newCurrentDataPays);
          setFetchFinish(true);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données des pays",
          error
        );
      }
    };

    fetchData();
  }, [isSelectVisible]);
  //ALL DATA APPPLICATION
  const [currentTypeResi, setTypeResi] = useState(0);
  const [accessibilityResi, setTypeAccessibilityResi] = useState(0);
  const [longLat, setLongLat] = useState({
    lat: 5.3602164,
    lng: -4.0210189,
  });

  const [currentCountrySelected, setCurrentCountrySelected] = useState(null);
  const [currentCity, setCurrentCity] = useState("");
  const [currentWeighborhood, setCurrentWeighborhood] = useState("");

  const [location, setLocation] = useState("");

  const [numberMaxPers, setNumberMaxPers] = useState(0);

  const [chambres, setChambres] = useState(0);
  const [salon, setSalon] = useState(0);
  const [douche, setDouche] = useState(0);
  const [toilet, setToilet] = useState(0);
  const [numberBathrooms, setNumberBathrooms] = useState(0);
  const [balcon, setBalcon] = useState(0);
  const [terrase, setTerrase] = useState(0);
  const [jardin, setJardin] = useState(0);
  const [salleAManger, setSalleAManger] = useState(0);
  const [garage, setGarage] = useState(0);
  const [bureau, setBureau] = useState(0);
  const [salleSport, setSalleSport] = useState(0);

  const [currentCommodities, setCurrentCommodities] = useState([]);
  const [title, setTitle] = useState("");
  const [baseDescription, setBaseDescription] = useState([
    {
      id: "",
      title: "A PROPOS",
      lengthTitle: 0,
      description: "",
      lengthDescription: 0,
    },
    {
      id: "",
      title: "ACCESSIBILITE",
      lengthTitle: 13,
      description: "",
      lengthDescription: 0,
    },
    {
      id: "",
      title: "HEURE D’ARRIVEE / DEPART",
      lengthTitle: 21,
      description: "",
      lengthDescription: 0,
    },
  ]);
  const [descriptionSections, setDescriptionSections] = useState([
    // {
    //   id: "",
    //   title: "",
    //   lengthTitle: 0,
    //   description: "",
    //   lengthDescription: 0,
    // },
  ]);
  const [conditions, setConditions] = useState(false);
  const [price, setPrice] = useState(0);
  const [currentP, setCurrentP] = useState("0");
  const [events, setEvents] = useState(0);
  const [smoke, setSmoke] = useState(0);
  const [animals, setAnimals] = useState(0);
  const [childs, setChilds] = useState(0);

  const [tableSocialsActivities, setTableSocialsActivities] = useState([]);
  const [tableRemboursement, setTableRemboursement] = useState([]);

  //verifier si on a des données dans le localstorage et les charger
  const loadData = async () => {
    const encryptedData = localStorage.getItem("residenceData");
    // console.log("encryptedData", encryptedData);
    const lastWaiting = await get(requestEndpoint.residenceWaiting);
    if (encryptedData) {
      const data = await decryptData(encryptedData, true);
      if (data) {
        if (data.userId != userData.id) {
          localStorage.removeItem("residenceData");
          deleteAllImagesFromLocalForage();
          return;
        }
        setCurrentResidenceId(data.residenceId);
        setCurrentStep(data.currentStep);
        setTypeResi(data.currentTypeResi);
        setCurrentWeighborhood(data.currentWeighborhood);
        setTypeAccessibilityResi(data.accessibilityResi);
        setLongLat(data.longLat);
        setMarkerPosition(data.markerPosition);
        setLocation(data.location);
        setNumberMaxPers(data.numberMaxPers);

        setChambres(data.chambres);
        setSalon(data.salon);
        setDouche(data.douche);
        setToilet(data.toilet);
        setNumberBathrooms(data.numberBathrooms);
        setBalcon(data.balcon);
        setTerrase(data.terrase);
        setJardin(data.jardin);
        setSalleAManger(data.salleAManger);
        setGarage(data.garage);
        setBureau(data.bureau);
        setSalleSport(data.salleSport);

        setCurrentCommodities(data.currentCommodities);
        setTitle(data.title);
        setBaseDescription(data.baseDescription);
        setDescriptionSections(data.description);
        setConditions(data.conditions);
        setPrice(data.price);
        setEvents(data.events);
        setSmoke(data.smoke);
        setAnimals(data.animals);
        setChilds(data.childs);
        setTableSocialsActivities(data.tableSocialsActivities);
        setTableRemboursement(data.tableRemboursement);
      }
    }
  };
  useEffect(() => {}, []);

  //countrie city gestions
  useEffect(() => {
    const loadDataCountryCity = async () => {
      const encryptedData = localStorage.getItem(
        "currentCountrySelectedForAddResi"
      );
      if (encryptedData) {
        const data = await decryptData(encryptedData, true);
        if (data) {
          setCurrentCountrySelected(data.country);
          setCurrentCity(data.city);
        }
      }
    };
    loadDataCountryCity();
  }, []);

  const handleSaveCountry = async () => {
    try {
      let data = {
        country: currentCountrySelected,
        city: currentCity,
      };
      const encryptedData = await encryptData(data);
      localStorage.setItem("currentCountrySelectedForAddResi", encryptedData);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSaveAllData = async () => {
    try {
      // const residenceId = await handleAddResidenceOnServer(false);
      // if (!residenceId) return null;

      // Assurez-vous que saveImagesToLocalForage est défini correctement
      saveImagesToLocalForage(currentPhotosSelected.slice(0, 15));

      let data = {
        // residenceId,
        userId: userData.id,
        currentStep,
        currentTypeResi,
        currentWeighborhood,
        accessibilityResi,
        longLat,
        markerPosition,
        location,
        numberMaxPers,
        chambres,
        salon,
        douche,
        toilet,
        numberBathrooms,
        balcon,
        terrase,
        jardin,
        salleAManger,
        garage,
        bureau,
        salleSport,
        currentCommodities,
        title,
        baseDescription: baseDescription,
        description: descriptionSections,
        conditions,
        price,
        events,
        smoke,
        animals,
        childs,
        tableSocialsActivities,
        tableRemboursement,
      };

      // Assurez-vous que encryptData est défini correctement
      const encryptedData = await encryptData(data);
      localStorage.setItem("residenceData", encryptedData);

      // return residenceId;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const handleSaveResidence = async () => {
    if (currentCountrySelected != null) {
      handleSaveCountry();
    }
    handleUpdateBlockScreen(true);
    const residenceId = await handleSaveAllData();
    // if (!residenceId) {
    //   handleUpdateBodyOverflow(true);
    //   handleUpdateBlockScreen(false);
    //   NotificationService.addToQueue(2, t("errorSaveResidence"));
    //   NotificationService.addToQueue(1, "Le nom de la résidence est déjà pris");
    //   return;
    // }
    // setCurrentResidenceId(residenceId);
    let timeout = setTimeout(() => {
      NotificationService.addToQueue(0, t("resiSaved"));
      handleUpdateBlockScreen(false);
      handleUpdateBodyOverflow(true);
      navigate("/");
    }, 1000);
    setTimeoutGoOut(timeout);
  };
  return (
    <>
      {/* <Header /> */}
      <AddResidenceTopHeader
        handleSaveResidence={handleSaveResidence}
        deleteAllImagesFromLocalForage={deleteAllImagesFromLocalForage}
        currentStep={currentStep}
        modalSaveOpen={modalSaveOpen}
        setModalSaveOpen={setModalSaveOpen}
      />
      <ContainerPersoModal
        isOpen={openConfirmSendResidence}
        toggle={setOpenConfirmSendResidence}
        title={"Confirmation"}
        baseTop={true}
        className="modal-resi-container"
      >
        <div className="modal-resi-add | flcolm addgap8">
          <p
            style={{ fontSize: 18, fontFamily: "Inter" }}
            className="p-just -c"
          >
            {t("paragraphConfirmSendResidenceTxt")}{" "}
          </p>
          <span
            style={{ fontSize: 14, fontFamily: "Mori gothic" }}
            className="-c"
          >
            {t("tcLearnBefore")}
          </span>
          <button
            className="btn pry-btn"
            onClick={() => {
              handleAddResidence();
            }}
          >
            {t("letsGoTxtAdd")}
          </button>
        </div>
      </ContainerPersoModal>
      <main className="flex">
        <Cover />
        <StepOneAnnonce handleBlockBtn={setBlockNextBtn} step={currentStep} />
        <Step2TypeResidence
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          currentTypeResi={currentTypeResi}
          setTypeResi={setTypeResi}
        />
        <Step3Accessibility
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          accessibilityResi={accessibilityResi}
          setTypeAccessibilityResi={setTypeAccessibilityResi}
        />
        <Step4BasicsLocation
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          countries={countries}
          isSelectVisible={isSelectVisible}
          setIsSelectVisible={setIsSelectVisible}
          currentCountrySelected={currentCountrySelected}
          setCurrentCountrySelected={setCurrentCountrySelected}
          currentCity={currentCity}
          setCurrentCity={setCurrentCity}
          currentWeighborhood={currentWeighborhood}
          setCurrentWeighborhood={setCurrentWeighborhood}
          alreadyFetch={alreadyFetch}
          fetchFinish={fetchFinish}
        />
        <Step4Location
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          longLat={longLat}
          currentCountrySelected={currentCountrySelected}
          currentCity={currentCity}
          currentWeighborhood={currentWeighborhood}
          setLongLat={setLongLat}
          setMarkerPosition={setMarkerPosition}
          markerPosition={markerPosition}
          location={location}
          setLocation={setLocation}
        />
        <Step5InfoLocalite
          page={1}
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          numberMaxPers={numberMaxPers}
          setNumberMaxPers={setNumberMaxPers}
          chambres={chambres}
          setChambres={setChambres}
          numberBathrooms={numberBathrooms}
          setNumberBathrooms={setNumberBathrooms}
          salon={salon}
          setSalon={setSalon}
          toilet={toilet}
          setToilet={setToilet}
          garage={garage}
          setGarage={setGarage}
          douche={douche}
          setDouche={setDouche}
          balcon={balcon}
          setBalcon={setBalcon}
          terrase={terrase}
          setTerrase={setTerrase}
          jardin={jardin}
          setJardin={setJardin}
          salleAManger={salleAManger}
          setSalleAManger={setSalleAManger}
          bureau={bureau}
          setBureau={setBureau}
          salleSport={salleSport}
          setSalleSport={setSalleSport}
        />
        {/* <Step5InfoLocalite
          page={2}
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          numberMaxPers={numberMaxPers}
          setNumberMaxPers={setNumberMaxPers}
          chambres={chambres}
          setChambres={setChambres}
          numberBathrooms={numberBathrooms}
          setNumberBathrooms={setNumberBathrooms}
          salon={salon}
          setSalon={setSalon}
          toilet={toilet}
          setToilet={setToilet}
          garage={garage}
          setGarage={setGarage}
          douche={douche}
          setDouche={setDouche}
          balcon={balcon}
          setBalcon={setBalcon}
          terrase={terrase}
          setTerrase={setTerrase}
          jardin={jardin}
          setJardin={setJardin}
          salleAManger={salleAManger}
          setSalleAManger={setSalleAManger}
          bureau={bureau}
          setBureau={setBureau}
          salleSport={salleSport}
          setSalleSport={setSalleSport}
        />
        <Step5InfoLocalite
          page={3}
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          numberMaxPers={numberMaxPers}
          setNumberMaxPers={setNumberMaxPers}
          chambres={chambres}
          setChambres={setChambres}
          numberBathrooms={numberBathrooms}
          setNumberBathrooms={setNumberBathrooms}
          salon={salon}
          setSalon={setSalon}
          toilet={toilet}
          setToilet={setToilet}
          garage={garage}
          setGarage={setGarage}
          douche={douche}
          setDouche={setDouche}
          balcon={balcon}
          setBalcon={setBalcon}
          terrase={terrase}
          setTerrase={setTerrase}
          jardin={jardin}
          setJardin={setJardin}
          salleAManger={salleAManger}
          setSalleAManger={setSalleAManger}
          bureau={bureau}
          setBureau={setBureau}
          salleSport={salleSport}
          setSalleSport={setSalleSport}
        /> */}
        <StepSixResidenceBig
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
        />
        <Step7Comodities
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          currentCommodities={currentCommodities}
          setCurrentCommodities={setCurrentCommodities}
        />
        <Step8Photos
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          currentPhotosSelected={currentPhotosSelected}
          setCurrentStep={setCurrentStep}
          onDropFunction={onDropFunction}
        />
        <Step9Photos
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          setPhotosSelected={setPhotosSelected}
          currentPhotosSelected={currentPhotosSelected}
          setCurrentStep={setCurrentStep}
          onDropFunction={onDropFunction}
          saveImagesToIndexedDB={saveImagesToLocalForage}
        />
        <Step10NameProperty
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          title={title}
          setTitle={setTitle}
        />
        <Step11Description
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          baseDescription={baseDescription}
          setBaseDescription={setBaseDescription}
          descriptionSections={descriptionSections}
          setDescriptionSections={setDescriptionSections}
        />
        <StepTwelveFinish handleBlockBtn={setBlockNextBtn} step={currentStep} />
        <Step13FinishPublish
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          conditions={conditions}
          setConditions={setConditions}
        />
        <Step14Price
          language={language}
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          price={price}
          setPrice={setPrice}
          setCurrentP={setCurrentP}
          currentP={currentP}
        />
        <Step15Reglement
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          events={events}
          setEvents={setEvents}
          smoke={smoke}
          setSmoke={setSmoke}
          animals={animals}
          setAnimals={setAnimals}
          childs={childs}
          setChilds={setChilds}
        />
        <Step16SocialsActivities
          language={language}
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          tableSocialsActivities={tableSocialsActivities}
          setTableSocialsActivities={setTableSocialsActivities}
        />
        <Step17Remboursement
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          tableRemboursement={tableRemboursement}
          setTableRemboursement={setTableRemboursement}
        />
        <StepEndFinish
          handleBlockBtn={setBlockNextBtn}
          step={currentStep}
          successCount={successCount}
        />
      </main>
      <AddResidenceNextFooter
        step={currentStep}
        handleGoBackStep={handleGoBackStep}
        handleGoNextStep={handleGoNextStep}
        handleGoOpenForAdd={setOpenConfirmSendResidence}
        blockStatus={blockNextBtn}
        handleGoToHostMode={handleGoToHostMode}
      />
    </>
  );
}
