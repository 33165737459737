import React, { useEffect, useState } from "react";
import { EyeSlashed, Marker, Pass, Tel } from "../../../images/icons/JsxIcons";
import { useTranslation } from "react-i18next";
import { patch } from "../../../services/useFetchAction";
import { requestEndpoint } from "../../../data/requestEndpoint";
import { NotificationService } from "../../../services/Notification.service";
import { useStorePageManager } from "../../../store/PageManage";
export default function ChangeHabitation({
  handleUpdateBlockScreen,
  handleOutModal,
  userData,
  handleUpdateUser,
}) {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState("");
  const [currentHabitation, setCurrentHabitation] = useState("");
  const [stateNewPassword, setStateNewPassword] = useState({
    focus: false,
    error: false,
    good: false,
  });

  const [seeNewPassword, setSeeNewPassword] = useState(false);

  const [stateHabitation, setStateHabitation] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const handleUpdateHabitation = (e) => {
    let newHabitation = e.target.value;
    newHabitation = newHabitation.replace(/\b\w/g, (match) =>
      match.toUpperCase()
    );
    setCurrentHabitation(newHabitation);
    e.target.value = newHabitation;
  };
  useEffect(() => {
    if (currentHabitation.length >= 1) {
      if (currentHabitation.length >= 4) {
        setStateHabitation({ ...stateHabitation, error: false, good: true });
      } else {
        setStateHabitation({ ...stateHabitation, error: true, good: false });
      }
    } else {
      setStateHabitation({ ...stateHabitation, error: false, good: false });
    }
  }, [currentHabitation]);

  const handleGoNewPass = async () => {
    if (stateHabitation.good) {
      handleUpdateBlockScreen(true);
      // handleUpdateUser
      let data = {
        // password: newPassword,
        location: currentHabitation,
      };
      await patch(requestEndpoint.updateProfilData, data)
        .then((res) => {
          // console.log(res);
          handleUpdateUser({ location: currentHabitation });
          NotificationService.addToQueue(0, t("changeLocationDone"));
        })
        .catch((err) => {
          console.log(err);
          NotificationService.addToQueue(2, t("changeLocationNotDone"));
        })
        .finally(() => {
          useStorePageManager.getState().handleUpdateBodyOverflow(true);
          handleUpdateBlockScreen(false);
          handleOutModal();
        });
      console.log("new habitation update");
    }
  };
  return (
    <>
      <label>{t("putGooLocation")}</label>
      <div className="input-field | flcolm">
        {/* {!userData.isSocial && (
          <div className="input password | rlve">
            <Pass color={stateNewPassword.focus ? "var(--gray-600)" : "none"} />
            <input
              onBlur={() =>
                setStateNewPassword({ ...stateNewPassword, focus: false })
              }
              onFocus={() =>
                setStateNewPassword({ ...stateNewPassword, focus: true })
              }
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder={t("yourPassword")}
              type={seeNewPassword ? "text" : "password"}
              name="password"
            />
            <button
              onClick={() => setSeeNewPassword(!seeNewPassword)}
              className="see-pass | btn"
            >
              <EyeSlashed
                stroke={seeNewPassword ? "var(--gray-300)" : "#9CA3AF"}
              />
            </button>
          </div>
        )} */}

        <div className="input | rlve">
          <Marker
            stroke="var(--gray-400)"
            color={stateHabitation.focus ? "var(--gray-600)" : "none"}
          />
          <input
            onBlur={() =>
              setStateHabitation({ ...stateHabitation, focus: false })
            }
            onFocus={() =>
              setStateHabitation({ ...stateHabitation, focus: true })
            }
            onChange={handleUpdateHabitation}
            placeholder={t("newLocation")}
            type="text"
          />
          {stateHabitation.focus == false && stateHabitation.error && (
            <div className="detect-issue-input">
              <div className="chevron"></div>
              <span>{t("issueHabitation")}</span>
            </div>
          )}
        </div>
      </div>
      <button
        // style={
        //   userData.isSocial
        //     ? {
        //         marginTop: "60px",
        //         borderRadius: "8px",
        //       }
        //     : {}
        // }
        onClick={handleGoNewPass}
        className="next-btn | btn flex"
        disabled={
          !(
            stateHabitation.good
            // &&
            // (stateNewPassword.good || userData.isSocial)
          )
        }
      >
        {t("changeLocationHabit")}
      </button>
    </>
  );
}
