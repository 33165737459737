import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../../data/requestEndpoint";
import { NotificationService } from "../../../services/Notification.service";
import { patch } from "../../../services/useFetchAction";
import { useStorePageManager } from "../../../store/PageManage";
import InputPhoneNumber from "../InputPhoneNumber";
export default function ChangeTelephone({
  handleUpdateBlockScreen,
  handleOutModal,
  userData,
  setSaveTokenWaiting,
  setTelNumberWaiting,
  handleUpdateUser,
  setIndicatifNumber,
  indicatifNumber,
}) {
  const { t } = useTranslation();

  // const [newPassword, setNewPassword] = useState("");

  const [currentTelephone, setCurrentTelephone] = useState("");

  const [stateTelephone, setStateTelephone] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [curentCountrySelected, setCurentCountrySelected] = useState();
  const handleUpdateTelephone = (e) => {
    let newTelephone = e.target.value.replace(/[^0-9]/g, "");
    setCurrentTelephone(newTelephone);
    e.target.value = newTelephone;
  };
  useEffect(() => {
    //si userData.contact
    let compareContact = null;
    if (userData.contact) {
      compareContact = userData.contact.replace(/[^0-9]/g, "");
    }
    if (currentTelephone.length > 2) {
      if (
        currentTelephone.length >= 8 &&
        (indicatifNumber + currentTelephone).replace(/[^0-9]/g, "") !=
          compareContact
      ) {
        setStateTelephone({ ...stateTelephone, error: false, good: true });
      } else {
        setStateTelephone({ ...stateTelephone, error: true, good: false });
      }
    } else {
      setStateTelephone({ ...stateTelephone, error: false, good: false });
    }
  }, [currentTelephone, indicatifNumber]);

  const handleGoNewTel = async () => {
    if (stateTelephone.good) {
      handleUpdateBlockScreen(true);
      let tel = indicatifNumber + currentTelephone;
      if (tel[0] != "+") tel = "+" + tel;
      let data = {
        // password: newPassword
        contact: tel,
        contactActivatedAt: null,
      };
      await patch(requestEndpoint.updateProfilData, data)
        .then((res) => {
          NotificationService.addToQueue(1, "Confirmer le code envoyé par SMS");
          handleUpdateUser({ contactChecked: false });
          setSaveTokenWaiting(res.data.updateContactToken);
          setTelNumberWaiting(tel);
        })
        .catch((err) => {
          console.log(err);
          NotificationService.addToQueue(
            1,
            "Le numéro de téléphone est déjà utilisé"
          );
          NotificationService.addToQueue(2, t("changeTelNotDone"));
        })
        .finally(() => {
          useStorePageManager.getState().handleUpdateBodyOverflow(true);
          handleUpdateBlockScreen(false);
          handleOutModal();
        });
    }
  };
  // console.log(userData);
  return (
    <>
      <label>{t("enterNumberPreceded")}</label>
      <div className="input-field | flcolm">
        <InputPhoneNumber
          setStateTelephone={setStateTelephone}
          stateTelephone={stateTelephone}
          handleUptadeTelephone={handleUpdateTelephone}
          curentCountrySelected={curentCountrySelected}
          setCurentCountrySelected={setCurentCountrySelected}
          setindicatif={setIndicatifNumber}
          indicatif={indicatifNumber}
          isProfile={true}
        />
        {/* <div className="input | rlve">
          <Tel color={stateTelephone.focus ? "var(--gray-600)" : "none"} />
          <input
            onBlur={() =>
              setStateTelephone({ ...stateTelephone, focus: false })
            }
            onFocus={() =>
              setStateTelephone({ ...stateTelephone, focus: true })
            }
            onChange={handleUpdateTelephone}
            placeholder={t("newNumber")}
            type="tel"
          />
          {stateTelephone.focus == false && stateTelephone.error && (
            <div className="detect-issue-input">
              <div className="chevron"></div>
              <span>{t("issueTelType")}</span>
            </div>
          )}
        </div> */}
      </div>
      <button
        onClick={handleGoNewTel}
        className="next-btn | btn flex"
        disabled={!(stateTelephone.good && indicatifNumber.trim().length > 0)}
      >
        {t("changeNumero")}
      </button>
    </>
  );
}
