import React, { useRef, useState } from "react";
import "../../styles/components/signalementModal.css";
import { useStorePageManager } from "../../store/PageManage";
import { post } from "../../services/useFetchAction";
import { requestEndpoint } from "../../data/requestEndpoint";
import { NotificationService } from "../../services/Notification.service";
import { useTranslation } from "react-i18next";
import { useStoreManager } from "../../store/StoreManage";
import { useStoreUserManager } from "../../store/UserManage";
import Cookies from "js-cookie";
import ContainerPersoModal from "../biggest/ContainerPersoModal";

export default function SignalementModal({ handleOutModal, data }) {
  const [currentSelect, toogleSelect] = useState(0);
  const [openModalWrite, setOpenModalWrite] = useState(false);
  const { t } = useTranslation();
  const handleCheckIfAlreadySignaled = (justCheck = false) => {
    const cookieData = Cookies.get("signalementSave");
    const myArray = cookieData ? JSON.parse(cookieData) : [];
    let isAlreadySignaled = false;
    myArray.map((signalement) => {
      if (
        signalement.residenceId == data.id &&
        signalement.userId == useStoreUserManager.getState().user.id
      ) {
        isAlreadySignaled = true;
      }
    });
    if (isAlreadySignaled && justCheck == false) {
      NotificationService.addToQueue(1, "Vous avez déjà signalé cette annonce");
    }
    return isAlreadySignaled;
  };

  const handleSaveToCookies = (dataSignalement) => {
    const cookieData = Cookies.get("signalementSave");
    const myArray = cookieData ? JSON.parse(cookieData) : [];
    myArray.push(dataSignalement);
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 1);
    Cookies.set("signalementSave", JSON.stringify(myArray), {
      expires: expirationDate,
    });
  };
  const refTextComment = useRef(null);
  const [currentComment, setCurrentComment] = useState("");
  const handleChangeComment = (e) => {
    refTextComment.current.value = e.target.value.slice(0, 300);
    setCurrentComment(refTextComment.current.value);
  };

  const handleSendSignalement = async () => {
    if (currentSelect == 0) return;
    if (useStoreUserManager.getState().user.connected == false) {
      NotificationService.addToQueue(
        1,
        "Vous devez être connecté pour signaler une annonce"
      );
      useStorePageManager.getState().handleUpdateConnexionPanelState(true);
      return;
    }
    //verifier si l'utilisateur n'a pas déjà signalé cette annonce

    if (handleCheckIfAlreadySignaled()) {
      return;
    }
    useStorePageManager.getState().handleUpdateBlockScreen(true);
    let signaleReason = "";
    let signaleReasonEn = "";
    switch (currentSelect) {
      case 1:
        signaleReason = "Cette publication est fausse";
        signaleReasonEn = "This publication is false";
        break;
      case 2:
        signaleReason = "Ce n’est pas un endroit réel";
        signaleReasonEn = "It's not a real place";
        break;
      case 3:
        signaleReason = "Cette résidence à déjà été postée";
        signaleReasonEn = "This residence has already been posted";
        break;
      case 4:
        signaleReason = "Cette résidence m'appartient";
        signaleReasonEn = "This residence is mine";
        break;
      case 5:
        signaleReason = currentComment;
        signaleReasonEn = "Other";
        break;
      default:
        break;
    }
    try {
      let dataSend = {
        residenceId: data.id,
        reason: signaleReason,
        reasonEn: signaleReasonEn,
      };
      const response = await post(
        requestEndpoint.signalementResidence,
        dataSend
      );
      handleOutModal();
      NotificationService.addToQueue(0, "Signalement effectué");

      let dataSignalement = {
        residenceId: data.id,
        userId: useStoreUserManager.getState().user.id,
      };
      handleSaveToCookies(dataSignalement);
    } catch (error) {
      console.log(error);
      NotificationService.addToQueue(1, "Une erreur est survenue");
    }
    useStorePageManager.getState().handleUpdateBlockScreen(false);
  };

  const leftLenght = 150 - currentComment.length;
  return (
    <>
      <div className="signalement-modal | flcolm">
        <div className="title-modal flcolm addgap8">
          <label>Pourquoi souhaitez-vous signaler cette annonce?</label>
          <p>Cette action ne sera pas partagée à l’hôte.</p>
        </div>
        <div className="contents | flcolm">
          <div className="selectors | flcolm">
            {/* <button
            onClick={() => toogleSelect(1)}
            className="select-btn | btn btw rlve"
          >
            <span>Cette publication est fausse</span>
            <div className="selector-circle rlve">
              {currentSelect == 1 && <span className="center-absolute"></span>}
            </div>
          </button> */}
            <button
              onClick={() => toogleSelect(2)}
              className="select-btn | btn btw rlve"
            >
              <span>Ce n’est pas un endroit réel</span>
              <div className="selector-circle rlve">
                {currentSelect == 2 && (
                  <span className="center-absolute"></span>
                )}
              </div>
            </button>
            <button
              onClick={() => toogleSelect(3)}
              className="select-btn | btn btw rlve"
            >
              <span>Cette résidence à déjà été postée</span>
              <div className="selector-circle rlve">
                {currentSelect == 3 && (
                  <span className="center-absolute"></span>
                )}
              </div>
            </button>
            <button
              onClick={() => toogleSelect(4)}
              className="select-btn | btn btw rlve"
            >
              <span>Cette résidence m'appartient</span>
              <div className="selector-circle rlve">
                {currentSelect == 4 && (
                  <span className="center-absolute"></span>
                )}
              </div>
            </button>
            <button
              onClick={() => toogleSelect(5)}
              className="select-btn | btn btw rlve"
            >
              <span>Autre</span>
              <div className="selector-circle rlve">
                {currentSelect == 5 && (
                  <span className="center-absolute"></span>
                )}
              </div>
            </button>
          </div>
        </div>
        {currentSelect == 5 ? (
          <button
            disabled={openModalWrite}
            onClick={() => setOpenModalWrite(true)}
            className="validate-btn | btn pry-btn"
          >
            <span>Suivant</span>
          </button>
        ) : (
          <button
            style={
              handleCheckIfAlreadySignaled(true)
                ? {
                    opacity: 1,
                    background: "var(--gray-500)",
                    cursor: "not-allowed",
                  }
                : {}
            }
            onClick={handleSendSignalement}
            disabled={currentSelect == 0}
            className="validate-btn | btn pry-btn"
          >
            <span>Envoyer</span>
          </button>
        )}
      </div>
      <ContainerPersoModal
        isOpen={openModalWrite}
        toggle={setOpenModalWrite}
        title="Ecrivez votre message"
        baseTop={true}
        noOverflow={true}
        className="modal-write"
      >
        <div className="modal-write-send flcolm addgap8">
          <div className="comment-div | flcolm">
            <textarea
              ref={refTextComment}
              maxLength={150}
              onChange={handleChangeComment}
              className="comment-part"
              placeholder={"Ecrivez votre message ici"}
            ></textarea>
            <span>{leftLenght}/150</span>
          </div>
          <button
            style={
              handleCheckIfAlreadySignaled(true)
                ? {
                    opacity: 1,
                    borderRadius: 8,
                    background: "var(--gray-500)",
                    cursor: "not-allowed",
                  }
                : {
                    borderRadius: 8,
                  }
            }
            disabled={leftLenght > 145}
            onClick={handleSendSignalement}
            className="btn pry-btn"
          >
            <span>{"Envoyer"}</span>
          </button>
        </div>
      </ContainerPersoModal>
    </>
  );
}
