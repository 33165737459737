/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { dataComodities } from "../../data/dataComodities";
import { requestEndpoint } from "../../data/requestEndpoint";
import { User } from "../../images/icons/JsxIcons";
import { NotificationService } from "../../services/Notification.service";
import { decryptData, encryptData } from "../../services/encryption.service";
import {
  patch,
  useGetData,
  useGetDelayRequest,
} from "../../services/useFetchAction";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreUserManager } from "../../store/UserManage";
import { useStoreComodities } from "../../store/dataComodities";
import { useStoreCurrencies } from "../../store/dataCurrencies";
import { useStoreAllStaticData } from "../../store/dataForAll";
import ContainerPersoModal from "./ContainerPersoModal";
export default function InitialyzeService() {
  const handleUpdateFavorisUser = useStoreUserManager(
    (state) => state.handleUpdateFavorisUser
  );
  const { t } = useTranslation();
  const userD = useStoreUserManager((state) => state.user);
  const location = useLocation();
  //get favoris
  const [dataFavoris, loadingFav, errorFav, fetchDataFavorite] =
    useGetDelayRequest({
      route: requestEndpoint.favoris,
      delay: 100,
      log: false,
    });

  const handleUpdateUser = useStoreUserManager(
    (state) => state.handleUpdateUser
  );
  const handleUpdateVersementMethod = useStoreAllStaticData(
    (state) => state.handleUpdateVersementMethod
  );

  const [dataPaiement, loadingPaiement, errorPaiement, fetchDataPaiement] =
    useGetDelayRequest({
      route: requestEndpoint.payment_methods_versement,
      delay: 0,
      loadingTime: 0,
    });
  useEffect(() => {
    fetchDataPaiement();
  }, []);
  useEffect(() => {
    if (loadingPaiement) return;
    if (dataPaiement) {
      let list = [];
      dataPaiement.data.map((item) => {
        list.push({
          ...item,
          selected: false,
        });
      });
      handleUpdateVersementMethod(list);
    }
  }, [dataPaiement, loadingPaiement]);
  // console.log("userD", userD);
  const handleDisconnect = () => {
    handleUpdateUser({
      lastname: "",
      firstname: "",
      connected: false,
      profil: "",
      mail: "",
      contact: "",
      isSocial: false,
    });
    useStoreUserManager.getState().handleDisconnectUser();
    handleUpdateFavorisUser([]);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  };
  useEffect(() => {
    if (loadingFav) return;
    if (!dataFavoris) return;
    if (errorFav) {
      console.log("errorFav", errorFav);
      // handleDisconnect();
    }
    if (errorFav) {
      // console.log("errorFav", errorFav);
      if (errorFav.response) {
        let status = errorFav?.response?.status;
        // if (status == 401) handleDisconnect();
      }
      handleUpdateFavorisUser([]);
      return;
    }
    let newFavoris = [];
    dataFavoris.data.map((fav) => {
      newFavoris.push(fav.residenceId);
    });
    handleUpdateFavorisUser(newFavoris);
  }, [loadingFav, userD, dataFavoris]);

  useEffect(() => {
    if (userD) {
      if (userD.connected) {
        fetchDataFavorite();
      }
    }
  }, [userD, location.pathname]);
  //Currencies
  const [dataCurrencies, loadingCurrencies, errorCurrencies] = useGetData({
    route: "/residences/currencies-list",
    testing: false,
  });
  const handleUpdateCurrencies = useStoreCurrencies(
    (state) => state.handleUpdateCurrencies
  );
  useEffect(() => {
    if (loadingCurrencies) return;
    if (!dataCurrencies) return;
    handleUpdateCurrencies({ currencies: dataCurrencies });
  }, [loadingCurrencies, dataCurrencies]);
  //Currencies
  const [dataRules, loadingRules, errorRules] = useGetData({
    route: "/residences/residence-rules-list",
    testing: false,
  });
  const handleUpdateRules = useStoreCurrencies(
    (state) => state.handleUpdateRules
  );
  useEffect(() => {
    if (loadingRules) return;
    if (!dataRules) return;
    // console.log("dataRules", dataRules);
    handleUpdateRules(dataRules);
  }, [loadingRules, dataRules]);
  //socials
  const [dataSocial, loadingSocial, errorSocial] = useGetData({
    route: "/residences/residence-activities-list",
    testing: false,
  });
  const handleUpdateSocials = useStoreCurrencies(
    (state) => state.handleUpdateSocials
  );
  useEffect(() => {
    if (loadingSocial) return;
    if (!dataSocial) return;
    // console.log("dataSocial", dataSocial);
    handleUpdateSocials(dataSocial);
  }, [loadingSocial, dataSocial]);

  //Type info
  const [dataTypeInfo, loadingTypeInfo, errorTypeInfo] = useGetData({
    route: requestEndpoint.getTypeResidence,
    testing: false,
  });
  const handleUpdateTypeResi = useStoreAllStaticData(
    (state) => state.handleUpdateTypeResi
  );
  useEffect(() => {
    if (loadingTypeInfo) return;
    if (!dataTypeInfo) return;
    let newDataTypeInfo = [];
    dataTypeInfo.map((type) => {
      newDataTypeInfo.push({
        id: type.id,
        name: type.name,
        nameEng: type.nameEn,
        icon:
          process.env.REACT_APP_API_URL +
          process.env.REACT_APP_LINK_TYPE_DOC +
          type.icon,
      });
    });
    // console.log("newDataTypeInfo", newDataTypeInfo);
    handleUpdateTypeResi(newDataTypeInfo);
  }, [loadingTypeInfo, dataTypeInfo]);
  //Auto connect
  const handleAutoConnect = async (dataEncrypted) => {
    const dataDecrypted = await decryptData(dataEncrypted);
    if (!dataDecrypted) {
      handleUpdateUser({
        lastname: "",
        firstname: "",
        connected: false,
        profil: "",
        mail: "",
        contact: "",
      });
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.reload();
      return;
    }
    axios
      .post(process.env.REACT_APP_API_URL + "/users/autologin", {
        email: dataDecrypted.email,
        token: dataDecrypted.token,
      })
      .then(async (res) => {
        // console.log("data-user", res);
        let dataUserSave = {
          token: res.data.token,
          email: res.data.email,
        };
        let dataToken = {
          access_token: res.data.access_token,
          refresh_token: res.data.refresh_token,
        };

        let dataEncrypted = await encryptData(dataUserSave);

        let encryptToken = await encryptData(dataToken);

        // console.log("dataEncrypted", res.data.contact);
        // console.log("res.data", res.data);
        handleUpdateUser({
          id: res.data.id,
          lastname: res.data.lastname,
          firstname: res.data.firstname,
          location: res.data.location,
          connected: true,
          profil: res.data.profile + `${res.data.enableHost ? "/hote" : ""}`,
          mail: res.data.email,
          contact: res.data.contact,
          emailVerified: res.data.mailActivatedAt && true,
          identityDoc: res.data.identityDoc ?? null,
          pieceChecked: res.data.identityDoc ? 1 : 0,
          profilImgUrl: res.data.avatar ?? null,
          contactChecked: res.data.contactActivatedAt && true,
          isSocial: res.data.facebookEmail
            ? true
            : res.data.googleEmail
            ? true
            : false,
          typeSocial: res.data.facebookEmail
            ? "facebook"
            : res.data.googleEmail
            ? "google"
            : "",
          enableHost: res.data.enableHost,
          payment_method_id: res.data.payment_method_id ?? 0,
        });

        await localStorage.setItem("token", encryptToken);
        await localStorage.setItem("user", dataEncrypted);

        fetchDataFavorite();
      })
      .catch((err) => {
        handleUpdateUser({
          lastname: "",
          firstname: "",
          connected: false,
          profil: "",
          mail: "",
          contact: "",
        });
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload();
      });
  };
  useEffect(() => {
    const fetchData = async () => {
      const dataEncrypted = localStorage.getItem("user");
      if (dataEncrypted) {
        fetchDataFavorite();
        // await handleAutoConnect(dataEncrypted);
      } else {
        handleDisconnect();
        localStorage.removeItem("user");
        localStorage.removeItem("token");
      }
    };
    fetchData();
  }, []);
  //Commodities
  const handleUpdateComodities = useStoreComodities(
    (state) => state.handleUpdateComodities
  );
  const [dataCommoditiesGet, loadingCommodities, errorCommodities] = useGetData(
    {
      route: "/residences/residence-assets-list",
      testing: false,
    }
  );
  useEffect(() => {
    if (loadingCommodities) return;
    if (!dataComodities) return;
    if (!dataCommoditiesGet) return;
    let dataInCommodities = dataComodities.data;
    // console.log("dataInCommodities", dataCommoditiesGet);
    let newCommodities = [];
    dataCommoditiesGet.map((com) => {
      let find = false;
      // console.log("find", com);
      for (let i = 0; i < dataInCommodities.length; i++) {
        if (dataInCommodities[i].idApi === com.id && find) {
          find = true;
          newCommodities.push({
            id: com.id,
            idApi: com.id,
            title: com.name,
            titleEng: com.nameEn,
            thumb: dataInCommodities[i].thumb,
            secondThumb: dataInCommodities[i].secondThumb,
          });
          break;
        }
        if (find) break;
      }

      if (!find) {
        newCommodities.push({
          id: com.id,
          idApi: com.id,
          title: com.name,
          titleEng: com.nameEn,
          thumb:
            process.env.REACT_APP_API_URL +
            process.env.REACT_APP_LINK_ICONS_DOC +
            com.icon,
          secondThumb:
            process.env.REACT_APP_API_URL +
            process.env.REACT_APP_LINK_ICONS_DOC +
            com.icon,
        });
      }
    });
    handleUpdateComodities(newCommodities);
  }, [loadingCommodities, dataComodities]);

  const [dataInscription, setDataInscription] = useState({
    addressMail: "",
    telephone: "",
    naming: "",
    firstName: "",
    mdp: "",
  });
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    if (userD?.connected) {
      if (!userD?.firstname || !userD?.lastname) {
        setOpenModal(true);
      } else {
        setOpenModal(false);
      }
    }
  }, [userD]);
  const [stateNaming, setStateNaming] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [stateFirstName, setFirstName] = useState({
    focus: false,
    error: false,
    good: false,
  });
  useEffect(() => {
    if (dataInscription.firstName.length > 0) {
      if (dataInscription.firstName.length > 2) {
        setFirstName({ ...stateFirstName, error: false, good: true });
      } else {
        setFirstName({ ...stateFirstName, error: true, good: false });
      }
    } else {
      setFirstName({ ...stateFirstName, error: false, good: false });
    }
  }, [dataInscription.firstName]);
  useEffect(() => {
    if (dataInscription.naming.length > 0) {
      if (dataInscription.naming.length > 2) {
        setStateNaming({ ...stateNaming, error: false, good: true });
      } else {
        setStateNaming({ ...stateNaming, error: true, good: false });
      }
    } else {
      setStateNaming({ ...stateNaming, error: false, good: false });
    }
  }, [dataInscription.naming]);

  const handleGoUpdateName = async () => {
    if (!stateFirstName.good || !stateNaming.good) return;
    useStorePageManager.getState().handleUpdateBlockScreen(true);
    let data = {
      firstname: dataInscription.firstName,
      lastname: dataInscription.naming,
    };
    await patch(requestEndpoint.updateProfilData, data)
      .then((res) => {
        handleUpdateUser({
          firstname: dataInscription.firstName,
          lastname: dataInscription.naming,
        });
        NotificationService.addToQueue(0, "Modification effectuée");
        setOpenModal(false);
      })
      .catch((err) => {
        NotificationService.addToQueue(2, "Une erreur est survenue");
      })
      .finally(() => {
        useStorePageManager.getState().handleUpdateBlockScreen(false);
        useStorePageManager.getState().handleUpdateBodyOverflow(true);
      });
  };

  // useEffect(async () => {
  //   let result = await createGetRouteNew(requestEndpoint.getAllComments, {
  //     assetIds: [1, 2, 3],
  //     activitiesIds: [1, 2, 3],
  //     roomIds: [
  //       { id: 1, quantity: 100 },
  //       { id: 2, quantity: 200 },
  //     ],
  //   });
  //   console.log(result);
  // }, []);

  if (!userD?.connected) return null;
  if (!openModal) return null;
  return (
    <ContainerPersoModal
      title="Modifier votre nom et prénom"
      isOpen={openModal}
      toggle={() => ""}
      baseTop={true}
      className="modal-change-name contains inscription-part"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleGoUpdateName();
        }}
        className="chooseName | flcolm"
      >
        <div className="input-select-perso input-field | flcolm addgap8">
          <div className="input | rlve">
            <User color={stateNaming.focus ? "var(--gray-600)" : "none"} />
            <input
              on
              onBlur={() => setStateNaming({ ...stateNaming, focus: false })}
              onFocus={() => setStateNaming({ ...stateNaming, focus: true })}
              onChange={(e) =>
                setDataInscription({
                  ...dataInscription,
                  naming: e.target.value,
                })
              }
              placeholder={t("lastNamePlaceholderTxt")}
              type="text"
              name="lastname"
            />
            {stateNaming.focus == false && stateNaming.error && (
              <div className="detect-issue-input">
                <div className="chevron"></div>
                <span>{t("issueNameType")}</span>
              </div>
            )}
          </div>
          <div className="input | rlve">
            <User color={stateFirstName.focus ? "var(--gray-600)" : "none"} />
            <input
              on
              onBlur={() => setFirstName({ ...stateFirstName, focus: false })}
              onFocus={() => setFirstName({ ...stateFirstName, focus: true })}
              onChange={(e) =>
                setDataInscription({
                  ...dataInscription,
                  firstName: e.target.value,
                })
              }
              placeholder={t("firstNamePlaceholderTxt")}
              type="text"
              name="firstname"
            />
            {stateFirstName.focus == false && stateNaming.error && (
              <div className="detect-issue-input">
                <div className="chevron"></div>
                <span>{t("issueNameType")}</span>
              </div>
            )}
          </div>
        </div>
        <button
          type="button"
          disabled={!stateFirstName.good || !stateNaming.good}
          onClick={() => handleGoUpdateName()}
          style={{
            padding: 12,
            position: "absolute",
            bottom: 16,
            left: 16,
            width: "calc(100% - 32px)",
            borderRadius: 8,
            zIndex: 5,
          }}
          className="confirm-btn | btn opt-btn"
        >
          <span style={{ fontWeight: 600 }}>Confirmer</span>
        </button>
      </form>
    </ContainerPersoModal>
  );
}
