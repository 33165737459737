export const VectorPaiementLeft = ({
  className = "",
  stokeColor = "#A273FF",
  strokeWidth = "1.5px",
}) => {
  return (
    <svg
      style={{ zIndex: 0 }}
      className={className}
      alt="design-vector"
      xmlns="http://www.w3.org/2000/svg"
      width="351"
      height="358"
      viewBox="0 0 351 358"
      fill="none"
    >
      <path
        d="M350.419 838.648C113.434 1025.18 -387.655 867.987 -350.2 597.88C-321.55 391.271 -99.4678 471.233 82.2077 244.437C330.288 -65.2567 -23.4798 41.2224 -108.664 -124.364"
        stroke={stokeColor}
        strokeWidth={strokeWidth}
        strokeDasharray="8 8"
      />
    </svg>
  );
};
export const VectorPaiementLeftMobile = ({
  className = "",
  stokeColor = "#A273FF",
  strokeWidth = "1.5px",
}) => {
  return (
    <svg
      style={{ zIndex: 1 }}
      className={className}
      alt="design-vector"
      xmlns="http://www.w3.org/2000/svg"
      width="153"
      height="202"
      viewBox="0 0 153 202"
      fill="none"
    >
      <path
        d="M-12.1314 195.355C64.0665 222.795 178.422 140.263 145.981 74.6135C121.166 24.397 71.3565 63.5977 5.83873 21.2095C-83.6267 -36.6723 15.5419 -39.53 23.2106 -88.9437"
        stroke={stokeColor}
        strokeWidth={strokeWidth}
        strokeDasharray="8 8"
      />
    </svg>
  );
};
export const VectorHowResidenceLeft = ({
  className = "",
  stokeColor = "#A273FF",
  strokeWidth = "1.5px",
}) => {
  return (
    <svg
      className={className}
      width="495"
      height="238"
      viewBox="0 0 495 238"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-513.101 237.27C-672.334 -18.8579 -824.822 -261.543 -560.501 -194.5C-358.317 -143.217 -165.855 -186.601 39.4995 19.0002C319.915 299.752 320.726 -46.3157 494.704 -112.701"
        stroke={stokeColor}
        strokeWidth={strokeWidth}
        strokeDasharray="8 8"
      />
    </svg>
  );
};
export const MailIcon = ({
  className = "",
  color = "none",
  stokeColor = "#374151",
  strokeWidth = "2",
}) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mail-01">
        <path
          id="Icon"
          d="M1.5 5.25L7.62369 9.53658C8.11957 9.8837 8.36751 10.0573 8.6372 10.1245C8.87542 10.1839 9.12458 10.1839 9.3628 10.1245C9.63249 10.0573 9.88043 9.8837 10.3763 9.53658L16.5 5.25M5.1 15H12.9C14.1601 15 14.7902 15 15.2715 14.7548C15.6948 14.539 16.039 14.1948 16.2548 13.7715C16.5 13.2902 16.5 12.6601 16.5 11.4V6.6C16.5 5.33988 16.5 4.70982 16.2548 4.22852C16.039 3.80516 15.6948 3.46095 15.2715 3.24524C14.7902 3 14.1601 3 12.9 3H5.1C3.83988 3 3.20982 3 2.72852 3.24524C2.30516 3.46095 1.96095 3.80516 1.74524 4.22852C1.5 4.70982 1.5 5.33988 1.5 6.6V11.4C1.5 12.6601 1.5 13.2902 1.74524 13.7715C1.96095 14.1948 2.30516 14.539 2.72852 14.7548C3.20982 15 3.83988 15 5.1 15Z"
          stroke={stokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
export const CheckCircle = ({
  className = "",
  color = "#A273FF",
  stokeColor = "#A273FF",
  strokeWidth = "2",
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color}
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill={stokeColor}
      />
      <path
        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={stokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 12L10.5 15L16.5 9"
        stroke="white"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const VectorPopuResidenceRight = ({
  className = "",
  stokeColor = "#A273FF",
  strokeWidth = "1.5px",
}) => {
  return (
    <svg
      className={className}
      width="1239"
      height="788"
      viewBox="0 0 1239 788"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.32647 1024.73C37.9115 947.732 256.09 619.485 585.452 648.492C997.155 684.751 1244.9 469.938 1237.55 196.705C1231.67 -21.8801 1177.49 -171.101 1151.14 -218.388"
        stroke={stokeColor}
        strokeWidth={strokeWidth}
        strokeDasharray="10 10"
      />
    </svg>
  );
};
