import React, { useEffect, useState } from "react";
import "../../styles/components/addResidencePart/step16.css";
import { Check, Warning, XClose } from "../../images/icons/JsxIcons";
import checkIcon from "../../images/icons/check.svg";
import dataSocialActivities from "../../data/dataSocialsActivities.json";
import { useStoreShareFunction } from "../../store/StoreShareFunction";

const currentStep = 16;
export default function Step16SocialsActivities({
  language,
  step,
  handleBlockBtn,
  tableSocialsActivities,
  setTableSocialsActivities,
}) {
  useEffect(() => {
    let newTabSocial = [];
    dataSocialActivities.data.map((activitie) => {
      let newSelectables = [];
      activitie.selectables.map((selectable) => {
        let newSelectable = { ...selectable, select: false };
        newSelectables.push(newSelectable);
      });
      let newActivitie = { ...activitie, selectables: newSelectables };
      newTabSocial.push(newActivitie);
    });
    setTableSocialsActivities(newTabSocial);
  }, []);
  useEffect(() => {
    if (currentStep == step) handleBlockBtn(false);
  }, [step]);

  const handleUpdateSelected = (key) => {
    let socialTabUpdated = [];
    tableSocialsActivities.map((activitie) => {
      let newSelectables = [];
      activitie.selectables.map((selectable) => {
        let newSelectable = selectable;
        if (key == selectable.id) {
          newSelectable = { ...newSelectable, select: !newSelectable.select };
        }
        newSelectables.push(newSelectable);
      });
      let newActivitie = { ...activitie, selectables: newSelectables };
      socialTabUpdated.push(newActivitie);
    });
    setTableSocialsActivities(socialTabUpdated);
  };
  const checkIfBlock = () => {
    let check = false;
    tableSocialsActivities.map((activitie) => {
      activitie.selectables.map((selectable) => {
        if (selectable.select) {
          check = true;
        }
      });
    });
    return check;
  };
  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step && checkIfBlock()) {
      document.activeElement.blur();
      useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [checkIfBlock, step, tableSocialsActivities]);
  return (
    <section
      className={`step-1-annonce step-16 ${
        currentStep == step ? "visible" : ""
      } 
      ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input input-resi | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            <label>Activités sociales</label>
            <p>
              Choisissez les types d’activités sociales que vous autorisez dans
              votre résidence.
            </p>
            <div className="regle | flrow liner">
              <div className="circle | flex jcenter liner">
                <Warning />
              </div>
              <p>
                Trouvechap n'assure pas le respect de vos règles par vos
                clients. Vous serez donc en charge des mesures à adopter face au
                non-respect des règles par vos clients.
              </p>
            </div>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm jcenter liner">
          <div className="contains-input | flcolm jcenter liner">
            <div className="contains-selects | flcolm">
              <div className="activities-type | flcolm">
                {tableSocialsActivities.map((activitie, index) => {
                  return (
                    <div
                      key={"activitie-" + index + activitie.label}
                      className="contains-zone | flcolm"
                    >
                      <label>{activitie.label}</label>
                      {activitie.selectables.map((selectable) => {
                        return (
                          <button
                            onClick={() => handleUpdateSelected(selectable.id)}
                            key={"selectable-" + selectable.id}
                            className="btn | flrow"
                          >
                            <span>
                              {" "}
                              {language == "fr-FR"
                                ? selectable.name
                                : selectable.nameEn}
                            </span>
                            <div className="case-cocher | flex jcenter liner">
                              {selectable.select && <div></div>}
                            </div>
                          </button>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
