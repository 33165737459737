import React, { useEffect, useState } from "react";
import "../../styles/components/addResidencePart/step5.css";
import AddLessDiv from "../molecules/AddLessDiv";
import { useStoreShareFunction } from "../../store/StoreShareFunction";
const currentStep = 5;
export default function Step5InfoLocalite({
  page,
  step,
  handleBlockBtn,
  numberMaxPers,
  setNumberMaxPers,
  chambres,
  setChambres,
  salon,
  setSalon,
  douche,
  setDouche,
  toilet,
  setToilet,
  numberBathrooms,
  setNumberBathrooms,
  balcon,
  setBalcon,
  terrase,
  setTerrase,
  jardin,
  setJardin,
  salleAManger,
  setSalleAManger,
  garage,
  setGarage,
  bureau,
  setBureau,
  salleSport,
  setSalleSport,
}) {
  useEffect(() => {
    if (currentStep == step)
      handleBlockBtn(
        numberMaxPers == 0 || chambres == 0 || numberBathrooms == 0
      );
  }, [step, numberMaxPers, chambres, numberBathrooms]);
  const checkIfBlock = () => {
    return numberMaxPers == 0 || chambres == 0 || numberBathrooms == 0;
  };
  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step && !checkIfBlock()) {
      document.activeElement.blur();
      useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [checkIfBlock, step, numberMaxPers, chambres, numberBathrooms]);

  return (
    <section
      className={`step-1-annonce step-5 ${
        currentStep + page - 1 == step ? "visible" : ""
      } 
      ${currentStep + page - 1 < step ? "go-top" : ""} ${
        currentStep + page - 1 > step ? "go-bottom" : ""
      } add-resi add-resi-input input-resi | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            {/* <span className="step">{page}/3</span> */}
            <label>
              Donnez les informations principales concernant votre résidence.
            </label>
            <span>
              Vous pourrez ajouter d'autres informations ou modifier celles
              présentes plus tard dans votre dashboard hote.
            </span>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm jcenter liner">
          <div className="contains-input | flcolm jcenter liner">
            <div className="filter-selected-panel | flcolm">
              <div className="info-localite | flcolm">
                {page == 1 && (
                  <>
                    <AddLessDiv
                      classname="maxPers-nbr"
                      title={"Nombre de personnes max"}
                      setData={setNumberMaxPers}
                      data={numberMaxPers}
                      // required={true}
                    />
                    <AddLessDiv
                      classname="room-nbr"
                      title={"Nombre de chambres"}
                      setData={setChambres}
                      data={chambres}
                      // required={true}
                    />
                    <AddLessDiv
                      classname="bathroom-nbr"
                      title={"Nombre de salles de bain"}
                      setData={setNumberBathrooms}
                      data={numberBathrooms}
                      // required={true}
                    />
                    <AddLessDiv
                      classname="bed-nbr"
                      title={"Nombre de salons"}
                      setData={setSalon}
                      data={salon}
                    />
                    {/* <AddLessDiv
                      classname="toilet-nbr"
                      title={"Nombre de toillettes"}
                      setData={setToilet}
                      data={toilet}
                    /> */}
                  </>
                )}
                {page == 2 && (
                  <>
                    {/* <AddLessDiv
                      classname="maxPers-nbr"
                      title={"Nombre de douches"}
                      setData={setDouche}
                      data={douche}
                    />
                    <AddLessDiv
                      classname="room-nbr"
                      title={"Nombre de balcons"}
                      setData={setBalcon}
                      data={balcon}
                    />
                    <AddLessDiv
                      classname="bathroom-nbr"
                      title={"Nombre de terrasses"}
                      setData={setTerrase}
                      data={terrase}
                    />
                    <AddLessDiv
                      classname="bed-nbr"
                      title={"Nombre de garages"}
                      setData={setGarage}
                      data={garage}
                    /> */}
                  </>
                )}
                {page == 3 && (
                  <>
                    {/* <AddLessDiv
                      classname="maxPers-nbr"
                      title={"Nombre de jardins"}
                      setData={setJardin}
                      data={jardin}
                    />
                    <AddLessDiv
                      classname="room-nbr"
                      title={"Nombre de salles à manger"}
                      setData={setSalleAManger}
                      data={salleAManger}
                    />
                    <AddLessDiv
                      classname="bathroom-nbr"
                      title={"Nombre de bureaux"}
                      setData={setBureau}
                      data={bureau}
                    />
                    <AddLessDiv
                      classname="bed-nbr"
                      title={"Nombre de salles de sport"}
                      setData={setSalleSport}
                      data={salleSport}
                    /> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
