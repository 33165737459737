/* eslint-disable eqeqeq */
import { Autocomplete } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import chevronDown from "../../images/icons/chevron-down.svg";
import { NotificationService } from "../../services/Notification.service";
import { useStoreShareFunction } from "../../store/StoreShareFunction";
import "../../styles/components/addResidencePart/step10.css";

const currentStep = 3;
export default function Step4BasicsLocation({
  step,
  handleBlockBtn,
  countries = [],
  isSelectVisible,
  setIsSelectVisible,
  currentCountrySelected,
  setCurrentCountrySelected,
  currentCity,
  setCurrentCity,
  currentWeighborhood,
  setCurrentWeighborhood,
  alreadyFetch,
  fetchFinish,
}) {
  const { t } = useTranslation();
  const selectRef = useRef();
  const refInputCity = useRef();
  const [isLoadedCity, setIsLoadedCity] = useState(false);
  const refInputWeighborhood = useRef();
  const [isLoadedWeighborhood, setIsLoadedWeighborhood] = useState(false);
  const [waitingToAddMarker, setWaitingToAddMarker] = useState(false);
  const [countriesFiltered, setCountriesFiltered] = useState([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [lastThereCountry, setLastThereCountry] = useState(null);
  useEffect(() => {
    setCountriesFiltered(countries);
  }, [countries]);
  const handleInputFocus = () => {
    setLastThereCountry(currentCountrySelected);
    setIsInputFocused(true);
  };
  // Fonction pour gérer la perte de focus de l'input
  const handleInputBlur = () => {
    setIsInputFocused(false);
    if (lastThereCountry && !currentCountrySelected) {
      setCurrentCountrySelected(lastThereCountry);
      inputCountryRef.current.value = lastThereCountry.name;
    }
    setLastThereCountry(null);
  };
  const handleFilterCountry = (e) => {
    setCurrentCountrySelected(null);
    let value = e.target.value;
    value = value.trim();
    if (value.length == 0) {
      setCountriesFiltered(countries);
      return;
    }
    value = value.replace(/[!@#$%^&*(),.?":{}|<>]/g, "");

    //replace all special characters like é è à ù etc by their normal character
    value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    let countriesFiltered = countries.filter((country) => {
      let countryName = country.name.toLowerCase();
      countryName = countryName.replace(/[!@#$%^&*(),.?":{}|<>]/g, "");
      countryName = countryName
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return countryName.includes(value.toLowerCase());
    });
    setCountriesFiltered(countriesFiltered);
  };

  useEffect(() => {
    if (isLoadedCity) return;
    if (!refInputCity.current) return;
    if (currentCity.toString().length > 0) {
      setIsLoadedCity(true);
      refInputCity.current.value = currentCity;
    }
  }, [currentCity, refInputCity, waitingToAddMarker]);
  useEffect(() => {
    if (isLoadedWeighborhood) return;
    if (!refInputWeighborhood.current) return;
    if (currentWeighborhood.toString().length > 0) {
      setIsLoadedWeighborhood(true);
      refInputWeighborhood.current.value = currentWeighborhood;
    }
  }, [currentWeighborhood, refInputWeighborhood, waitingToAddMarker]);

  const checkIfBlock = () => {
    let isBloked = false;
    if (currentWeighborhood.trim().length <= 2) {
      isBloked = true;
    }
    if (currentCity.trim().length <= 2) {
      isBloked = true;
    }
    if (currentCountrySelected == null) {
      isBloked = true;
    }
    return isBloked;
  };
  useEffect(() => {
    if (currentStep != step) return;
    let isBloked = checkIfBlock();
    handleBlockBtn(isBloked);
  }, [step, currentWeighborhood, currentCity, currentCountrySelected]);

  const checkAndReturnGoodPart = (value) => {
    if (value == null) return null;
    let valueText = value;
    if (valueText.trim() != "") {
      valueText = valueText.split(",")[0];
      valueText = valueText.split(".")[0];
      valueText = valueText.split("-")[0];
      valueText = valueText.split("(")[0];
      valueText = valueText.split(")")[0];
    }
    return valueText;
  };
  const [oldLocation, setOldLocation] = useState("");
  const handleBlurForUpdateLocation = () => {
    let isBloked = checkIfBlock();
    let currentInputCity = refInputCity.current.value;
    if (currentInputCity.trim() != "") {
      currentInputCity = currentInputCity.split(",")[0];
      currentInputCity = checkAndReturnGoodPart(currentInputCity)
        .trim()
        .slice(0, 30);
      setCurrentCity(currentInputCity.toLocaleUpperCase());
      refInputCity.current.value = currentInputCity.toLocaleUpperCase();
    }
    let currentInputWeighborhood = refInputWeighborhood.current.value;
    if (currentInputWeighborhood.trim() != "") {
      currentInputWeighborhood = checkAndReturnGoodPart(
        currentInputWeighborhood
      );
      currentInputWeighborhood = currentInputWeighborhood.trim().slice(0, 45);

      //parcourir les lettres si on trouve un ( ou , ou . ou - on coupe la chaine et on garde que ce qu'il y a avant

      setCurrentWeighborhood(currentInputWeighborhood.toLocaleUpperCase());
      refInputWeighborhood.current.value =
        currentInputWeighborhood.toLocaleUpperCase();
    }
    if (isBloked) return;
    let country =
      currentCountrySelected?.name?.toString()?.toLowerCase() ?? null;
    let city = currentInputCity?.toString()?.toLowerCase() ?? null;
    let weighborhood =
      currentInputWeighborhood?.toString()?.toLowerCase() ?? null;
    if (country == null || city == null || weighborhood == null) return;
    let valueLocation = `${weighborhood}, ${city}, ${country}`;
    valueLocation = valueLocation.replace(/\w\S*/g, (w) =>
      w.replace(/^\w/, (c) => c.toUpperCase())
    );
    if (oldLocation == valueLocation) return;
    setOldLocation(valueLocation);

    useStoreShareFunction.getState().searchAndPanToPoint(valueLocation, false);
  };
  const handleChangeTitle = (e, refInput, setState, sliceNumber = 30) => {
    // console.log("handleChangeTitle");
    //permettre les accents, les espaces, les chiffres et les lettres
    const caracteresSpeciauxRegex = /[!@#$%^&*(),.?":{}|<>]/g;
    let value = e.target.value;
    value = value.toLocaleUpperCase();
    if (caracteresSpeciauxRegex.test(value)) {
      NotificationService.addToQueue(
        1,
        "Les caractères spéciaux ne sont pas autorisés"
      );
      // return; // Arrête la fonction si des caractères spéciaux sont détectés
    }
    value = value.replace(/[!@#$%^&*(),.?":{}|<>]/g, "");
    value = value.slice(0, sliceNumber);
    refInput.current.value = value;
    setState(value);
  };
  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsSelectVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // console.log(window);
    let timer = setTimeout(() => {
      if (window.google) {
        // console.log("google loaded");
        setWaitingToAddMarker(true);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [window]);

  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step && !checkIfBlock()) {
      document.activeElement.blur();
      useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    checkIfBlock,
    step,
    currentWeighborhood,
    currentCity,
    currentCountrySelected,
  ]);
  const inputCountryRef = useRef();
  if (!waitingToAddMarker) return <></>;
  return (
    <section
      className={`step-1-annonce step-10 step-4-1 ${
        currentStep == step ? "visible" : ""
      } 
      ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input input-resi paiement-recap | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            <label>Où est situé votre résidence ?</label>
            <p>
              Indiquez le pays, la ville et le quartier où se situe votre
              résidence, ces informations sont importantes pour les clients et
              leur permettent de trouver votre résidence plus facilement.
            </p>
            <span
              id="selecteur"
              className={`input-select-perso country-billing add-resi-select-country listing ${
                isSelectVisible ? "selected" : ""
              } | rlve`}
            >
              {alreadyFetch && (
                <div
                  ref={selectRef}
                  className={
                    isSelectVisible
                      ? "select-visible select-input | flcolm"
                      : "select-input | flcolm"
                  }
                >
                  {countriesFiltered.map((country, index) => (
                    <button
                      type="index"
                      className="select-btn | flrow liner"
                      key={index}
                      value={country.name}
                      onClick={() => {
                        if (inputCountryRef.current)
                          inputCountryRef.current.blur();
                        setCurrentCountrySelected(country);
                        setIsSelectVisible(false);
                        inputCountryRef.current.value = country.name;
                      }}
                    >
                      {fetchFinish && (
                        <img
                          style={{ width: 24, height: 24 }}
                          src={country.flag}
                          alt={country.flag}
                        />
                      )}{" "}
                      <span> {country.name}</span>
                    </button>
                  ))}
                </div>
              )}
              <button
                type="button"
                style={{
                  MozUserFocus: "none",
                }}
                onClick={(e) => {
                  if (isInputFocused && isSelectVisible) return;
                  setIsSelectVisible(!isSelectVisible);
                  inputCountryRef.current.focus();
                }}
                className={"preview | flrow liner addgap8 "}
              >
                <>
                  {(currentCountrySelected || lastThereCountry) && (
                    <img
                      style={
                        lastThereCountry
                          ? {
                              filter: "grayscale(100%)",
                            }
                          : {}
                      }
                      src={
                        lastThereCountry
                          ? lastThereCountry.flag
                          : currentCountrySelected.flag
                      }
                      alt="currentFlag"
                    />
                  )}
                  <input
                    name="country"
                    type="text"
                    autoCorrect="off"
                    autoComplete="off"
                    autoCapitalize="off"
                    ref={inputCountryRef}
                    defaultValue={currentCountrySelected?.name}
                    placeholder="Pays"
                    onChange={handleFilterCountry}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                  />
                  {/* <span>{currentCountrySelected.name}</span> */}
                </>

                <img className="chevron-img" src={chevronDown} alt="chevron" />
              </button>
            </span>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm jcenter liner">
          <div className="contains-input  | flcolm jcenter liner addgap8">
            <Autocomplete
              options={
                currentCountrySelected
                  ? {
                      types: ["(cities)"],
                      componentRestrictions: {
                        country: currentCountrySelected?.code,
                      },
                    }
                  : {
                      types: ["(cities)"],
                    }
              }
            >
              <div
                onClick={() => {
                  if (!currentCountrySelected) {
                    NotificationService.addToQueue(
                      1,
                      "Veuillez choisir un pays avant de choisir une ville"
                    );
                    //mettre le focus sur rien
                    document.activeElement.blur();
                    return;
                  }
                }}
              >
                <input
                  className={currentCountrySelected ? "" : "disabled-input"}
                  ref={refInputCity}
                  // disabled={!currentCountrySelected}
                  maxLength={30}
                  placeholder="Ville"
                  autoComplete=""
                  onBlur={handleBlurForUpdateLocation}
                  onChange={(e) =>
                    handleChangeTitle(e, refInputCity, setCurrentCity)
                  }
                  name="Ville"
                />
                <span
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  {refInputCity.current?.value?.toString()?.length ?? 0}/30
                  caractères
                </span>
              </div>
            </Autocomplete>

            <Autocomplete
              options={
                currentCountrySelected
                  ? {
                      types: [
                        "hospital",
                        "pharmacy",
                        "bakery",
                        "neighborhood",
                        "sublocality",
                      ],
                      componentRestrictions: {
                        country: currentCountrySelected?.code,
                      },
                    }
                  : {
                      types: [
                        "hospital",
                        "pharmacy",
                        "bakery",
                        "neighborhood",
                        "sublocality",
                      ],
                    }
              }
            >
              <div
                onClick={() => {
                  if (!currentCountrySelected) {
                    NotificationService.addToQueue(
                      1,
                      "Veuillez choisir un pays avant de choisir un quartier"
                    );
                    //mettre le focus sur rien
                    document.activeElement.blur();
                    return;
                  }
                  if (currentCity.trim() == "") {
                    NotificationService.addToQueue(
                      1,
                      "Veuillez choisir une ville avant de choisir un quartier"
                    );
                    refInputCity.current.focus();
                  }
                }}
              >
                <input
                  className={
                    currentCountrySelected && currentCity.trim() != ""
                      ? ""
                      : "disabled-input"
                  }
                  ref={refInputWeighborhood}
                  // disabled={!currentCountrySelected || currentCity.trim() == ""}
                  maxLength={45}
                  onBlur={handleBlurForUpdateLocation}
                  placeholder="Quartier"
                  autoComplete=""
                  onChange={(e) =>
                    handleChangeTitle(
                      e,
                      refInputWeighborhood,
                      setCurrentWeighborhood,
                      45
                    )
                  }
                  name="Quartier"
                />
                <span
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  {refInputWeighborhood.current?.value?.toString()?.length ?? 0}
                  /45 caractères
                </span>
              </div>
            </Autocomplete>
          </div>
        </div>
      </div>
    </section>
  );
}
