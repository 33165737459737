import { useEffect, useState } from "react";
import { Mail, Tel } from "../../images/icons/JsxIcons";
import { LoginSocialFacebook } from "reactjs-social-login";
import googleIcon from "../../images/icons/social/google.png";
import fbIcon from "../../images/icons/social/fb.png";
import { useGoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import { useStorePageManager } from "../../store/PageManage";
import { NotificationService } from "../../services/Notification.service";
import InputPhoneNumber from "../cells/InputPhoneNumber";

export const InscriptionPanelConnexionPart = ({
  currentState = 1,
  panelState,
  setPanelState,
  dataInscription,
  setDataInscription,
  launchGoogleLogin,
  facebookLoginVoid,
  refFirstName,
  refLastName,
  refPassword,
}) => {
  const { t } = useTranslation();
  const [stateMail, setStateMail] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [stateTelephone, setStateTelephone] = useState({
    focus: false,
    error: false,
    good: false,
  });

  const [curentCountrySelected, setCurentCountrySelected] = useState();

  useEffect(() => {
    if (dataInscription.addressMail.length > 0) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(dataInscription.addressMail)) {
        setStateMail({ ...stateMail, error: false, good: true });
      } else {
        setStateMail({ ...stateMail, error: true, good: false });
      }
    } else {
      setStateMail({ ...stateMail, error: false, good: false });
    }
  }, [dataInscription.addressMail]);
  useEffect(() => {
    if (dataInscription.telephone.length > 2) {
      if (dataInscription.telephone.length >= 8) {
        setStateTelephone({ ...stateTelephone, error: false, good: true });
      } else {
        setStateTelephone({ ...stateTelephone, error: true, good: false });
      }
    } else {
      setStateTelephone({ ...stateTelephone, error: false, good: false });
    }
  }, [dataInscription.telephone]);

  const handleUptadeTelephone = (e) => {
    let newTelephone = e.target.value.replace(/[^0-9]/g, "");
    setDataInscription({ ...dataInscription, telephone: newTelephone });
    e.target.value = newTelephone;
  };

  const handleGoToFinishInscription = () => {
    if (stateMail.good && stateTelephone.good && curentCountrySelected) {
      refFirstName.current.value = "";
      refLastName.current.value = "";
      refPassword.current.value = "";
      setPanelState({
        ...panelState,
        inscriptionBegin: 0,
        inscriptionFinish: 1,
      });
    }
  };

  const handleGoToConnexionPanel = () => {
    setDataInscription({
      ...dataInscription,
      naming: "",
      firstName: "",
      addressMail: "",
      telephone: "",
      mdp: "",
    });
    setPanelState({
      ...panelState,
      inscriptionBegin: 0,
      connexion: 1,
    });
  };

  return (
    <div
      className={`contains inscription-part ${
        currentState == 0
          ? "go-left-side"
          : currentState == 1
          ? "come-center"
          : "go-right-side"
      } | flcolm`}
    >
      <label>{t("inscriptionTitle")}</label>
      <div className="input-field | flcolm">
        <div className="input | rlve">
          <Mail color={stateMail.focus ? "var(--gray-600)" : "none"} />
          <input
            onBlur={() => setStateMail({ ...stateMail, focus: false })}
            onFocus={() => setStateMail({ ...stateMail, focus: true })}
            onChange={(e) =>
              setDataInscription({
                ...dataInscription,
                addressMail: e.target.value,
              })
            }
            autoCorrect="false"
            autoCapitalize="false"
            autoComplete="false"
            placeholder={t("placeHolderMail")}
            type="email"
          />
          {stateMail.focus == false && stateMail.error && (
            <div className="detect-issue-input">
              <div className="chevron"></div>
              <span>{t("issueEmailType")}</span>
            </div>
          )}
        </div>
        <InputPhoneNumber
          dataInscription={dataInscription}
          setDataInscription={setDataInscription}
          setStateTelephone={setStateTelephone}
          stateTelephone={stateTelephone}
          handleUptadeTelephone={handleUptadeTelephone}
          curentCountrySelected={curentCountrySelected}
          setCurentCountrySelected={setCurentCountrySelected}
        />
      </div>
      <button
        onClick={handleGoToFinishInscription}
        className="next-btn | btn flex"
        disabled={
          !(stateMail.good && stateTelephone.good && curentCountrySelected)
        }
      >
        {t("continueText")}
      </button>
      <div className="separator | flrow allcenter">
        <div className="line"></div>
        <span>{t("ouText")}</span>
        <div className="line"></div>
      </div>
      <div className="social-connect | flcolm addgap8">
        <button
          onClick={() => {
            // NotificationService.addToQueue(
            //   1,
            //   "Fonctionnalité bientôt disponible"
            // );
            // return;
            useStorePageManager.getState().handleUpdateBlockScreen(true);
            launchGoogleLogin();
          }}
          className="google-btn | btn flrow center"
        >
          <img src={googleIcon} alt="google-logo" />
          <span>{t("continueWithGoogle")}</span>
        </button>
        <button
          onClick={() => {
            NotificationService.addToQueue(
              1,
              "Fonctionnalité bientôt disponible"
            );
            return;
            useStorePageManager.getState().handleUpdateBlockScreen(true);

            launchGoogleLogin();
          }}
          type="button"
          className="fb-btn | btn flrow center"
        >
          <img src={fbIcon} alt="fb-logo" />
          <span>{t("continueWithFacebook")}</span>
        </button>
        {/* <LoginSocialFacebook
          className="fb-login | maxwidth"
          appId={process.env.REACT_APP_CONNEXION_FACEBOOK}
          onLoginStart={(_response) => {
            useStorePageManager.getState().handleUpdateBlockScreen(true);
          }}
          onResolve={(response) => {
            useStorePageManager.getState().handleUpdateBlockScreen(true);
            facebookLoginVoid(response);
          }}
          onReject={(_response) => {
            useStorePageManager.getState().handleUpdateBlockScreen(false);
            NotificationService.addToQueue(
              2,
              "Une erreur est survenue lors de la connexion avec Facebook"
            );
          }}
          onError={(_response) => {
            useStorePageManager.getState().handleUpdateBlockScreen(false);
            NotificationService.addToQueue(
              2,
              "Une erreur est survenue lors de la connexion avec Facebook"
            );
          }}
        >
          <button
            style={{
              pointerEvents: "none",
              pointerEvents: "none",
            }}
            className="fb-btn | btn flrow center"
          >
            <img src={fbIcon} alt="fb-logo" />
            <span>{t("continueWithFacebook")}</span>
          </button>
        </LoginSocialFacebook> */}
        <p className="other-choice">
          {t("youHaveAnAccount")}{" "}
          <span onClick={handleGoToConnexionPanel}>{t("connexionTxt")}</span>
        </p>
      </div>
    </div>
  );
};
