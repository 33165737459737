import React, { useEffect, useRef, useState } from "react";
import "../../styles/components/addResidencePart/step10.css";
import { NotificationService } from "../../services/Notification.service";
import { useStoreShareFunction } from "../../store/StoreShareFunction";
const currentStep = 10;
export default function Step10NameProperty({
  step,
  handleBlockBtn,
  title,
  setTitle,
}) {
  const refInput = useRef();
  const leftCharacters = 50 - title.length;
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (isLoaded) return;
    if (title.toString().length > 0) {
      setIsLoaded(true);
      refInput.current.value = title;
    }
  }, [title]);

  useEffect(() => {
    if (currentStep == step) handleBlockBtn(title.trim().length <= 4);
  }, [step, title]);

  const handleChangeTitle = (e) => {
    if (e.target.value == title) return;
    // const caracteresSpeciauxRegex = /[^a-zA-Z0-9\s]/;
    let value = e.target.value;

    // if (caracteresSpeciauxRegex.test(value)) {
    //   NotificationService.addToQueue(
    //     1,
    //     "Les caractères spéciaux ne sont pas autorisés"
    //   );
    //   // return; // Arrête la fonction si des caractères spéciaux sont détectés
    // }

    // value = value.replace(/[^a-zA-Z0-9\s]/g, "");
    value = value.slice(0, 50);
    refInput.current.value = value;
    setTitle(value);
  };
  const checkIfBlock = () => {
    return title.trim().length <= 4;
  };

  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step && !checkIfBlock()) {
      document.activeElement.blur();
      useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [checkIfBlock, step, title]);
  return (
    <section
      className={`step-1-annonce step-10 ${
        currentStep == step ? "visible" : ""
      } 
      ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input input-resi | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            <label>Attirez les clients avec un titre unique</label>
            <p>
              Les titres courts sont généralement les plus efficaces. Ne vous
              inquiétez pas, vous pourrez toujours le modifier plus tard.
            </p>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm jcenter liner">
          <div className="contains-input | flcolm jcenter liner addgap8">
            <input
              ref={refInput}
              maxLength={50}
              placeholder=""
              autoComplete="none"
              onChange={handleChangeTitle}
              name="titre"
            />
            <span>{title.length}/50 caractères</span>
          </div>
        </div>
      </div>
    </section>
  );
}
