import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { requestEndpoint } from "../../data/requestEndpoint";
import { MailIcon } from "../../images/icons/JsxDesign";
import closeIcon from "../../images/icons/x-close.svg";
import { NotificationService } from "../../services/Notification.service";
import { useCurrentScrollY } from "../../services/useCurrentScrollY";
import { patch, remove } from "../../services/useFetchAction";
import { getJustDateFormat } from "../../services/utils";
import { useStoreUserManager } from "../../store/UserManage";
import { UserPreferences } from "../../store/UserPreferences";
import "../../styles/components/notificationContent.css";

export default function NotificationContent({
  notifications = null,
  loading,
  error,
}) {
  // CheckingConnectedUser();
  const userData = useStoreUserManager((state) => state.user);
  const { checkingPosY } = useCurrentScrollY(80);
  const language = UserPreferences((state) => state.language);
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    if (!userData) return;
    if (userData.connected == false) {
      navigate("/");
      NotificationService.addToQueue(1, t("youNeedConnect"));
    }
  }, [userData]);

  const [currentNotifs, setCurrentNotifs] = useState([]);
  const handleUpdateNotificationsUser = useStoreUserManager(
    (state) => state.handleUpdateNotificationsUser
  );
  useEffect(() => {
    if (!notifications) return;
    setCurrentNotifs(notifications.data);
    // console.log(currentNotifs);
  }, [notifications]);

  const GoSeeThisNotif = async (notifId) => {
    // const responseNotif = put("notification", { id: notifId });
    // if (responseNotif.statusText != 201) {
    //   console.log("error");
    // }
    const response = patch(`/users/${notifId}/read`);
    let newTab = [];
    currentNotifs.map((notif) => {
      if (notif.id != notifId) {
        newTab.push(notif);
      } else {
        let nNotif = notif;
        nNotif.seen = true;
        newTab.push(nNotif);
      }
    });
    setCurrentNotifs(newTab);
  };

  const GoRemoveThisNotif = async (notifId) => {
    try {
      const response = await remove(
        `${requestEndpoint.deleteNotification}/${notifId}`
      );
    } catch (error) {
      console.log(error);
    }

    const newNotif = currentNotifs
      .slice()
      .filter((notif) => notif.id != notifId);
    setCurrentNotifs(newNotif);
  };

  const handleClickOn = (notif) => {
    if (notif.seen == false) {
      GoSeeThisNotif(notif.id);
    }
    if (notif.link) {
      const nouvelOnglet = window.open(notif.link, "_blank");
      if (nouvelOnglet) {
        nouvelOnglet.focus();
      } else {
        NotificationService.addToQueue(
          1,
          "Le bloqueur de fenêtres popup a empêché l'ouverture de la nouvelle page"
        );
      }
    }
  };
  useEffect(() => {
    if (!currentNotifs) return;
    if (loading) return;
    let newNotifs = [];
    currentNotifs.map((notif) => {
      if (notif.seen == false) newNotifs.push(notif.id);
    });
    handleUpdateNotificationsUser(newNotifs);
  }, [currentNotifs, loading]);

  const handleFormatReturn = (texte) => {
    let splitText = texte.split("<br>");
    if (splitText.length == 1) return texte;

    let paragraphes = [];
    for (let i = 0; i < splitText.length; i++) {
      paragraphes.push(<p>{splitText[i]}</p>);
    }
    return paragraphes;
  };
  return (
    <>
      <section className="notifcations-section | flcolm max-container rlve">
        <div className="contains | flcolm">
          {checkingPosY && (
            <div className="title second | max-container">
              <h2>{t("notifTxt")}</h2>
            </div>
          )}
          <h2>{t("notifTxt")}</h2>
          {!notifications && loading ? (
            <div className="contains-notifs | flcolm">
              {!notifications && loading == true && (
                <p className="not-notif">{t("loadNotificationTxt")} ...</p>
              )}
              {Array.from({ length: 2 }).map((_, index) => {
                return (
                  <div key={index + "notif"} className="notif | flrow liner">
                    <button
                      className="btn see-message | flrow"
                      style={{
                        pointerEvents: "none",
                        userSelect: "none",
                        width: "100%",
                      }}
                    >
                      <div
                        className="skeleton"
                        style={{ width: 48, height: 48, borderRadius: "50%" }}
                      ></div>

                      <div className={`message | flcolm `}>
                        <span
                          className="skeleton span-skeleton"
                          style={{ width: "100%", height: 14, marginBottom: 8 }}
                        ></span>
                        <span
                          className="skeleton span-skeleton"
                          style={{ width: "100%", height: 14, marginBottom: 8 }}
                        ></span>
                        <span
                          className="skeleton span-skeleton"
                          style={{ width: "70%", height: 14, marginBottom: 16 }}
                        ></span>
                        <span
                          className="skeleton span-skeleton"
                          style={{ width: 150, height: 12 }}
                        ></span>
                      </div>
                    </button>
                    <button className="close-btn | btn jcenter liner">
                      <div
                        className="skeleton"
                        style={{ width: 24, height: 24, borderRadius: "50%" }}
                      ></div>
                    </button>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="contains-notifs | flcolm">
              {currentNotifs.length == 0 && (
                <p className="not-notif">{t("noNotificationTxt")}</p>
              )}
              {currentNotifs.map((notif) => {
                return (
                  <div key={notif.id} className="notif | flrow liner">
                    <button
                      onClick={() => handleClickOn(notif)}
                      className="btn see-message | maxwidth flrow"
                    >
                      <div className="icon-message | flex jcenter liner">
                        <MailIcon
                          stokeColor="var(--primary-500)"
                          strokeWidth="1.5"
                        />
                      </div>
                      <div
                        className={`message ${
                          notif.seen ? "seen" : ""
                        } | flcolm `}
                      >
                        <p>
                          {language == "fr-FR"
                            ? handleFormatReturn(notif.text)
                            : handleFormatReturn(notif.textEn)}
                          <p
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: "2px",
                              color: "#129CFF",
                            }}
                          >
                            {notif.link}
                          </p>
                        </p>

                        <span>{getJustDateFormat(notif.createdAt)}</span>
                      </div>
                    </button>
                    <button
                      onClick={() => GoRemoveThisNotif(notif.id)}
                      className="close-btn | btn jcenter liner"
                    >
                      <img src={closeIcon} alt="close-icon" />
                    </button>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
}
