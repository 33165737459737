import { create } from "zustand";

export const useStoreShareFunction = create((set, get) => ({
  searchAndPanToPoint: null,
  nextPage: null,
  handleGoOpenForAdd:null,
  handleUpdateSearchAndPanToPoint: (payload) => {
    set(() => ({ searchAndPanToPoint: payload }));
  },
  handleUpdateNextPage: (payload) => {
    set(() => ({ nextPage: payload }));
  },
  handleUpdateGoOpenForAdd: (payload) => {
    set(() => ({ handleGoOpenForAdd: payload }));
  },
}));
