/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from "react-i18next";
import { SearchLgN } from "../../images/icons/JsxIcons";
import crossIcon from "../../images/icons/x-close.svg";
import "../../styles/components/filterMobileAccueil.css";
import "../../styles/components/reservationMobilePanel.css";
import DestinationInput from "../cells/DestinationInput";
import WhenYouTravelDate from "../cells/WhenYouTravelDate";
import AddLessDiv from "../molecules/AddLessDiv";
export default function FilterMobileAccueil({
  handleCloseReservationPanel,
  handleGoSearch,
  handleGoErase,
  destination,
  setDestination,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  nbrPersonne,
  setNbrPersonne,
  isTopMobileStore = false,
  setCurrentLat,
  setCurrentLng,
  setViewPort,
  viewPort,
}) {
  const { t } = useTranslation();

  return (
    <div
      style={{ top: isTopMobileStore ? -73 : 0 }}
      className="galerie-residence reservation-mobile-show filter-accueil-mobile | flcolm "
    >
      <div className="header-galerie-residence | flrow btw liner max-container maxwidth">
        <button
          style={{
            borderRadius: "50%",
            background: "none",
            padding: 10,
            border: "1px solid var(--gray-300)",
          }}
          onClick={() => handleCloseReservationPanel()}
          className="close-galerie | btn"
        >
          <img
            style={{
              width: 16,
              height: 16,
            }}
            src={crossIcon}
            alt="icon-residence"
          />
        </button>
      </div>
      <div
        id="mobile-contains-scroll-filter"
        className="full-contains | flcolm max-container"
      >
        <DestinationInput
          destination={destination}
          setDestination={setDestination}
          t={t}
          setCurrentLat={setCurrentLat}
          setCurrentLng={setCurrentLng}
          setViewPort={setViewPort}
          viewPort={viewPort}
        />
        <WhenYouTravelDate
          start={startDate}
          end={endDate}
          setStart={setStartDate}
          setEnd={setEndDate}
        />
        <AddLessDiv
          classname="add-personne-mobile"
          title={t("personneNumber")}
          setData={setNbrPersonne}
          data={nbrPersonne}
          mineId="mine-just-add-less-div-mobile"
        />

        <div className="next-step | maxwidth flrow btw">
          <button
            onClick={() => handleGoErase()}
            className="btn-reservation | btn "
          >
            <span>{t("btnErase")}</span>
          </button>
          <button
            onClick={() => handleGoSearch()}
            className="btn-reservation | btn pry-btn"
          >
            <SearchLgN />
            <span>{t("btnSearch")}</span>
          </button>
        </div>
      </div>
    </div>
  );
}
