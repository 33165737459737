import React, { useEffect, useState } from "react";
import "../../styles/components/addResidencePart/step2.css";
import {
  BuildingTypeIcon,
  ChaletTypeIcon,
  HomeTypeIcon,
} from "../../images/icons/JsxIcons";
import { useStoreAllStaticData } from "../../store/dataForAll";
import { useStoreShareFunction } from "../../store/StoreShareFunction";
const currentStep = 1;
export default function Step2TypeResidence({
  step,
  handleBlockBtn,
  currentTypeResi,
  setTypeResi,
}) {
  useEffect(() => {
    if (currentStep == step) handleBlockBtn(currentTypeResi == 0);
  }, [step, currentTypeResi]);
  const typeResi = useStoreAllStaticData((state) => state.typeResi);

  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step && currentTypeResi != 0) {
      document.activeElement.blur();
      useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [step, currentTypeResi]);
  return (
    <section
      className={`step-1-annonce step-2 ${currentStep == step ? "visible" : ""} 
      ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input input-resi | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            <label>
              Parmi les propositions suivantes, laquelle décrit le mieux votre
              logement ?
            </label>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm jcenter liner">
          <div className="contains-input | flcolm jcenter liner">
            <div className="filter-selected-panel | flcolm">
              <div className="residence-type | flrow">
                {typeResi.map((type, index) => {
                  return (
                    <button
                      key={type.id + "-resi-" + index}
                      onClick={() => {
                        setTypeResi(type.id);
                      }}
                      className={`${
                        currentTypeResi == type.id && "selected-type | "
                      } btn flcolm`}
                    >
                      <img className="svg-img-show" src={type.icon} />

                      <span>{type.name}</span>
                    </button>
                  );
                })}
                {/* <button
                  onClick={() => {
                    setTypeResi(1);
                  }}
                  className={`${
                    currentTypeResi == 1 && "selected-type | "
                  } btn flcolm`}
                >
                  <HomeTypeIcon
                    stroke={currentTypeResi == 1 ? "#1E1A21" : "#1E1A21"}
                  />
                  <span>Maison</span>
                </button>
                <button
                  onClick={() => {
                    setTypeResi(2);
                  }}
                  className={`${
                    currentTypeResi == 2 && "selected-type | "
                  } btn flcolm`}
                >
                  <BuildingTypeIcon
                    stroke={currentTypeResi == 2 ? "#1E1A21" : "#1E1A21"}
                  />
                  <span>Appartement</span>
                </button>
                <button
                  onClick={() => {
                    setTypeResi(3);
                  }}
                  className={`${
                    currentTypeResi == 3 && "selected-type | "
                  } btn flcolm`}
                >
                  <ChaletTypeIcon
                    stroke={currentTypeResi == 3 ? "#1E1A21" : "#1E1A21"}
                  />
                  <span>Chalet</span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
