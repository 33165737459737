import React from "react";
import { useStorePageManager } from "../../store/PageManage";
import "../../styles/components/screenBlockLoader.css";
export default function ScreenBlockLoader() {
  const blockScreen = useStorePageManager((state) => state.blockScreen);
  return (
    blockScreen && (
      <div className="block-loader">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    )
  );
}
