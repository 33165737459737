import React from "react";

export default function ComoditiesModal({ handleOutModal, data }) {
  return (
    <div className="comodities-modal | flcolm">
      <div className="title-modal flcolm addgap8">
        <label>Ce que propose ce logement</label>
      </div>
      <div className="contents | flrow">
        {data.map((comoditie) => (
          <div key={comoditie.id} className="comoditie | flrow liner">
            <img src={comoditie.thumb} alt={`commoditie-${comoditie.title}`} />
            <span>{comoditie.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
