import { create } from "zustand";

export const useStorePageManager = create((set, get) => ({
  lastResidenceTouched: null,
  overflow: true,
  connexionPanel: false,
  filterOpen: true,
  blockScreen: false,
  desactiveHeader: false,
  storePage: true,
  residencePage: false,
  faqPage: false,
  hotePage: false,
  storeMap: false,
  whaBlock: false,
  currentMapTouched: null,
  currentMarkersList: [],
  nextLoadingNave: "",
  locateUserOnMap: null,
  goToStorePage: null,
  handleUpdateLocateUserOnMap: (payload) => {
    set(() => ({ locateUserOnMap: payload }));
  },
  handleUpdateLastResidenceTouched: (payload) => {
    set(() => ({ lastResidenceTouched: payload }));
  },
  handleUpdateBlockScreen: (payload) => {
    set(() => ({ blockScreen: payload }));
  },
  handleUpdateBodyOverflow: (payload) => {
    set(() => ({ overflow: payload }));
  },
  handleUpdateConnexionPanelState: (payload, nexpLoadingN = "") => {
    get().handleUpdateBodyOverflow(!payload);
    set(() => ({ connexionPanel: payload }));
    set(() => ({ nextLoadingNave: nexpLoadingN }));
  },
  handleUpdateDesactiveHeader: (payload) => {
    set(() => ({ desactiveHeader: payload }));
  },
  handleUpdateStorePage: (payload) => {
    set(() => ({ storePage: payload }));
  },
  handleUpdateResidencePage: (payload) => {
    set(() => ({ residencePage: payload }));
  },
  handleUpdateFaqPage: (payload) => {
    set(() => ({ faqPage: payload }));
  },
  handleUpdateHotePage: (payload) => {
    set(() => ({ hotePage: payload }));
  },
  handleUpdateNextLoadingNave: (payload) => {
    set(() => ({ nextLoadingNave: payload }));
  },
  handleUdpateStoreMap: (payload) => {
    set(() => ({
      storeMap: payload,
    }));
  },
  handleUpdateCurrentMapTouched: (payload) => {
    //replace in url id of paylod
    const url = new URL(window.location.href);
    if (payload === null) {
      url.searchParams.delete("currentMarkerId");
      window.history.replaceState({}, null, url);
    }
    set(() => ({
      currentMapTouched: payload,
    }));
  },
  handleUpdateWhaBlock: (payload) => {
    set(() => ({ whaBlock: payload }));
  },
  handleUpdateCurrentMarkersList: (payload) => {
    set(() => ({ currentMarkersList: payload }));
  },
  handleUpdateGoToStorePage: (payload) => {
    set(() => ({ goToStorePage: payload }));
  },
}));
