import React, { useEffect } from "react";
import Footer from "../components/biggest/Footer";
import { useStorePageManager } from "../store/PageManage";
import ReservationContent from "../components/biggest/ReservationContent";
import { smoothScrollUp } from "../services/utils";
import { useStoreUserManager } from "../store/UserManage";
import { useNavigate } from "react-router-dom";
import { NotificationService } from "../services/Notification.service";
import { useTranslation } from "react-i18next";
import Header from "../components/biggest/Header";

export default function ProfileReservation() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleUpdateStorePage = useStorePageManager(
    (state) => state.handleUpdateStorePage
  );
  const handleUpdateDesactiveHeader = useStorePageManager(
    (state) => state.handleUpdateDesactiveHeader
  );
  const userData = useStoreUserManager((state) => state.user);
  useEffect(() => {
    handleUpdateStorePage(false);
    handleUpdateDesactiveHeader(false);
  }, []);
  useEffect(() => {
    let newTimeout = setTimeout(() => {
      smoothScrollUp(true);
    }, 100);
    return () => {
      clearTimeout(newTimeout);
    };
  }, []);
  useEffect(() => {
    if (!userData) return;
    if (userData.connected == false) {
      navigate("/");
      NotificationService.addToQueue(1, t("youNeedConnect"));
    }
  }, [userData]);
  return (
    <>
      {/* <Header /> */}
      <main>
        <ReservationContent />
        <Footer />
      </main>
    </>
  );
}
