/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import imgGallery from "../../images/icons/upload-img.png";
import "../../styles/components/addResidencePart/step8.css";

const currentStep = 8;
export default function Step8Photos({
  step,
  handleBlockBtn,
  currentPhotosSelected,
  setCurrentStep,
  onDropFunction,
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (files) => onDropFunction(files, currentStep + 1),
    accept: {
      "image/*": [".jpeg", ".png"],
    },
    multiple: true,
  });

  useEffect(() => {
    if (currentStep == step) {
      if (currentPhotosSelected.length > 0) setCurrentStep(currentStep + 1);
      handleBlockBtn(currentPhotosSelected.length < 5);
    }
  }, [step, currentPhotosSelected]);

  return (
    <section
      className={`step-1-annonce step-8 ${currentStep == step ? "visible" : ""} 
      ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input input-resi | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            <label>Ajoutez quelques photos de votre résidence</label>
            <p>
              Pour commencer, vous aurez besoin de 5 photos. Vous pourrez en
              ajouter d'autres ou faire des modifications plus tard.
            </p>
          </div>
        </div>
        <div className={`right-part-add-resi | flcolm jcenter liner`}>
          <div className="contains-input | flcolm jcenter liner">
            <div
              className={`photos-adder | flcolm jcenter liner`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <img
                className="icon-ww"
                style={{ width: 44 }}
                src={imgGallery}
                alt="icon-gallery"
              />
              {isDragActive ? (
                <label>Vous pouvez lacher ici</label>
              ) : (
                <label>Faites glisser vos photos ici</label>
              )}
              <p>Choisissez au moins 5 photos</p>
              <span>Télécharger depuis votre appareil</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
