import React, { useEffect } from "react";
import illusStep2 from "../../images/illustrations/illus-step2.png";
import "../../styles/components/addResidencePart/step1Annonce.css";
import { useStoreShareFunction } from "../../store/StoreShareFunction";
const currentStep = 6;
export default function StepSixResidenceBig({ step, handleBlockBtn }) {
  useEffect(() => {
    if (currentStep == step) handleBlockBtn(false);
  }, [step]);
  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step) {
      document.activeElement.blur();
      useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [step]);
  return (
    <section
      className={`step-1-annonce step-6 ${currentStep == step ? "visible" : ""} 
      ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            <span className="step">ETAPE 2</span>
            <label>Faites sortir votre annonce du lot</label>
            <p>
              Au cours de cette étape, vous pourrez ajouter certains des
              équipements proposés dans votre logement et au moins 5 photos.
              Vous pourrez ensuite ajouter un titre et une description.
            </p>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm jcenter liner">
          <img src={illusStep2} alt="illustration" />
        </div>
      </div>
    </section>
  );
}
