import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Warning } from "../../images/icons/JsxIcons";
import crossIcon from "../../images/icons/x-close.svg";
import { checkDateJust } from "../../services/utils";
import { useStoreManager } from "../../store/StoreManage";
import "../../styles/components/reservationMobilePanel.css";
import ConfirmReservationPanelToGo from "../cells/ConfirmReservationPanelToGo";
import TileResidence from "../cells/TileResidence";
import WhenYouTravelDate from "../cells/WhenYouTravelDate";
import AddLessDiv from "../molecules/AddLessDiv";
import ContainerPersoModal from "./ContainerPersoModal";

export default function ReservationMobilePanel({
  residence,
  handleCloseReservationPanel,
}) {
  const { t } = useTranslation();
  const reservationParams = useStoreManager((state) => state.reservationParams);
  const storeParameters = useStoreManager((state) => state.searchStoreParams);
  const handleUpdateReservationParameters = useStoreManager(
    (state) => state.handleUpdateReservationParameters
  );
  const location = useLocation();
  const navigate = useNavigate();
  //on recupere les parametres de la recherche
  let searchUrlParams = new URLSearchParams(location.search);
  let searchParams = {};
  for (let [key, value] of searchUrlParams) {
    searchParams[key] = value;
  }
  let startDateUrl = useMemo(() => searchParams.startDate ?? null);
  let endDateUrl = useMemo(() => searchParams.endDate ?? null);
  let numPeopleUrl = useMemo(() => searchParams.nbrPersonne ?? null);
  if (numPeopleUrl) {
    numPeopleUrl = parseInt(numPeopleUrl);
  }
  const [startDate, setStartDate] = useState(
    checkDateJust(startDateUrl)
      ? new Date(startDateUrl)
      : reservationParams.startDate ?? null
  );
  const [endDate, setEndDate] = useState(
    checkDateJust(endDateUrl)
      ? new Date(endDateUrl)
      : reservationParams.endDate ?? null
  );

  const [nbrPersonne, setNbrPersonne] = useState(
    numPeopleUrl ? numPeopleUrl : storeParameters.numPeople ?? 1
  );
  useEffect(() => {
    //mettre a jour les parametres de l'url dans l'url de la page
    if (!residence) return;
    let addUrls = [];
    checkDateJust(startDate) &&
      addUrls.push("startDate=" + startDate.toISOString().split("T")[0]);
    checkDateJust(endDate) &&
      addUrls.push("endDate=" + endDate.toISOString().split("T")[0]);
    nbrPersonne > 1 && addUrls.push("nbrPersonne=" + nbrPersonne);
    let url = `/residence/${residence.slug}`;
    if (addUrls.length > 0) {
      url += "?" + addUrls.join("&");
    }
    navigate(url, { replace: true });
  }, [startDate, endDate, nbrPersonne, residence]);
  const [toggleConfirm, setToggleConfirm] = useState(false);

  // useEffect(() => {
  //   if (!startDate || !endDate) {
  //     setToggleConfirm(true);
  //   } else {
  //     setToggleConfirm(false);
  //   }
  // }, [startDate, endDate, nbrPersonne]);
  // console.log("reservationParams", reservationParams);
  if (!residence) return;
  return (
    <div className="galerie-residence reservation-mobile-show | flcolm ">
      <div className="header-galerie-residence | flrow btw liner max-container maxwidth">
        <button
          onClick={() => handleCloseReservationPanel()}
          className="close-galerie | btn"
        >
          <img
            style={{
              width: 16,
              height: 16,
            }}
            src={crossIcon}
            alt="icon-residence"
          />
          <span>{t("demandeReservationTxt")}</span>
        </button>
      </div>
      <div className="full-contains | flcolm max-container">
        <TileResidence residence={residence} pader={true} />
        <WhenYouTravelDate
          residence={residence}
          handleUpdateReservationParameters={handleUpdateReservationParameters}
          start={startDate}
          end={endDate}
          setStart={setStartDate}
          setEnd={setEndDate}
        />
        <AddLessDiv
          classname="add-personne-mobile"
          min={1}
          max={residence.maxPeople}
          title={t("personneNumber")}
          setData={setNbrPersonne}
          data={nbrPersonne}
          baseData={nbrPersonne ?? 1}
        />
        <div className="info-reglement-warning flrow liner addgap16">
          <div className="flex liner">
            <Warning />
          </div>
          <p>{t("infoReservationHoteTxt")}</p>
        </div>
        <div className="next-step | maxwidth">
          <button
            style={{ opacity: toggleConfirm ? 0.1 : 1 }}
            disabled={toggleConfirm || !startDate || !endDate}
            onClick={() => setToggleConfirm(true)}
            className="btn-reservation | btn pry-btn"
          >
            {t("nextBtnTxt")}
          </button>
        </div>
      </div>
      <ContainerPersoModal
        className="confirm-reservation"
        title={t("recapTxt")}
        isOpen={toggleConfirm}
        toggle={setToggleConfirm}
        baseTop={true}
        noOverflow={true}
      >
        <ConfirmReservationPanelToGo
          residence={residence}
          startDate={startDate}
          endDate={endDate}
          nbrPersonne={nbrPersonne}
          t={t}
          handleUpdateReservationParameters={handleUpdateReservationParameters}
        />
      </ContainerPersoModal>
    </div>
  );
}
