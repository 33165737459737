import { create } from "zustand";
import { checkDateJust } from "../services/utils";

export const useStoreManager = create((set, get) => ({
  storeParameters: {
    destination: "",
    arriveeDate: null,
    departDate: null,
    personnes: null,
    minPrice: 0,
    maxPrice: 90000000,
    chambre: null,
    salleDeBain: null,
    occupation: null,
    typeIds: [],
    commodites: [],
    roomIds: [],
    activitiesIds: [],
    page: 1,
    lng: null,
    lat: null,
    viewPort: null,
    loadPreviousPage: false,
    mapOpen: false,
    centerMap: null,
    mapZoom: null,
    mapBounds: null,
  },
  refreshStore: null,
  refreshRange: null,
  updateRange: null,
  searchStoreParams: {
    location: "",
    startDate: null,
    endDate: null,
    numPeople: null,
    minPrice: 0,
    maxPrice: 2000000000,
    typeIds: [],
    assetIds: [],
    roomIds: [],
    activitiesIds: [],
    chambre: null,
    salleDeBain: null,
    occupation: null,
    page: 1,
    lng: null,
    lat: null,
    viewPort: null,
    loadPreviousPage: false,
    mapOpen: false,
    centerMap: null,
    mapZoom: null,
    mapBounds: null,
  },
  reservationParams: {
    lastPage: null,
    residence: null,
    startDate: null,
    endDate: null,
    nbrPersonne: 1,
    residenceId: null,
  },
  handleUpdateStoreParameters: (payload, caller = null) => {
    // console.log("caller", caller);
    let arriveeDate = checkDateJust(payload.arriveeDate)
      ? payload.arriveeDate
      : null;
    let departDate = checkDateJust(payload.departDate)
      ? payload.departDate
      : null;
    set(() => ({ storeParameters: { ...payload, arriveeDate, departDate } }));
  },
  handleUpdateReservationParameters: (payload) => {
    set(() => ({ reservationParams: payload }));
  },
  handleUpdateRefreshStore: (payload) => {
    set(() => ({ refreshStore: payload }));
  },
  handleUpdateRefreshRange: (payload) => {
    set(() => ({ refreshRange: payload }));
  },
  handleUpdateRange: (payload) => {
    set(() => ({ updateRange: payload }));
  },
  handleUpdateConfirmNewSearchStoreParameters: (payload) => {
    set(() => ({ searchStoreParams: payload }));
  },

  handleUpdateSearchStoreParameters: () => {
    const {
      destination,
      arriveeDate,
      departDate,
      personnes,
      minPrice,
      maxPrice,
      occupation,
      commodites,
      activitiesIds,
      roomIds,
      typeIds,
      page,
      lng,
      lat,
      viewPort,
      loadPreviousPage,
      mapOpen,
      centerMap,
      mapZoom,
      mapBounds,
    } = get().storeParameters;
    set(() => ({
      searchStoreParams: {
        location: destination,
        startDate: checkDateJust(arriveeDate) ? arriveeDate : null,
        endDate: checkDateJust(departDate) ? departDate : null,
        numPeople: personnes == 0 ? null : personnes,
        minPrice: minPrice,
        maxPrice: maxPrice,
        typeIds: typeIds,
        assetIds: commodites,
        roomIds: roomIds,
        activitiesIds: activitiesIds,
        chambre: null,
        salleDeBain: null,
        occupation: occupation,
        page: page,
        lng: lng,
        lat: lat,
        viewPort: viewPort,
        loadPreviousPage: loadPreviousPage,
        mapOpen: mapOpen,
        centerMap: centerMap,
        mapZoom: mapZoom,
        mapBounds: mapBounds,
      },
    }));
  },
}));
