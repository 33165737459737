import CryptoJS from "crypto-js";
const key = process.env.REACT_APP_SECRET_KEY;
const encryptionIV = process.env.REACT_APP_SECRET_IV;

export function encryptData(data) {
  let cipher = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
    iv: encryptionIV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  let result = cipher.toString();
  return result;
}

export function decryptData(data) {
  try {
    let cipher = CryptoJS.AES.decrypt(data, key, {
      iv: encryptionIV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return cipher.toString(CryptoJS.enc.Utf8) !== ""
      ? JSON.parse(cipher.toString(CryptoJS.enc.Utf8))
      : cipher.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return null;
  }
}
