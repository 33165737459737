import React, { useEffect, useState } from "react";
import "../../styles/components/addResidenceTopHeader.css";
import logo_tc from "../../images/logos/logo-tc.svg";
import helpIcon from "../../images/icons/message-question-circle.svg";
import menuIcon from "../../images/icons/menu-04.svg";
import { useStorePageManager } from "../../store/PageManage";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import { useStoreUserManager } from "../../store/UserManage";
import tempProfilPicture from "../../images/temps/user-02.svg";

export default function AddResidenceTopHeader({
  handleSaveResidence,
  deleteAllImagesFromLocalForage,
  currentStep,
  modalSaveOpen,
  setModalSaveOpen,
  handleSaveCountry,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleUpdateBlockScreen = useStorePageManager(
    (state) => state.handleUpdateBlockScreen
  );
  const userData = useStoreUserManager((state) => state.user);

  const [menuOpen, setMenuOpen] = useState(false);
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );

  const handleOpenMenu = () => {
    handleUpdateBodyOverflow(false);
    setMenuOpen(true);
  };
  const handleCloseMenu = () => {
    handleUpdateBodyOverflow(true);
    setMenuOpen(false);
  };

  const [modalHelpOpen, setModalHelpOpen] = useState(false);

  const [timeoutGoOut, setTimeoutGoOut] = useState(false);

  const handleGoOut = () => {
    handleUpdateBlockScreen(true);
    deleteAllImagesFromLocalForage();
    localStorage.removeItem("residenceData");
    let timeout = setTimeout(() => {
      handleUpdateBlockScreen(false);
      handleUpdateBodyOverflow(true);
      navigate("/");
    }, 1000);
    setTimeoutGoOut(timeout);
  };
  useEffect(() => {
    return () => {
      clearTimeout(timeoutGoOut);
    };
  }, [timeoutGoOut]);
  return (
    <>
      <div className="top-header-add-resi">
        <div className="contains-top-header | max-container flrow liner btw">
          <button
            onClick={() =>
              currentStep > 1 && currentStep != 17 && currentStep != 18
                ? setModalSaveOpen(true)
                : handleGoOut()
            }
            className="tc-btn"
          >
            <img src={logo_tc} alt="logo-trouvechap" />
          </button>
          <div className="hd-right-btn | flrow liner addgap8">
            <button
              onClick={() => setModalHelpOpen(true)}
              className="help-btn | btn"
            >
              <img src={helpIcon} alt="helpIcon" />
              <span>{t("helpTxt")}</span>
            </button>
            {currentStep != 18 && currentStep >2 && (
              <>
                <button
                  onClick={() => setModalSaveOpen(true)}
                  className="save-btn | btn"
                >
                  <span>{t("saveAndQuitBtn")}</span>
                </button>
              </>
            )}
            <button
              onClick={handleOpenMenu}
              className="menu-btn connected-menu-btn | btn liner rlve"
            >
              <img src={menuIcon} alt="menu-icon" />

              <img
                className="profil-picture"
                src={
                  userData.profilImgUrl
                    ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_PROFIL_PICTURE}${userData.profilImgUrl}`
                    : tempProfilPicture
                }
                alt="profil-icon"
              />
            </button>
          </div>

          {menuOpen && (
            <div className="menu-tc">
              <div onClick={handleCloseMenu} className="background-touch"></div>
              <div
                style={{
                  padding: 16,
                }}
                className="menu-container | flcolm"
              >
                <div className="menu-top | flcolm liner">
                  <button
                    onClick={() => setModalHelpOpen(true)}
                    className="btn flrow liner"
                  >
                    <span>{t("helpTxt")}</span>
                  </button>
                  {currentStep != 18 && currentStep >2 && (
                    <button
                      onClick={() => setModalSaveOpen(true)}
                      className="btn flrow liner"
                    >
                      <span>{t("saveAndQuitBtn")}</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ContainerPersoModal
        baseTop={true}
        className="quit-save"
        isOpen={modalSaveOpen}
        toggle={setModalSaveOpen}
        title={t("saveAndQuitBtn")}
      >
        <div className="save-progress | flcolm addgap16 liner">
          <p className="p-just -c">{t("wouldSaveAndQuit")}</p>
          <div className="flcolm liner btw addgap8 maxwidth">
            <button onClick={handleSaveResidence} className="btn pry-btn">
              <span>{t("saveAndQuitBtn")}</span>
            </button>
            <button onClick={handleGoOut} className="btn cc-btn">
              <span>{t("handleQuitWithoutSave")}</span>
            </button>
          </div>
        </div>
      </ContainerPersoModal>
    </>
  );
}
