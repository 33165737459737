import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { requestEndpoint } from "../../data/requestEndpoint";
import { SmartStar, Star } from "../../images/icons/JsxIcons";
import { NotificationService } from "../../services/Notification.service";
import { post } from "../../services/useFetchAction";
import { useStorePageManager } from "../../store/PageManage";
// import "../../styles/components/commentaryModal.css";
export default function NewCommentaryModal({
  handleOutModal,
  residence,
  noteCommentary,
  handleCloseModalSend,
}) {
  const { t } = useTranslation();
  const [starsShow, setStarsShow] = useState();
  const [starsShowPropriete, setStarsShowPropriete] = useState();
  const [starsShowEmplacement, setStarsShowEmplacement] = useState();
  const [starsShowRapportQp, setStarsShowRapportQp] = useState();
  const [starsShowAssets, setStarsShowAssets] = useState();
  const [starsShowCommunication, setStarsShowCommunication] = useState();

  const [totalScore, setTotalScore] = useState({
    total: 0,
    property: 0,
    emplacement: 0,
    rapportQP: 0,
    assets: 0,
    communication: 0,
  });
  const handleTotalNewScore = (score) => {
    setTotalScore({
      total: score,
      property: score,
      emplacement: score,
      rapportQP: score,
      assets: score,
      communication: score,
    });
  };
  const handleOthers = (score) => {
    let newScore = { ...totalScore, ...score };
    let newTotal =
      (newScore.property +
        newScore.emplacement +
        newScore.rapportQP +
        newScore.assets +
        newScore.communication) /
      5;
    newScore = { ...newScore, total: newTotal };
    setTotalScore(newScore);
  };
  const ReturnMyState = (score, functionClick, otherName = "") => {
    let newStarShow = [];
    Array.from({ length: 5 }).map((_, index) => {
      let calculCurrentStar = score - index;
      let transitionDelay = 0;
      if (calculCurrentStar < 0) calculCurrentStar = 0;
      else if (calculCurrentStar >= 1) calculCurrentStar = 1;
      else {
        transitionDelay = 0.2 * index;
        calculCurrentStar = calculCurrentStar / 1;
      }
      newStarShow.push(
        <button
          onClick={() =>
            otherName
              ? functionClick({ [otherName]: index + 1 })
              : functionClick(index + 1)
          }
          className={`${
            transitionDelay > 0 ||
            (calculCurrentStar == 1 && index + 1 == score)
              ? ""
              : "not"
          } btn rlve`}
        >
          <Star color="#FACC15" />
          <SmartStar
            transitionDelay={`${transitionDelay}s`}
            color="#FACC15"
            clipPath={`inset(0 0 0 calc(100% * ${calculCurrentStar}))`}
          />
        </button>
      );
    });
    return newStarShow;
  };

  useEffect(() => {
    setStarsShow(ReturnMyState(totalScore.total, handleTotalNewScore));
    setStarsShowPropriete(
      ReturnMyState(totalScore.property, handleOthers, "property")
    );
    setStarsShowEmplacement(
      ReturnMyState(totalScore.emplacement, handleOthers, "emplacement")
    );
    setStarsShowRapportQp(
      ReturnMyState(totalScore.rapportQP, handleOthers, "rapportQP")
    );
    setStarsShowAssets(
      ReturnMyState(totalScore.assets, handleOthers, "assets")
    );
    setStarsShowCommunication(
      ReturnMyState(totalScore.communication, handleOthers, "communication")
    );
  }, [totalScore]);

  const handleShareCommentary = async () => {
    if (totalScore.total == 0) return;
    useStorePageManager.getState().handleUpdateBlockScreen(true);
    try {
      let data = {
        text: refTextComment.current.value,
        quality: totalScore.property,
        communication: totalScore.communication,
        location: totalScore.emplacement,
        assets: totalScore.assets,
        globalExperience: totalScore.rapportQP,
        residenceId: residence.id,
      };
      await post(requestEndpoint.sendUserComment, data);
      NotificationService.addToQueue(0, "Commentaire envoyé avec succès");
      NotificationService.addToQueue(
        0,
        "Celui ci sera affiché a votre prochaine venue"
      );
    } catch (error) {
      console.log(error);
      NotificationService.addToQueue(
        2,
        "Erreur lors de l'envoi du commentaire"
      );
    }
    useStorePageManager.getState().handleUpdateBlockScreen(false);
    handleCloseModalSend();
  };

  const refTextComment = useRef(null);
  const [currentComment, setCurrentComment] = useState("");
  const handleChangeComment = (e) => {
    refTextComment.current.value = e.target.value.slice(0, 300);
    setCurrentComment(refTextComment.current.value);
  };
  const leftLenght = 150 - currentComment.length;
  return (
    <div className="new-commentary-contains | flcolm">
      <div className="residence-view | flrow liner">
        <img
          src={
            residence.medias
              ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${residence.medias[0]?.filename}`
              : residence.thumb
          }
          alt="residence-photo"
        />
        <div className="info-residence-view | flcolm liner">
          <label className="label-title">{residence.name}</label>
          <div className="info-second | flcolm">
            <p>{residence.address}</p>
            <div className="star-info | flrow liner">
              <Star
                color={residence.stars ? "#FACC15" : "var(--shade-black)"}
              />
              <span>
                {residence.stars
                  ? parseFloat(residence.stars?.toString()).toFixed(1)
                  : "N/A"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="stars-selector | flcolm">
        <div className="stars | flrow liner">{starsShow}</div>
        <div className="comment-div | flcolm">
          <textarea
            ref={refTextComment}
            maxLength={150}
            onChange={handleChangeComment}
            className="comment-part"
            placeholder={t("writeCommentaryPlaceholderTxt")}
          ></textarea>
          <span>{leftLenght}/150</span>
        </div>
        <div className="others-note-div | flcolm maxwidth">
          <span>{t("sayMoreTxt")}</span>
          <div className="others-note | flrow btw">
            <div className="card-note | flcolm">
              <div className="title | flcolm">
                <label>{t("EmplacementTxt")}</label>
                <span>{t("propeteDescriptionTxt")}</span>
              </div>
              <div className="stars | flrow btw liner">
                {starsShowPropriete}
              </div>
            </div>
            <div className="card-note | flcolm">
              <div className="title | flcolm">
                <label>{t("EmplacementTxt")}</label>
                <span>{t("emplacementDescriptionTxt")}</span>
              </div>
              <div className="stars | flrow btw liner">
                {starsShowEmplacement}
              </div>
            </div>
            <div className="card-note | flcolm">
              <div className="title | flcolm">
                <label>{t("RapportQualitePrixTxt")}</label>
                <span>{t("rapportQualitePrixDescriptionTxt")}</span>
              </div>
              <div className="stars | flrow btw liner">
                {starsShowRapportQp}
              </div>
            </div>
            <div className="card-note | flcolm">
              <div className="title | flcolm">
                <label>Commodités</label>
                <span>
                  Est-ce que le logement est bien équipé et fonctionnel ?
                </span>
              </div>
              <div className="stars | flrow btw liner">{starsShowAssets}</div>
            </div>
            <div className="card-note | flcolm">
              <div className="title | flcolm">
                <label>Communication</label>
                <span>
                  Est-ce que le propriétaire est disponible et réactif ?
                </span>
              </div>
              <div className="stars | flrow btw liner">
                {starsShowCommunication}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn-send-commentary | flrow maxwidth">
        <button
          disabled={totalScore.total == 0}
          onClick={handleShareCommentary}
          className="btn pry-btn"
        >
          <span>{t("sendTxt")}</span>
        </button>
      </div>
    </div>
  );
}
