import React, { useState } from "react";
import "../../styles/components/faqPart.css";
import FaqItem from "../cells/FaqItem";
export default function FaqPart() {
  const [faqContent, setFaqContent] = useState([
    {
      title: "Comment réserver un logement sur Trouvechap ?",
      description: (
        <div className="flcolm faq-description">
          <p>La réservation du logement se fait de manière simple :</p>
          <ul className="flcolm">
            <li>
              Insérez la destination, les dates, ainsi que le nombre de
              résidents qui séjourneront.
            </li>
            <li>
              Ajoutez des filtres selon vos exigences (piscine, jacuzzi,
              possibilité d’y organiser un évènement)
            </li>
            <li>Sélectionnez ensuite le logement que vous désirez</li>
            <li>
              Cliquez sur <span>réserver</span>
            </li>
            <li>
              Rentrez ensuite vos coordonnées, puis cliquez sur{" "}
              <span>demande de réservation.</span>
            </li>
          </ul>
          <p>
            Une demande est ensuite envoyée à l’hôte. Le paiement pourra
            s’effectuer lorsque l’hôte aura accepté votre demande.
          </p>
        </div>
      ),
    },
    {
      title: "Comment mettre en ligne un logement sur Trouvechap ?",
      description: (
        <div className="flcolm faq-description">
          <ul style={{ marginTop: 20 }} className="flcolm">
            <li>
              Cliquez sur <span>lister ma résidence</span>
            </li>
            <li>Suivez les différentes étapes</li>
          </ul>
          <p>
            Une notification vous sera envoyée, lorsque l’équipe de Trouvechap
            aura examiné votre demande.
          </p>
        </div>
      ),
    },
    {
      title: "En tant qu’hôte, comment annuler une réservation ?",
      description: (
        <div className="flcolm faq-description">
          <p>
            En tant qu’hôte vous pouvez être emmené à annuler une réservation
            pour diverses raisons, indépendamment de votre volonté. Pour se
            faire, veuillez contacter l’équipe de Trouvechap par appel, ou par
            Whatsapp. Cependant, il n’est pas conseillé d’annuler une
            réservation. Cela pourrait avoir un impact sur le classement de
            votre logement.
          </p>
        </div>
      ),
    },
  ]);
  return (
    <section className="paiement faq-part-section  | flcolm liner max-container rlve">
      <div className="contains | flcolm jcenter">
        <div className="title | flcolm liner addgap8">
          <h2>FAQ</h2>
          <p>
            Recevez des réponses rapides à toutes vos questions et
            préoccupations concernant Trouvechap. Nous avons des réponses prêtes
            pour vous, que vous soyez un invité, un hôte ou un simple membre.
          </p>
        </div>
        <div className="contents-faq | flcolm addgap16 jcenter liner">
          {faqContent.map((faq) => (
            <FaqItem title={faq.title}>{faq.description}</FaqItem>
          ))}
        </div>
      </div>
    </section>
  );
}
