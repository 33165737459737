import fr from "date-fns/locale/fr";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { NotificationService } from "../../services/Notification.service";
import { generateDateRangeArray } from "../../services/utils";
import "../../styles/components/whenYouTravelDate.css";

export default function WhenYouTravelDate({
  residence,
  start,
  end,
  setStart,
  setEnd,
}) {
  const { t } = useTranslation();
  const [excludeDates, setBlockedReservation] = useState([]);

  const checkIfDateIsExcluded = (date, excludeFound) => {
    //convert to iso string to compare
    let arrayCheck = excludeFound ?? excludeDates;
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    if (date <= yesterday) {
      return false;
    }
    date.setHours(0, 0, 0, 0);
    date = date.toISOString();

    //verifier si la date est superieure a la date d'hier

    let result = false;
    arrayCheck.forEach((element) => {
      if (element.toISOString() === date) {
        result = true;
      }
    });

    //si la date est superieure a
    return result;
  };

  useEffect(() => {
    if (!residence) return;
    let blockedReservation = [];
    residence.bookings.forEach((reservation) => {
      if (
        // reservation.status === "planified" ||
        reservation.status === "progressing"
      ) {
        // console.log("reservation", reservation);
        //faire -1jour au toDate
        let toDate = new Date(reservation.toDate);
        toDate.setDate(toDate.getDate() - 1);
        reservation.toDate = toDate;
      }
      blockedReservation.push(
        ...generateDateRangeArray(reservation.fromDate, reservation.toDate)
      );
    });
    setBlockedReservation(blockedReservation);
  }, []);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStart(start);
    setEnd(end);
    if (excludeDates) {
      let currentDate = new Date(moment(start, "DD/MM/YYYY").toDate());
      let stopDate = new Date(moment(end, "DD/MM/YYYY").toDate() ?? start);
      // console.log(currentDate, stopDate);
      let newDateRange = [];
      while (currentDate <= stopDate) {
        newDateRange.push(new Date(currentDate.getTime()));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      let find = false;
      newDateRange.forEach((value) => {
        if (find == false)
          if (checkIfDateIsExcluded(value, excludeDates)) {
            find = true;
            NotificationService.addToQueue(1, "Selectionnez une autre date");
            NotificationService.addToQueue(
              1,
              "La réservation configurée couvre une période d'indisponibilité de la résidence"
            );
            setStart(null);
            setEnd(null);
          }
      });
      checkForGo();
    }
  };
  const checkForGo = () => {
    if (!start) return;
    if (!end) return;

    // if (end <= start) {
    //   const newEndDate = new Date(start);
    //   newEndDate.setDate(newEndDate.getDate() + 1);
    //   setEnd(newEndDate);
    // }
  };
  return (
    <div className="when-travel | flcolm">
      <label>{t("whenYouWantTravelTxt")}</label>
      <DatePicker
        wrapperClassName="date-picker-wrapper"
        selected={start}
        onChange={onChange}
        onClickOutside={() => checkForGo()}
        minDate={new Date()}
        startDate={start}
        endDate={end}
        selectsRange
        inline
        showDisabledMonthNavigation
        monthClassName={(date) => "month-date-picker"}
        dayClassName={(date) => {
          let returned = "day-date-picker";
          if (checkIfDateIsExcluded(date)) {
            returned += " exclude-date";
          }
          //si la date != start et != end
          if (date > start || date < start)
            returned += !start || !end ? " no-violet-survol" : "";
          return returned;
        }}
        locale={fr}
      />
    </div>
  );
}
