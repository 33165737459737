import React from "react";
import "../../styles/components/selectFiltersOptionCard.css";
export default function SelectFiltersOptionCard({
  language,
  mine,
  setFilters,
}) {
  const handleUpdateMyFilter = () => {
    // setFilters((prev) => {
    //   const newFilters = prev.map((filter) => {
    //     if (filter.id === mine.id) {
    //       return { ...filter, selected: !filter.selected };
    //     }
    //     return filter;
    //   });
    //   return newFilters;
    // });
    setFilters(mine);
  };
  return (
    <button
      onClick={handleUpdateMyFilter}
      className={`filter-btn | btn opt-btn ${
        !mine.selected && "not-active-opt"
      } flrow`}
    >
      <img src={mine.secondThumb} alt={"icon-" + mine.title} />
      <span>{language == "fr-FR" ? mine.title : mine.titleEng}</span>
    </button>
  );
}
