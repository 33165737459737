import React, { useEffect } from "react";
import Footer from "../components/biggest/Footer";
import { useStorePageManager } from "../store/PageManage";
import { smoothScrollUp } from "../services/utils";
import Header from "../components/biggest/Header";
import ConfidentialiteContent from "../components/biggest/ConfidentialiteContent";

export default function ConfidentialitePage() {
  const handleUpdateStorePage = useStorePageManager(
    (state) => state.handleUpdateStorePage
  );
  useEffect(() => {
    handleUpdateStorePage(false);
  }, []);
  useEffect(() => {
    let newTimeout = setTimeout(() => {
      smoothScrollUp(true);
    }, 100);
    return () => {
      clearTimeout(newTimeout);
    };
  }, []);
  return (
    <>
      {/* <Header /> */}
      <main>
        <ConfidentialiteContent />
        <Footer />
      </main>
    </>
  );
}
