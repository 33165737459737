import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/initialize/index.css";
import "./styles/initialize/fonts-import.css";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./globalTraduction";

const rootElement = document.getElementById("root");
const renderApp = () => {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CONNEXION_GOOGLE}>
      <App />
    </GoogleOAuthProvider>
  );
};
renderApp();
