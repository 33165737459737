import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchLg } from "../../images/icons/JsxIcons";
import { getJustDateFormat } from "../../services/utils";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreManager } from "../../store/StoreManage";
import AddLessDiv from "../molecules/AddLessDiv";
import InputHeaderDestination from "../molecules/InputHeaderDestination";
import { InputDatePicker } from "./InputDatePicker";
import { InputHeaderSecondPersonne } from "./InputHeaderSecondPersonne";

export default function HeaderSearchStore() {
  const { t } = useTranslation();
  const storeParameters = useStoreManager((state) => state.storeParameters);
  const searchStoreParams = useStoreManager((state) => state.searchStoreParams);
  const [currentDestination, setCurrentDestination] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [personneNumber, setPersonneNumber] = useState(0);

  const [blurFilter, setBlurFilter] = useState(false);
  const [persoShow, setPersonneShow] = useState(0);
  const [currentLat, setCurrentLat] = useState(null);
  const [currentLng, setCurrentLng] = useState(null);
  const [viewPort, setViewPort] = useState(null);

  const [isAlreadyLoad, setIsAlreadyLoad] = useState(false);
  useEffect(() => {
    if (!searchStoreParams) return;
    if (isAlreadyLoad) return;

    let startDate = searchStoreParams.startDate ?? null;
    let datacheck = new Date().setHours(0, 0, 0, 0);
    if (startDate == datacheck) startDate = null;
    let endDate = searchStoreParams.endDate ?? null;
    if (startDate == null) endDate = null;
    setCurrentDestination(searchStoreParams.location ?? "");
    setStartDate(startDate);
    setEndDate(endDate);
    setPersonneNumber(searchStoreParams.numPeople ?? 0);
    setPersonneShow(searchStoreParams.numPeople ?? 0);
    setViewPort(searchStoreParams.viewPort ?? null);
    setIsAlreadyLoad(true);
  }, [searchStoreParams]);
  const handleResetAllFilters = () => {
    setPersonneNumber(0);
    setPersonneShow(0);
  };
  const handleValidateFilter = () => {
    setPersonneShow(personneNumber);
    setBlurFilter(false);
  };
  const handleUpdateStoreParameters = useStoreManager(
    (state) => state.handleUpdateStoreParameters
  );
  const handleUpdateSearchStoreParameters = useStoreManager(
    (state) => state.handleUpdateSearchStoreParameters
  );
  const refreshStore = useStoreManager((state) => state.refreshStore);

  const handleSearch = async () => {
    await handleUpdateStoreParameters({
      ...storeParameters,
      page: 1,
      destination: currentDestination,
      arriveeDate: startDate,
      departDate: endDate,
      personnes: personneNumber,
      lng: currentLng,
      lat: currentLat,
      viewPort: viewPort,
      handleSearch: true,
    });
    await handleUpdateSearchStoreParameters();
    useStorePageManager.getState().handleUpdateCurrentMapTouched(null);
    if (refreshStore) refreshStore();
  };

  return (
    <div className="hd-search-bar header-search | flrow liner rlve">
      <InputHeaderDestination
        currentDestination={currentDestination}
        setCurrentDestination={setCurrentDestination}
        searchStoreParams={searchStoreParams}
        noLabel={true}
        setCurrentLat={setCurrentLat}
        currentLat={currentLat}
        setCurrentLng={setCurrentLng}
        currentLng={currentLng}
        setViewPort={setViewPort}
        viewPort={viewPort}
      />
      <hr />
      <InputDatePicker
        startDate={startDate}
        searchStoreParams={searchStoreParams}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        title={t("arrivedPlaceholderTxt")}
        type="start"
        placeholder={getJustDateFormat(startDate, "D MMM Y", "Arrivée")}
        format={"D MMM Y"}
        nbrMonth={1}
        className="date-part date-header"
        direct={true}
        noLabel={true}
        myPlaceHolder={"Arrivée"}
      />
      <hr />
      <InputDatePicker
        startDate={startDate}
        setStartDate={setStartDate}
        searchStoreParams={searchStoreParams}
        endDate={endDate}
        setEndDate={setEndDate}
        title={t("departPlaceholderTxt")}
        type="end"
        placeholder={getJustDateFormat(endDate, "D MMM Y", "Départ")}
        format={"D MMM Y"}
        nbrMonth={1}
        className="date-part date-header"
        direct={true}
        noLabel={true}
        myPlaceHolder={"Départ"}
      />
      <hr />
      <InputHeaderSecondPersonne
        title={t("personneNumber")}
        placeholder={t("addTravelerTxt")}
        typeInput="number"
        typer={t("personneTxt")}
        secondHeader={t("personneTxt") + "s"}
        value={persoShow}
        baseFlex={1.8}
        setBlurFilter={setBlurFilter}
        blurFilter={blurFilter}
        noLabel={true}
      />
      {blurFilter && (
        <>
          <div
            onClick={() => setBlurFilter(false)}
            className="background-touch-hide"
          ></div>
          <div className="filter-extend-searching filter-selected-panel | flcolm">
            <AddLessDiv
              classname="pers-nbr"
              title={t("personneNumber")}
              setData={setPersonneNumber}
              data={personneNumber}
            />
            <div className="reset-done | flrow center btw">
              <button
                onClick={handleResetAllFilters}
                className="reset-btn | btn"
              >
                {t("btnErase")}
              </button>
              <button
                onClick={handleValidateFilter}
                className="done-btn | btn pry-btn"
              >
                {t("btnValidate")}
              </button>
            </div>
          </div>
        </>
      )}
      <button onClick={handleSearch} className="go-search | flex">
        <SearchLg />
      </button>
    </div>
  );
}
