import React from "react";

export default function Tooltip({
  minWidth = 150,
  maxWidth = 200,
  disable = false,
  children,
  zIndex = 1,
}) {
  return (
    <span
      style={{
        minWidth: minWidth,
        maxWidth: maxWidth,
        zIndex: zIndex,
      }}
      className={`tooltip ${disable ? "disable-tooltip" : ""}`}
    >
      {children}
    </span>
  );
}
