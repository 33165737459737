import React, { useState } from "react";
import "../../styles/components/politiqueContent.css";
import policy from "../../data/politique.json";
import { useCurrentScrollY } from "../../services/useCurrentScrollY";
export default function ConfidentialiteContent() {
  const { checkingPosY } = useCurrentScrollY(100);
  return (
    <section
      style={{ paddingBottom: 30 }}
      className="policy-section | flcolm max-container rlve"
    >
      <div className="content | flcolm">
        {checkingPosY && (
          <div className="title second | max-container">
            <label>Politique de confidentialité</label>
          </div>
        )}
        <div className="title | flcolm">
        </div>

        <h1 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Politique de confidentialité
        </h1>

        <p>
          Trouvechap a pour objectif de créer des liens entre les personnes pour
          un monde plus ouvert et inclusif. Autrement dit, créer un monde où
          chacun peut se sentir chez soi partout. Nous sommes une communauté qui
          repose sur la confiance. Et pour créer un rapport de confiance, il est
          fondamental de faire preuve de transparence quant à la manière dont
          nous utilisons vos informations et protégeons votre vie privée. La
          présente Politique de confidentialité décrit comment Trouvechap,
          traite les informations personnelles relatives à votre utilisation de
          la plateforme Trouvechap. En fonction de l'endroit où vous vivez et de
          ce que vous faites sur la plateforme Trouvechap, les pages
          supplémentaires indiquées ci-dessous et concernant la confidentialité
          pourraient s'appliquer à vous.
        </p>

        <h2>DÉFINITIONS</h2>
        <p>
          Les termes non définis dans la présente Politique de confidentialité
          ont la même définition que celle indiquée dans nos Conditions de
          service <h3>(« Conditions »)</h3>.
        </p>
        <h2>INFORMATIONS PERSONNELLES QUE NOUS COLLECTONS</h2>
        <p>
          <h3>
            informations nécessaires pour utiliser la plateforme Trouvechap.
          </h3>
        </p>
        <p>
          Nous collectons des informations personnelles vous concernant lorsque
          vous utilisez la plateforme Trouvechap. Sans elles, nous ne serions
          pas en mesure de vous fournir les services demandés. Ces informations
          comprennent :
        </p>
        <ul>
          <li>
            <h3>
              Coordonnées, informations de compte et informations de profil.
            </h3>{" "}
            Vos prénom, nom de famille, numéro de téléphone, adresse postale,
            adresse e-mail, date de naissance et photo de profil, en fonction
            des fonctionnalités que vous utilisez.
          </li>
          <li>
            <h3>Vérification d'identité et informations de paiement.</h3> Des
            images de votre pièce d'identité officielle (conformément à la
            législation applicable), votre numéro de pièce d'identité ou autres
            informations de vérification, un selfie au moment de la vérification
            de votre pièce d'identité, vos coordonnées bancaires ou votre compte
            de paiement. Si vous n'êtes pas un utilisateur Trouvechap, nous
            pouvons recevoir des informations de paiement vous concernant, par
            exemple lorsqu'un utilisateur Trouvechap fournit votre carte de
            paiement pour effectuer une réservation. Si une copie de votre pièce
            d'identité nous est fournie, nous pouvons numériser, utiliser et
            stocker les informations contenues dans votre pièce d'identité pour
            vérifier votre identité et à des fins de sécurité.
          </li>
        </ul>
        <p>
          <h3>2.2 Informations que vous choisissez de nous fournir.</h3>
        </p>
        <p>
          Vous pouvez choisir de nous fournir des informations personnelles
          supplémentaires. Ces informations peuvent inclure :
        </p>
        <ul>
          <li>
            <h3>Informations de profil supplémentaires</h3> Par exemple, le
            sexe, la ou les langues préférées, la ville et une description
            personnelle. Certaines de ces informations, comme indiqué dans les
            paramètres de votre compte, font partie de votre page de profil
            public et seront donc publiquement visibles.
          </li>
          <li>
            <h3>Informations sur d'autres personnes</h3> Par exemple, un
            instrument de paiement appartenant à une autre personne. En nous
            fournissant des informations personnelles sur d'autres personnes,
            vous certifiez que vous avez l'autorisation de fournir ces
            informations à Trouvechap aux fins décrites dans la présente
            Politique de confidentialité et que vous avez partagé la Politique
            de confidentialité de Trouvechap avec elles.
          </li>
          <li>
            <h3>Autres informations.</h3> Par exemple, celles fournies lorsque
            vous remplissez un formulaire, ajoutez des informations à votre
            compte, répondez à des sondages, publiez dans des forums
            communautaires, participez à des promotions, communiquez avec
            l'assistance Trouvechap et d'autres Membres, importez ou saisissez
            manuellement les contacts du carnet d'adresses, fournissez votre
            adresse et/ou votre géolocalisation, ou partagez votre expérience
            avec nous. Celles-ci peuvent inclure des informations sur la santé,
            si vous choisissez de nous les communiquer.
          </li>
        </ul>
        <p>
          <h3>
            2.3 Informations collectées automatiquement lors de l'utilisation de
            la plateforme Trouvechap et de nos services de paiement.
          </h3>
        </p>
        <p>
          Lorsque vous utilisez la plateforme Trouvechap et les Services de
          paiement, nous collectons automatiquement des informations
          personnelles et autres informations. Ces informations peuvent inclure
          :
        </p>
        <ul>
          <li>
            <h3>Informations de géolocalisation.</h3> Une localisation précise
            ou approximative déterminée à partir de votre adresse IP, du GPS de
            votre téléphone portable ou d'un autre appareil, ou d'autres
            informations que vous partagez avec nous, en fonction des réglages
            de votre appareil. Nous pouvons également collecter ces informations
            lorsque vous n'utilisez pas l'application, si vous activez cette
            option dans vos paramètres ou dans les autorisations de l'appareil.
          </li>
          <li>
            <h3>Informations d'utilisation</h3> Les pages ou le contenu que vous
            consultez, les recherches d'Annonces, les réservations que vous avez
            faites, les services supplémentaires que vous avez ajoutés et les
            autres actions effectuées sur la plateforme Trouvechap.
          </li>
          <li>
            <h3>Cookies et technologies similaires</h3>
          </li>
          <li>
            <h3>Informations sur les transactions de paiement.</h3> Le mode de
            paiement utilisé, la date et l'heure, le montant du paiement, la
            date d'expiration du mode de paiement et le code postal de
            facturation, l'adresse e-mail PayPal, les informations IBAN, votre
            adresse et autres informations de transaction associées.
          </li>
        </ul>

        <p>
          <h3>
            2.4 Informations personnelles collectées auprès de tierces parties
          </h3>
        </p>
        <p>
          Nous collectons des informations personnelles auprès d'autres sources,
          notamment :
        </p>

        <ul>
          <li>
            <h3>Services tiers : </h3>Si vous établissez un lien, vous connectez
            ou accédez à la plateforme Trouvechap avec un service tiers (par
            exemple, Google, Facebook ou WeChat), vous demandez à ce service de
            nous envoyer des informations telles que votre inscription, votre
            liste d'amis et votre profil, conformément aux modalités de contrôle
            de ce service ou à l'autorisation accordée par vous dans vos
            paramètres de confidentialité relatifs à ce service.
          </li>
          <li>
            <h3>Autres sources </h3>Dans la mesure autorisée par la législation
            en vigueur, nous pouvons recevoir des informations complémentaires
            vous concernant, telles que des recommandations, des données
            démographiques ou des informations contribuant à la détection de
            fraudes et de failles de sécurité, de la part de prestataires de
            services tiers ou partenaires, et les recouper avec les informations
            vous concernant déjà en notre possession. Par exemple, nous pouvons
            recevoir des résultats de vérification d'antécédents ou des
            avertissements de fraude de la part de prestataires de services de
            vérification d'identité dans le cadre de nos démarches de prévention
            contre les fraudes, d'enquête de sécurité et d'évaluation des
            risques. Nous pouvons recevoir des informations sur vous et vos
            activités sur et hors de la plateforme Trouvechap, ou sur vos
            expériences et interactions avec nos partenaires. Nous pouvons
            recevoir des informations sur la santé, y compris, sans toutefois
            s'y limiter, des informations concernant des maladies contagieuses.
          </li>
        </ul>
        <h2>COMMENT NOUS UTILISONS LES INFORMATIONS QUE NOUS COLLECTONS</h2>
        <p>
          <h3>
            3.1 Fourniture, amélioration et développement de la plateforme
            Trouvechap
          </h3>
          Nous pouvons traiter ces informations pour :
        </p>
        <ul>
          <li>
            Vous permettre d'accéder à la plateforme Trouvechap et d'effectuer
            et de recevoir des paiements
          </li>
          <li>Vous permettre de communiquer avec d'autres membres</li>
          <li>Traiter votre demande</li>
          <li>Effectuer des analyses, des débogages et mener des recherches</li>
          <li>
            Proposer une formation aux équipes de l'assistance utilisateurs
          </li>
          <li>
            TVous envoyer des messages, des mises à jour, des alertes de
            sécurité et des notifications de compte
          </li>
          <li>
            Traiter, gérer ou évaluer des déclarations de dommages ou des
            demandes similaires
          </li>
          <li>
            Personnaliser votre expérience en fonction de vos interactions avec
            la plateforme Trouvechap, de votre historique de recherche et de
            réservation, de vos informations et préférences de profil et
            d'autres contenus envoyés par vous
          </li>
          <li>
            Vous permettre d'utiliser nos produits Entreprise et nos services
            d'hébergement
          </li>
        </ul>
        <p>
          <h3>
            3.2 Créer et maintenir un environnement de confiance sécurisé.
          </h3>
          Nous pouvons traiter ces informations pour :
        </p>
        <ul>
          <li>
            Détecter et prévenir les fraudes, spams, abus, incidents de sécurité
            et autres activités préjudiciables
          </li>
          <li>
            Étudier et combattre la discrimination conformément à notre
            Politique de non-discrimination
          </li>
          <li>
            TMener des enquêtes sur la prévention de la fraude, la sécurité et
            des évaluations du risque
          </li>
          <li>
            Vérifier ou authentifier les informations que vous avez fournies
          </li>
          <li>
            Effectuer des vérifications dans des bases de données et d'autres
            sources d'information, y compris des vérifications concernant les
            antécédents
          </li>
          <li>
            Respecter nos obligations légales, protéger la santé et le bien-être
            de nos résidents, hôtes, employés des hôtes et membres du public
          </li>
          <li>Résoudre les litiges avec nos Membres</li>
          <li>Faire respecter nos accords avec des tiers</li>
          <li>
            Déterminer les conditions requises pour certains types de
            réservation, tels que la réservation instantanée
          </li>
          <li>
            Se conformer à la loi, répondre aux réquisitions, prévenir les
            dommages et protéger nos droits
          </li>
          <li>
            Faire respecter nos Conditions et nos autres politiques (notamment
            la Politique de non-discrimination)
          </li>
          <li>
            Évaluer vos interactions avec la plateforme Trouvechap ainsi que les
            informations obtenues auprès de tierces parties. Dans un nombre de
            cas limité, les processus automatisés, qui analysent votre compte et
            vos activités sur la plateforme Trouvechap, ainsi que les
            informations relatives aux activités sur la plateforme Trouvechap et
            en dehors de celle-ci qui peuvent vous être associées, peuvent
            restreindre ou suspendre votre accès à la plateforme Trouvechap si
            ces processus détectent une activité qui peut présenter un risque de
            sécurité ou un autre type de risque pour Trouvechap, notre
            communauté ou toute tierce partie. Si vous souhaitez contester des
            décisions basées sur des processus automatisés, nous vous remercions
            de bien vouloir nous contacter aux coordonnées figurant à la section
            Coordonnées ci-dessous.
          </li>
        </ul>
        <p>
          <h3>
            3.3 Fournir, personnaliser, évaluer et améliorer nos activités
            publicitaires et marketing
          </h3>
          Nous pouvons traiter ces informations pour :
        </p>
        <ul>
          <li>
            Vous envoyer des messages promotionnels et marketing, de la
            publicité et d'autres informations en fonction de vos préférences,
            et de la publicité sur les réseaux sociaux par le biais des
            plateformes de réseaux sociaux
          </li>
          <li>Personnaliser, évaluer et améliorer nos publicités</li>
          <li>
            Gérer les récompenses, programmes de parrainage, enquêtes, loteries,
            concours, ou autres activités ou événements promotionnels organisés
            ou gérés par Trouvechap ou ses partenaires commerciaux
          </li>
          <li>
            Analyser des caractéristiques et préférences afin de vous envoyer
            des messages promotionnels et marketing, de la publicité et d'autres
            informations susceptibles de vous intéresser
          </li>
          <li>Vous inviter à des événements ou autres occasions</li>
          <li>
            Envoyer, avec votre consentement, des messages promotionnels, des
            informations commerciales, publicitaires et autres qui peuvent vous
            intéresser, en fonction de vos préférences
          </li>
        </ul>
        <p>
          <h3>3.4 Analyser et partager vos communications.</h3>
        </p>
        <p>
          Nous pouvons consulter, examiner ou analyser vos communications sur la
          plateforme Trouvechap pour les raisons exposées dans la section «
          Notre utilisation des informations que nous collectons » de la
          présente politique, notamment dans le cadre de prévention des fraudes,
          d'enquêtes de sécurité, d'évaluation des risques, de conformité
          réglementaire, de développement de produit, de recherche, d'analyse,
          d'application de nos Conditions de service et d'assistance
          utilisateurs. Par exemple, dans le cadre de nos efforts de prévention
          des fraudes, nous parcourons et analysons les messages pour masquer
          les coordonnées et les références à d'autres sites web, et sous
          réserve de la loi applicable, nous examinons et analysons toutes les
          images téléchargées par les utilisateurs sur la plateforme Trouvechap
          dans les fils de discussion, les profils, les annonces et les
          expériences pour détecter certaines activités illégales ou
          inappropriées (telles que des preuves d'exploitation d'enfants) dans
          le but d'identifier et de signaler les violations de contenu aux
          autorités compétentes. Dans certains cas, nous pouvons également
          examiner, consulter ou analyser les messages à des fins de résolution
          de bugs, d'amélioration et d'extension de notre offre de produits.
          Nous utilisons autant que possible des méthodes automatisées. Il se
          peut que nous ayons parfois besoin de consulter les communications
          manuellement, par exemple dans le cadre d'enquêtes sur les fraudes et
          d'assistance aux utilisateurs, ou encore afin d'évaluer et d'améliorer
          la fonctionnalité de ces outils automatisés. Nous ne procéderons pas à
          la consultation, à l'examen ou à l'analyse de vos communications pour
          vous adresser des messages marketing provenant de tierces parties, et
          nous ne vendrons pas les examens ou analyses de ces communications.
          Nous pouvons également partager vos communications ;
        </p>
        <p>
          <h3>3.5 Lien vers des comptes tiers.</h3>
        </p>

        <p>
          Vous pouvez lier votre compte Trouvechap à certains services tiers
          tels que les réseaux sociaux. Vos contacts figurant sur ces services
          tiers sont référencés comme « amis ». Lorsque vous autorisez et
          dirigez le partage de données en créant ce lien :
        </p>
        <ul>
          <li>
            Certaines des informations qui nous sont fournies à partir des
            comptes liés peuvent être publiées sur votre profil public.
          </li>
          <li>
            Vos activités sur la plateforme Trouvechap peuvent être affichées à
            vos amis sur la plateforme Trouvechap ou ledit service tiers.
          </li>
          <li>
            Un lien vers votre profil public figurant sur ce service tiers peut
            être inclus dans votre profil public Trouvechap.
          </li>
          <li>
            Les autres utilisateurs de Trouvechap pourront voir vos éventuels
            amis communs ou que vous êtes un ami de leur ami, le cas échéant.
          </li>
          <li>
            Les autres utilisateurs Trouvechap peuvent voir les écoles, villes
            d'origine ou autres groupes que vous avez en commun avec eux tels
            qu'ils apparaissent sur vos services de réseaux sociaux liés.
          </li>
          <li>
            Les informations que vous nous divulguez en liant vos comptes
            peuvent être conservées, traitées et transmises à des fins de
            prévention des fraudes, d'enquêtes de sécurité et d'évaluation des
            risques.
          </li>
          <li>
            La publication et l'affichage des informations que vous fournissez à
            la plateforme Trouvechap par le biais de ces liens dépendent des
            paramètres et autorisations définis par vous sur la plateforme
            Trouvechap et le service tiers.
          </li>
        </ul>
        <p>
          <h3>3.6 Fournir des services de paiement.</h3>
          {""}Les informations personnelles sont utilisées pour permettre ou
          autoriser des tiers à utiliser les services de paiement, notamment
          pour :
        </p>
        <ul>
          <li>
            Détecter et prévenir le blanchiment d'argent, la fraude, les abus et
            les incidents de sécurité, et procéder à des évaluations des
            risques.
          </li>
          <li>
            Se conformer aux obligations légales et de conformité (telles que
            les réglementations pour la lutte contre le blanchiment d'argent et
            l'application des sanctions).
          </li>
          <li>
            Appliquer les Conditions de paiement et autres politiques de
            paiement
          </li>
          <li>Fournir et améliorer les services de paiement</li>
        </ul>
        <h2>PARTAGE ET DIVULGATION</h2>
        <p>
          <h3>Partage avec votre consentement ou selon vos instructions</h3>
        </p>
        <p>
          Lorsque vous donnez votre consentement, nous partageons vos
          informations telles que décrites au moment du consentement, par
          exemple lorsque vous autorisez une application ou un site web tiers à
          accéder à votre compte Trouvechap ou participez à des activités
          promotionnelles proposées par des partenaires de Trouvechap ou des
          tiers.
        </p>
        <p>
          Lorsque la législation le permet, nous pouvons utiliser certaines
          informations vous concernant, telles que votre adresse e-mail, que
          nous partageons avec des plateformes de réseaux sociaux, afin de
          générer des hôtes potentiels, du trafic vers Trouvechap ou encore
          promouvoir nos produits et services de toute autre manière.
        </p>
        <p>
          <h3>4.2 Partage entre membres</h3>
        </p>
        <p>
          Dans le but de faciliter les réservations ou d'autres interactions
          entre Membres (qui peuvent être situés dans des juridictions
          présentant des niveaux variables de protection des données, ou faire
          appel à des prestataires de services situés dans ces juridictions),
          nous pouvons partager des informations dans des situations telles que
          :
        </p>
        <p>
          <h3>Entre Résident(s) et Hôte(s) quand : </h3>
        </p>
        <ul>
          <li>
            Une demande de réservation est faite, lorsqu'il y a un co-hôte ou
            qu'un litige est soumis, comme leur profil, leur nom, le nom de tout
            Résident supplémentaire, l'historique des annulations, les
            commentaires publiés, l'âge du Résident (sauf interdiction légale),
            l'issue du litige (le cas échéant) et d'autres informations que vous
            choisissez de partager et d'envoyer.
          </li>
          <li>
            Une réservation est confirmée, des informations supplémentaires sont
            partagées pour faciliter la coordination du voyage, comme la photo
            de profil et le numéro de téléphone.
          </li>
          <li>
            Vous confirmez une réservation en tant qu'Hôte, certaines
            informations sont partagées avec le Résident (et tout Résident
            supplémentaire invité, le cas échéant) pour coordonner la
            réservation, comme votre profil, votre nom complet, votre numéro de
            téléphone et l'adresse de votre logement.
          </li>
          <li>
            Vous communiquez avec un Membre, notamment en utilisant votre nom,
            votre photo de profil et le contenu de vos messages.
          </li>
        </ul>
        <p>
          <h3>
            Informations que vous publiez dans les Profils, les Annonces et
            autres informations publiques.
          </h3>
        </p>
        <p>
          Vous pouvez rendre certaines informations publiquement visibles par
          les autres utilisateurs, telles que :
        </p>
        <ul>
          <li>
            Votre page de profil publique, incluant votre photo de profil, votre
            prénom (ou initiales le cas échéant), votre description et votre
            ville
          </li>
          <li>
            Les pages d'annonces contenant des informations telles que la
            description approximative ou précise du logement ou de l'expérience,
            la disponibilité, la photo de profil, les informations agrégées
            liées à la demande (telles que le nombre de vues de la page sur une
            certaine période) et toute information supplémentaire que vous
            choisissez de partager
          </li>
          <li>Les commentaires, évaluations et remarques du public</li>
          <li>
            Le contenu dans un forum de discussion ou de la communauté, un blog
            ou un article sur les réseaux sociaux
          </li>
        </ul>
        <p>
          Nous pouvons afficher certaines parties de votre profil public et
          d'autres contenus que vous mettez à la disposition du public, tels que
          les détails de l'annonce, sur des applications, plateformes et sites
          tiers.
        </p>
        <p>
          Les informations que vous partagez publiquement sur la plateforme
          Trouvechap peuvent être indexées par le biais de moteurs de recherche
          tiers. Dans certains cas, il vous sera possible de désactiver cette
          fonctionnalité dans les paramètres de votre compte.
        </p>
        <p>
          <h3>4.4 Prestataires de services d'hôte</h3>
        </p>

        <p>
          Les Hôtes peuvent s'appuyer sur des services tiers pour gérer ou
          fournir leurs services, tels que les services de ménage ou de
          serrurerie. Les Hôtes peuvent utiliser les fonctionnalités de la
          plateforme Trouvechap pour partager des informations sur le Résident
          (notamment les informations d'arrivée et de départ, son nom et son
          numéro de téléphone) avec lesdits prestataires de services tiers.
        </p>
        <p>
          <h3>
            4.5 Respect de la législation, réponse aux réquisitions des
            autorités de police et de justice, prévention des préjudices et
            protection de nos droits
          </h3>
        </p>
        <p>
          Nous pouvons divulguer vos informations aux tribunaux, aux autorités
          policières, aux autorités gouvernementales ou publiques, aux autorités
          fiscales, à des tiers autorisés, ou à d'autres Membres, si et dans la
          mesure où nous sommes tenus ou autorisés à le faire par la loi, ou
          lorsque la divulgation est raisonnablement nécessaire pour : nous
          conformer à nos obligations légales ; nous conformer à une exigence
          judiciaire valide (comme une ordonnance ou décision de justice) ou
          pour répondre aux réclamations dirigées à l'encontre de Trouvechap ;
          répondre à une demande légale valide en relation avec une enquête
          criminelle portant sur une activité illégale ou présumée, ou pour
          répondre à toute autre activité susceptible d'exposer Trouvechap, vous
          ou tout autre utilisateur, à une responsabilité légale ou
          réglementaire, appliquer et administrer nos contrats avec les Membres,
          notamment nos Conditions, nos Conditions juridiques additionnelles, et
          nos Politiques ; ou protéger les droits, les biens ou la sécurité
          personnelle de Trouvechap, de ses employés, de ses membres ou des
          membres du public.{" "}
        </p>
        <p>
          Le cas échéant, nous pouvons informer les membres de l'existence de
          demandes légales, sauf si : la notification est interdite par le
          processus juridique lui-même, par une décision de justice que nous
          recevons, ou par la loi en vigueur ; ou nous estimons que la
          notification est inutile, inefficace, provoquerait un risque de
          blessure ou un préjudice corporel sur un individu ou un groupe, ou
          créerait ou augmenterait un risque de fraude ou un préjudice vis-à-vis
          de Trouvechap ou de nos membres, ou exposerait Trouvechap à une
          plainte pour obstruction à la justice.
        </p>
        <p>
          Lorsque la loi applicable l'exige ou l'autorise, nous pouvons
          divulguer les informations relatives aux Hôtes ou aux Résidents aux
          autorités fiscales ou à d'autres organismes gouvernementaux afin que
          les autorités fiscales déterminent la conformité aux obligations
          fiscales en vigueur. Les obligations fiscales en vigueur comprennent
          les obligations fiscales de Trouvechap sur ses frais de service, son
          rôle de facilitateur pour des taxes sur les hébergements et les
          retenues à la source, ainsi que les obligations fiscales individuelles
          des Hôtes. Les informations pouvant être divulguées comprennent, mais
          sans s'y limiter, les noms des Hôtes et des Résidents, les adresses
          des logements, les adresses des Hôtes, le(s) numéro(s)
          d'identification fiscale/commerciale, la date de naissance et/ou les
          coordonnées, les numéros d'identification des parcelles, les
          informations de versement, les dates et montants des transactions, le
          nombre de nuits réservées ainsi que le nombre de Résidents, la valeur
          brute et nette des réservations et les montants des versements, les
          taxes collectées par Trouvechap au nom des Résidents et des Hôtes,
          dans la mesure où ces informations sont connues de Trouvechap.
        </p>
        <p>
          Dans les juridictions où Trouvechap encourage ou exige une démarche
          d'enregistrement, de notification, d'autorisation, d'obtention d'une
          licence ou le numéro d'un Hôte auprès d'une autorité gouvernementale,
          nous pouvons communiquer des informations sur les Hôtes participants
          auprès de l'autorité compétente, aussi bien pendant le processus de
          demande que lors de la publication de l'annonce, et le cas échéant,
          périodiquement par la suite, comme le nom complet et les coordonnées
          de l'Hôte, l'adresse du logement, le numéro d'identification fiscale,
          le numéro d'enregistrement, de permis ou de licence, les détails de
          l'annonce, les informations de réservation et le nombre de nuits
          réservées, sous réserve des lois applicables.
        </p>
        <p>
          <h3>
            4.6 Programmes avec les gestionnaires et propriétaires immobiliers
          </h3>
        </p>
        <p>
          Nous pouvons partager des informations personnelles concernant des
          Hôtes et des Résidents, avec des bailleurs, des sociétés de gestion,
          des syndics de copropriété, des propriétaires de logement ou des
          experts en gestion locative, (le « Responsable de l'immeuble »),
          telles que des informations de réservation et des informations
          relatives au respect des lois applicables, afin de faciliter les
          programmes avec le Responsable de l'immeuble. Par exemple, la
          réservation de Résidents et les Informations personnelles, y compris
          les coordonnées des Résidents, peuvent être partagées avec le
          Responsable de l'immeuble, du complexe ou de la communauté où vit un
          Hôte et/ou où se trouve le logement, afin de faciliter les services
          d'hébergement, le respect des lois applicables, la sécurité, la
          facturation et d'autres services.
        </p>
        <p>
          <h3>4.7 Prestataires de services</h3>
        </p>
        <p>
          Nous partageons les informations personnelles avec des prestataires de
          services affiliés et non affiliés (y compris leurs prestataires de
          services) pour nous aider à gérer nos activités et aux fins de leurs
          propres exigences de conformité, notamment avec ceux nous aidant : à
          vérifier votre identité ou à authentifier vos pièces d'identité, à
          comparer les informations avec les bases de données publiques, à
          effectuer des vérifications des antécédents judiciaires, la prévention
          des fraudes, des enquêtes de sécurité et des évaluations des risques,
          à procéder au développement, à la maintenance et au débogage des
          produits, à permettre la fourniture des Services Trouvechap par le
          biais de plateformes et d'outils logiciels tiers (par exemple, par
          intégration à nos API), à fournir une assistance aux utilisateurs, de
          la publicité ou des services de paiement, à offrir des services
          supplémentaires que vous choisissez, à traiter, gérer ou évaluer des
          déclarations de dommages ou des déclarations similaires, ou à
          consulter, examiner et analyser les communications sur la Plateforme
          Trouvechap à certaines fins (telles que des preuves d'exploitation
          d'enfants). Pour plus d'informations, consultez la section Analyser et
          partager vos communications. Ces prestataires sont contractuellement
          tenus de protéger vos informations personnelles et n'y ont accès que
          pour effectuer les tâches mentionnées ci-dessus. D'autres Membres
          peuvent utiliser des services autres qu'Trouvechap pour traiter vos
          données. Il peut s'agir d'un logiciel de gestion des e-mails ou des
          réservations. Ces services échappent au contrôle de Trouvechap et
          seront soumis aux lois applicables dans le monde entier avec des
          niveaux variables de protection des données.
        </p>
        <p>
          <h3>4.8 Cession d'entreprise</h3>
        </p>
        <p>
          Si l'entreprise Trouvechap entreprend ou participe à une fusion,
          acquisition, restructuration, cession d'actifs ou procédure de
          faillite ou d'insolvabilité, elle sera alors en droit de vendre, céder
          ou partager tout ou partie de ses actifs, y compris vos informations
          en lien avec ladite transaction ou en prévision de ladite transaction
          (ex : due diligence). Dans ce cas, nous vous informerons avant que vos
          informations à caractère personnel ne soient transférées et ne soient
          régies par une autre Politique de confidentialité.
        </p>
        <h2>5. AUTRES INFORMATIONS IMPORTANTES</h2>
        <p>
          <h3>5.1 Partenaires et intégrations tiers.</h3>
        </p>
        <p>
          Certaines parties de Trouvechap peuvent être liées à des services
          tiers. Trouvechap ne possède ni ne contrôle ces tierces parties.
          Lorsque vous interagissez avec ces tierces parties et choisissez
          d'utiliser leur service, vous leur fournissez des informations vous
          concernant. Votre utilisation de ces services est soumise aux
          politiques de confidentialité de ces fournisseurs, y compris les
          Conditions d'utilisation supplémentaires de Google Maps/Google Earth,
          la Politique de confidentialité de Google (voir ici pour plus
          d'informations sur la manière dont Google utilise les informations),
          la Déclaration de confidentialité de PayPal, la Déclaration de
          confidentialité de CINETPAY.
        </p>
        <h2>6. VOS DROITS</h2>
        <p>
          Vous pouvez exercer l'un des droits décrits dans la présente section
          conformément à la législation applicable. Les utilisateurs de la
          plateforme Trouvechap possède plusieurs droits :{" "}
        </p>
        <ul>
          <li>Mises à jours et corrections des informations de compte</li>
          <li>
            Refuser que Trouvechap utilise vos données personnelles à des fins
            de marketing en nous contactant par mail, ou par appel
          </li>
          <li>Nous contacter</li>
        </ul>
        <p>
          <h3>6.1 Gestion de vos informations</h3>
        </p>
        <p>
          Vous pouvez visualiser et mettre à jour certaines de vos informations
          en accédant aux paramètres de votre compte. Si vous avez connecté
          votre compte Trouvechap à un service tiers, tel que Facebook ou
          Google, vous pouvez modifier vos paramètres et vous déconnecter de ce
          service en accédant aux paramètres de votre compte. Vous êtes
          responsable de la mise à jour de vos informations personnelles.
        </p>
        <p>
          <h3>6.2 Accès aux données et portabilité</h3>
        </p>
        <p>
          Dans certaines juridictions, la loi en vigueur peut vous donner le
          droit de demander des copies de vos informations personnelles ou des
          informations sur la manière dont nous traitons vos informations
          personnelles, ou encore de demander des copies des informations
          personnelles que vous nous avez fournies dans un format structuré,
          couramment utilisé et lisible par une machine, et/ou de demander que
          nous transmettions ces informations à un autre prestataire de services
          (lorsque cela est techniquement faisable).
        </p>
        <p>
          <h3>6.3 Suppression des données</h3>
        </p>
        <p>
          Dans certaines juridictions, vous pouvez demander la suppression de
          vos informations personnelles. Veuillez noter que si vous demandez la
          suppression de vos informations personnelles, ou si votre compte est
          suspendu, résilié ou volontairement fermé :
        </p>
        <ul>
          <li>
            Nous pouvons conserver les informations personnelles nécessaires à
            nos intérêts légitimes, tels que la détection et la prévention de la
            fraude et l'amélioration de la sécurité. Par exemple, si nous
            suspendons un compte Trouvechap pour des raisons de fraude ou de
            sécurité, nous pouvons conserver certaines informations relatives à
            ce compte Trouvechap pour empêcher ce membre d'ouvrir un nouveau
            compte Trouvechap à l'avenir.
          </li>
          <li>
            Nous pouvons conserver et utiliser vos informations personnelles
            dans la mesure nécessaire pour nous conformer à nos obligations
            légales. Par exemple, Trouvechap et Trouvechap Payments peuvent
            conserver certaines de vos informations dans le cadre d'obligations
            fiscales, de reporting légal et d'audit.
          </li>
          <li>
            Les informations que vous avez partagées avec d'autres personnes
            (par exemple, commentaires, publications sur un forum) peuvent
            continuer à être publiques sur la plateforme Trouvechap également
            après la suppression de votre compte Trouvechap.
          </li>
          <li>
            Dans la mesure où nous protégeons les informations contre toute
            perte accidentelle ou malveillante et contre toute destruction, des
            copies résiduelles de vos informations personnelles pourraient ne
            pas être supprimées de nos systèmes de sauvegarde pendant une
            période limitée.
          </li>
        </ul>
        <h2>7. SÉCURITÉ</h2>
        <p>
          Bien qu'aucune organisation ne puisse garantir une sécurité parfaite,
          nous mettons en œuvre et mettons à jour en permanence nos mesures de
          sécurité administratives, techniques et physiques pour protéger vos
          informations contre les accès, pertes, destructions ou modifications
          illicites ou non autorisés.
        </p>
        <h2>8. MODIFICATIONS DE LA PRÉSENTE POLITIQUE DE CONFIDENTIALITÉ</h2>
        <p>
          Nous nous réservons le droit de modifier la présente Politique de
          confidentialité à tout moment conformément à la législation en
          vigueur. Le cas échéant, nous publierons la version révisée de la
          Politique de confidentialité et actualiserons la « Date de dernière
          mise à jour » figurant au début de celle-ci. En cas de modification
          importante, nous vous en informerons également par e-mail au minimum
          trente (30) jours avant la date d'entrée en vigueur de la nouvelle
          version. Si vous n'approuvez pas les nouvelles conditions de la
          Politique de confidentialité, nous vous invitons à supprimer votre
          compte. Si vous ne supprimez pas votre compte avant la date d'entrée
          en vigueur de la nouvelle Politique de confidentialité, votre accès et
          votre utilisation continus de la plateforme Trouvechap seront soumis à
          la nouvelle Politique de confidentialité.
        </p>
        <h2>9. COORDONNÉES ET ENTITÉS TROUVECHAP RESPONSABLES</h2>
        <p>
          Pour toute question ou réclamation concernant la présente Politique de
          confidentialité ou le traitement des informations personnelles par
          Trouvechap : si vous résidez en côte d’ivoire, envoyez-nous un e-mail
          à contact@trouvechap.com ou appellez-vous au numéros suivants
        </p>
        <p>+225 07 12 94 39 44 ; +225 01 43 44 94 94 ; +225 05 56 09 46 36 .</p>
      </div>
    </section>
  );
}
