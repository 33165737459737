import React, { useEffect, useRef, useState } from "react";
import "../../styles/components/inputSelect.css";
export const InputSelectText = ({
  regex = null,
  title = "",
  error = "",
  showError = false,
  myState,
  setMyState,
  className = "",
  oneUpper = false,
  upper = false,
  trim = false,
  fullLower = false,
  moreValue = false,
  replaceeme = false,
  minimun = 0,
}) => {
  const ref = useRef(null);
  const handleChangeInput = (e) => {
    let value = "" + e.target.value;
    let newStateValue = myState;

    if (upper) value = value.toUpperCase();
    if (replaceeme) {
      // mettre en majuscules les è,é,à,ç,ù etc en gardant les accents
      value = value.replace(/è/g, "È");
      value = value.replace(/é/g, "É");
      value = value.replace(/à/g, "À");
      value = value.replace(/ç/g, "Ç");
      value = value.replace(/ù/g, "Ù");
      value = value.replace(/ê/g, "Ê");
      value = value.replace(/ë/g, "Ë");
      value = value.replace(/î/g, "Î");
      value = value.replace(/ï/g, "Ï");
      value = value.replace(/ô/g, "Ô");
      value = value.replace(/ö/g, "Ö");
      value = value.replace(/û/g, "Û");
      value = value.replace(/ü/g, "Ü");
      value = value.replace(/æ/g, "Æ");
      value = value.replace(/œ/g, "Œ");
    }
    if (oneUpper) {
      value = value.toLowerCase();
      // mettre en majuscule la première lettre de chaque mot en prenant en compte les accents etc
      value = value.replace(/(^|\s)([a-zéèàêëîïôöûüç])/g, function (m, p1, p2) {
        return p1 + p2.toUpperCase();
      });
    }

    if (showError) {
      if (value.length > 0) {
        if (regex) {
          if (regex.test(value) && value.length >= minimun)
            newStateValue = { ...newStateValue, good: true, error: false };
          else newStateValue = { ...newStateValue, good: false, error: true };
        }
      } else newStateValue = { ...newStateValue, good: false, error: false };
    } else {
      newStateValue = { ...newStateValue, good: true, error: false };
    }

    ref.current.value = value;
    newStateValue = { ...newStateValue, data: value };
    setMyState(newStateValue);
  };
  const handleFocusInput = () => {
    let newStateValue = myState;
    newStateValue = { ...newStateValue, focus: true };
    setMyState(newStateValue);
  };
  const handleBlurInput = () => {
    let newStateValue = myState;
    let newValue = "" + ref.current.value;
    if (trim) newValue = newValue.trimEnd();
    if (fullLower) newValue = newValue.toLowerCase();
    ref.current.value = newValue;
    newStateValue = { ...newStateValue, focus: false, data: newValue };
    setMyState(newStateValue);
  };
  const [alreadyTakeSave, setAlreadyTakeSave] = useState(false);
  useEffect(() => {
    if (myState.save) {
      if (!alreadyTakeSave) {
        setAlreadyTakeSave(true);
        ref.current.value = myState.save;
        // setMyState({ ...myState, data: myState.save });
        handleBlurInput();
      }
    }
  }, [myState]);
  return (
    <div
      id={className}
      className={`input-select-perso ${className} ${
        myState.focus ? "selected" : ""
      } ${myState.error && !myState.focus ? "error" : ""}`}
    >
      <label
        className={`${
          myState.focus || myState.data.length > 0 ? "selected" : ""
        }`}
      >
        {title}
      </label>
      <input
        onChange={handleChangeInput}
        onFocus={handleFocusInput}
        onBlur={handleBlurInput}
        ref={ref}
        name={title}
        type="text"
      />
      {myState.focus == false && myState.error && showError && (
        <div className="detect-issue-input">
          <div className="chevron"></div>
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};
export const InputSelectTel = ({
  title = "",
  error = "",
  showError = false,
  myState,
  setMyState,
  className = "",
  minimun = 8,
  notCheckMore = true,
}) => {
  const ref = useRef(null);
  const handleChangeInput = (e) => {
    let value = "" + e.target.value;
    let newStateValue = myState;
    value = value.replace(/[^0-9+]/g, "");

    if (showError) {
      if (value.length > 0) {
        if (value.length >= minimun && (value[0] == "+" || !notCheckMore))
          newStateValue = { ...newStateValue, good: true, error: false };
        else newStateValue = { ...newStateValue, good: false, error: true };
      } else newStateValue = { ...newStateValue, good: false, error: false };
    } else {
      newStateValue = { ...newStateValue, good: true, error: false };
    }

    ref.current.value = value;
    newStateValue = { ...newStateValue, data: value };
    setMyState(newStateValue);
  };
  const handleFocusInput = () => {
    let newStateValue = myState;
    newStateValue = { ...newStateValue, focus: true };
    setMyState(newStateValue);
  };
  const handleBlurInput = () => {
    let newStateValue = myState;
    newStateValue = { ...newStateValue, focus: false };
    setMyState(newStateValue);
  };
  const [alreadyTakeSave, setAlreadyTakeSave] = useState(false);
  useEffect(() => {
    if (myState.save) {
      if (!alreadyTakeSave) {
        setAlreadyTakeSave(true);
        ref.current.value = myState.save;
        // setMyState({ ...myState, data: myState.save });
        handleBlurInput();
      }
    }
  }, [myState]);
  return (
    <div
      id={className}
      className={`input-select-perso ${className} ${
        myState.focus ? "selected" : ""
      } ${myState.error && !myState.focus ? "error" : ""}`}
    >
      <label
        className={`${
          myState.focus || myState.data.length > 0 ? "selected" : ""
        }`}
      >
        {title}
      </label>
      <input
        onChange={handleChangeInput}
        onFocus={handleFocusInput}
        onBlur={handleBlurInput}
        ref={ref}
        name={title}
        type="tel"
      />
      {myState.focus == false && myState.error && showError && (
        <div className="detect-issue-input">
          <div className="chevron"></div>
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};
