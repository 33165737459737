function serializeRoomIds(roomIds) {
  return roomIds
    .map((item, index) => {
      return Object.keys(item).map((key) => {
        return `roomIds[${index}][${key}]=${item[key]}`;
      });
    })
    .flat()
    .join("&");
}

function serializeActivitiesIds(activitiesIds) {
  return activitiesIds
    .map((id, index) => `activitiesIds[${index}]=${id}`)
    .join("&");
}

function serializeTypeIds(typeIds) {
  return typeIds.map((id, index) => `typeIds[${index}]=${id}`).join("&");
}

function serializeAssetIds(assetIds) {
  return assetIds.map((id, index) => `assetIds[${index}]=${id}`).join("&");
}

export const routeCreateGet = (baseRoute, params) => {
  const serializeObject = (obj, prefix = "") => {
    const serialized = [];
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const prop = obj[key];
        const newKey = prefix ? `${prefix}[${key}]` : key;

        if (typeof prop === "object" && prop !== null && !Array.isArray(prop)) {
          serialized.push(...serializeObject(prop, newKey));
        } else {
          serialized.push(`${newKey}=${prop}`);
        }
      }
    }
    return serialized;
  };

  Object.keys(params).forEach((key, index) => {
    if (key === "assetIds" && Array.isArray(params[key])) {
      const assetIdsSerialized = serializeAssetIds(params[key]);
      baseRoute += (index > 0 ? "&" : "?") + assetIdsSerialized;
    } else if (key === "activitiesIds" && Array.isArray(params[key])) {
      const activitiesIdsSerialized = serializeActivitiesIds(params[key]);
      baseRoute += (index > 0 ? "&" : "?") + activitiesIdsSerialized;
    } else if (key === "typeIds" && Array.isArray(params[key])) {
      const typeIdsSerialized = serializeTypeIds(params[key]);
      baseRoute += (index > 0 ? "&" : "?") + typeIdsSerialized;
    } else if (key === "roomIds" && Array.isArray(params[key])) {
      const roomIdsSerialized = serializeRoomIds(params[key]);
      baseRoute += (index > 0 ? "&" : "?") + roomIdsSerialized;
    } else if (Array.isArray(params[key])) {
      params[key].forEach((item, itemIndex) => {
        const serialized = serializeObject(item, `${key}[${itemIndex}]`);
        baseRoute += (index > 0 ? "&" : "?") + serialized.join("&");
      });
    } else if (
      typeof params[key] === "object" &&
      params[key] !== null &&
      !Array.isArray(params[key])
    ) {
      const serialized = serializeObject(params[key], key);
      baseRoute += (index > 0 ? "&" : "?") + serialized.join("&");
    } else {
      baseRoute += (index > 0 ? "&" : "?") + `${key}=${params[key]}`;
    }
  });

  return baseRoute;
};
