import React from "react";

export default function Cover() {
  return (
    <section className="cover add-resi">
      <div className="content | max-container">
        <div className="cover-div-left left-part-add-resi"></div>
      </div>
    </section>
  );
}
