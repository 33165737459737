import React from "react";
import "../../styles/components/listerPopuResidence.css";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { VectorPopuResidenceRight } from "../../images/icons/JsxDesign";
import { FlasH, Wallet } from "../../images/icons/JsxIcons";
import temp1 from "../../images/temps/temp1.webp";
import temp2 from "../../images/temps/temp2.webp";
import { NotificationService } from "../../services/Notification.service";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreUserManager } from "../../store/UserManage";
// import temp3 from "../../images/temps/temp3.jpg";

export default function ListerPopuResidence({ direct = false }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userData = useStoreUserManager((state) => state.user);
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );
  const handleUpdateConnexionPanelState = useStorePageManager(
    (state) => state.handleUpdateConnexionPanelState
  );
  const handleGoListerResi = () => {
    if (direct == false) {
      navigate("/newresidenceinfo");
      return;
    }
    if (userData.connected == false) {
      NotificationService.addToQueue(1, t("infoNeedConnect"));

      handleUpdateConnexionPanelState(true, "/addresidence");
      handleUpdateBodyOverflow(false);
      return;
    }
    // if (
    //   userData.mailChecked == false ||
    //   userData.telephoneChecked == false ||
    //   userData.emailVerified == false
    // ) {
    //   NotificationService.addToQueue(1, t("infoNeedCheckerData"));
    // navigate("/profile");
    //   return;
    // }
    navigate("/addresidence");
  };
  return (
    <section className="lister-resi | flrow rlve max-container liner">
      <div className="left-side-resi | flcolm">
        <div className="title | flcolm addgap16">
          <h2>{t("listPopuResiLabel")}</h2>
          <p>
            {t("listPopuResiP")}{" "}
            <span style={{ color: "var(--primary-500)", fontSize: 16 }}>
              1000
            </span>{" "}
            {t("listPopuResiP2")}
          </p>
        </div>
        <button onClick={handleGoListerResi} className="btn pry-btn">
          {t("listMyResidenceBtn")}
        </button>
      </div>
      <div className="right-side-resi | rlve">
        <img
          style={{ zIndex: 2 }}
          className="alt-1-resi"
          src={temp1}
          alt="alt-1-resi"
        />
        <img
          style={{ zIndex: 2 }}
          className="alt-2-resi"
          src={temp2}
          alt="alt-2-resi"
        />
        <div className="design-card">
          <div className="contains | rlve">
            <div className="blur | center-absolute"></div>
            <div className="content | flrow  center-absolute">
              <div className="circle-prof">
                <div className="second-prof">
                  <Wallet />
                </div>
              </div>
              <p>{t("winMonnaieWith")}</p>
            </div>
          </div>
        </div>
        <div className="design-card">
          <div className="contains | rlve">
            <div className="blur | center-absolute"></div>
            <div className="content | flrow  center-absolute">
              <div className="circle-prof">
                <div className="second-prof">
                  <FlasH />
                </div>
              </div>
              <p>Résidence Noria Bassam</p>
            </div>
          </div>
        </div>
      </div>
      <VectorPopuResidenceRight className="vector-right-paiement desktop-vector | design" />
      {/* <VectorPaiementLeftMobile
        className="vector-left-paiement mobile-vector | design"
        stokeColor="#DAC7FF"
      /> */}
    </section>
  );
}
