import React, { useEffect, useState } from "react";
import "../../styles/components/addResidencePart/step13.css";
import { useStoreShareFunction } from "../../store/StoreShareFunction";

const currentStep = 13;

export default function Step13FinishPublish({
  step,
  handleBlockBtn,
  setConditions,
  conditions,
}) {
  useEffect(() => {
    if (currentStep == step) handleBlockBtn(conditions == false);
  }, [step, conditions]);

  const checkIfBlock = () => {
    return conditions == false;
  };
  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step && !checkIfBlock()) {
      document.activeElement.blur();
      useStoreShareFunction.getState().nextPage();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [checkIfBlock, step, conditions]);
  return (
    <section
      className={`step-1-annonce step-13 ${
        currentStep == step ? "visible" : ""
      } 
          ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            <span className="step">ETAPE 3</span>
            <label>Terminez et publiez</label>
            <p>
              Enfin, vous choisissez les paramètres de réservation, définissez
              votre tarification et publiez votre annonce.
            </p>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm jcenter liner">
          <div className="content | flcolm addgap16">
            <p>
              Les clients réservent instantanément les jours disponibles.
              N'obtenez des réservations que lorsque vous pouvez accueillir en
              gardant votre calendrier et vos paramètres de disponibilité à
              jour.
            </p>
            <p>
              Toutes demandes de réservation sera soumise à une validation de
              votre part en tant qu’hôte.
            </p>
            <button
              onClick={() => setConditions(!conditions)}
              className="btn | flrow liner"
            >
              <div className="card-coche | flex liner jcenter">
                {conditions && <span></span>}
              </div>
              <span>J’ai compris, j’accepte ces conditions.</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
