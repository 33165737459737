import React, { useEffect, useRef, useState } from "react";
import { Heart, ShareIcon, Star } from "../../images/icons/JsxIcons";
import { useObserveToY } from "../../services/useCurrentScrollY";
import useCurrentWidth from "../../services/useCurrentWidth";

import { useTranslation } from "react-i18next";
import { formatStringToOneUpperCase } from "../../services/utils";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import ShareModal from "./ShareModal";

export default function HeroTitleResidence({
  isFav,
  handleFavoris,
  residence = null,
  loading,
}) {
  const { windowWidth } = useCurrentWidth();
  const { t } = useTranslation();
  const refHeroTitle = useRef(null);
  const refHeroReplace = useRef(null);

  const { state, bottomSeuil, currentBottom } = useObserveToY(
    refHeroReplace,
    -20
  );

  const [openShareModal, setOpenShareModal] = useState(false);

  const [residenceName, setResidenceName] = useState("");

  useEffect(() => {
    if (!refHeroTitle.current || !refHeroReplace.current) return;
    refHeroReplace.current.style.height =
      refHeroTitle.current.clientHeight + "px";
  }, [windowWidth, residence, residenceName]);

  useEffect(() => {
    if (!residence) return;
    let residenceName = formatStringToOneUpperCase(residence.name);
    // residenceName = residenceName.toLocaleLowerCase();
    // residenceName = residenceName.replace(/\b\w/g, (l) => l.toUpperCase());
    setResidenceName(residenceName);
  }, [residence]);

  return (
    <>
      <ContainerPersoModal
        bottomChildren={true}
        baseTop={true}
        title={"Partager"}
        isOpen={openShareModal}
        toggle={setOpenShareModal}
      >
        <ShareModal data={residence} />
      </ContainerPersoModal>
      <div
        style={{ height: "auto" }}
        ref={refHeroReplace}
        className="title-residence-replace rlve"
      >
        {" "}
        <div
          ref={refHeroTitle}
          className={`title-residence-share ${
            state ? "fixed-to-header" : ""
          } | flrow liner btw`}
        >
          <div
            className={`resi-info ${
              (!loading && residence) == false ? " in-load" : ""
            } | flcom`}
          >
            {!loading && residence ? (
              <>
                <h2>{residenceName}</h2>
                <div className="stars-location | flrow liner">
                  <div className="star-resi | flrow liner">
                    <Star
                      color={residence.stars ? "#FACC15" : "var(--shade-black)"}
                    />
                    <span>
                      {residence.stars
                        ? parseFloat(residence.stars?.toString()).toFixed(1)
                        : t("noneTxtNote")}
                    </span>
                  </div>
                  <hr />
                  <h3>{residence.address}</h3>
                </div>
              </>
            ) : (
              <>
                <span
                  style={{ width: "45%", height: "18.5px" }}
                  className="skeleton span-skeleton"
                ></span>
                <div className="stars-location | flrow liner">
                  <div className="star-resi | flrow liner">
                    <span
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                      }}
                      className="skeleton span-skeleton"
                    ></span>
                    <span
                      style={{ width: "25px", height: "15px" }}
                      className="skeleton span-skeleton"
                    ></span>
                  </div>
                  <hr />
                  <span
                    style={{ width: "60%", height: "18px" }}
                    className="skeleton span-skeleton"
                  ></span>
                </div>
              </>
            )}
          </div>
          <div className="actions-btn | flrow liner addgap16">
            <button
              onClick={() => setOpenShareModal(true)}
              disabled={loading && !residence}
              className="btn"
            >
              <ShareIcon />
              <span>{t("shareBtn")}</span>
            </button>
            <button
              disabled={loading && !residence}
              onClick={handleFavoris}
              className="btn"
            >
              <Heart
                color={isFav ? "var(--destructive-500)" : "transparent"}
                stroke={isFav ? "var(--destructive-500)" : "#1E1A21"}
              />
              <span>{t("saveBtn")}</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
