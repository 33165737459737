/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Autocomplete } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import { requestEndpoint } from "../../data/requestEndpoint";
import {
  get,
  useGetDelayRequest,
  useGetStoreWithDelay,
} from "../../services/useFetchAction";
import { getLatLngFromAddress } from "../../services/utils";
import { useStoreManager } from "../../store/StoreManage";
export default function InputHeaderDestination({
  currentDestination,
  setCurrentDestination,
  searchStoreParams,
  noLabel = false,
  setCurrentLat,
  setCurrentLng,
  setViewPort,
  viewPort,
  currentLat,
  currentLng,
}) {
  const [destinationFocus, setDestinationFocus] = useState(false);
  const [timeout, setTimeout] = useState();
  const refInputDestination = useRef();
  const selectRefLocation = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const [loadingLocationLongLat, setLoadingLocation] = useState(false);

  //recuperation de la valeur de la query mapOpen
  const locationParams = new URLSearchParams(window.location.search);
  const mapOpen = locationParams.get("mapOpen");
  const centerMap = locationParams.get("centerMap");
  useEffect(() => {
    if (refInputDestination.current) {
      if (mapOpen == 1 || mapOpen == "1" || mapOpen == true) {
        if (refInputDestination.current.value == "Depuis la carte") {
          setCurrentDestination("");
        }
        refInputDestination.current.value = "Depuis la carte";
      } else {
        const searchStoreParams = useStoreManager.getState().searchStoreParams;
        if (searchStoreParams) {
          if (searchStoreParams.location) {
            refInputDestination.current.value = searchStoreParams.location;
            setCurrentDestination(searchStoreParams.location);
          } else refInputDestination.current.value = currentDestination;
        } else refInputDestination.current.value = currentDestination;
      }
    }
  }, [mapOpen, centerMap]);

  const handleOnChange = (e) => {
    setCurrentDestination(e.target.value);
  };
  const [dataLocation, loadinglocation, errorLocation, fetchLocation] =
    useGetDelayRequest({
      delay: 200,
    });

  const [firstUpdate, setFirstUpdate] = useState(false);
  useEffect(() => {
    if (currentDestination.length < 1) return;
    if (firstUpdate) return;
    refInputDestination.current.value = currentDestination;
    setFirstUpdate(true);
  }, [currentDestination]);
  const handleOnFocus = () => {
    clearTimeout(timeout);
    setDestinationFocus(true);
    setShowSearch(true);
    if (refInputDestination.current.value == "Depuis la carte")
      refInputDestination.current.value = "";
  };

  const handleOnBlur = () => {
    clearTimeout(timeout);
    let timeoutForGo = setTimeout(() => {
      setDestinationFocus(false);
    }, 100);
    setTimeout(timeoutForGo);
    if (
      refInputDestination.current.value.trim() == "" &&
      (mapOpen == 1 || mapOpen == "1" || mapOpen == true)
    ) {
      setCurrentDestination("");
      refInputDestination.current.value = "Depuis la carte";
    }
  };
  const handleSelectChange = async (val) => {
    setCurrentDestination(val.name);
    refInputDestination.current.value = val.name;

    setCurrentLat(val.longlat.lat);
    setCurrentLng(val.longlat.lng);
    setShowSearch(false);
  };

  const [alreadyUpdate, setAlreadyUpdate] = useState(false);
  useEffect(() => {
    if (window.location.pathname == "/store") {
      if (alreadyUpdate) return;
      if (searchStoreParams) {
        if (searchStoreParams.destination) {
          refInputDestination.current.value = searchStoreParams.destination;
          setAlreadyUpdate(true);
        }
      }
    } else {
      setAlreadyUpdate(false);
    }
  }, [currentDestination]);
  const { loading, data, error, fetchData } = useGetStoreWithDelay(
    requestEndpoint.getSuggestions + "?search=" + currentDestination,
    null,
    null,
    0,
    400
  );
  const [suggestionsDatas, setSuggestionsDatas] = useState([]);

  useEffect(() => {
    if (!currentDestination) return;
    if (currentDestination.length < 3) return;
    // fetchData();
    // setTimeout(() => {
    //   fetchData();
    // }, 50);
    const newPRoute =
      requestEndpoint.getLocationResidence + "?location=" + currentDestination;
    setLoadingLocation(true);
    // fetchLocation(null, newPRoute);
  }, [currentDestination]);

  useEffect(() => {
    if (loading) {
      setSuggestionsDatas([]);
      return;
    }
    if (!data) return;
    if (data.length == 0) return;
    let dataLoadSuggestions = [];
    data.map((item) => {
      dataLoadSuggestions.push({
        name: item,
      });
    });

    setSuggestionsDatas(dataLoadSuggestions);
  }, [data, loading]);

  const handleClickOutside = (event) => {
    if (
      selectRefLocation.current &&
      !selectRefLocation.current.contains(event.target)
    ) {
      setShowSearch(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //SUGGESTIONS DATA MANAGE

  const [dataLocationShow, setDataLocationShow] = useState([]);

  const fetchMyLngLat = async (place_id) => {
    const response = await get(
      requestEndpoint.reverseGeo + `?search=${place_id}`
    );
    if (response?.data?.result?.geometry?.location)
      return response.data.result.geometry.location;
    return null;
  };
  const handleTakeMineLngLat = async (dataLocation) => {
    let newDatas = [];
    await Promise.all(
      dataLocation.data.predictions.slice(0, 4).map(async (search) => {
        const longlat = await fetchMyLngLat(search.place_id);
        newDatas.push({
          name: search.description,
          longlat: longlat,
        });
      })
    );
    setLoadingLocation(false);
    setDataLocationShow(newDatas);
  };
  useEffect(() => {
    if (loadinglocation) {
      setDataLocationShow([]);
      return;
    }
    if (!dataLocation) {
      setLoadingLocation(false);
      return;
    }
    if (dataLocation.data.predictions) {
      handleTakeMineLngLat(dataLocation);
    } else {
      setLoadingLocation(false);
    }
  }, [loadinglocation, dataLocation]);

  const handleResetLngLat = () => {
    setCurrentLat(null);
    setCurrentLng(null);
    setViewPort(null);
  };

  const [oldValue, setOldValue] = useState("");
  const handleSearchLocation = async () => {
    let value = refInputDestination.current.value;
    setCurrentDestination(value);

    if (oldValue === value) {
      return;
    }

    try {
      const { lat, lng, viewport } = await getLatLngFromAddress(value);

      if (!lat || !lng) {
        return;
      }
      setCurrentLat(lat);
      setCurrentLng(lng);
      setViewPort(viewport);
    } catch (error) {
      console.error("Erreur lors de la récupération des coordonnées:", error);
    }
    setOldValue(value);
  };
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (currentDestination != oldValue) return;
    if (!firstLoad) {
      setFirstLoad(true);
      return;
    }
    // console.log("currentLat", currentLat);
    // console.log("currentLng", currentLng);
    // console.log("viewPort", viewPort);
  }, [currentLat, currentLng, viewPort, currentDestination]);
  return (
    <div
      ref={selectRefLocation}
      style={
        noLabel && destinationFocus
          ? { flex: 2, transition: "0.2s" }
          : noLabel
          ? { transition: "0.2s" }
          : {}
      }
      className={`input input-location ${
        currentDestination.length > 0 || destinationFocus
          ? `focus ${noLabel ? "no-label" : ""}`
          : ""
      } | rlve`}
    >
      <label
        className={
          currentDestination.length > 0 ||
          destinationFocus ||
          mapOpen == 1 ||
          mapOpen == "1" ||
          mapOpen == true
            ? `on-top ${noLabel ? "no-label" : ""}`
            : ""
        }
      >
        Destination
      </label>
      <Autocomplete
        options={{
          types: [],
          componentRestrictions: {
            country: "ci",
          },
        }}
      >
        <input
          style={noLabel ? { fontWeight: 600 } : {}}
          ref={refInputDestination}
          onFocus={handleOnFocus}
          onBlur={() => {
            handleSearchLocation();
            handleOnBlur();
          }}
          placeholder=""
          onChange={(e) => {
            setSuggestionsDatas([]);
            handleResetLngLat();
            setOldValue(null);
            handleOnChange(e);
          }}
        />
      </Autocomplete>
      {/* <Searchbar
        thumbLeft={locationPosition}
        loading={loadinglocation || loadingLocationLongLat}
        data={dataLocationShow.slice(0, 4)}
        handleFunctionLocation={handleSelectChange}
        open={showSearch && currentDestination.length >= 3}
      /> */}
    </div>
  );
}
