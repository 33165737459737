/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../globalTraduction";
import { NotificationService } from "../../services/Notification.service";
import { useStoreCurrencies } from "../../store/dataCurrencies";
import { UserPreferences } from "../../store/UserPreferences";
import "../../styles/components/choiceLanguageDeviseModal.css";
import "../../styles/components/signalementModal.css";

export default function ChoiceLanguageDeviseModal() {
  const {
    handleChangeDevise,
    handleChangeShowDevise,
    userLanguage,
    showDevise,
    devise,
    sign,
  } = UserPreferences();
  const {
    currencies,
    currentCurrencyId,
    handleUpdateCurrentCurrencyId,
    handleUpdateSign,
  } = useStoreCurrencies();
  const [deviseSelected, setDeviseSelected] = useState(devise);
  const [showDeviseSelected, setShowDeviseSelected] = useState(showDevise);
  const [signSelected, setSignSelected] = useState(sign);
  const [selectedCurrency, setSelectedCurrency] = useState(currentCurrencyId);
  const [currentMenu, setCurrentMenu] = useState(1);
  const { t } = useTranslation();
  const [canConfirm, setCanConfirm] = useState(false);
  useEffect(() => {
    if (selectedCurrency !== currentCurrencyId) {
      setCanConfirm(true);
    } else {
      setCanConfirm(false);
    }
  }, [selectedCurrency]);
  const handleConfirm = () => {
    handleUpdateCurrentCurrencyId(selectedCurrency);
    handleChangeDevise(deviseSelected);
    if (signSelected === "€") {
      handleChangeShowDevise("€");
    } else {
      handleChangeShowDevise(showDeviseSelected);
    }
    handleUpdateSign(signSelected);
    window.location.reload();
  };
  return (
    <div className="signalement-modal choice-user | flcolm">
      <div className="choose-menu | flrow liner maxwidth">
        <button
          onClick={() => setCurrentMenu(1)}
          className={`btn ${currentMenu == 1 ? "selected" : ""}`}
        >
          <span>{t("selectLangueLabel")}</span>
        </button>
        <button
          onClick={() => setCurrentMenu(2)}
          className={`btn ${currentMenu == 2 ? "selected" : ""}`}
        >
          <span>{t("selectDeviseLabel")}</span>
        </button>
      </div>

      <div
        className={`contents side-scroll ${
          currentMenu == 2 ? "show-devise" : ""
        } | flrow`}
        style={currentMenu == 1 ? { height: 136 } : {}}
      >
        <div className="selectors | flcolm">
          <button
            onClick={() => {
              i18n.changeLanguage("fr");
            }}
            className="select-btn | btn btw rlve"
          >
            <span>{t("francaisName")} </span>
            <div className="selector-circle rlve">
              {userLanguage == "fr-FR" && (
                <span className="center-absolute"></span>
              )}
            </div>
          </button>
          <button
            style={{
              background: "var(--gray-200)",
              border: "1px solid var(--gray-200)",
            }}
            onClick={() => {
              NotificationService.addToQueue(
                1,
                "Fonctionnalité bientôt disponible"
              );
              return;
              i18n.changeLanguage("en");
            }}
            className="select-btn | btn btw rlve"
          >
            <span>{t("anglaisName")}</span>
            <div className="selector-circle rlve">
              {userLanguage == "en-EN" && (
                <span className="center-absolute"></span>
              )}
            </div>
          </button>
        </div>
        <div className="selectors | flcolm">
          {currencies.currencies.map((currency) => (
            <button
              key={currency.id}
              onClick={() => {
                setSelectedCurrency(currency.id);
                setShowDeviseSelected(currency.code);
                setDeviseSelected(currency.name);
                setSignSelected(currency.sign);
              }}
              className="select-btn | btn btw rlve"
            >
              <span>{`(${currency.code}) ${currency.name}`}</span>
              <div className="selector-circle rlve">
                {selectedCurrency === currency.id && (
                  <span className="center-absolute"></span>
                )}
              </div>
            </button>
          ))}
          <button
            disabled={!canConfirm}
            className="btn confirm-btn"
            onClick={handleConfirm}
          >
            <span>{t("confirmTxt")}</span>
          </button>
        </div>
      </div>
    </div>
  );
}
