import React, { useEffect, useState } from "react";
import "../../styles/components/mainResidencePage.css";
import CancelConditionResidence from "../cells/CancelConditionResidence";
import ComoditiesResidence from "../cells/ComoditiesResidence";
import DescriptionResidence from "../cells/DescriptionResidence";
import HoteLittleInfo from "../cells/HoteLittleInfo";

import { useNavigate } from "react-router-dom";
import { NotificationService } from "../../services/Notification.service";
import ApercuResidence from "../cells/ApercuResidence";
import CommentaryResidence from "../cells/CommentaryResidence";
import LogementResidence from "../cells/LogementResidence";
import MobileReservationPanel from "../cells/MobileReservationPanel";
import ReservationPanel from "../cells/ReservationPanel";

export default function MainResidencePage({
  residence = null,
  loading,
  error,
}) {
  const [previewPrice, setPreviewPrice] = useState(null);
  const [dateNotValid, setDateNotValid] = useState(null);
  const navigate = useNavigate();
  //check si l'error a le status 404
  useEffect(() => {
    if (error) {
      if (error.response?.status == 404) {
        navigate("/404");
        NotificationService.addToQueue(
          1,
          "Cette résidence est peut etre en maintenace pour le moment, revenez plus tard."
        );
      }
    }
  }, [error]);
  return (
    <section className="main-residence-section | max-container flcolm rlve">
      <div className="contains-main-residence | flcolm rlve">
        <HoteLittleInfo residence={residence} />
        <DescriptionResidence residence={residence} />
        <ComoditiesResidence residence={residence} />
        <ApercuResidence residence={residence} />
        <CancelConditionResidence residence={residence} />
        <ReservationPanel
          residence={residence}
          setPreviewPrice={setPreviewPrice}
          setDateNotValidForMobile={setDateNotValid}
        />
      </div>
      <CommentaryResidence residence={residence} />
      <LogementResidence residence={residence} />
      <MobileReservationPanel
        residence={residence}
        previewPrice={previewPrice}
        dateNotValid={dateNotValid}
      />
    </section>
  );
}
