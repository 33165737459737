import React, { useEffect, useState } from "react";
import { EyeSlashed, Pass } from "../../../images/icons/JsxIcons";
import { patch, post, put } from "../../../services/useFetchAction";
import { requestEndpoint } from "../../../data/requestEndpoint";
import { useTranslation } from "react-i18next";
import { NotificationService } from "../../../services/Notification.service";
import { useStorePageManager } from "../../../store/PageManage";
import { checkIfPassIsGood } from "../../../services/utils";
export default function ChangePassword({
  handleUpdateBlockScreen,
  handleOutModal,
}) {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState("");
  const [secPassword, setSecPassword] = useState("");
  const [stateNewPassword, setStateNewPassword] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [stateSecPassword, setStateSecPassword] = useState({
    focus: false,
    error: false,
    good: false,
  });
  const [seeNewPassword, setSeeNewPassword] = useState(false);
  const [seeSecPassword, setSeeSecPassword] = useState(false);

  useEffect(() => {
    if (secPassword.length >= 8) {
      if (checkIfPassIsGood(secPassword) && secPassword != newPassword) {
        setStateSecPassword({ ...stateSecPassword, error: false, good: true });
      } else
        setStateSecPassword({ ...stateSecPassword, error: true, good: false });
    } else {
      setStateSecPassword({ ...stateSecPassword, error: false, good: false });
    }
    if (newPassword.length >= 8) {
      setStateNewPassword({ ...stateNewPassword, error: false, good: true });
    } else {
      setStateNewPassword({ ...stateNewPassword, error: false, good: true });
    }
  }, [secPassword, newPassword]);

  const handleGoNewPass = async () => {
    if (checkIfPassIsGood(secPassword)) {
      handleUpdateBlockScreen(true);
      let data = {
        oldPassword: newPassword,
        newPassword: secPassword,
      };
      await put(requestEndpoint.updatePassword, data)
        .then((res) => {
          // console.log(res);
          NotificationService.addToQueue(0, t("motPasseSwitchDone"));
          handleOutModal();
        })
        .catch((err) => {
          console.log(err);
          NotificationService.addToQueue(1, "Mot de passe actuel incorrect");
          NotificationService.addToQueue(2, t("motPasseSwitchNotDone"));
        })
        .finally(() => {
          useStorePageManager.getState().handleUpdateBodyOverflow(true);
          handleUpdateBlockScreen(false);
        });
    }
  };
  return (
    <>
      <label>
        Le nouveau mot de passe doit contenir au moins un symbole ou un chiffre
        et comporter au moins 8 caractères et être différent de l’ancien.
      </label>
      <div className="input-field | flcolm">
        <div className="input password | rlve">
          <Pass color={stateNewPassword.focus ? "var(--gray-600)" : "none"} />
          <input
            onBlur={() =>
              setStateNewPassword({ ...stateNewPassword, focus: false })
            }
            onFocus={() =>
              setStateNewPassword({ ...stateNewPassword, focus: true })
            }
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Mot de passe actuel"
            type={seeNewPassword ? "text" : "password"}
            name="password"
          />
          <button
            onClick={() => setSeeNewPassword(!seeNewPassword)}
            className="see-pass | btn"
          >
            <EyeSlashed
              stroke={seeNewPassword ? "var(--gray-300)" : "#9CA3AF"}
            />
          </button>
          {stateNewPassword.focus == false && stateNewPassword.error && (
            <div className="detect-issue-input">
              <div className="chevron"></div>
              <span>Mot de passe non valide</span>
            </div>
          )}
        </div>
        <div className="input password | rlve">
          <Pass color={stateSecPassword.focus ? "var(--gray-600)" : "none"} />
          <input
            onBlur={() =>
              setStateSecPassword({ ...stateSecPassword, focus: false })
            }
            onFocus={() =>
              setStateSecPassword({ ...stateSecPassword, focus: true })
            }
            onChange={(e) => setSecPassword(e.target.value)}
            placeholder="Nouveau mot de passe"
            type={seeSecPassword ? "text" : "password"}
            name="password"
          />
          <button
            onClick={() => setSeeSecPassword(!seeSecPassword)}
            className="see-pass | btn"
          >
            <EyeSlashed
              stroke={seeSecPassword ? "var(--gray-300)" : "#9CA3AF"}
            />
          </button>
          {stateSecPassword.focus == false && stateSecPassword.error && (
            <div className="detect-issue-input">
              <div className="chevron"></div>
              <span>Mot de passe non valide ou identique</span>
            </div>
          )}
        </div>
      </div>
      <button
        onClick={handleGoNewPass}
        className="next-btn | btn flex"
        disabled={!(stateSecPassword.good && stateNewPassword.good)}
      >
        Changer le mot de passe
      </button>
    </>
  );
}
