import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleFormatToLocalString } from "../../services/usePersoFormat";
import {
  checkDateJust,
  getJustDateFormat,
  handlePreviewPrice,
} from "../../services/utils";
import { useStoreCurrencies } from "../../store/dataCurrencies";
import { useStorePageManager } from "../../store/PageManage";
import { UserPreferences } from "../../store/UserPreferences";
import TileResidence from "./TileResidence";

export default function ConfirmReservationPanelToGo({
  residence,
  startDate,
  endDate,
  t,
  nbrPersonne,
  handleUpdateReservationParameters,
}) {
  const sign = useStoreCurrencies((state) => state.sign);

  const language = UserPreferences((state) => state.language);
  const showDevise = UserPreferences((state) => state.showDevise);
  const [totalPrice, setTotalPrice] = useState({
    normalPrice: 0,
    discountPrice: 0,
    discountAmount: 0,
    discountPercent: 0,
    subtotal: 0,
    fee: 0,
    total: 0,
  });
  const [days, setDays] = useState(1);
  const [timeoutId, setTimeoutId] = useState();
  const handleUpdateBlockScreen = useStorePageManager(
    (state) => state.handleUpdateBlockScreen
  );
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );
  const navigate = useNavigate();

  const handleGoToReservation = () => {
    handleUpdateBlockScreen(true);
    handleUpdateBodyOverflow(true);
    handleUpdateReservationParameters({
      residenceId: residence.id,
      residence: residence,
      lastPage: "/residence/" + residence.slug,
      startDate,
      endDate,
      nbrPersonne: nbrPersonne,
    });
    let timer = setTimeout(() => {
      handleUpdateBlockScreen(false);
      navigate("/reservation/0");
    }, 1000);
    setTimeoutId(timer);
  };
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [reductionApply, setReductionApply] = useState(false);
  useEffect(() => {
    if (!residence) return;
    if (!startDate) return;
    if (!endDate) return;
    if (!nbrPersonne) return;
    const startMoment = moment(startDate).startOf("day");
    const endMoment = moment(endDate).endOf("day");
    let fraisPrice = 0;
    let daysDifference = endMoment.diff(startMoment, "days");
    if (daysDifference <= 0) daysDifference = 1;
    setDays(daysDifference);
    let { subTotalPriceN = 0, fraisPriceN = 0, totalPriceResidenceN = 0 } = {};
    if (checkDateJust(startDate) && checkDateJust(endDate)) {
      setLoadingPreview(true);
      let startDFinish = startDate;
      let endDFinish = endDate;
      if (!checkDateJust(startDFinish) || !checkDateJust(endDFinish)) {
        startDFinish = new Date();
        endDFinish = new Date(new Date().getTime() + 86400000);
        startDFinish.setHours(0, 0, 0, 0);
        endDFinish.setHours(0, 0, 0, 0);
        startDFinish = new Date(startDFinish);
        endDFinish = new Date(endDFinish);
      }
      handlePreviewPrice(
        startDFinish,
        endDFinish,
        nbrPersonne || 1,
        residence.id
      ).then((data) => {
        setTotalPrice(data);
        if (data?.discountAmount > 0) {
          setReductionApply(true);
        }
        setLoadingPreview(false);
      });
      return;
    }
    setReductionApply(false);

    let priceBase = residence.price;
    let totalPriceResidence = 0;
    totalPriceResidence = Math.round(daysDifference * priceBase);
    fraisPrice = Math.round((totalPriceResidence * 7) / 100);
    if (fraisPrice.toString().includes(".")) {
      fraisPrice = fraisPrice.toFixed(2);
    }
    totalPriceResidence = totalPriceResidence + fraisPrice;
    if (totalPriceResidence.toString().includes(".")) {
      totalPriceResidence = totalPriceResidence.toFixed(2);
    }

    // let nbrDay = moment(endDate)
    //   .startOf("day")
    //   .diff(moment(startDate).endOf("day"), "days");
    // nbrDay++;

    const total = priceBase * daysDifference;
    setCurrentPrice(total);
    setTotalPrice({ total: total });
  }, [residence, startDate, endDate, nbrPersonne, language, showDevise]);
  return (
    <div className="confirm-reservation | flcolm jcenter liner">
      <div className="confirm-reservation-info | flcolm liner">
        <TileResidence residence={residence} />
        <div className="date-one | flcolm addgap8">
          <label>Date</label>
          <span>
            {getJustDateFormat(startDate, "D MMMM YYYY")} -{" "}
            {getJustDateFormat(endDate, "D MMMM YYYY")}
          </span>
          <span>
            {days} {"nuit"}
            {days > 1 ? "s" : ""}
          </span>
        </div>
        <div className="date-one | flcolm addgap8">
          <label>{t("personneNumber")}</label>
          <span>
            {nbrPersonne} {t("personneTxt")}
            {nbrPersonne > 1 ? "s" : ""}
          </span>
        </div>

        {loadingPreview ? (
          <div className="date-one | flcolm addgap8 maxwidth">
            <span
              style={{
                height: "20px",
                width: "60%",
                maxWidth: "100%",
              }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{
                height: "20px",
                width: "50%",
              }}
              className="skeleton span-skeleton"
            ></span>
          </div>
        ) : (
          <>
            {totalPrice.state === "DOWN" && (
              <>
                <div className="date-one | flcolm addgap8">
                  <label>{"Prix normal"}</label>
                  <span>
                    {handleFormatToLocalString(
                      totalPrice.normalPrice,
                      language,
                      sign
                    )}{" "}
                    {showDevise}
                  </span>
                </div>
                <div className="date-one | flcolm addgap8">
                  <div className="flrow liner addgap8">
                    <label>Réduction</label>{" "}
                    <span className="discount-txt">
                      -{totalPrice?.discountPercent || 0}%
                    </span>
                  </div>
                  <span>
                    -
                    {handleFormatToLocalString(
                      totalPrice?.discountAmount || 0,
                      language,
                      sign
                    ) +
                      " " +
                      showDevise}
                  </span>
                </div>
              </>
            )}

            <div className="date-one | flcolm addgap8">
              <label>
                {totalPrice.state === "DOWN"
                  ? "Prix après réduction"
                  : "Prix total"}
              </label>
              <span className={totalPrice.state === "DOWN" ? "flash-me" : ""}>
                {handleFormatToLocalString(totalPrice.total, language, sign)}{" "}
                {showDevise}
              </span>
            </div>
          </>
        )}
      </div>
      <div className="confirm-reservation-btns | flrow liner btw maxwidth">
        <button
          disabled={loadingPreview}
          onClick={handleGoToReservation}
          className="confirm-btn | btn pry-btn maxwidth"
        >
          {t("nextBtnTxt")}
        </button>
      </div>
    </div>
  );
}
