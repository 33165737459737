import { Autocomplete } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import { requestEndpoint } from "../../data/requestEndpoint";
import {
  get,
  useGetDelayRequest,
  useGetStoreWithDelay,
} from "../../services/useFetchAction";
import { getLatLngFromAddress } from "../../services/utils";
import "../../styles/components/inputHeader.css";
import "../../styles/components/inputHeaderSecond.css";
export const InputHeaderSecond = ({
  title = "",
  placeholder = "",
  codeUpdateInput = "",
  value,
  typeInput = "text",
  typer = "",
  baseFlex = 1,
  setHider,
  setInput,
  handleGoSearch,
  secondHeader = false,
  currentLat,
  currentLng,
  setCurrentLat,
  setCurrentLng,
  setViewPort,
  viewPort,
}) => {
  const [currentInput, setCurrentInput] = useState("");
  const { loading, data, error, fetchData } = useGetStoreWithDelay(
    requestEndpoint.getSuggestions + "?search=" + currentInput,
    null,
    null,
    0,
    400
  );

  const [loadingLocationLongLat, setLoadingLocation] = useState(false);
  const [showValue, setShowValue] = useState("");
  const [currentBlur, setCurrentBlur] = useState(false);
  const [loadSearchBar, setLoadSearchBar] = useState(false);
  const refInput = useRef(null);

  const [firstUpdated, setFirstUpdated] = useState(false);
  const [timeoutIdSearch, setTimeoutIdSearch] = useState();
  const [stockBeforeInput, setStockBeforeInput] = useState("");

  useEffect(() => {
    if (!handleGoSearch) return;
    if (currentBlur) return;
    if (currentBlur == false && stockBeforeInput == currentInput) return;
    // console.log("currentInput", currentInput);
    setStockBeforeInput(currentInput);
    clearTimeout(timeoutIdSearch);
    let timer = setTimeout(() => {
      // handleGoSearch();
    }, 100);
    setTimeoutIdSearch(timer);
    return () => {
      clearTimeout(timer);
    };
  }, [showValue, currentBlur]);
  useEffect(() => {
    if (firstUpdated) return;
    if (!refInput) return;
    if (!value) return;
    setCurrentInput(value);
    setShowValue(value);
    refInput.current.value = value;
    setFirstUpdated(true);
  }, [refInput, value]);

  let timeoutId;

  useEffect(() => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setLoadSearchBar(currentBlur);
    }, 200);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentBlur]);

  const handleClick = () => {
    if (refInput.current.value == "Depuis la carte")
      refInput.current.value = "";
    setCurrentBlur(true);
    if (typeInput === "number") {
      const inputValue = currentInput.trim();
      const cleanedValue = inputValue.replace(/[^0-9]/g, "");
      if (cleanedValue) setShowValue(cleanedValue);
    }
  };
  const handleResetLngLat = () => {
    setCurrentLat(null);
    setCurrentLng(null);
    setViewPort(null);
  };

  const handleBlur = () => {
    setCurrentBlur(false);
    if (typeInput !== "number") return;
    if (currentInput === "0") {
      setCurrentInput("");
      refInput.current.value = "";
      return;
    }
    const numericValue = parseInt(currentInput);

    if (numericValue > 0) {
      if (numericValue === 1)
        setShowValue(numericValue + " " + typer + " minimum");
      else setShowValue(numericValue + " " + typer + "s minimum");
    }
  };

  //SUGGESTIONS DATA MANAGE

  const [dataLocation, loadinglocation, errorLocation, fetchLocation] =
    useGetDelayRequest({
      delay: 200,
    });

  const [dataLocationShow, setDataLocationShow] = useState([]);

  const fetchMyLngLat = async (place_id) => {
    const response = await get(
      requestEndpoint.reverseGeo + `?search=${place_id}`
    );
    if (response?.data?.result?.geometry?.location)
      return response.data.result.geometry.location;
    return null;
  };
  const handleTakeMineLngLat = async (dataLocation) => {
    let newDatas = [];
    await Promise.all(
      dataLocation.data.predictions.slice(0, 4).map(async (search) => {
        const longlat = await fetchMyLngLat(search.place_id);
        newDatas.push({
          name: search.description,
          longlat: longlat,
        });
      })
    );
    setLoadingLocation(false);
    setDataLocationShow(newDatas);
  };
  useEffect(() => {
    if (loadinglocation) return;
    if (!dataLocation) {
      setLoadingLocation(false);
      return;
    }
    if (dataLocation.data.predictions) {
      handleTakeMineLngLat(dataLocation);
    } else {
      setLoadingLocation(false);
    }
  }, [loadinglocation, dataLocation]);
  const handleChange = (e) => {
    setCurrentInput(e.target.value);
    setShowValue(e.target.value);
    if (setInput) {
      setInput(e.target.value);
    }
    let value = e.target.value;
    value = value.replace(/[^\w\s]/gi, "");
    setDataLocationShow([]);
    if (value.trim().length <= 3) return;
    const newPRoute =
      requestEndpoint.getLocationResidence + "?location=" + value;
    setLoadingLocation(true);
    // fetchLocation(null, newPRoute);
  };

  //SUGGESTIONS DATA MANAGE
  const [suggestionsDatas, setSuggestionsDatas] = useState([]);

  useEffect(() => {
    if (!currentInput) return;
    if (currentInput.length < 3) return;
    // fetchData();
    setTimeout(() => {
      // fetchData();
    }, 50);
  }, [currentInput]);

  useEffect(() => {
    // if (loading) {
    //   setSuggestionsDatas([]);
    //   return;
    // }
    // if (!data) return;
    // if (data.length == 0) return;
    // let dataLoadSuggestions = [];
    // data.map((item) => {
    //   dataLoadSuggestions.push({
    //     name: item,
    //   });
    // });
    // setSuggestionsDatas(dataLoadSuggestions);
  }, [data, loading]);

  const [oldValue, setOldValue] = useState("");
  const handleSearchLocation = async () => {
    let value = refInput.current.value;
    setCurrentInput(value);
    setShowValue(value);
    setOldValue(value);

    if (oldValue === value) {
      if (setInput) {
        setInput(value);
      }
      return;
    }

    try {
      const { lat, lng, viewport } = await getLatLngFromAddress(value);
      if (!lat || !lng) {
        return;
      }
      setCurrentLat(lat);
      setCurrentLng(lng);
      setViewPort(viewport);
    } catch (error) {
      console.error("Erreur lors de la récupération des coordonnées:", error);
    }
    if (setInput) {
      setInput(value);
    }
    // handleGoSearch();
  };
  const [firstLoad, setFirstLoad] = useState(false);
  useEffect(() => {
    if (value != oldValue) return;
    if (!firstLoad) {
      setFirstLoad(true);
      return;
    }
    // console.log("currentLat", currentLat);
    // console.log("currentLng", currentLng);
    // console.log("viewPort", viewPort);
    handleGoSearch();
  }, [currentLat, currentLng, viewPort, value]);

  const handleSelectChange = async (value) => {
    // console.log("minevalue", value.name);
    // return;
    refInput.current.value = value.name;
    setCurrentInput(value.name);
    setShowValue(value.name);
    setCurrentLat(value.longlat.lat);
    setCurrentLng(value.longlat.lng);
    setLoadSearchBar(false);

    if (setInput) {
      setInput(value.name);
    }
  };
  useEffect(() => {
    if (setHider) {
      setTimeout(() => {
        setHider(currentBlur);
      }, 10);
    }
  }, [setHider, currentBlur]);

  //si il est blur et que le type n'est pas number et que l'on clique sur entrer alors on enleve le focus
  useEffect(() => {
    if (!currentBlur) return;
    if (typeInput === "number") return;
    const handleKeyDown = (e) => {
      if (e.keyCode === 13) {
        refInput.current.blur();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentBlur]);
  return (
    <div
      style={{
        flex: baseFlex,
        transition: "all 0.15s ease",
        background: currentBlur ? "var(--shade-white)" : "none",
      }}
      className={"hd-inp hd-input-second | flcolm liner addgap8 rlve"}
    >
      {secondHeader ? (
        <>
          <label className="lbl-inp-second first-shower-lbl-inp">{title}</label>
          <label className="lbl-inp-second second-shower-lbl-inp">
            {secondHeader}
          </label>
        </>
      ) : (
        <label className="lbl-inp-second">{title}</label>
      )}
      <Autocomplete
        options={{
          types: ["establishment"],
          fields: ["address_components", "name"],
          componentRestrictions: {
            country: "ci",
          },
        }}
      >
        <input
          style={{ opacity: !currentBlur && showValue && typeInput ? 0 : 1 }}
          onBlur={() => {
            handleSearchLocation();
            handleBlur();
          }}
          onClick={handleClick}
          // onFocus={handleResetLngLat}
          placeholder={placeholder}
          ref={refInput}
          type={typeInput}
          onChange={(e) => {
            handleResetLngLat();
            setOldValue(null);
            handleChange(e);
          }}
        />
      </Autocomplete>
      <span
        className={
          !currentBlur && showValue && typeInput
            ? "show-me spanner-show"
            : "spanner-show"
        }
      >
        {showValue}
      </span>
      {/* <Searchbar
        className="searchbar-destination"
        thumbLeft={locationPosition}
        loading={loadinglocation || loadingLocationLongLat}
        data={dataLocationShow.slice(0, 4)}
        handleFunctionLocation={handleSelectChange}
        open={loadSearchBar && currentInput.length >= 3}
      /> */}
    </div>
  );
};
