import React, { useEffect, useState } from "react";
import { dataComodities } from "../../data/dataComodities";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import ComoditiesModal from "./ComoditiesModal";
import { useTranslation } from "react-i18next";
import { UserPreferences } from "../../store/UserPreferences";
import { useStoreComodities } from "../../store/dataComodities";

export default function ComoditiesResidence({ residence = null }) {
  const { t } = useTranslation();
  const language = UserPreferences((state) => state.language);
  const comodities = useStoreComodities((state) => state.comodities);
  const allComodities = comodities;
  const [residenceComodities, setResidenceComodities] = useState([]);

  useEffect(() => {
    if (residence == null) return;
    let comodities = [];
    let comoditiesId = [];
    comoditiesId = residence.assets.map((comoditie) => comoditie);
    comoditiesId = [...comoditiesId];

    comoditiesId.map((comoditie) => {
      for (let index = 0; index < allComodities.length; index++) {
        if (allComodities[index].id == comoditie.assetId) {
          comodities.push(allComodities[index]);
          break;
        }
      }
    });
    setResidenceComodities(comodities);
  }, [residence]);

  const [isOpen, toggle] = useState(false);

  return (
    <>
      <ContainerPersoModal
        isOpen={isOpen}
        toggle={toggle}
        title={t("proposeLogementTxt")}
        baseTop={false}
      >
        <ComoditiesModal data={residenceComodities} />
      </ContainerPersoModal>
      <div className="comodities-residence | flcolm">
        {residence ? (
          <>
            <label>{t("labelComodities")}</label>
            <div className="comodities-contains | flrow">
              {residenceComodities.slice(0, 8).map((comoditie) => (
                <div key={comoditie.id} className="comoditie | flrow liner">
                  <img
                    src={comoditie.thumb}
                    alt={`commoditie-${comoditie.title}`}
                  />
                  <span>
                    {language == "fr-FR" ? comoditie.title : comoditie.titleEng}
                  </span>
                </div>
              ))}
            </div>
            {residenceComodities.length > 8 && (
              <button
                onClick={() => toggle(true)}
                className="show-all-commodities | btn"
              >
                <span>{t("labelShowAllCommodities")}</span>
              </button>
            )}
          </>
        ) : (
          <>
            <span
              style={{ width: "22%", height: "28px", marginBottom: 8 }}
              className="skeleton span-skeleton"
            ></span>
            <div className="comodities-contains | flrow">
              {Array.from({ length: 6 }).map((_, index) => (
                <div
                  key={`comoditie-${index}`}
                  className="comoditie | flrow liner"
                >
                  <span
                    style={{ width: "20px", height: "20px" }}
                    className="skeleton span-skeleton"
                  ></span>
                  <span
                    style={{
                      width: "108px",
                      height: "14px",
                      marginBottom: 2,
                    }}
                    className="skeleton span-skeleton"
                  ></span>
                </div>
              ))}
            </div>
            <span
              style={{ width: "22%", height: "32px" }}
              className="skeleton span-skeleton"
            ></span>
          </>
        )}
      </div>
    </>
  );
}
