import React, { useEffect, useState } from "react";
import "../../styles/components/shareModal.css";
import copyIcon from "../../images/icons/copy-02.svg";
import mailAdressIcon from "../../images/icons/mail-01.svg";
import WhaIcon from "../../images/icons/whatsapp.svg";
import MessageIcon from "../../images/icons/message-circle-01.svg";
import { NotificationService } from "../../services/Notification.service";
import { Star } from "../../images/icons/JsxIcons";
export default function ShareModal({ handleOutModal, data }) {
  const [linkCopied, setLinkCopied] = useState(false);
  useEffect(() => {
    if (linkCopied == true)
      setTimeout(() => {
        setLinkCopied(false);
      }, 4000);
  }, [linkCopied, setLinkCopied]);

  const handleCopyLink = () => {
    const link = window.location.href;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        NotificationService.addToQueue(0, "Lien copié avec succès");
        setLinkCopied(true);
      })
      .catch((error) => {
        NotificationService.addToQueue(1, "Erreur lors de la copie du lien");
      });
  };
  const handleShareByMail = () => {
    const link = window.location.href;

    const subject = "Résidence de wow sur Trouvechap!";
    const body = `Salut,\n\nJ'ai trouvé cette résidence super intéressante sur le site Trouvechap : ${link}\n\n`;

    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const handleShareByWhatsapp = () => {
    const link = window.location.href;

    const text =
      "Salut,\n\nJ'ai trouvé cette résidence super intéressante sur le site Trouvechap : " +
      link;

    const whatsappLink = `https://wa.me/?text=${encodeURIComponent(text)}`;

    window.open(whatsappLink, "_blank");
  };

  const handleShareDirect = async () => {
    const link = window.location.href;

    if (navigator.share) {
      try {
        await navigator.share({
          title: "Résidence sur Trouvechap",
          text: "Salut,\n\nJ'ai trouvé cette résidence super intéressante sur le site Trouvechap : ",
          url: link,
        });
        NotificationService.addToQueue(0, "Lien partagé avec succès");
      } catch (error) {
        NotificationService.addToQueue(1, "Erreur lors du partage du lien");
      }
    } else {
      console.log(
        "L'API Web Share n'est pas prise en charge par ce navigateur."
      );
      NotificationService.addToQueue(1, "Erreur lors du partage du lien");
    }
  };

  return (
    <div className="share-modal | flcolm">
      <div className="contents | flcolm">
        <div className="residence-show | flrow addgap16 liner">
          <img
            src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${data.medias[0]?.filename}`}
            alt="residence-photo"
          />
          <div className="infos | flcolm">
            <label>{data.name}</label>
            <div className="second-info | flrow addgap8 liner">
              <div className="note | flrow liner">
                <Star color="#374151" />
                <span>
                  {data.stars ? parseFloat(data.stars).toFixed(1) : "N/A"}
                </span>
              </div>
              <span>{data.address}</span>
            </div>
          </div>
        </div>
        <div className="btns-action | flrow addgap16">
          <button
            onClick={handleCopyLink}
            disabled={linkCopied}
            className="link-copy | btn rlve"
          >
            <img src={copyIcon} alt="copy-icon" />
            <span>Copier le lien</span>
          </button>
          <button onClick={handleShareByMail} className="mail-go | btn rlve">
            <img src={copyIcon} alt="mail-icon" />
            <span>Adresse mail</span>
          </button>
          <button
            onClick={handleShareByWhatsapp}
            className="mail-go | btn rlve"
          >
            <img src={WhaIcon} alt="wha-icon" />
            <span>Whatsapp</span>
          </button>
          <button onClick={handleShareDirect} className="mail-go | btn rlve">
            <img src={MessageIcon} alt="message-icon" />
            <span>Messages</span>
          </button>
        </div>
      </div>
    </div>
  );
}
