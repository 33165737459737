import React, { useEffect, useRef, useState } from "react";
import { useStorePageManager } from "../../store/PageManage";
import "../../styles/components/connexionPanel.css";
import { InscriptionPanelConnexionPart } from "./InscriptionPanelConnexionPart";
import { FinishPanelConnexionPart } from "./FinishPanelConnexionPart";
import { ConnectPanelConnexionPart } from "./ConnectPanelConnexionPart";
import { ForgetPasssPanelConnexion } from "./ForgetPasssPanelConnexion";
import { NewPassPanelConnexion } from "./NewPassPanelConnexion";
import { useTranslation } from "react-i18next";
import { NotificationService } from "../../services/Notification.service";
import { post } from "../../services/useFetchAction";
import { useGoogleLogin } from "@react-oauth/google";
import { requestEndpoint } from "../../data/requestEndpoint";
import { encryptData } from "../../services/encryption.service";
import { useStoreUserManager } from "../../store/UserManage";
import { useNavigate } from "react-router-dom";

export default function ConnexionPanel() {
  const panelOpen = useStorePageManager((state) => state.connexionPanel);
  const handleUpdateConnexionPanelState = useStorePageManager(
    (state) => state.handleUpdateConnexionPanelState
  );
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );

  const refInputConnexionEmail = useRef(null);
  const refInputConnexionMdp = useRef(null);

  const refInputInscriptionEmail = useRef(null);
  const refInputInscriptionMdp = useRef(null);
  const refInputNameMdp = useRef(null);
  const refInputFirstNameMdp = useRef(null);
  const refInputTelInscription = useRef(null);

  const refFirstName = useRef(null);
  const refLastName = useRef(null);
  const refPassword = useRef(null);
  const navigate = useNavigate();
  const [panelState, setPanelState] = useState({
    inscriptionBegin: 0,
    inscriptionFinish: 2,
    connexion: 1,
    passwordForgotten: 2,
    newPassword: 2,
  });
  const [dataInscription, setDataInscription] = useState({
    addressMail: "",
    telephone: "",
    naming: "",
    firstName: "",
    mdp: "",
    indicatif: "",
  });
  useEffect(() => {
    if (!panelOpen) {
      setPanelState({
        inscriptionBegin: 0,
        inscriptionFinish: 2,
        connexion: 1,
        passwordForgotten: 2,
        newPassword: 2,
      });
      setDataInscription({
        addressMail: "",
        telephone: "",
        naming: "",
        firstName: "",
        birthday: "",
        mdp: "",
        indicatif: "",
      });
    }
  }, [panelOpen]);

  const [token, setToken] = useState("");
  const [requestId, setRequestId] = useState("");

  const launchGoogleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      const url = "https://www.googleapis.com/oauth2/v3/userinfo";
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${response.access_token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          let datasend = {
            socialId: data.sub,
            socialEmail: data.email.toLowerCase(),
            socialType: "google",
            statusEmail: data.email_verified,
            firstname: data.given_name,
            lastname: data.family_name,
          };
          post(requestEndpoint.socialGFLogin, datasend)
            .then(async (response) => {
              if (!response.data.enableClient) {
                NotificationService.addToQueue(
                  1,
                  "Votre compte à été bloqué par Trouvechap, veuillez nous contacter par mail."
                );
                return;
              }
              if (
                !response.data.payment_method_id &&
                response.data.enableHost
              ) {
                NotificationService.addToQueue(
                  1,
                  "N'oubliez pas de renseigner votre moyen de paiement dans votre profil"
                );
              }
              NotificationService.addToQueue(
                0,
                "Connexion avec Google réussie"
              );
              let dataToken = {
                access_token: response.data.access_token,
                refresh_token: response.data.refresh_token,
              };
              let encryptToken = await encryptData(dataToken);
              localStorage.setItem("token", encryptToken);

              let dataUserSave = {
                token: response.data.token,
                email: response.data.email,
              };
              let dataEncrypted = await encryptData(dataUserSave);
              // console.log(response);
              useStoreUserManager.getState().handleUpdateUser({
                id: response.data.id,
                lastname: response.data.lastname,
                firstname: response.data.firstname,
                location: response.data.location,
                connected: true,
                profil:
                  response.data.profile +
                  `${response.data.enableHost ? "/hote" : ""}`,
                mail: response.data.email,
                contact: response.data.contact ? response.data.contact : null,
                contactChecked: response.data.contactActivatedAt && true,
                emailVerified: response.data.mailActivatedAt,
                pieceChecked: response.data.identityDoc ? 1 : 0,
                identityDoc: response.data.identityDoc ?? null,
                profilImgUrl: response.data.avatar ?? null,
                isSocial: true,
                typeSocial: "google",
                enableHost: response.data.enableHost,
                payment_method_id: response.data.payment_method_id ?? 0,
              });
              localStorage.setItem("user", dataEncrypted);
              useStorePageManager.getState().handleUpdateBlockScreen(false);
              useStorePageManager
                .getState()
                .handleUpdateConnexionPanelState(false);
            })
            .catch((error) => {
              useStorePageManager.getState().handleUpdateBlockScreen(false);
              NotificationService.addToQueue(
                2,
                "Un utilisateur avec cet adresse email existe déjà"
              );
              return error;
            });
        })
        .catch((error) => {
          console.log(error);
          useStorePageManager.getState().handleUpdateBlockScreen(false);
          NotificationService.addToQueue(
            2,
            "Échec de la connexion avec Google"
          );
        });
    },
    onError: () => {
      useStorePageManager.getState().handleUpdateBlockScreen(false);
      NotificationService.addToQueue("Echec de la connexion avec Google");
    },
  });
  const facebookLoginVoid = (response) => {
    let datasend = {
      socialId: response.data.userID,
      socialEmail: response.data.email.toLowerCase(),
      socialType: "facebook",
      statusEmail: true,
      firstname: response.data.short_name,
      lastname: response.data.last_name,
    };
    post(requestEndpoint.socialGFLogin, datasend)
      .then(async (response) => {
        if (!response.data.enableClient) {
          NotificationService.addToQueue(
            1,
            "Votre compte à été bloqué par Trouvechap, veuillez nous contacter par mail."
          );
          return;
        }
        if (!response.data.payment_method_id && response.data.enableHost) {
          NotificationService.addToQueue(
            1,
            "N'oubliez pas de renseigner votre moyen de paiement dans votre profil"
          );
        }
        NotificationService.addToQueue(0, "Connexion avec Facebook réussie");
        let dataToken = {
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
        };
        let encryptToken = await encryptData(dataToken);
        localStorage.setItem("token", encryptToken);

        let dataUserSave = {
          token: response.data.token,
          email: response.data.email,
        };
        let dataEncrypted = await encryptData(dataUserSave);
        useStoreUserManager.getState().handleUpdateUser({
          id: response.data.id,
          lastname: response.data.lastname,
          firstname: response.data.firstname,
          location: response.data.location,

          connected: true,
          profil:
            response.data.profile +
            `${response.data.enableHost ? "/hote" : ""}`,
          mail: response.data.email,
          contact: response.data.contact ? response.data.contact : null,
          contactChecked: response.data.contactActivatedAt && true,
          emailVerified: response.data.mailActivatedAt,
          pieceChecked: response.data.identityDoc ? 1 : 0,
          identityDoc: response.data.identityDoc ?? null,
          profilImgUrl: response.data.avatar ?? null,
          isSocial: true,
          typeSocial: "facebook",
          enableHost: response.data.enableHost,
          payment_method_id: response.data.payment_method_id ?? 0,
        });
        localStorage.setItem("user", dataEncrypted);
        useStorePageManager.getState().handleUpdateBlockScreen(false);
        useStorePageManager.getState().handleUpdateConnexionPanelState(false);
      })
      .catch((error) => {
        useStorePageManager.getState().handleUpdateBlockScreen(false);
        NotificationService.addToQueue(
          2,
          "Un utilisateur avec cet adresse email existe déjà"
        );
        return error;
      });
  };

  return (
    panelOpen && (
      <div className="connexion-panel">
        <div
          className="background-touch"
          onClick={() => {
            handleUpdateConnexionPanelState(false);
          }}
        ></div>
        <InscriptionPanelConnexionPart
          setPanelState={setPanelState}
          panelState={panelState}
          currentState={panelState.inscriptionBegin}
          dataInscription={dataInscription}
          setDataInscription={setDataInscription}
          launchGoogleLogin={launchGoogleLogin}
          facebookLoginVoid={facebookLoginVoid}
          refFirstName={refFirstName}
          refLastName={refLastName}
          refPassword={refPassword}
        />
        <FinishPanelConnexionPart
          setPanelState={setPanelState}
          panelState={panelState}
          currentState={panelState.inscriptionFinish}
          dataInscription={dataInscription}
          setDataInscription={setDataInscription}
          refFirstName={refFirstName}
          refLastName={refLastName}
          refPassword={refPassword}
        />
        <ConnectPanelConnexionPart
          setPanelState={setPanelState}
          panelState={panelState}
          currentState={panelState.connexion}
          dataInscription={dataInscription}
          setDataInscription={setDataInscription}
          launchGoogleLogin={launchGoogleLogin}
          facebookLoginVoid={facebookLoginVoid}
          refInputConnexionEmail={refInputConnexionEmail}
          refInputConnexionMdp={refInputConnexionMdp}
        />
        <ForgetPasssPanelConnexion
          setRequestId={setRequestId}
          setToken={setToken}
          requestId={requestId}
          token={token}
          setPanelState={setPanelState}
          panelState={panelState}
          currentState={panelState.passwordForgotten}
          dataInscription={dataInscription}
          setDataInscription={setDataInscription}
        />
        <NewPassPanelConnexion
          requestId={requestId}
          token={token}
          setPanelState={setPanelState}
          panelState={panelState}
          currentState={panelState.newPassword}
          dataInscription={dataInscription}
          setDataInscription={setDataInscription}
        />
      </div>
    )
  );
}
