/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/biggest/Footer";
import HeaderResidence from "../components/biggest/HeaderResidence";
import MainResidencePage from "../components/biggest/MainResidencePage";
import { requestEndpoint } from "../data/requestEndpoint";
import { useGetDelayRequest } from "../services/useFetchAction";
import {
  clearEmptyParams,
  formatStringToOneUpperCase,
  smoothScrollUp,
} from "../services/utils";
import { useStorePageManager } from "../store/PageManage";
import "../styles/pages_styles/residencePage.css";

export default function ResidencePage() {
  const { slug } = useParams() || null;
  const handleUpdateStorePage = useStorePageManager(
    (state) => state.handleUpdateStorePage
  );
  const handleUpdateDesactiveHeader = useStorePageManager(
    (state) => state.handleUpdateDesactiveHeader
  );
  useEffect(() => {
    handleUpdateStorePage(false);
    handleUpdateDesactiveHeader(false);
  }, []);
  const [data, loading, error, fetchData] = useGetDelayRequest({
    route: requestEndpoint.getResidence + "/" + slug,
    log: false,
    loadingTime: 0,
    delay: 0,
  });

  useEffect(() => {
    fetchData();
    useStorePageManager.getState().handleUpdateResidencePage(true);
    let newTimeout = setTimeout(() => {
      smoothScrollUp(true);
    }, 100);
    return () => {
      useStorePageManager.getState().handleUpdateResidencePage(false);

      clearTimeout(newTimeout);
    };
  }, []);

  const [canRunCode, setCanRunCode] = useState(true);

  useEffect(() => {
    // handleAddUrlParams();
    if (data?.data) {
      useStorePageManager
        .getState()
        .handleUpdateLastResidenceTouched(data?.data.id);

      document.title = `${formatStringToOneUpperCase(data?.data?.name)}, ${
        data?.data?.address
      } - Trouvechap`;

      let description = data?.data?.description[0]?.text;

      if (description.length > 150) {
        description = description.slice(0, 150) + "...";
      }

      const addressWithoutCountry = data?.data?.address.split("-")[0];
      const rating = data?.data?.stars
        ? parseFloat(data?.data?.stars?.toString()).toFixed(1)
        : "N/A";
      const findChamberCount = data?.data?.rooms.find(
        (room) => room.roomId == 1
      ) ?? { count: 0 };
      const findSalleBainCount = data?.data?.rooms.find(
        (room) => room.roomId == 5
      ) ?? { count: 0 };
      const chambresTxt = findChamberCount.count > 1 ? "chambres" : "chambre";
      const salleBainTxt =
        findSalleBainCount.count > 1 ? "salles de bain" : "salle de bain";
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute(
          "content",
          `${process.env.REACT_APP_API_URL}/assets/uploads/residences/${data?.data?.medias[0]?.filename}`
        );
      document
        .querySelector('meta[property="og:title"]')
        .setAttribute(
          "content",
          `Résidence - ${formatStringToOneUpperCase(
            addressWithoutCountry
          )} · ${addressWithoutCountry} · ★${rating} · ${
            findChamberCount.count
          } ${chambresTxt} · ${findSalleBainCount.count} ${salleBainTxt} `
        );
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute("content", formatStringToOneUpperCase(data?.data?.name));

      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", description);
    }
    return () => {
      document.title =
        "Trouvechap | Locations saisonnières de résidences, villa, et appartements meublées en Côte d’ivoire";
      document
        .querySelector('meta[property="og:title"]')
        .setAttribute(
          "content",
          "Trouvechap | Locations saisonnières de résidences, villa, et appartements meublées en Côte d’ivoire"
        );
      document
        .querySelector('meta[property="og:description"]')
        .setAttribute(
          "content",
          "Trouvez des logements d’exceptions pour vos séjours et activités en Côte d’Ivoire à des prix compétitifs et en toute sécurité."
        );
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Trouvez des logements d’exceptions pour vos séjours et activités en Côte d’Ivoire à des prix compétitifs et en toute sécurité."
        );
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute(
          "content",
          `${process.env.REACT_APP_API_URL}/assets/home-banner.jpg`
        );
    };
  }, [data, loading]);
  useEffect(() => {
    clearEmptyParams();
  }, []);
  if (!canRunCode) return null;

  return (
    <>
      {/* <Header /> */}
      <main className="residence-page">
        <HeaderResidence
          residence={data?.data}
          loading={loading}
          error={error}
        />
        <MainResidencePage
          residence={data?.data}
          loading={loading}
          error={error}
        />
        <Footer />
      </main>
    </>
  );
}
