import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Home,
  HoteChambersIcon,
  HotePersonsIcon,
} from "../../images/icons/JsxIcons";
import tempProfilPicture from "../../images/temps/user-02.svg";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import RoomsModal from "./RoomsModal";
export default function HoteLittleInfo({ residence = null }) {
  const { t } = useTranslation();
  let hostName = "";
  if (residence) {
    hostName = residence.host.firstname + " " + residence.host.lastname;
    //capitalize first letter of each word
    hostName = hostName.toLocaleLowerCase();
    // hostName = hostName.replace(/\b\w/g, (l) => l.toUpperCase());
    hostName = hostName.toLowerCase();
    // mettre en majuscule la première lettre de chaque mot en prenant en compte les accents etc
    hostName = hostName.replace(
      /(^|\s)([a-zéèàêëîïôöûüç])/g,
      function (m, p1, p2) {
        return p1 + p2.toUpperCase();
      }
    );
  }
  // console.log(residence);
  const findRoom = (roomId) => {
    let room = residence?.rooms.find((room) => room.roomId == roomId);
    if (room) return room.count;
    return 0;
  };
  const [isOpen, toggle] = useState(false);

  const [checkIfNeedShowMore, setCheckIfNeedShowMore] = useState(false);

  useEffect(() => {
    if (residence) {
      //si le nombre de rooms ayant au moint count > 0 est supérieur à 5 faire le test avec findroom
      let check = false;
      let count = 0;

      for (let i = 1; i <= 12; i++) {
        if (findRoom(i) ?? 0 > 0) {
          count++;
        }
      }
      if (count > 5) {
        check = true;
      }

      setCheckIfNeedShowMore(check);
    }
  }, [residence]);

  const showInfoResi = (max = 5, normal = true) => {
    let returned = [];

    findRoom(1) > 0 &&
      returned.push(
        <p
          style={{ columnGap: 4, margin: 0, fontWeight: normal ? 500 : 600 }}
          className="flrow liner"
        >
          <HoteChambersIcon />
          {t("chambreTxt").replace(/\b\w/g, (l) => l.toUpperCase())}
          {findRoom(1) > 1 ? "s : " : " : "}
          {findRoom(1)}
        </p>
      );

    findRoom(2) > 0 &&
      returned.push(
        <p
          style={{ columnGap: 4, margin: 0, fontWeight: normal ? 500 : 600 }}
          className="flrow liner"
        >
          <HoteChambersIcon />
          {"Salon".replace(/\b\w/g, (l) => l.toUpperCase())}
          {findRoom(2) > 1 ? "s : " : " : "}
          {findRoom(2)}
        </p>
      );

    findRoom(3) > 0 &&
      returned.push(
        <p
          style={{ columnGap: 4, margin: 0, fontWeight: normal ? 500 : 600 }}
          className="flrow liner"
        >
          <HoteChambersIcon />
          {"Douche".replace(/\b\w/g, (l) => l.toUpperCase())}
          {findRoom(3) > 1 ? "s : " : " : "}
          {findRoom(3)}
        </p>
      );

    findRoom(4) > 0 &&
      returned.push(
        <p
          style={{ columnGap: 4, margin: 0, fontWeight: normal ? 500 : 600 }}
          className="flrow liner"
        >
          <HoteChambersIcon />
          {"Toilette".replace(/\b\w/g, (l) => l.toUpperCase())}
          {findRoom(4) > 1 ? "s : " : " : "}
          {findRoom(4)}
        </p>
      );

    findRoom(5) > 0 &&
      returned.push(
        <p
          style={{ columnGap: 4, margin: 0, fontWeight: normal ? 500 : 600 }}
          className="flrow liner"
        >
          <HoteChambersIcon />
          {"Salle de bain".replace(/\b\w/g, (l) => l.toUpperCase())}
          {findRoom(5) > 1 ? "s : " : " : "}
          {findRoom(5)}
        </p>
      );

    findRoom(6) > 0 &&
      returned.push(
        <p
          style={{ columnGap: 4, margin: 0, fontWeight: normal ? 500 : 600 }}
          className="flrow liner"
        >
          <HoteChambersIcon />
          {"Balcon".replace(/\b\w/g, (l) => l.toUpperCase())}
          {findRoom(6) > 1 ? "s : " : " : "}
          {findRoom(6)}
        </p>
      );

    findRoom(7) > 0 &&
      returned.push(
        <p
          style={{ columnGap: 4, margin: 0, fontWeight: normal ? 500 : 600 }}
          className="flrow liner"
        >
          <HoteChambersIcon />
          {"Terrase".replace(/\b\w/g, (l) => l.toUpperCase())}
          {findRoom(7) > 1 ? "s : " : " : "}
          {findRoom(7)}
        </p>
      );

    findRoom(8) > 0 &&
      returned.push(
        <p
          style={{ columnGap: 4, margin: 0, fontWeight: normal ? 500 : 600 }}
          className="flrow liner"
        >
          <HoteChambersIcon />
          {"Jardin".replace(/\b\w/g, (l) => l.toUpperCase())}
          {findRoom(8) > 1 ? "s : " : " : "}
          {findRoom(8)}
        </p>
      );

    findRoom(9) > 0 &&
      returned.push(
        <p
          style={{ columnGap: 4, margin: 0, fontWeight: normal ? 500 : 600 }}
          className="flrow liner"
        >
          <HoteChambersIcon />
          {"Salle à manger".replace(/\b\w/g, (l) => l.toUpperCase())}
          {findRoom(9) > 1 ? "s : " : " : "}
          {findRoom(9)}
        </p>
      );

    findRoom(10) > 0 &&
      returned.push(
        <p
          style={{ columnGap: 4, margin: 0, fontWeight: normal ? 500 : 600 }}
          className="flrow liner"
        >
          <HoteChambersIcon />
          {"Garage".replace(/\b\w/g, (l) => l.toUpperCase())}
          {findRoom(10) > 1 ? "s : " : " : "}
          {findRoom(10)}
        </p>
      );

    findRoom(11) > 0 &&
      returned.push(
        <p
          style={{ columnGap: 4, margin: 0, fontWeight: normal ? 500 : 600 }}
          className="flrow liner"
        >
          <HoteChambersIcon />
          {"Bureau".replace(/\b\w/g, (l) => l.toUpperCase())}
          {findRoom(11) > 1 ? "x : " : " : "}
          {findRoom(11)}
        </p>
      );

    findRoom(12) > 0 &&
      returned.push(
        <p
          style={{ columnGap: 4, margin: 0, fontWeight: normal ? 500 : 600 }}
          className="flrow liner"
        >
          <HoteChambersIcon />
          {"Salle de sport".replace(/\b\w/g, (l) => l.toUpperCase())}
          {findRoom(12) > 1 ? "s : " : " : "}
          {findRoom(12)}
        </p>
      );

    return returned.slice(0, max);
  };
  return (
    <>
      {residence ? (
        <div
          id="main-reservation-hero"
          className="hote-litle-info | flrow addgap16 liner"
        >
          <ContainerPersoModal
            isOpen={isOpen}
            toggle={toggle}
            title={"Liste des pièces"}
          >
            <RoomsModal
              allshow={showInfoResi(12, false)}
              residence={residence}
              t={t}
            />
          </ContainerPersoModal>
          <img
            src={
              residence.host.avatar
                ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_PROFIL_PICTURE}${residence.host.avatar}`
                : tempProfilPicture
            }
          />
          <div className="name-hote-info | flcolm">
            <span>{t("hoteTxt")}</span>
            <h2>{hostName}</h2>
            <div className="type-logement | flrow liner addgap8">
              <Home color="var(--primary-500)" strokeWidth={3} />
              <p>
                {residence.occupation.toLocaleLowerCase() ==
                "Partial".toLocaleLowerCase()
                  ? t("partialTxt")
                  : t("fullResidenceTxt")}
              </p>
            </div>
            <div
              style={{ flexWrap: "wrap" }}
              className="info-resi | flrow liner addgap8"
            >
              <p style={{ columnGap: 4, margin: 0 }} className="flrow liner">
                <HotePersonsIcon />
                {t("personneTxt")}
                {residence.maxPeople > 1 ? "s max : " : " max : "}
                {residence.maxPeople}
              </p>
              {showInfoResi(5)}
              {checkIfNeedShowMore && (
                <button
                  onClick={() => toggle(true)}
                  style={{
                    padding: 0,
                    color: "var(--primary-500)",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                  className="btn"
                >
                  Voir plus
                </button>
              )}

              {/* <p style={{ columnGap: 4, margin: 0 }} className="flrow liner">
                <HoteChambersIcon />
                {t("cuisineTxt")}
                {residence.cookieRooms > 2 ? "s : " : " : "}
                {residence.cookieRooms}
              </p> */}
            </div>
            {/* <p>
              • {residence.bed} {t("bedTxt")}
              {residence.bed > 2 ? "s" : ""} • {residence.bathRooms} sale
              {residence.bathRooms > 2 ? "s" : ""} de bain
              {residence.bathRooms > 2 ? "s" : ""} • {residence.cookieRooms}{" "}
              {t("cuisineTxt")}
              {residence.cookieRooms > 2 ? "s" : ""}
            </p> */}
          </div>
        </div>
      ) : (
        <div
          id="main-reservation-hero"
          className="hote-litle-info | flrow addgap16 liner"
        >
          <span
            style={{
              flex: "none",
              width: "95px",
              height: "95px",
              borderRadius: "50%",
            }}
            className="skeleton span-skeleton"
          ></span>
          <div className="name-hote-info | flcolm">
            <span
              style={{ width: "37px", height: "15px", marginBottom: 9 }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{ width: "30%", height: "30px", marginBottom: 14 }}
              className="skeleton "
            ></span>
            <span
              style={{ width: "55%", height: "15px", marginBottom: 9 }}
              className="skeleton span-skeleton"
            ></span>
          </div>
        </div>
      )}
    </>
  );
}
