import React from "react";
import { HoteChambersIcon } from "../../images/icons/JsxIcons";

export default function RoomsModal({ handleOutModal, allshow, residence, t }) {
  const findRoom = (roomId) => {
    let room = residence?.rooms.find((room) => room.roomId == roomId);
    if (room) return room.count;
    return 0;
  };
  //chambres, salon, douche, toilet, numberBathrooms, balcon, terrase, jardin, salleAManger, garage, bureau, salleSport

  return (
    <div className="comodities-modal | flcolm">
      <div
        style={{
          flexDirection: "row",
        }}
        className="contents | flrow"
      >
        {allshow}
      </div>
    </div>
  );
}
