import "../styles/pages_styles/listerResidenceFaq.css";
import Footer from "../components/biggest/Footer";
import HowWork from "../components/biggest/HowWork";
import ListerPopuResidence from "../components/biggest/ListerPopuResidence";

import { useStorePageManager } from "../store/PageManage";
import FaqPart from "../components/biggest/FaqPart";
import { smoothScrollUp } from "../services/utils";
import { useEffect } from "react";
import Header from "../components/biggest/Header";

export default function ListerResidenceFaq() {
  const handleUpdateFaqPage = useStorePageManager(
    (state) => state.handleUpdateFaqPage
  );
  handleUpdateFaqPage();
  useEffect(() => {
    let newTimeout = setTimeout(() => {
      smoothScrollUp(true);
    }, 100);
    return () => {
      clearTimeout(newTimeout);
    };
  }, []);
  return (
    <>
      {/* <Header /> */}
      <main>
        <ListerPopuResidence direct={true} />
        <HowWork />
        <FaqPart />
        <Footer />
      </main>
    </>
  );
}
