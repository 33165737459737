import { useDndIsReallyActiveId } from "./utils";

export function DragOverlayItem({
  id,
  currentPhotos,
  indexFirstSelected,
  hotePage = false,
}) {
  const photo = currentPhotos.find((photo) => photo.id === id);
  const index = currentPhotos.indexOf(photo);
  const isReallyActive = useDndIsReallyActiveId(id);
  let width = "100%";
  if (!hotePage) {
    if (indexFirstSelected === 0 && index !== 0 && !isReallyActive)
      width = "calc(50% - 20px)";
    if (indexFirstSelected !== 0 && index === 0)
      width = "calc(100% + 100% + 20px)";
  }
  return (
    <div
      style={{
        ...styles.cardStyles,
        height: "100%",
        padding: 0,
        transform: isReallyActive ? "scale(1.05)" : "none",
        transition: "width 0.15s",
        cursor: "grabbing",
        backgroundImage: `url(${photo.url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: 10,
        width,
        boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
      }}
      className="one-img rlve"
    ></div>
  );
}

const styles = {
  cardStyles: {
    background: "white",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
    borderRadius: 10,
    userSelect: "none",
    touchAction: "none",
  },
};
