import { useDndContext } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

export function useDndIsReallyActiveId(id) {
  const context = useDndContext();
  const isActive = context.active?.id === id;
  return isActive;
}
//Put the photo at the beginning of the list
export const handlePutCover = (index, currentPhotos, setCurrentPhotos) => {
  const updatedPhotos = [...currentPhotos];
  const movedPhoto = updatedPhotos.splice(index, 1)[0];
  updatedPhotos.unshift(movedPhoto);
  setCurrentPhotos(updatedPhotos);
};

//Delete the photo from the list
export const handleDeleteThisPick = (
  index,
  currentPhotos,
  setCurrentPhotos
) => {
  const updatedPhotos = [...currentPhotos];
  updatedPhotos.splice(index, 1);
  setCurrentPhotos(updatedPhotos);
};
//Start the drag
export function handleDragStart({
  active,
  setIndexFirstSelected,
  setActiveId,
  currentPhotos,
}) {
  const findIndex = currentPhotos.findIndex((photo) => photo.id === active.id);
  setIndexFirstSelected(findIndex);
  setActiveId(active.id);
  if (window.navigator.vibrate) {
    window.navigator.vibrate(100);
  }
}
//Move the photo to the new position in the list and update the list
export function handleDragOver({
  active,
  over,
  currentPhotos,
  setCurrentPhotos,
}) {
  const activeIndex = currentPhotos.findIndex(
    (photo) => photo.id === active.id
  );
  const overIndex = over
    ? currentPhotos.findIndex((photo) => photo.id === over.id)
    : currentPhotos.length - 1;
  const newPhotos = arrayMove(currentPhotos, activeIndex, overIndex);
  setCurrentPhotos(newPhotos);
}
//End the drag
export function handleDragEnd({ setActiveId }) {
  setActiveId(null);
}

export const DELAY_TIME_DND = 145;
export const TOLERANCE_TIME_DND = 10;
export const DISTANCE_DETECT_DND = 5;
