import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import illusFinish from "../../images/illustrations/listening-happy-music/cuate.png";
import "../../styles/components/addResidencePart/step1Annonce.css";
const currentStep = 18;
export default function StepEndFinish({ step, handleBlockBtn }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    if (currentStep == step) handleBlockBtn(false);
  }, [step]);
  const handleKeyDown = (e) => {
    if (e.key == "Enter" && currentStep == step) {
      navigate("/hote", { replace: true, state: { menu: 1 } });
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [step]);
  return (
    <section
      className={`step-1-annonce step-18 ${
        currentStep == step ? "visible" : ""
      } 
      ${currentStep < step ? "go-top" : ""} ${
        currentStep > step ? "go-bottom" : ""
      } add-resi add-resi-input | relve`}
    >
      <div className="contains |  max-container flrow liner">
        <div className="left-part-add-resi | flcolm jcenter">
          <div className="content | flcolm jcenter">
            {/* <span className="step">{t("step1Lbl")}</span> */}
            <label>{t("step18Title")}</label>
            <p>{t("step18SubTitle")}</p>
          </div>
        </div>
        <div className="right-part-add-resi | flcolm jcenter liner">
          <img src={illusFinish} alt="illustration" />
        </div>
      </div>
    </section>
  );
}
