import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export const UserPreferences = create(
  persist(
    (set, get) => ({
      devise: "Franc CFA",
      showDevise: "XOF",
      language: "fr-FR",

      handleChangeDevise: (payload) => {
        set(() => ({ devise: payload }));
      },
      handleChangeShowDevise: (payload) => {
        set(() => ({ showDevise: payload }));
      },
      handleChangeLanguage: (payload) => {
        set(() => ({ language: payload }));
      },
    }),
    {
      name: "userPreferences",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
