import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerPersoModal from "../biggest/ContainerPersoModal";
import useFetchData from "../../services/useFetchData";
import { requestEndpoint } from "../../data/requestEndpoint";
import { post, useGetDelayRequest } from "../../services/useFetchAction";
import { useStorePageManager } from "../../store/PageManage";
import { NotificationService } from "../../services/Notification.service";

export default function BottomCancelConditionsReservation({
  t,
  data,
  canAskReservation,
  handleSaveInfo,
  badRenseignement,
  handleJustReserveBilling,
  reservationCard,
  dataPaiement,
  setStateName,
  setStateTel,
  setStateMail,
  setStateAdress,
  setStateVille,
  setStateZip,
}) {
  const navigate = useNavigate();
  const [openConfirmReservation, setOpenConfirmReservation] = useState(false);
  const [saveBilling, setSaveBilling] = useState(false);
  const [newCondition, setNewConditionState] = useState([]);

  useEffect(() => {
    if (!data) return;
    const position1Value = {
      id: 1,
      value: Object.values(data?.residence?.refundGrid)[0],
    };
    const position2Value = {
      id: 2,
      value: Object.values(data?.residence?.refundGrid)[1],
    };
    const position3Value = {
      id: 3,
      value: Object.values(data?.residence?.refundGrid)[2],
    };
    const position4Value = {
      id: 4,
      value: Object.values(data?.residence?.refundGrid)[3],
    };
    const position5Value = {
      id: 5,
      value: Object.values(data?.residence?.refundGrid)[4],
    };
    let newConditionLoad = [
      position1Value,
      position2Value,
      position3Value,
      position4Value,
      position5Value,
    ];
    setNewConditionState(newConditionLoad);
  }, [data]);

  const handleCheckForReservation = () => {
    setOpenConfirmReservation(false);
    setSaveBilling(true);
  };
  const [newWindow, setNewWindow] = useState(null);
  const [urlPaid, setUrlPaid] = useState(null);
  const [openNewWindowPaid, setOpenNewWindow] = useState(false);

  const handleGoPay = async () => {
    if (urlPaid) {
      useStorePageManager.getState().handleUpdateBodyOverflow(false);
      useStorePageManager.getState().handleUpdateBlockScreen(false);
      setOpenNewWindow(true);
      return;
    }
    if (dataPaiement.currentPaiement == null) return;
    if (!reservationCard) return;
    // if (canAskReservation) return;
    try {
      useStorePageManager.getState().handleUpdateBlockScreen(true);
      const response = await post(requestEndpoint.payment, {
        contact: dataPaiement.tel,
        zip_code: dataPaiement.zip,
        countryISO2: dataPaiement.country.code.toUpperCase(),
        provinceISO: dataPaiement.province
          ? dataPaiement.province.iso.toUpperCase()
          : null,
        city: dataPaiement.ville,
        address: dataPaiement.adress,
        email: dataPaiement.mail,
        firstname: dataPaiement.name.split(" ")[0],
        lastname: dataPaiement.name.replace(
          dataPaiement.name.split(" ")[0] + " ",
          ""
        ),
        payment_method_id: dataPaiement.currentPaiement.id,
        booking_id: reservationCard.id,
      });
      // console.log(response);
      let newUrl = response.data.payment_url;
      setUrlPaid(newUrl);
      setOpenNewWindow(true);
      useStorePageManager.getState().handleUpdateBodyOverflow(false);
      useStorePageManager.getState().handleUpdateBlockScreen(false);
      // openNewWindow(newUrl);
    } catch (error) {
      console.log(error);
      useStorePageManager.getState().handleUpdateBodyOverflow(true);
      useStorePageManager.getState().handleUpdateBlockScreen(false);
      NotificationService.addToQueue(
        2,
        "Erreur lors de la tentative de paiement"
      );
      NotificationService.addToQueue(
        1,
        "Un paiement est déjà en cours, vous pourrez réessayer dans quelques instants"
      );
    }
  };
  const openNewWindow = (url) => {
    // let nW = window.open(url, "_blank");
    // window.location.href = url;
    let nW = window.open(url, "_blank");
    if (!nW) {
      NotificationService.addToQueue(
        1,
        "La fenêtre de paiement a été bloquée. Veuillez autoriser les fenêtres contextuelles pour ce site."
      );
      return;
    }
    clearTimeout(timeoutCount);
    setTimeout(() => {
      NotificationService.addToQueue(
        1,
        "Veillez patienter pendant que nous vérifions votre paiement"
      );
      useStorePageManager.getState().handleUpdateBlockScreen(false);
      useStorePageManager.getState().handleUpdateBodyOverflow(true);
      navigate("/profilereservation");
    }, 15000);
    if (nW) {
      nW.addEventListener("beforeunload", handleWindowClose);
    }
    setNewWindow(nW);
  };

  //fait un countdown de 5 minutes
  const [timeoutCount, setTimeoutCount] = useState();
  useEffect(() => {
    if (!urlPaid) {
      if (timeoutCount) clearTimeout(timeoutCount);
      return;
    }
    // console.log(urlPaid);
    let newTimeout = setTimeout(() => {
      setUrlPaid(null);
      setOpenNewWindow(false);
      NotificationService.addToQueue(
        1,
        "Le lien de paiement à expiré. Veuillez réessayer."
      );
    }, 300100);
    setTimeoutCount(newTimeout);
    return () => {
      clearTimeout(timeoutCount);
    };
  }, [urlPaid]);
  const handleGoOnReservationPanel = () => {
    clearTimeout(timeoutCount);
    useStorePageManager.getState().handleUpdateBlockScreen(true);
    setTimeout(() => {
      NotificationService.addToQueue(
        1,
        "Lorsque vous aurez terminé votre paiement, la page se rechargera automatiquement."
      );
      useStorePageManager.getState().handleUpdateBlockScreen(false);
      useStorePageManager.getState().handleUpdateBodyOverflow(true);
      navigate("/profilereservation");
    }, 5000);
  };
  const handleWindowClose = () => {
    useStorePageManager.getState().handleUpdateBlockScreen(false);
    useStorePageManager.getState().handleUpdateBodyOverflow(true);
    NotificationService.addToQueue(
      1,
      "Veillez patienter pendant que nous vérifions votre paiement"
    );
    NotificationService.addToQueue(
      1,
      "Vous recevrez un mail de confirmation une fois votre paiement validé"
    );
    navigate("/profilereservation");
  };
  const handleJustReserve = () => {
    setOpenConfirmReservation(false);
    setSaveBilling(false);
    handleJustReserveBilling();
    // navigate("/reservation-paiement");
  };
  const handleReserveSave = () => {
    handleSaveInfo();
    // setOpenConfirmReservation(false);
    // setSaveBilling(false);
    // navigate("/reservation-paiement");
  };
  const handleScrollSmoothToId = (id) => {
    let element = document.getElementById(id);
    if (!element) return;
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  const handleCheckInfoForCanBooking = () => {
    let currentRenseignement = badRenseignement;
    //mettre le isError a true pour chaque input
    if (!currentRenseignement.name) {
      NotificationService.addToQueue(
        1,
        "Veuillez renseigner votre nom complet"
      );
      setStateName((prev) => ({ ...prev, error: true }));
      handleScrollSmoothToId("name-billing");
      return;
    }
    if (!currentRenseignement.tel) {
      NotificationService.addToQueue(
        1,
        "Veuillez renseigner votre numéro de téléphone"
      );
      setStateTel((prev) => ({ ...prev, error: true }));
      handleScrollSmoothToId("tel-billing");
      return;
    }
    if (!currentRenseignement.mail) {
      NotificationService.addToQueue(
        1,
        "Veuillez renseigner votre adresse mail"
      );
      setStateMail((prev) => ({ ...prev, error: true }));
      handleScrollSmoothToId("email-billing");
      return;
    }
    if (!currentRenseignement.adress) {
      NotificationService.addToQueue(1, "Veuillez renseigner votre adresse");
      setStateAdress((prev) => ({ ...prev, error: true }));
      handleScrollSmoothToId("adress-billing");
      return;
    }
    if (!currentRenseignement.ville) {
      NotificationService.addToQueue(1, "Veuillez renseigner votre ville");
      setStateVille((prev) => ({ ...prev, error: true }));
      handleScrollSmoothToId("ville-billing");
      return;
    }
    if (!currentRenseignement.zip) {
      NotificationService.addToQueue(
        1,
        "Veuillez renseigner votre code postal"
      );
      setStateZip((prev) => ({ ...prev, error: true }));
      handleScrollSmoothToId("zip-billing");
      return;
    }
    if (!currentRenseignement.country) {
      NotificationService.addToQueue(1, "Veuillez renseigner votre pays");
      handleScrollSmoothToId("country-billing");
      return;
    }

    if (!currentRenseignement.currentPaiement && reservationCard) {
      NotificationService.addToQueue(
        1,
        "Veuillez choisir un moyen de paiement"
      );
      handleScrollSmoothToId("paiement-billing");
      return;
    }

    if (
      currentRenseignement.country &&
      !canAskReservation &&
      !currentRenseignement.province
    ) {
      NotificationService.addToQueue(1, "Veuillez renseigner votre province");
      handleScrollSmoothToId("province-billing");
      return;
    }
    setOpenConfirmReservation(true);
  };
  return (
    <>
      <div className="cancel-condition cancel-condition-residence | flcolm">
        <label>{t("cancelConditionTxt")}</label>
        <ul className="list-condition | flcolm addgap24">
          <li className="flcolm liner addgap8">
            <p>Annulation moins de 48 heures avant le début du séjour : </p>
            <span>
              {newCondition[0]?.value == 0
                ? t("notRemboursement")
                : `${t("amountToGiveTxt")}${" "}${newCondition[0]?.value}% ${t(
                    "totalMontantTxt"
                  )}`}
            </span>
          </li>
          <li className="flcolm liner addgap8">
            <p>Annulation entre 48h et 1 semaine avant le début du séjour : </p>
            <span>
              {newCondition[1]?.value == 0
                ? t("notRemboursement")
                : `${t("amountToGiveTxt")}${" "}${newCondition[1]?.value}% ${t(
                    "totalMontantTxt"
                  )}`}
            </span>
          </li>
          <li className="flcolm liner addgap8">
            <p>
              Annulation entre 1 semaine et 1 mois avant le début du séjour :{" "}
            </p>
            <span>
              {newCondition[2]?.value == 0
                ? t("notRemboursement")
                : `${t("amountToGiveTxt")}${" "}${newCondition[2]?.value}% ${t(
                    "totalMontantTxt"
                  )}`}
            </span>
          </li>
          <li className="flcolm liner addgap8">
            <p>Annulation entre 1 mois et 3 mois avant le début du séjour : </p>
            <span>
              {newCondition[3]?.value == 0
                ? t("notRemboursement")
                : `${t("amountToGiveTxt")}${" "}${newCondition[3]?.value}% ${t(
                    "totalMontantTxt"
                  )}`}
            </span>
          </li>
          <li className="flcolm liner addgap8">
            <p>Annulation plus de 3 mois avant le début du séjour : </p>
            <span>
              {newCondition[4]?.value == 0
                ? t("notRemboursement")
                : `${t("amountToGiveTxt")}${" "}${newCondition[4]?.value}% ${t(
                    "totalMontantTxt"
                  )}`}
            </span>
          </li>
        </ul>
      </div>
      <div className="btn-infos | flcolm">
        <p>
          {t("infoReglementTxt1")} <a href="#">{t("infoReglementTxt2")}</a>,{" "}
          <a href="/politique" target="_blank">
            {t("infoReglementTxt3")}
          </a>{" "}
          {t("infoReglementTxt4")} <a href="#">{t("infoReglementTxt5")}</a>{" "}
          {t("infoReglementTxt6")}
        </p>

        {reservationCard ? (
          <button
            onClick={() => {
              handleGoPay();
            }}
            disabled={!canAskReservation || openNewWindowPaid}
            className="btn pry-btn"
          >
            Procéder au paiement
          </button>
        ) : (
          <button
            onClick={() => handleCheckInfoForCanBooking()}
            // disabled={!canAskReservation}
            className="btn pry-btn"
          >
            {t("btnAskReservationTxt")}
          </button>
        )}
      </div>
      <ContainerPersoModal
        isOpen={openConfirmReservation}
        toggle={setOpenConfirmReservation}
        title={t("confirmReservationTxt")}
        baseTop={true}
        className="request-conf"
      >
        <div className="modal-request-conf | flcolm">
          <p style={{ marginBottom: 16 }} className="p-just -c">
            {t("confirmInfoTxt")}
          </p>
          <button
            style={{ borderRadius: 6 }}
            onClick={handleCheckForReservation}
            className="btn pry-btn"
          >
            <span>{t("continueText")}</span>
          </button>
        </div>
      </ContainerPersoModal>
      <ContainerPersoModal
        isOpen={saveBilling}
        toggle={setSaveBilling}
        title={t("saveBillingTxt")}
        baseTop={true}
        className="request-conf"
      >
        <div className="modal-request-conf save-billing | flcolm">
          <p style={{ marginTop: 15, marginBottom: 16 }} className="p-just -c">
            {t("confirmSaveBillingTxt")}
          </p>
          <button
            style={{ borderRadius: 6, marginBottom: 6 }}
            onClick={handleReserveSave}
            className="btn pry-btn"
          >
            <span
              style={{
                fontWeight: 600,
              }}
            >
              {t("saveAndReserveBillingTxt")}
            </span>
          </button>
          <button onClick={handleJustReserve} className="btn pryy-btn">
            <span>{t("justReserveTxt")}</span>
          </button>
        </div>
      </ContainerPersoModal>
      <ContainerPersoModal
        isOpen={openNewWindowPaid}
        toggle={() => ""}
        title="Lien sécurisé de paiement"
        fullBlock={true}
        baseTop={true}
        className="paid-window"
      >
        <div className="modal-paid flcolm addgap16">
          <div className="flcolm addgap8">
            <p className="p-just">
              Votre lien de paiement sécurisé est prêt. Veuillez cliquer sur le
              button ci-dessous pour être redirigé vers la page de paiement.
            </p>
            <p>Le lien de paiement est ouvert pendant 5 minutes.</p>
          </div>
          <a
            onClick={handleGoOnReservationPanel}
            href={urlPaid}
            target="_blank"
          >
            Continuer vers le paiement
          </a>
        </div>
      </ContainerPersoModal>
    </>
  );
}
