import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { requestEndpoint } from "../../data/requestEndpoint";
import {
  ChevronRightSimple,
  Heart,
  PhotoIcon,
  ShareIcon,
} from "../../images/icons/JsxIcons";
import crossIcon from "../../images/icons/x-close.svg";
import { NotificationService } from "../../services/Notification.service";
import useCurrentWidth from "../../services/useCurrentWidth";
import { get, post, remove } from "../../services/useFetchAction";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreUserManager } from "../../store/UserManage";
import "../../styles/components/headerResidence.css";
import GalerieResidence from "../cells/GalerieResidence";
import HeroTitleResidence from "../cells/HeroTitleResidence";
import ShareModal from "../cells/ShareModal";
import ContainerPersoModal from "./ContainerPersoModal";

export default function HeaderResidence({ residence = null, loading, error }) {
  const { t } = useTranslation();

  const [img1, setImg1] = useState(false);
  const [img2, setImg2] = useState(false);
  const [img3, setImg3] = useState(false);

  const [allGoodImg, setAllGoodImg] = useState(false);
  const { windowWidth } = useCurrentWidth();

  const [isFav, setIsFav] = useState(false);
  const [baseFav, setBaseFav] = useState(false);
  const cancelToken = useRef(null);
  const [loadingFav, setLoadingFav] = useState(false);

  const abortController = new AbortController();
  const userData = useStoreUserManager((state) => state.user);
  const favorisUser = useStoreUserManager((state) => state.favorites);
  const handleUpdateFavorisUser = useStoreUserManager(
    (state) => state.handleUpdateFavorisUser
  );
  useEffect(() => {
    if (!residence) return;
    try {
      get(requestEndpoint.newTraficResidence + residence.id);
    } catch (error) {
      console.log(error);
    }
  }, [residence]);

  useEffect(() => {
    if (!residence) return;
    if (loadingFav) return;
    let find = false;

    for (let index = 0; index < favorisUser.length; index++) {
      const element = favorisUser[index];
      if (element == residence.id) {
        find = true;
        setIsFav(true);
        setBaseFav(true);
        break;
      }
    }
    if (find == false) {
      setIsFav(false);
      setBaseFav(false);
    }
    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel(
          "Requête annulée car le composant est démonté."
        );
      }
    };
  }, [favorisUser, loadingFav, residence]);

  const handleFavoris = async (e) => {
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }
    // e.stopPropagation();
    document.body.focus();
    setLoadingFav(true);
    if (userData?.connected == false) {
      useStorePageManager.getState().handleUpdateConnexionPanelState(true);
      NotificationService.addToQueue(1, t("youNeedConnect1Txt"));
      return;
    }
    let favState = !isFav;
    setIsFav(favState);

    if (cancelToken.current) {
      cancelToken.current.cancel(
        "Requête annulée car une nouvelle a été déclenchée."
      );
    }

    cancelToken.current = axios.CancelToken.source();
    let response = null;
    try {
      if (favState) {
        response = await post(
          requestEndpoint.favoritesAdd,
          {
            residenceId: residence.id,
          },
          {},
          {
            cancelToken: cancelToken.current.token,
            signal: abortController.signal,
          }
        );
        // console.log(response);
        NotificationService.addToQueue(0, t("addFavorisDone"));
        setIsFav(true);
        setBaseFav(true);
      } else {
        const url =
          `${requestEndpoint.favoritesDelete}?residenceId=` + residence.id;
        response = await remove(
          url,
          {},
          {},
          { cancelToken: cancelToken.current }
        );
        // console.log(response);

        setIsFav(false);
        setBaseFav(false);
      }
      let newFav = [];
      if (favState) {
        favorisUser.map((fav) => {
          newFav.push(fav);
        });
        newFav.push(residence.id);
      } else {
        favorisUser.map((fav) => {
          if (fav != residence.id) newFav.push(fav);
        });
      }
      handleUpdateFavorisUser(newFav);
      setLoadingFav(false);
    } catch (error) {
      // console.log(error?.response?.status);
      let status = error?.response?.status;
      if (status == 401)
        NotificationService.addToQueue(1, t("errorSendFavorite"));
      if (axios.isCancel(error)) return;
      setIsFav(baseFav);
    }
  };

  useEffect(() => {
    if (loading || !residence) return;
    if (allGoodImg) return;
    if (img1 && img2 && img3) {
      setAllGoodImg(true);
      return;
    }

    // const newTimeout = setTimeout(() => {
    //   setImg1(true);
    //   setImg2(true);
    //   setImg3(true);
    // }, 10);

    // return () => clearTimeout(newTimeout);
  }, [residence, loading, img1, img2, img3, allGoodImg]);

  const [galerieOpen, setGalerieOpen] = useState(false);

  const [openShareModal, setOpenShareModal] = useState(false);

  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [allImgsJustForShow, setAllImgsJusteOne] = useState(null);

  useEffect(() => {
    if (!residence) return;
    if (residence.medias.length == 0) return;
    let newAllImages = [];
    residence.medias.map((photo, index) => {
      let newPhoto = {
        src: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${photo.filename}`,
        alt: "image-" + residence.name + "-" + index + 1,
      };
      newAllImages.push(newPhoto);
    });
    setAllImgsJusteOne(newAllImages);
  }, [residence]);

  const handleNextImage = () => {
    if (currentImageIndex == allImgsJustForShow.length - 1) return;
    setCurrentIndex(currentImageIndex + 1);
  };
  const handlePrevImage = () => {
    if (currentImageIndex == 0) return;
    setCurrentIndex(currentImageIndex - 1);
  };
  const handleSelectThisImage = (index) => {
    // useStorePageManager.getState().handleUpdateDesactiveHeader(true);
    setCurrentIndex(index);
    setIsOpen(true);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isOpen) {
        switch (event.key) {
          case "ArrowLeft":
            handlePrevImage();
            break;
          case "ArrowRight":
            handleNextImage();
            break;
          case "Escape":
            // useStorePageManager.getState().handleUpdateDesactiveHeader(false);
            setIsOpen(false);
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, handlePrevImage, handleNextImage]);

  return (
    <>
      {galerieOpen && (
        <GalerieResidence
          isFav={isFav}
          handleFavoris={handleFavoris}
          residence={residence}
          handleOpenGalerie={setGalerieOpen}
        />
      )}
      <ContainerPersoModal
        bottomChildren={true}
        baseTop={true}
        title={"Partager"}
        isOpen={openShareModal}
        toggle={setOpenShareModal}
      >
        <ShareModal data={residence} />
      </ContainerPersoModal>
      <section
        style={{
          marginTop: 10,
        }}
        className="residence-hero-section | flcolm max-container rlve"
      >
        <div className="contains-hero-residence | max-width rlve">
          {windowWidth <= 550 && residence && (
            <div className="actions-btn | flrow liner addgap16">
              <button onClick={() => setOpenShareModal(true)} className="btn">
                <ShareIcon />
              </button>
              <button onClick={() => handleFavoris()} className="btn">
                <Heart
                  color={isFav ? "var(--destructive-500)" : "transparent"}
                  stroke={isFav ? "var(--destructive-500)" : "#1E1A21"}
                />
              </button>
            </div>
          )}
          {!loading && residence ? (
            <>
              <img
                onClick={() => handleSelectThisImage(0)}
                className={`img${0} ${
                  img1 == true || allGoodImg ? "loaded-img" : "not-loaded"
                }`}
                src={
                  residence.medias
                    ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${residence.medias[0]?.filename}`
                    : residence.thumb
                }
                alt="img-residence"
                onLoad={() => setImg1(true)}
                style={{
                  display: img1 == true ? "inline" : "none",
                }}
              />
              {allGoodImg == false && !img1 && (
                <div className={`skeleton skeleton-img img${0} img-skel`}></div>
              )}
              {residence.medias.slice(1, 3).map((photo, index) => (
                <React.Fragment key={residence.slug + index + 1}>
                  <img
                    onClick={() => handleSelectThisImage(index + 1)}
                    className={`img${index + 1} ${
                      (index == 0 && img2 == true) ||
                      (index == 1 && img3 == true) ||
                      allGoodImg ||
                      allGoodImg
                        ? "loaded-img"
                        : "not-loaded"
                    }`}
                    src={`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_RESIDENCE_MEDIA}${photo.filename}`}
                    alt="img-residence"
                    onLoad={() => (index == 0 ? setImg2(true) : setImg3(true))}
                    style={{
                      display:
                        (index == 0 && img2 == true) ||
                        (index == 1 && img3 == true)
                          ? "inline"
                          : "none",
                    }}
                  />

                  {allGoodImg == false &&
                    !(
                      (index == 0 && img2 == true) ||
                      (index == 1 && img3 == true)
                    ) && (
                      <div
                        className={`skeleton skeleton-img img${
                          index + 1
                        } img-skel`}
                      ></div>
                    )}
                </React.Fragment>
              ))}
              {residence.medias.length > 3 && (
                <button
                  onClick={() => setGalerieOpen(true)}
                  className="open-galerie-btn | btn"
                >
                  <PhotoIcon />
                  <span>
                    {windowWidth > 600 ? (
                      <>
                        +{residence.medias.length - 3} {t("pictureName") + "s"}
                      </>
                    ) : (
                      <>
                        +{residence.medias.length - 1} {t("pictureName") + "s"}
                      </>
                    )}
                  </span>
                </button>
              )}
            </>
          ) : (
            Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className={`skeleton skeleton-img img${index}`}
              ></div>
            ))
          )}
        </div>
        <HeroTitleResidence
          isFav={isFav}
          handleFavoris={handleFavoris}
          residence={residence}
          loading={loading}
        />
      </section>
      {allImgsJustForShow && isOpen && (
        <>
          <div className="contains-photos-zoom">
            <div className="background-photo"></div>
            {allImgsJustForShow.map((photo, index) => (
              <div
                className={`transform-photos ${
                  currentImageIndex < index
                    ? "next"
                    : currentImageIndex > index
                    ? "prev"
                    : ""
                }`}
                key={photo.alt + index}
              >
                <TransformWrapper initialScale={1}>
                  <TransformComponent>
                    <img src={photo.src} alt={photo.alt} />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            ))}

            <button
              onClick={() => {
                // useStorePageManager.getState().handleUpdateDesactiveHeader(false);
                setIsOpen(false);
              }}
              className="btn opt-btn close-btn"
            >
              <img src={crossIcon} alt="icon-residence" />
            </button>
            <div className="flrow addgap16 liner jcenter info-panel">
              <button
                onClick={handlePrevImage}
                disabled={currentImageIndex == 0}
                className="btn pry-btn move-back"
              >
                <ChevronRightSimple
                  color="var(--shade-white)"
                  stroke="var(--shade-white)"
                />
                <span style={{ fontWeight: 600 }}>Precedent</span>
              </button>
              <div className="flcolm jcenter liner">
                <span>Photos</span>
                <p className="-c">
                  {currentImageIndex + 1} / {allImgsJustForShow.length}
                </p>
              </div>
              <button
                onClick={handleNextImage}
                disabled={currentImageIndex == allImgsJustForShow.length - 1}
                className="btn pry-btn move-next"
              >
                <span style={{ fontWeight: 600 }}>Suivant</span>{" "}
                <ChevronRightSimple
                  color="var(--shade-white)"
                  stroke="var(--shade-white)"
                />
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
