import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { ChevronDownSimple } from "../../images/icons/JsxIcons";

const FaqItem = ({ title, children }) => {
  const [isOpen, toggle] = useState(false);

  const animation = useSpring({
    height: isOpen ? "auto" : 0,
    opacity: isOpen ? 1 : 0,
    overflow: "hidden",
  });
  // console.log(children);
  return (
    <div className="faq-item" onClick={() => toggle(!isOpen)}>
      <div className="faq-title | flrow btw liner">
        <h2>{title}</h2>
        <ChevronDownSimple classname={`arrow ${isOpen ? "open" : ""}`} />
      </div>
      <animated.div style={animation}>{isOpen && children}</animated.div>
    </div>
  );
};

export default FaqItem;
