export const requestEndpoint = {
  login: "/users/login",
  activeAccount: "/users/account_activation",
  getTypeResidence: "/residences/residence-types-list",
  newsletters: "/users/send_newsletter",
  favoris: "/favorites",
  getResidence: "/residences",
  getStore: "/residences/store",
  getResidenceCommentary: "/residences/comments/",
  sendCommentHomePage: "/users/contact",
  notifications: "/users/notification",
  readNotification: "/users/notification/read",
  deleteNotification: "/users/notifications",
  mostBooked: "/residences/most_booked",
  favoritesAdd: "/favorites/add",
  residenceAdd: "/residences",
  residenceUpdate: "/residences/update",
  favoritesDelete: "/favorites/delete-favorites",
  updateProfilPicture: "/users/update_avatar",
  updateProfilData: "/users/update_profile",
  updatePassword: "/users/update-password",
  uploadPiece: "/users/add_doc",
  booking: "/bookings",
  payment: "/payment",
  getReservation: "/reservationsHote",
  getReservationUser: "/users/booking",
  getLocationResidence: "/residences/map_location",
  cancelBooking: "users/cancel_booking",
  remboursementBooking: "users/refund_booking",
  getCountries: "/countries",
  socialGFLogin: "/users/login_social",
  residenceWaiting: "/residences/waiting_add",
  getHoteResidence: "/users/residences/host",
  deleteResidence: "/residences/delete/",
  confirmReservation: "/users/confirm_booking",
  rejectReservation: "/users/rejeted_booking",
  payment_methods: "/payment_methods",
  googleSearchLocation:
    "https://maps.googleapis.com/maps/api/geocode/json?address=",
  getHoteReservation: "/users/booking/host",
  codeCheck: "/users/confirmation_code",
  sendComentary: "/residences/comments",
  getAllComments: "/users/comments/host",
  getNotes: "/users/rating",
  getTraffic: "/users/statistics",
  getGains: "/users/earnings",
  sendUserComment: "/users/create_comment",
  signalementResidence: "/residences/signale",
  getSuggestions: "/residences/adress_suggestions",
  getCancelStatisticsHote: "/users/booking/tx_cancel",
  cancelRefund: "/users/cancel_refund/",
  getRefund: "/users/refund_requests",
  residencePriceRange: "/residences/price_range",
  verifyOtp: "/users/verify_code_otp",
  reverseGeo: "/residences/reverse_geocoding",
  refundPreview: "/users/residences/refund_preview",
  newTraficResidence: "/residences/visit/",
  resendEmailConfirmation: "/users/resend_confirmation_email",
  confirContact: "/users/confirmation_contact",
  payment_methods_versement: "/payment_methods/versements",
  sendIndispobility: "/bookings/external",

  getAllDiscounts: "/residences/discounts/",
  updateDiscount: "/residences/update_nbdays_discount/",
  sendSpecificDiscount: "/residences/specific_discount",
  updateSpecificDiscount: "/residences/specific_discount/",
  deleteSpecificDiscount: "/residences/specific_discount/",
  previewBooking: "/bookings/preview_price",

  addHoteVersement: "/users/host/add_versement_method",
  getHoteVersement: "/users/host/versement_methods/",
  updateHoteVersement: "/users/host/update_versement_method",
  deleteHoteVersement: "/users/host/versement_method/",
  postVersementConfirm: "/users/confirm_booking_versement",
  imgDownload: "/residences/download/",

  uploadImage: "/residences/upload-single-image",
  newGetStore: "/residences/storeV2",
  modifyPiece: "/users/add_doc",

  getResidenceByMap: "/residences/map",
};
