import { addDays } from "date-fns";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
export const useStoreUserManager = create(
  persist(
    (set, get) => ({
      user: {
        id: 0,
        profilImgUrl: null,
        identityDoc: null,
        name: "",
        lastname: "",
        firstname: "",
        mail: "",
        profil: "",
        location: "",
        birthday: "",
        contact: "",
        enableHost: false,
        mailChecked: false,
        contactChecked: false,
        emailVerified: false,
        pieceChecked: 0,
        isSocial: false,
        typeSocial: "",
        connected: false,
        payment_method_id: 0,
      },
      favorites: [],
      notifications: [],
      handleUpdateUser: (payload) => {
        set((state) => ({
          user: { ...state.user, ...payload },
        }));
      },
      handleUpdateFavorisUser: (payload) => {
        set(() => ({
          favorites: payload,
        }));
      },
      handleUpdateNotificationsUser: (payload) => {
        set(() => ({
          notifications: payload,
        }));
      },
      handleDisconnectUser: () => {
        set((state) => ({
          user: {
            id: 0,
            profilImgUrl: null,
            identityDoc: null,
            name: "",
            lastname: "",
            firstname: "",
            mail: "",
            profil: "",
            location: "",
            birthday: "",
            contact: "",
            enableHost: false,
            mailChecked: false,
            contactChecked: false,
            emailVerified: false,
            pieceChecked: 0,
            connected: false,
            isSocial: false,
            typeSocial: "",
            payment_method_id: 0,
          },
        }));
        localStorage.removeItem("userInformation");
        sessionStorage.removeItem("userInformation");
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        localStorage.removeItem("user");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("userData");
        sessionStorage.removeItem("user");
        if (sessionStorage.getItem("temporaryVersement")) {
          sessionStorage.removeItem("temporaryVersement");
        }
      },
    }),
    {
      name: "userInformation",
      storage: createJSONStorage(() => sessionStorage),
      expires: addDays(new Date(), 3),
    }
  )
);
