import React, { useCallback, useEffect, useRef, useState } from "react";
import { useStoreManager } from "../../store/StoreManage";
import "./multiRangeSlider.css";

const MultiRangeSlider = ({ baseV, min, max, onChange }) => {
  const [minVal, setMinVal] = useState(baseV ? baseV[0] : min);
  const [maxVal, setMaxVal] = useState(baseV ? baseV[1] : max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef(null);

  const refreshRange = () => {
    setMinVal(min);
    setMaxVal(max);
  };
  const updateRange = (range) => {
    setMinVal(range[0]);
    setMaxVal(range[1]);

    const minPercent = getPercent(range[0]);
    const maxPercent = getPercent(range[1]);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
    const value = Math.min(Number(range[0]), Number(range[1]) - 1);
    setMinVal(value);
    minValRef.current = value;
    const value2 = Math.max(Number(range[1]), Number(range[0]) + 1);
    setMaxVal(value2);
    maxValRef.current = value2;
  };
  const handleUpdateRefreshRange = useStoreManager(
    (state) => state.handleUpdateRefreshRange
  );
  const handleUpdateRange = useStoreManager((state) => state.handleUpdateRange);
  useEffect(() => {
    // onChange({ min: baseV[0], max: baseV[1] });
    // setMinVal(baseV[0]);
    // setMaxVal(baseV[1]);
    handleUpdateRange(updateRange);
    handleUpdateRefreshRange(refreshRange);
  }, [baseV]);
  // // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max, baseV, onChange]
  );

  // // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent, maxVal]);

  // // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent, minVal]);

  // // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal]);

  const [currentShow, setCurrentShow] = useState(<></>);
  useEffect(() => {
    setCurrentShow(
      <>
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          onChange={(event) => {
            const value = Math.min(Number(event.target.value), maxVal - 1);
            setMinVal(value);
            minValRef.current = value;
          }}
          className="thumb thumb--left"
          style={{ zIndex: minVal > max - 100 && "5" }}
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          onChange={(event) => {
            const value = Math.max(Number(event.target.value), minVal + 1);
            setMaxVal(value);
            maxValRef.current = value;
          }}
          className="thumb thumb--right"
        />
      </>
    );
  }, [minVal, maxVal, baseV]);

  return (
    <div className="container">
      {currentShow}

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value">{minVal}</div>
        <div className="slider__right-value">{maxVal}</div>
      </div>
    </div>
  );
};

export default MultiRangeSlider;
