import React, { useRef, useState } from "react";
import "../../styles/components/footer.css";

import tcLogo from "../../images/logos/logo-tc.svg";

import sendIcon from "../../images/icons/send-01.svg";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { requestEndpoint } from "../../data/requestEndpoint";
import { WhatsappIcon } from "../../images/icons/JsxIcons";
import fbIcon from "../../images/icons/social/facebook.svg";
import igIcon from "../../images/icons/social/instagram.svg";
import ttIcon from "../../images/icons/social/tiktok.svg";
import { NotificationService } from "../../services/Notification.service";
import { CheckCompareRef } from "../../services/useCurrentScrollY";
import useCurrentWidth from "../../services/useCurrentWidth";
import { post } from "../../services/useFetchAction";
import { smoothScrollUp } from "../../services/utils";
import { useStorePageManager } from "../../store/PageManage";
import ContainerPersoModal from "./ContainerPersoModal";

export default function Footer({ extend = false }) {
  const refInputMail = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { windowWidth } = useCurrentWidth();
  const residencePage = useStorePageManager((state) => state.residencePage);
  // console.log("resipage", residencePage);
  const onSubmitNewsLetter = (e) => {
    e.preventDefault();
    setSendInLoading(true);
    post(requestEndpoint.newsletters, { email: refInputMail.current.value })
      .then((response) => {
        refInputMail.current.value = "";
        NotificationService.addToQueue(0, t("addNewsLetterDone"));
      })
      .catch((error) => {
        console.log(error);
        NotificationService.addToQueue(1, t("errorSendNewsLetter"));
      })
      .finally(() => {
        setSendInLoading(false);
      });
  };
  const [canSend, setCanSend] = useState(false);
  const [sendInLoading, setSendInLoading] = useState(false);
  const handleChangeInput = (e) => {
    const emailTestRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const value = e.target.value;
    if (emailTestRegex.test(value)) {
      setCanSend(true);
    } else {
      setCanSend(false);
    }
  };
  const { stateCompare, refFromTop1, refFromTop2 } = CheckCompareRef(
    "btn-wha",
    "footer"
  );
  const [openWhatsapp, setOpenWhatsapp] = useState(false);
  const handleWhatsappOpen = () => {
    setOpenWhatsapp(false);
    useStorePageManager.setState({ overflow: true });
    let textmessage = "Bonjour, pourrais je avoir de l’aide ?";
    window.open(
      `https://api.whatsapp.com/send?phone=+2250712943945&text=${textmessage}`
    );
  };
  const goToPage = (page) => {
    smoothScrollUp(true);
    navigate(page);
  };
  const overflow = useStorePageManager((state) => state.overflow);
  const whaBlock = useStorePageManager((state) => state.whaBlock);
  return (
    <>
      <ContainerPersoModal
        className="wha-modal"
        isOpen={openWhatsapp}
        toggle={setOpenWhatsapp}
        baseTop={true}
        title={t("contactAssistanceWithWhaTitleTxt")}
      >
        <div className="wha-content | flcolm">
          <p
            style={{
              fontSize: 16,
              fontWeight: 500,
              lineHeight: "24px",
            }}
            className="p-just -c"
          >
            {t("paragraphWhaTxt")}
          </p>
          <button onClick={handleWhatsappOpen} className="btn pry-btn">
            <WhatsappIcon />

            <span
              style={{
                fontWeight: 600,
              }}
            >
              {t("btnWhaTxt")}
            </span>
          </button>
        </div>
      </ContainerPersoModal>
      <button
        id="btn-wha"
        style={{ bottom: windowWidth <= 550 && residencePage ? 84 : 24 }}
        onClick={() => setOpenWhatsapp(true)}
        className={`btn-wha ${
          refFromTop1 > refFromTop2 || whaBlock ? "survol" : ""
        } ${!overflow ? "hide" : ""}`}
      >
        <WhatsappIcon />
      </button>
      <footer id="footer">
        <div
          className={`content | flcolm max-container ${
            extend ? "max-container-store" : ""
          }`}
        >
          <div className="tc-container | flrow liner btw">
            <div className="tc-side | flcolm">
              <button>
                <img src={tcLogo} alt="trouvechap-icon" />
              </button>
              <ul className="page-link | flrow addgap24">
                <li onClick={() => goToPage("/newresidenceinfo")}>
                  <Link to={""}>{t("faqTxt")}</Link>
                </li>
                <li onClick={() => goToPage("/politique")}>
                  <Link to={""}>{"Politique de confidentialité"}</Link>
                </li>
                <li onClick={() => goToPage("/conditions")}>
                  <Link to={""}>{"Termes et conditions"}</Link>
                </li>
                <li onClick={() => goToPage("/legales")}>
                  <Link to={""}>{"Mentions Légales"}</Link>
                </li>
                {/* <li onClick={() => goToPage("/addresidence")}>
                  <Link to={""}>
                    {t("listResiTxt")} <span> {t("listResiTxt2")}</span>
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="news-side | flcolm addgap24">
              <p>{t("recieveLastNewsTxt")}</p>
              <form
                onSubmit={onSubmitNewsLetter}
                className="flrow liner addgap8"
              >
                <input
                  ref={refInputMail}
                  type="email"
                  onChange={handleChangeInput}
                  placeholder={t("writeEmailPlaceholderTxt")}
                />
                <button
                  disabled={!(!sendInLoading && canSend)}
                  type="submit"
                  className="flrow liner addgap8 btn pry-btn"
                >
                  <span>{t("sendTxtNews")}</span>
                  <img src={sendIcon} alt="send-icon" />
                </button>
              </form>
            </div>
          </div>
          <div className="info-tc | flrow liner btw">
            <button>
              <img src={tcLogo} alt="trouvechap-icon" />
            </button>
            <span>© 2024 Trouvechap. {t("allRightReservedTxt")}.</span>
            <ul className="social | flrow addgap16">
              <li>
                <a
                  href="https://www.facebook.com/trouvechap/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={fbIcon} alt="fbIcon" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/trouvechap/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={igIcon} alt="igIcon" />
                </a>
              </li>

              <li>
                <a
                  href="https://www.tiktok.com/@trouvechap?_t=8ijjQAoZ0cA&_r=1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={ttIcon} alt="ttIcon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}
