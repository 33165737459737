import React from "react";
import "../../styles/components/cardResiSkeleton.css";
export default function CardResidenceSkeleton() {
  return (
    <button className="card-resi | rlve flcolm">
      <div className="top-card | rlve">
        <div
          style={{ width: "100%", height: "100%" }}
          className="skeleton"
        ></div>
        <span className="btn-fav">{/* <Heart /> */}</span>
      </div>
      <div style={{ rowGap: "16px" }} className="bot-val | flcolm addgap8">
        <span
          style={{ width: "55%", height: "12.5px" }}
          className="skeleton span-skeleton"
        ></span>
        <span
          style={{ width: "60%", height: "15.5px" }}
          className="skeleton span-skeleton"
        ></span>
        <div
          style={{ alignItems: "flex-start" }}
          className="price-note | flrow addgap16 liner"
        >
          <span
            style={{ width: "49%", height: "12.5px" }}
            className="skeleton span-skeleton"
          ></span>
          <hr />
          <div className="star-resi | flrow liner">
            <span
              style={{ width: "40%", height: "22px" }}
              className="skeleton span-skeleton"
            ></span>
            <span
              style={{ width: "15px", height: "15px" }}
              className="skeleton span-skeleton"
            ></span>
            {/* <Star /> */}
          </div>
        </div>
      </div>
    </button>
  );
}
