import React, { useState } from "react";
import "../../styles/components/politiqueContent.css";
import policy from "../../data/politique.json";
import { useCurrentScrollY } from "../../services/useCurrentScrollY";
export default function MentionLegalesContent() {
  const { checkingPosY } = useCurrentScrollY(100);
  return (
    <section
      style={{ paddingBottom: 30 }}
      className="policy-section | flcolm max-container rlve"
    >
      <div className="content | flcolm">
        {checkingPosY && (
          <div className="title second | max-container">
            <label>Mentions Legales</label>
          </div>
        )}
        <div className="title | flcolm">
          {/* <label>Politique de confidencialité</label> */}
          {/* <p>{policy.policyFirstInfo}</p> */}
        </div>
        {/* {policy.articles.map((article, index) => (
          <div className="article | flcolm">
            <label>Article {index + 1}</label>
            <p>{article.text}</p>
          </div>
        ))} */}
        <h1 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          MENTIONS LEGALES
        </h1>
        <h3>Éditeur du site web :</h3>
        <p>
          <a
            href="https://impactplus-digital.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Impact Plus Digital
          </a>
        </p>
        <h3>Siège social :</h3>
        <p>Abidjan</p>
        <p>Commune de Cocody</p>
        <p>Angré 8è Tranche (RCI) en face pharmacie 8e tranche - Rue L259</p>
        <p>Immeuble le Continental 3e étage porte B2</p>
        <p>Côte d’Ivoire</p>
        <h3>Numéro de TVA intracommunautaire : </h3>
        <p>DECLARATION FISCALE D’EXISTENCE (DFE) : 2305086 Z</p>
        <h3>Numéro d'immatriculation : </h3>
        <p>NUMÉRO D’IDENTIFICATION UNIQUE (IDU) : CI-2023-0064051 B</p>
        <p>
          REGISTRE DU COMMERCE ET DU CREDIT MOBILIER (RCCM) :
          CI-ABJ-03-2023-B12-06222
        </p>
        <h3>Capital social : </h3>
        <p>2.350.000 FCFA</p>
        <h3>Assemblée générale : </h3>
        <p>Neuville BOUO BELLA, Kevin KOFFI</p>
        <p>Nous contacter :</p>
        <h3>Adresse e-mail :</h3>
        <p>
          <a href="mailto:contact@trouvechap.com">contact@trouvechap.com</a>
        </p>
        <h3>Assistance trouvechap : </h3>
        <p>
          Trouvechap agit en qualité d’intermédiaire technique et n’intervient
          pas dans le choix des contenus mis en ligne par ses utilisateurs.
          Toutefois Trouvechap, s’engage à :
        </p>
        <p>
          vérifier l’authenticité des images des résidences postées par les
          hôtes.
        </p>
        <p>
          S’assurer du respect de type de contenus a poster sur la plateforme.{" "}
        </p>
        <p>
          Plus d’informations dans nos{" "}
          <a href="/conditions">Conditions de service</a>
        </p>
        <h3>Prestataire technique (hébergeur du site web) :</h3>
        <p>
          Trouvechap utilise les services cloud d’ Hostinger pour héberger son
          site web. Le siège social se situe 61 Lordou Vironos Street, 6023
          Larnaca, Chypre, joignable par le moyen suivant :
        </p>
        <a href="https://www.hostinger.fr/contact">
          https://www.hostinger.fr/contact
        </a>
        <p>
          <h3>
            Partie contractante relative aux services de paiement destinés aux
            utilisateurs dont le lieu de résidence se trouve dans la CEDEAO :
          </h3>
        </p>
        <p>Abidjan</p>
        <p>Commune de Cocody</p>
        <p>Angré 8è Tranche (RCI) en face pharmacie 8e tranche - Rue L259</p>
        <p>Immeuble le Continental 3e étage porte B2</p>
        <p>Côte d’Ivoire</p>
        <h3>Numéro de l'entreprise :</h3>
        <p>NUMÉRO D’IDENTIFICATION UNIQUE (IDU) : CI-2023-0064051 B</p>
        <p>
          REGISTRE DU COMMERCE ET DU CREDIT MOBILIER (RCCM) :
          CI-ABJ-03-2023-B12-06222
        </p>
        <h3>Assemblée générale :</h3>
        <p>Neuville BOUO BELLA, Kevin KOFFI</p>
        <p>
          Trouvechap est régie par les dispositions de l’Acte uniforme révisé de
          l’OHADA du 30 Janvier 2014 relatif au droit des Sociétés commerciales
          et du Groupement d’intérêt économique (GIE), ainsi que par toutes
          autres dispositions légales ou réglementaires applicables et ses
          présents statuts pour :
        </p>
        <ul>
          <li>
            La mise en relation numérique de propriétaires immobiliers et de
            locataires
          </li>
          <li>La gestion de résidence meublées ou non</li>
          <li>La création et la gestion de site web</li>
          <li>La publicité et la promotion de biens de toutes nature</li>
          <li>Et divers ;</li>
        </ul>
      </div>
    </section>
  );
}
