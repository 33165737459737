import React from "react";
import "../../styles/components/descriptionModal.css";
import { useTranslation } from "react-i18next";
import { UserPreferences } from "../../store/UserPreferences";
export default function DescriptionModal({ handleOutModal, data }) {
  const { t } = useTranslation();
  const language = UserPreferences((state) => state.language);
  return (
    <div className="description-modal | flcolm">
      <div className="title-modal flcolm addgap8">
        <label>{t("titleProposLogement")}</label>
      </div>
      <div style={{ rowGap: 6 }} className="contents | flcolm">
        {data?.description?.map((item, index) => {
          const capitalizedTitle =
            item.title.charAt(0).toUpperCase() + item.title.slice(1);
          const capitalizedText =
            item.text.charAt(0).toUpperCase() + item.text.slice(1);
          return (
            <div className="maxwidth flcolm" key={index}>
              <h6>
                {capitalizedTitle == "A PROPROS"
                  ? "A PROPOS"
                  : capitalizedTitle}
              </h6>
              <p>{capitalizedText}</p>
            </div>
          );
        })}
        {/* <p>{language == "fr-FR" ? data.description : data.descriptionEn}</p> */}
      </div>
    </div>
  );
}
