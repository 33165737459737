import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export const useStoreCurrencies = create(
  persist(
    (set, get) => ({
      currentCurrencyId: 1,
      sign: "₣",
      currencies: [],
      rules: [],
      socials: [],
      handleUpdateCurrentCurrencyId: (payload) => {
        set(() => ({ currentCurrencyId: payload }));
      },
      handleUpdateSign: (payload) => {
        set(() => ({ sign: payload }));
      },
      handleUpdateCurrencies: (payload) => {
        set(() => ({ currencies: payload }));
      },
      handleUpdateRules: (payload) => {
        set(() => ({ rules: payload }));
      },
      handleUpdateSocials: (payload) => {
        set(() => ({ socials: payload }));
      },
    }),
    {
      name: "currencies-storage",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        currentCurrencyId: state.currentCurrencyId,
        sign: state.sign,
      }),
    }
  )
);
