/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import globeIcon from "../../images/icons/globe-01.svg";
import menuIcon from "../../images/icons/menu-04.svg";
import logo_tc_2 from "../../images/logos/logo-tc-2.png";
import logo_tc from "../../images/logos/logo-tc.svg";
import tempProfilPicture from "../../images/temps/user-02.svg";
import "../../styles/components/header.css";

import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { requestEndpoint } from "../../data/requestEndpoint";
import { ClocheState } from "../../images/icons/JsxIcons";
import helpIcon from "../../images/icons/message-question-circle.svg";
import { NotificationService } from "../../services/Notification.service";
import { useCurrentScrollY } from "../../services/useCurrentScrollY";
import useCurrentWidth from "../../services/useCurrentWidth";
import { useGetDelayRequest } from "../../services/useFetchAction";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreManager } from "../../store/StoreManage";
import { useStoreUserManager } from "../../store/UserManage";
import ChoiceLanguageDeviseModal from "../cells/ChoiceLanguageDeviseModal";
import HeaderSearchStore from "../cells/HeaderSearchStore";
import ContainerPersoModal from "./ContainerPersoModal";
import HeaderHotePage from "./HeaderHotePage";

export default function Header() {
  const navigate = useNavigate();
  // const location = useLocation();
  const { t } = useTranslation();
  const handleUpdateConnexionPanelState = useStorePageManager(
    (state) => state.handleUpdateConnexionPanelState
  );

  const userData = useStoreUserManager((state) => state.user);
  const notifications = useStoreUserManager((state) => state.notifications);
  const storePage = useStorePageManager((state) => state.storePage);
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );
  const handleUpdateDesactiveHeader = useStorePageManager(
    (state) => state.handleUpdateDesactiveHeader
  );
  const desactiveHeader = useStorePageManager((state) => state.desactiveHeader);
  const faqPage = useStorePageManager((state) => state.faqPage);
  const hotePage = useStorePageManager((state) => state.hotePage);

  const handleUpdateUser = useStoreUserManager(
    (state) => state.handleUpdateUser
  );
  const handleUpdateNotificationsUser = useStoreUserManager(
    (state) => state.handleUpdateNotificationsUser
  );
  const handleDisconnectUser = useStoreUserManager(
    (state) => state.handleDisconnectUser
  );

  const [menuOpen, setMenuOpen] = useState(false);

  const { windowWidth } = useCurrentWidth();
  const { checkingPosY } = useCurrentScrollY(35);

  const handleDisconnect = () => {
    //si le pathname  est /profile ou /hote on le redirige vers la home
    if (
      window.location.pathname === "/profile" ||
      window.location.pathname === "/hote"
    ) {
      navigate("/");
    }
    handleDisconnectUser();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.reload();
  };

  const handleOpenMenu = () => {
    handleUpdateBodyOverflow(false);
    setMenuOpen(true);
  };
  const handleCloseMenu = () => {
    handleUpdateBodyOverflow(true);
    setMenuOpen(false);
  };

  const [openChoiceModal, toogleChoiceModal] = useState(false);

  const navigateToHomePage = () => {
    if (window.location.pathname === "/") {
      window.location.reload();
    } else {
      navigate("/");
    }
  };
  const handleGoToAddResidence = () => {
    if (window.location.pathname != "/newresidenceinfo") {
      navigate("/newresidenceinfo");
      return;
    }
    if (userData.connected == false) {
      handleUpdateConnexionPanelState(true);
      NotificationService.addToQueue(1, t("youNeedConnect"));
      return;
    }
    navigate("/addresidence");
  };

  //notification service
  const [dataNotifs, loadingNotif, errorNotif, fetchDataNotif] =
    useGetDelayRequest({
      route: requestEndpoint.notifications,
      delay: 100,
      log: false,
    });

  useEffect(() => {
    if (loadingNotif) return;
    if (!dataNotifs) return;
    let newNotifs = [];
    dataNotifs.data.map((notif) => {
      if (notif.seen == false) newNotifs.push(notif.id);
    });
    handleUpdateNotificationsUser(newNotifs);
  }, [dataNotifs]);
  const location = useLocation();
  useEffect(() => {
    if (userData.connected) {
      fetchDataNotif();
    }
  }, [location.pathname]);
  const handleUpdateStoreParameters = useStoreManager(
    (state) => state.handleUpdateStoreParameters
  );
  const handleGoToStorePage = useStorePageManager(
    (state) => state.handleUpdateGoToStorePage
  );
  const goToStorePage = useStorePageManager((state) => state.goToStorePage);
  const handleGoToStorePageFunction = async () => {
    useStorePageManager.getState().handleUdpateStoreMap(false);
    useStorePageManager.getState().handleUpdateLastResidenceTouched(null);
    useStorePageManager.getState().handleUpdateCurrentMapTouched(null);
    useStorePageManager.getState().handleUpdateCurrentMarkersList([]);
    await handleUpdateStoreParameters(
      {
        destination: "",
        arriveeDate: null,
        departDate: null,
        personnes: null,
        minPrice: 0,
        maxPrice: 90000000,
        chambre: null,
        salleDeBain: null,
        occupation: null,
        typeIds: [],
        commodites: [],
        roomIds: [],
        activitiesIds: [],
        page: 1,
        lng: null,
        lat: null,
        viewPort: null,
      },
      "ResidenceMoreVisit"
    );
    await useStoreManager.getState().handleUpdateSearchStoreParameters();
    navigate("/store");
  };
  const refreshStore = useStoreManager((state) => state.refreshStore);

  useEffect(() => {
    handleGoToStorePage(handleGoToStorePageFunction);
  }, []);

  return (
    <>
      <header
        className={`flrow liner ${
          desactiveHeader == true ? "desactive-header" : ""
        } ${checkingPosY ? " shadow-box-active" : ""} ${
          hotePage ? " shadow-box-active-hote" : ""
        } ${storePage ? " border-bot-force" : ""}`}
      >
        <div
          className={`hd-container | flrow liner btw max-container 
          ${hotePage ? " max-container-hote" : ""}
          ${storePage ? " max-container-store" : ""}`}
        >
          <button
            onClick={navigateToHomePage}
            className={`tc-btn ${hotePage ? "hote-p" : ""}`}
          >
            <img src={logo_tc} alt="logo-trouvechap" />
          </button>
          <button
            onClick={navigateToHomePage}
            className={`tc-btn-2 ${hotePage ? "hote-p" : ""}`}
          >
            <img src={logo_tc_2} alt="logo-trouvechap-2" />
          </button>
          {storePage && <HeaderSearchStore />}
          {hotePage && <HeaderHotePage />}
          <div
            style={{
              flex: storePage && windowWidth <= 1350 && 1,
              justifyContent: storePage && windowWidth <= 1350 && "flex-end",
            }}
            className="hd-right-btn | flrow liner chd-container addgap16 rlve"
          >
            <button
              onClick={() => toogleChoiceModal(true)}
              className="devise-language | btn"
            >
              <img src={globeIcon} alt="globe-icon" />
            </button>
            {userData.connected ? (
              <>
                {hotePage ? (
                  <Link
                    style={{ marginLeft: 10 }}
                    to={"/notifications"}
                    // onClick={() => navigate("/notifications")}
                    className="devise-language notif-btn | btn"
                  >
                    <ClocheState />
                  </Link>
                ) : (
                  userData.enableHost && (
                    <button
                      onClick={() => {
                        navigate("/hote", { state: { menu: 1 } });
                        handleCloseMenu();
                      }}
                      className="lister-resi-btn connected-hote | btn sd-btn"
                    >
                      {t("hoteModeTxt")}
                    </button>
                  )
                )}

                <button
                  onClick={handleOpenMenu}
                  className="menu-btn connected-menu-btn | btn liner rlve"
                >
                  <img src={menuIcon} alt="menu-icon" />

                  <img
                    className="profil-picture"
                    src={
                      userData.profilImgUrl
                        ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LINK_PROFIL_PICTURE}${userData.profilImgUrl}`
                        : tempProfilPicture
                    }
                    alt="profil-icon"
                  />
                  {notifications.length > 0 && (
                    <span className="notify flash-me"></span>
                  )}
                </button>
              </>
            ) : (
              <>
                {faqPage ? (
                  <>
                    <button className="help-btn | btn">
                      <img src={helpIcon} alt="helpIcon" />
                      <span>{t("helpTxt")}</span>
                    </button>
                    <button
                      onClick={handleGoToAddResidence}
                      className="lister-resi-btn | btn pry-btn"
                    >
                      {t("listResiTxt")}
                    </button>
                    <button
                      onClick={handleOpenMenu}
                      className="menu-btn | btn liner"
                    >
                      <img src={menuIcon} alt="menu-icon" />
                    </button>
                  </>
                ) : (
                  <>
                    {storePage && windowWidth <= 1350 ? (
                      <>
                        <button
                          onClick={handleOpenMenu}
                          className="menu-btn connected-menu-btn | btn liner"
                        >
                          <img src={menuIcon} alt="menu-icon" />
                          {/* <img
                            className="profil-picture"
                            src={tempProfilPicture}
                            alt="profil-icon"
                          /> */}
                        </button>
                      </>
                    ) : (
                      <>
                        {/* <button
                          onClick={handleGoToAddResidence}
                          className="lister-resi-btn | btn sd-btn"
                        >
                          {t("listResiTxt")}
                        </button> */}
                        <button
                          onClick={() => {
                            if (goToStorePage) {
                              goToStorePage();
                              if (storePage && refreshStore)
                                setTimeout(() => {
                                  refreshStore();
                                }, 100);
                              handleCloseMenu();
                            }
                          }}
                          className="lister-resi-btn | btn sd-btn"
                        >
                          {t("listStore")}
                        </button>
                        <button
                          onClick={() => handleUpdateConnexionPanelState(true)}
                          className="connect-btn | btn pry-btn"
                        >
                          {t("connectInscriTxt")}
                        </button>
                        <button
                          onClick={handleOpenMenu}
                          className="menu-btn | btn liner"
                        >
                          <img src={menuIcon} alt="menu-icon" />
                        </button>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {menuOpen && (
              <div
                className={`menu-tc ${
                  userData.connected == false && "not-connected-menu"
                }`}
              >
                <div
                  onClick={handleCloseMenu}
                  className="background-touch"
                ></div>
                <div
                  style={{
                    padding:
                      userData && !userData.connected && windowWidth > 610
                        ? 16
                        : "24px 16px",
                  }}
                  className="menu-container | flcolm"
                >
                  {userData && userData.connected && (
                    <>
                      <div className="menu-top | flcolm liner">
                        <button
                          onClick={() =>
                            NotificationService.addToQueue(
                              1,
                              "Fonctionnalité bientôt disponible"
                            )
                          }
                          style={{
                            opacity: 0.3,
                          }}
                          className="btn flrow liner"
                        >
                          <span>{t("msgTxt")}</span>
                          {/* <span className="indicator"></span> */}
                        </button>
                        <button
                          onClick={() => {
                            navigate("/notifications");
                            handleCloseMenu();
                          }}
                          className="btn flrow liner"
                        >
                          <span>{t("notifTxt")}</span>
                          {notifications.length > 0 && (
                            <span className="indicator flash-me"></span>
                          )}
                        </button>
                        <button
                          onClick={() => {
                            navigate("/favoris");
                            handleCloseMenu();
                          }}
                          className="btn"
                        >
                          <span>{t("favTxt")}</span>
                        </button>
                      </div>
                    </>
                  )}
                  <div className="menu-mid | flcolm liner">
                    {userData && userData.connected && (
                      <>
                        <button
                          onClick={() => {
                            handleCloseMenu();
                            navigate("/profile");
                          }}
                          className="btn flrow liner"
                        >
                          <span>{t("profilTxt")}</span>
                          {userData.emailVerified ? (
                            userData.contactChecked ? (
                              userData.mailChecked || userData.identityDoc ? (
                                ""
                              ) : (
                                <span className="indicator"></span>
                              )
                            ) : (
                              <span className="indicator"></span>
                            )
                          ) : (
                            <span className="indicator"></span>
                          )}
                        </button>
                        <button
                          onClick={() => {
                            handleCloseMenu();
                            navigate("/profilereservation");
                          }}
                          className="btn flrow liner"
                        >
                          <span>{t("myReservationsTxt")}</span>
                        </button>
                        <button
                          onClick={() => {
                            handleCloseMenu();
                            if (
                              window.location.pathname === "/newresidenceinfo"
                            ) {
                              handleGoToAddResidence();
                              return;
                            }
                            navigate("/newresidenceinfo");
                          }}
                          className="btn"
                        >
                          <span>{t("listResiTxt")}</span>
                        </button>
                        {/* {window.location.pathname !== "/store" && ( */}
                        <button
                          onClick={() => {
                            if (goToStorePage) {
                              goToStorePage();
                              if (storePage && refreshStore)
                                setTimeout(() => {
                                  refreshStore();
                                }, 100);
                              handleCloseMenu();
                            }
                          }}
                          className="btn"
                        >
                          <span>{t("listStore")}</span>
                        </button>
                        {/* )} */}
                      </>
                    )}
                    {(windowWidth <= 610 ||
                      (windowWidth <= 1350 && storePage)) &&
                      (userData.connected ? (
                        userData.enableHost && (
                          <>
                            <button
                              onClick={() => {
                                navigate("/hote", { state: { menu: 1 } });
                                handleCloseMenu();
                              }}
                              className="btn"
                            >
                              <span>{t("hoteModeTxt")}</span>
                            </button>
                          </>
                        )
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              if (goToStorePage) {
                                goToStorePage();
                                if (storePage && refreshStore)
                                  setTimeout(() => {
                                    refreshStore();
                                  }, 100);
                                handleCloseMenu();
                              }
                            }}
                            className="btn"
                          >
                            <span>{t("listStore")}</span>
                          </button>
                          <button
                            onClick={() => {
                              handleCloseMenu();
                              if (
                                window.location.pathname === "/newresidenceinfo"
                              ) {
                                handleGoToAddResidence();
                                return;
                              }
                              navigate("/newresidenceinfo");
                            }}
                            className="btn"
                          >
                            <span>{t("listResiTxt")}</span>
                          </button>
                        </>
                      ))}
                    {userData &&
                      (!userData.connected ||
                        (!userData.connected &&
                          windowWidth <= 1350 &&
                          storePage)) && (
                        <>
                          {!storePage && windowWidth >= 610 && (
                            <button
                              onClick={() => {
                                handleCloseMenu();
                                if (
                                  window.location.pathname ===
                                  "/newresidenceinfo"
                                ) {
                                  handleGoToAddResidence();
                                  return;
                                }
                                navigate("/newresidenceinfo");
                              }}
                              className="btn"
                            >
                              <span>{t("listResiTxt")}</span>
                            </button>
                          )}

                          <button
                            onClick={() => {
                              setMenuOpen(false);
                              handleUpdateConnexionPanelState(true);
                            }}
                            className="btn"
                          >
                            <span>{t("connectInscriTxt")}</span>
                          </button>
                        </>
                      )}
                  </div>
                  {userData && userData.connected && (
                    <div className="menu-bottom | flcolm liner">
                      <button
                        onClick={() => {
                          setMenuOpen(false);
                          handleDisconnect();
                        }}
                        className="deco-btn | btn"
                      >
                        <span>{t("deconnexionTxt")}</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
      <div
        className={`header-replace ${
          desactiveHeader == true ? "desactive-header-replace" : ""
        }`}
      ></div>
      <ContainerPersoModal
        className="choice-user-prefs"
        isOpen={openChoiceModal}
        toggle={toogleChoiceModal}
      >
        <ChoiceLanguageDeviseModal />
      </ContainerPersoModal>
    </>
  );
}
