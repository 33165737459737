import climatisation from "../images/icons/svg-comodities-primary/air-conditioner.svg";
import wifi from "../images/icons/svg-comodities-primary/wifi.svg";
import refrigerator from "../images/icons/svg-comodities-primary/refrigerator.svg";
import washing from "../images/icons/svg-comodities-primary/washing-machine.svg";
import fan from "../images/icons/svg-comodities-primary/fan.svg";
import television from "../images/icons/svg-comodities-primary/screen.svg";

import climatisation2 from "../images/icons/air-conditioner.svg";
import wifi2 from "../images/icons/wifi.svg";
import refrigerator2 from "../images/icons/refrigerator.svg";
import washing2 from "../images/icons/washing-machine.svg";
import fan2 from "../images/icons/fan.svg";
import television2 from "../images/icons/screen.svg";
export const dataComodities = {
  data: [
    {
      id: "1-wifi",
      title: "Wifi",
      titleEng: "Wifi",
      idApi: 1,
      thumb: wifi,
      secondThumb: wifi2,
    },
    {
      id: "2-television",
      title: "Télévision",
      titleEng: "Television",
      idApi: 2,
      thumb: television,
      secondThumb: television2,
    },
    {
      id: "3-washing-seche",
      title: "Sèche Linge",
      titleEng: "Washing machine",
      idApi: 3,
      thumb: washing,
      secondThumb: washing2,
    },
    {
      id: "4-clim",
      title: "Climatisation",
      titleEng: "Air conditioning",
      idApi: 4,
      thumb: climatisation,
      secondThumb: climatisation2,
    },
    {
      id: "5-fan",
      title: "Ventilateur",
      titleEng: "Fan",
      idApi: 5,
      thumb: fan,
      secondThumb: fan2,
    },
    {
      id: "6-washing",
      title: "Lave Linge",
      titleEng: "Washing machine",
      idApi: 6,
      thumb: washing,
      secondThumb: washing2,
    },
    {
      id: "7-cuisiniere",
      title: "Cuisinière",
      titleEng: "Cooking range",
      idApi: 7,
      thumb: refrigerator,
      secondThumb: refrigerator2,
    },
  ],
};
