import React, { useEffect, useRef, useState } from "react";
import { requestEndpoint } from "../../data/requestEndpoint";
import { Tel } from "../../images/icons/JsxIcons";
import { get } from "../../services/useFetchAction";
import chevronDown from "../../images/icons/chevron-down.svg";
import json from "../../data/countries.json";
import { useTranslation } from "react-i18next";

export default function InputPhoneNumber({
  dataInscription,
  setindicatif,
  indicatif,
  setDataInscription,
  setStateTelephone,
  stateTelephone,
  handleUptadeTelephone,
  curentCountrySelected,
  setCurentCountrySelected,
  showFirstValue = false,
  currentTelephone,
  isProfile = false,
  onFocusFunction = () => {
    "";
  },
  onBlurFunction = () => {
    "";
  },
}) {
  const { t } = useTranslation();
  const [alreadyFetch, setAlreadyFetch] = useState(false);
  const [fetchFinish, setFetchFinish] = useState(false);
  const [countries, setCountries] = useState([]);
  const [currentFilterCountries, setCurrentFilterCountries] = useState([]);
  const [isSelectVisible, setIsSelectVisible] = useState(false);
  const selectRef = useRef(null);
  const refInput = useRef(null);
  const refInputTelephone = useRef(null);

  useEffect(() => {
    if (alreadyFetch) return;
    const fetchData = async () => {
      const countrieDb = await get(requestEndpoint.getCountries);
      setCountries(countrieDb.data);
      setAlreadyFetch(true);
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        if (response.ok) {
          const data = await response.json();
          const countryData = data.map((country) => ({
            countryCode: country.cca2,
            flagPath: country.flags.svg,
          }));
          setCountries(countryData);
          let currentDataPays = [];
          for (let index = 0; index < json.length; index++) {
            for (let jindex = 0; jindex < countryData.length; jindex++) {
              if (
                json[index].code.toString().toUpperCase() ==
                countryData[jindex].countryCode.toString().toUpperCase()
              ) {
                currentDataPays.push({
                  ...json[index],
                  flag: countryData[jindex].flagPath,
                });
                // break;
              }
            }
          }
          const countrieDb = await get(requestEndpoint.getCountries);
          let newCurrentDataPays = [];
          countrieDb.data.forEach((element) => {
            let find = false;
            let currentElement = { ...element };
            let finded = {};
            for (let index = 0; index < currentDataPays.length; index++) {
              if (
                currentDataPays[index].code.toString().toUpperCase() ==
                currentElement.iso2.toString().toUpperCase()
              ) {
                finded = currentDataPays[index];
                find = true;
                break;
              }
            }
            if (!find) {
              newCurrentDataPays.push({
                ...currentElement,
                flag: null,
              });
            } else {
              newCurrentDataPays.push({
                ...finded,
                name: currentElement.name,
                id: currentElement.id,
                province: currentElement.province,
              });
            }
          });

          setCountries(newCurrentDataPays);
          setCurrentFilterCountries(newCurrentDataPays);
          // setCurentCountrySelected(currentDataPays[53]);
          setFetchFinish(true);
        } else {
          console.error("Réponse HTTP non réussie :", response);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données des pays",
          error
        );
      }
    };

    fetchData();
  }, [isSelectVisible]);

  const handleUpdateIndicatif = (e) => {
    let newIndicatif = e.target.value.replace(/[^0-9]/g, "");
    filtersCountries(e);
    if (setindicatif) setindicatif(newIndicatif);
    else setDataInscription({ ...dataInscription, indicatif: newIndicatif });
    e.target.value = newIndicatif;
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsSelectVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectCountry = (country) => {
    if (setindicatif) setindicatif(country.dial_code);
    else
      setDataInscription({ ...dataInscription, indicatif: country.dial_code });
    setCurentCountrySelected(country);
    refInput.current.value = country.dial_code.replace("+", "");
    setIsSelectVisible(false);
  };

  const returnedSlice = (value) => {
    if (value.length > 50) {
      return value.slice(0, 50) + "...";
    } else return value;
  };

  const filtersCountries = (e) => {
    let value = e.target.value;
    let currentFilterCountries = [];
    if (value.length > 0) {
      currentFilterCountries = countries.filter((country) => {
        return country.dial_code.toLowerCase().includes(value.toLowerCase());
      });
    } else {
      currentFilterCountries = countries;
    }
    setCurrentFilterCountries(currentFilterCountries);
  };

  const autoSelectCountry = () => {
    let value = refInput.current.value;
    if (value.length > 0) {
      countries.forEach((country) => {
        if (
          country.dial_code.replace("+", "").toLowerCase() ==
          value.replace("+", "").toLowerCase()
        ) {
          setCurentCountrySelected(country);
        }
      });
    }
  };

  useEffect(() => {
    if (isSelectVisible == false) autoSelectCountry();
  }, [isSelectVisible]);

  const [isFetched, setIsFetched] = useState(false);
  useEffect(() => {
    if (isFetched) return;
    if (showFirstValue) {
      if (refInput.current && refInputTelephone.current) {
        if (indicatif) {
          refInput.current.value = indicatif;
          autoSelectCountry();
        }
        if (currentTelephone) {
          refInputTelephone.current.value = currentTelephone;
        }
        setIsFetched(true);
      }
    }
  }, [fetchFinish, refInput, refInputTelephone]);
  let calculLenght = 0;
  if (dataInscription) {
    calculLenght = dataInscription.indicatif.toString().trim().length;
  } else {
    if (indicatif) calculLenght = indicatif.toString().trim().length;
  }
  return (
    <div className="input | rlve phone-input">
      <Tel
        color={
          stateTelephone.focus || isSelectVisible ? "var(--gray-600)" : "none"
        }
      />
      <div ref={selectRef} className="indicatif-select">
        <div
          className={`indicatif flrow liner addgap2 ${
            calculLenght === 0 ? "nothing" : ""
          }`}
          style={
            curentCountrySelected
              ? {
                  width: refInput.current
                    ? refInput.current.value.length == 4
                      ? "90px"
                      : "80px"
                    : "85px",
                }
              : { width: "54px" }
          }
        >
          <input
            autoCorrect="false"
            autoCapitalize="false"
            autoComplete="false"
            ref={refInput}
            onBlur={() => onBlurFunction()}
            onFocus={() => {
              onFocusFunction();
              setIsSelectVisible(true);
              setCurentCountrySelected(null);
            }}
            onChange={handleUpdateIndicatif}
            placeholder="000"
            className="select-indicatif"
            type="tel"
            style={{
              border: "none",
            }}
          />
          {curentCountrySelected && (
            <img src={curentCountrySelected.flag} alt="flag" />
          )}
        </div>
        {fetchFinish && isSelectVisible && (
          <div
            style={
              isProfile
                ? {
                    width: "360px",
                  }
                : {}
            }
            className="indicatif-selected flcolm"
          >
            {currentFilterCountries.map((country, index) => (
              <button
                key={index + country.name}
                className="indicatif-country btn"
                onClick={() => handleSelectCountry(country)}
              >
                <img src={country.flag} alt="flag" />
                <span className="-l">{returnedSlice(country.name)}</span>
                <strong>{country.dial_code}</strong>
              </button>
            ))}
          </div>
        )}
      </div>
      <input
        style={
          curentCountrySelected
            ? { paddingLeft: 132 }
            : {
                paddingLeft: 105,
              }
        }
        onBlur={() => setStateTelephone({ ...stateTelephone, focus: false })}
        onFocus={() => setStateTelephone({ ...stateTelephone, focus: true })}
        autoCorrect="false"
        autoCapitalize="false"
        autoComplete="false"
        onChange={handleUptadeTelephone}
        placeholder={t("placeHolderTel")}
        type="tel"
        ref={refInputTelephone}
      />
      {stateTelephone.focus == false &&
        (stateTelephone.error ||
          (!curentCountrySelected &&
            calculLenght > 0 &&
            stateTelephone.focus == false &&
            isSelectVisible == false)) && (
          <div className="detect-issue-input">
            <div className="chevron"></div>
            {curentCountrySelected ? (
              isProfile ? (
                <span>
                  Entrez un numero de téléphone correct et different du
                  précedent
                </span>
              ) : (
                <span>{t("issueTelType")}</span>
              )
            ) : (
              <span>{t("issueTelType")}</span>
            )}
          </div>
        )}
    </div>
  );
}
