import { requestEndpoint } from "../data/requestEndpoint";
import { NotificationService } from "../services/Notification.service";
import { post } from "../services/useFetchAction";

export const sendPhotosToDatabase = async (
  photosBlob = [],
  setSuccessCount
) => {
  const photosURL = [];
  let errorSend = false;
  let successCount = 0; // Compteur pour les images téléchargées avec succès

  for (let i = 0; i < photosBlob.length; i++) {
    try {
      const postImage = await post(
        requestEndpoint.uploadImage,
        {
          file: photosBlob[i],
        },
        {
          "Content-Type": "multipart/form-data",
        }
      );

      photosURL.push(postImage.data.filename);
      successCount++;
      setSuccessCount(successCount); // Mettre à jour le nombre d'images téléchargées avec succès
    } catch (error) {
      errorSend = true;
      console.error("Erreur lors du téléchargement de l'image", error);
      NotificationService.addToQueue(
        2,
        "Erreur lors du téléchargement de l'image"
      );
      //annuler le téléchargement des images restantes si nécessaire
      break;
      // Gérer les erreurs de téléchargement d'image ici si nécessaire
    }
  }

  return { photosURL, successCount, errorSend }; // Retourne à la fois les URL des images téléchargées et le nombre total d'images téléchargées avec succès
};
