/* eslint-disable eqeqeq */
import Cookies from "js-cookie";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryParam } from "use-query-params";
import { SearchLg, Setting04 } from "../../images/icons/JsxIcons";
import ArrowStoreListing from "../../images/icons/localisation.svg";
import { NotificationService } from "../../services/Notification.service";
import useCurrentWidth from "../../services/useCurrentWidth";
import { getJustDateFormat } from "../../services/utils";
import { useStorePageManager } from "../../store/PageManage";
import { useStoreManager } from "../../store/StoreManage";
import "../../styles/components/searchStore.css";
import FilterMobileAccueil from "../biggest/FilterMobileAccueil";
import { InputHeaderSecond } from "../cells/InputHeaderSecond";
import AddLessDiv from "../molecules/AddLessDiv";
import { InputDatePicker } from "./InputDatePicker";
import { InputHeaderSecondPersonne } from "./InputHeaderSecondPersonne";

const currentDate = moment();
const newDate = moment(currentDate).add(7, "days").format("YYYY-MM-DD");

export default function SearchStore({
  className = "",
  handleFilters,
  isStore = false,
}) {
  const { t } = useTranslation();
  const searchStoreParams = useStoreManager((state) => state.searchStoreParams);
  const reservationParams = useStoreManager((state) => state.reservationParams);
  const storeParameters = useStoreManager((state) => state.storeParameters);
  const [loadFinish, setLoadFinish] = useState(false);
  const [destinationInput, setDestinationInput] = useState("");

  const [currentLat, setCurrentLat] = useState(null);
  const [currentLng, setCurrentLng] = useState(null);
  const [viewPort, setViewPort] = useState(null);
  const location = useLocation();
  // let searchUrlParams = new URLSearchParams(location.search);
  // let searchParams = {};
  // for (let [key, value] of searchUrlParams) {
  //   searchParams[key] = value;
  // }
  // let startDateUrl = useMemo(() => searchParams.startDate);
  // let endDateUrl = useMemo(() => searchParams.endDate);
  // let numPeopleUrl = useMemo(() => searchParams.nbrPersonne);
  // if (numPeopleUrl) {
  //   numPeopleUrl = parseInt(numPeopleUrl);
  // }
  const [startDate, setStartDate] = useState(
    // checkDateJust(startDateUrl) ? new Date(startDateUrl) :
    null
  );
  const [endDate, setEndDate] = useState(
    // checkDateJust(endDateUrl) ? new Date(endDateUrl) :
    null
  );

  const [personneNumber, setPersonneNumber] = useState(
    // numPeopleUrl ??
    0
  );

  const [alreadyCheck, setAlreadyCheck] = useState(false);
  useEffect(() => {
    if (!searchStoreParams) return;
    // if (!searchStoreParams.destination) return;
    const checkForSet = () => {
      // console.log("searchStoreParams", searchStoreParams);
      let startDate = searchStoreParams.startDate ?? null;
      let datacheck = new Date().setHours(0, 0, 0, 0);
      if (startDate == datacheck) startDate = null;
      let endDate = searchStoreParams.endDate ?? null;
      if (startDate == null) endDate = null;
      setDestinationInput(searchStoreParams.location ?? "");
      setStartDate(startDate);
      setEndDate(endDate);
      setPersonneNumber(searchStoreParams.numPeople ?? 0);
      setPersonneShow(searchStoreParams.numPeople ?? 0);
      setCurrentLat(searchStoreParams.lat ?? null);
      setCurrentLng(searchStoreParams.lng ?? null);
      setViewPort(searchStoreParams.viewPort ?? null);
      setAlreadyCheck(true);
    };
    setLoadFinish(true);
    if (!alreadyCheck) checkForSet();
  }, [searchStoreParams]);
  // console.log(destinationInput, startDate, endDate, personneNumber);
  const navigate = useNavigate();
  const { windowWidth } = useCurrentWidth();
  const [currentMonth, setCurrentMonth] = useState(2);
  const [days, setDays] = useState(1);
  const [currentSelected, setCurrentSelected] = useState(false);
  const [blurFilter, setBlurFilter] = useState(false);
  const [persoShow, setPersonneShow] = useState(0);
  const [mapOpen] = useQueryParam("mapOpen");
  const [centerMap] = useQueryParam("centerMap");

  const handleUpdateDesactiveHeader = useStorePageManager(
    (state) => state.handleUpdateDesactiveHeader
  );
  const handleUpdateBodyOverflow = useStorePageManager(
    (state) => state.handleUpdateBodyOverflow
  );
  const handleUpdateReservationParameters = useStoreManager(
    (state) => state.handleUpdateReservationParameters
  );
  const handleUpdateStoreParameters = useStoreManager(
    (state) => state.handleUpdateStoreParameters
  );
  const handleUpdateSearchStoreParameters = useStoreManager(
    (state) => state.handleUpdateSearchStoreParameters
  );
  const locateUserOnMap = useStorePageManager((state) => state.locateUserOnMap);
  useEffect(() => {
    if (windowWidth <= 630 && currentMonth == 2) {
      setCurrentMonth(1);
    } else if (windowWidth > 630 && currentMonth == 1) {
      setCurrentMonth(2);
    }
  }, [windowWidth]);

  const handleResetAllFilters = () => {
    setPersonneNumber(0);
    setPersonneShow(0);
    setDestinationInput("");
    setStartDate(null);
    setEndDate(null);
  };
  const handleValidateFilter = () => {
    setPersonneShow(personneNumber);
    setBlurFilter(false);
  };
  const [mobileFilter, setMobileFilter] = useState(false);
  const handleOpenMobileFilter = (e) => {
    handleUpdateDesactiveHeader(true);
    handleUpdateBodyOverflow(false);
    setMobileFilter(true);
    if (e) {
      e.preventDefault();
    }
  };
  const handleCloseMobileFilter = () => {
    handleUpdateDesactiveHeader(false);
    handleUpdateBodyOverflow(true);
    setMobileFilter(false);
  };
  const checkIfLastSearchIsSame = (destination) => {
    const lastSearches = handleGetLastSearches();
    if (lastSearches.length == 0) return false;
    if (lastSearches[0].name == destination) return true;
    return false;
  };
  const handleGetLastSearches = () => {
    const cookieData = Cookies.get("myArrayRecentSearch");
    const myArray = cookieData ? JSON.parse(cookieData) : [];
    const lastTwoElements = myArray.slice(-2);
    return lastTwoElements;
  };
  const handleSearch = () => {
    if (!handleFilters) {
      if (destinationInput == "") {
        NotificationService.addToQueue(1, t("destinationInputError"));
        return;
      }
      if (startDate == null || endDate == null) {
        NotificationService.addToQueue(1, t("addDateInputError"));
        return;
      }
      if (personneNumber == 0) {
        const mobileContainsScrollFilter = document.getElementById(
          "mobile-contains-scroll-filter"
        );
        const personneScroll = document.getElementById(
          "mine-just-add-less-div-mobile"
        );
        if (mobileContainsScrollFilter) {
          mobileContainsScrollFilter.scrollTo({
            top: personneScroll.offsetTop,
            behavior: "smooth",
          });
        }
        NotificationService.addToQueue(1, t("addPersonneInputError"));
        return;
      }
    }
    if (handleFilters) {
      handleSearchSecond();
    } else {
      useStoreManager.getState().handleUpdateStoreParameters({
        ...storeParameters,
        destination: destinationInput,
        arriveeDate: startDate,
        departDate: endDate,
        personnes: personneNumber,
      });
      useStoreManager.getState().handleUpdateSearchStoreParameters();
    }
    handleUpdateReservationParameters({
      residenceId: null,
      lastPage: null,
      startDate: startDate,
      endDate: endDate,
      nbrPersonne: personneNumber,
    });
    if (
      destinationInput.trim() != "" &&
      checkIfLastSearchIsSame(destinationInput) == false
    ) {
      handleSaveToCookies({
        name: destinationInput,
        longlat: { lat: currentLat, lng: currentLng },
        viewPort: viewPort,
      });
    }
    if (!handleFilters) {
      useStorePageManager.getState().handleUpdateLastResidenceTouched(null);
      navigate(
        `/store?destination=${destinationInput}&arrivalDate=${
          new Date(startDate).toISOString().split("T")[0]
        }&departureDate=${
          new Date(endDate).toISOString().split("T")[0]
        }&numPeople=${personneNumber}`,
        {
          state: {
            destination: destinationInput,
            startDate: startDate,
            endDate: endDate,
            nbrPersonne: personneNumber,
          },
        }
      );
    } else {
      handleCloseMobileFilter();
    }
  };

  const refreshStore = useStoreManager((state) => state.refreshStore);

  const handleSearchSecond = async () => {
    //a voir si on laisse
    useStoreManager.getState().handleUpdateStoreParameters({
      ...storeParameters,
      destination: destinationInput,
      arriveeDate: startDate,
      departDate: endDate,
      personnes: personneNumber,
    });
    useStoreManager.getState().handleUpdateSearchStoreParameters();
    //a voir si on laisse
    await handleUpdateStoreParameters({
      ...storeParameters,
      page: 1,
      destination: destinationInput,
      arriveeDate: startDate,
      departDate: endDate,
      personnes: personneNumber,
      lng: currentLng,
      lat: currentLat,
      viewPort: viewPort,
    });
    await handleUpdateSearchStoreParameters();
    useStorePageManager.getState().handleUpdateCurrentMapTouched(null);
    if (refreshStore) refreshStore();
  };

  const checkForDirectSearch = () => {
    if (loadFinish == false) return;
    // console.log("testing", "f");
    if (
      windowWidth >= 550 &&
      windowWidth <= 950 &&
      window.location.pathname == "/store"
    ) {
      handleSearch();
    }
  };
  const handleSaveToCookies = (valToSave) => {
    //si valToSave name == "Depuis la carte" on ne le sauvegarde pas
    if (valToSave?.name?.toLowerCase() == "Depuis la carte".toLowerCase())
      return;
    const cookieData = Cookies.get("myArrayRecentSearch");
    const myArray = cookieData ? JSON.parse(cookieData) : [];
    myArray.push(valToSave);
    //si valToSave est deja dans le tableau on le supprime
    const myArrayFiltered = myArray.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.name === item.name &&
            t.longlat.lat === item.longlat.lat &&
            t.longlat.lng === item.longlat.lng
        )
    );
    if (myArrayFiltered.length > 2) {
      myArrayFiltered.shift();
    }
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 1);
    Cookies.set("myArrayRecentSearch", JSON.stringify(myArrayFiltered), {
      expires: expirationDate,
    });
  };
  useEffect(() => {
    let calculDays = 1;
    if (startDate == null) return;
    if (endDate == null) return;
    if (endDate != null) {
      const date1 = moment(startDate);
      const date2 = moment(endDate);
      const diff = date2.diff(date1, "days");
      calculDays = diff;
    }
    setDays(calculDays);
  }, [destinationInput, startDate, endDate, personneNumber]);
  useEffect(() => {
    if (!centerMap) return;
    if (mapOpen == 1 || mapOpen == "1" || mapOpen == true) {
      setDestinationInput("Depuis la carte");
    }
  }, [centerMap]);

  return (
    <div
      style={{
        transition: "all 0.15s ease",
        background: currentSelected ? "var(--gray-200)" : "var(--shade-white)",
      }}
      className={`hd-search-bar second-search-bar ${className} | flrow liner rlve`}
    >
      {windowWidth <= 550 ? (
        <>
          {mobileFilter && (
            <FilterMobileAccueil
              handleCloseReservationPanel={() => handleCloseMobileFilter()}
              handleGoSearch={() => handleSearch()}
              handleGoErase={() => handleResetAllFilters()}
              destination={destinationInput}
              setDestination={setDestinationInput}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              nbrPersonne={personneNumber}
              setNbrPersonne={setPersonneNumber}
              direct={searchStoreParams.numPeople || searchStoreParams.location}
              setCurrentLat={setCurrentLat}
              setCurrentLng={setCurrentLng}
              setViewPort={setViewPort}
              viewPort={viewPort}
            />
          )}
          <button
            onClick={() => handleOpenMobileFilter()}
            className="btn-mobile-select | btn flcolm center"
          >
            <label>
              {destinationInput == "" ? "Destination" : destinationInput}
            </label>
            <div className="second-info | flrow center">
              <p className="date-select">
                {endDate === null
                  ? "Date"
                  : days + " " + t("nightTxt") + (days > 1 ? "s" : "")}
              </p>

              <span>•</span>
              <p className="others-select">
                {personneNumber > 0
                  ? personneNumber +
                    " " +
                    t("personneTxt") +
                    (personneNumber > 1 ? "s" : "")
                  : t("addTravelerTxt")}
              </p>
            </div>
          </button>
        </>
      ) : (
        <>
          <InputHeaderSecond
            title="Destination"
            placeholder={t("placeholderDestinationTxt")}
            baseFlex={1.6}
            value={destinationInput}
            setHider={setCurrentSelected}
            setInput={setDestinationInput}
            handleGoSearch={() => checkForDirectSearch()}
            setCurrentLat={setCurrentLat}
            setCurrentLng={setCurrentLng}
            currentLat={currentLat}
            currentLng={currentLng}
            setViewPort={setViewPort}
            viewPort={viewPort}
          />
          <hr />
          <InputDatePicker
            startDate={startDate}
            searchStoreParams={searchStoreParams}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            title={t("arrivedPlaceholderTxt")}
            placeholder={
              searchStoreParams.startDate
                ? getJustDateFormat(startDate, "D MMMM YYYY")
                : "Date"
            }
            type="start"
            nbrMonth={currentMonth}
            setHider={setCurrentSelected}
            className="date-part"
            handleGoSearch={() => checkForDirectSearch()}
            secondCall={"input-second-end-2"}
            direct={
              searchStoreParams.numPeople ||
              searchStoreParams.location ||
              storeParameters.arriveeDate != new Date().setHours(0, 0, 0, 0) ||
              window.location.pathname == "/store"
            }
          />
          <hr />
          <InputDatePicker
            startDate={startDate}
            searchStoreParams={searchStoreParams}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            title={t("departPlaceholderTxt")}
            placeholder={
              searchStoreParams.endDate
                ? getJustDateFormat(endDate, "D MMMM YYYY")
                : "Date"
            }
            type="end"
            nbrMonth={currentMonth}
            setHider={setCurrentSelected}
            className="date-part"
            handleGoSearch={() => checkForDirectSearch()}
            myId={"input-second-end-2"}
            direct={
              searchStoreParams.numPeople ||
              searchStoreParams.location ||
              storeParameters.arriveeDate != new Date().setHours(0, 0, 0, 0) ||
              window.location.pathname == "/store"
            }
          />
          <hr />
          <InputHeaderSecondPersonne
            title={t("personneNumber")}
            placeholder={t("addTravelerTxt")}
            typeInput="number"
            typer={t("personneTxt")}
            secondHeader={t("personneTxt") + "s"}
            value={persoShow}
            baseFlex={1.8}
            setHider={setCurrentSelected}
            setBlurFilter={setBlurFilter}
            blurFilter={blurFilter}
            handleGoSearch={() => checkForDirectSearch()}
          />
        </>
      )}
      {blurFilter && (
        <>
          <div
            onClick={() => setBlurFilter(false)}
            className="background-touch-hide"
          ></div>
          <div className="filter-extend-searching filter-selected-panel | flcolm">
            <AddLessDiv
              classname="pers-nbr"
              title={t("personneNumber")}
              min={1}
              setData={setPersonneNumber}
              data={personneNumber}
              baseData={personneNumber}
            />
            <div className="reset-done | flrow center btw">
              <button
                onClick={handleResetAllFilters}
                className="reset-btn | btn"
              >
                {t("btnErase")}
              </button>
              <button
                onClick={handleValidateFilter}
                className="done-btn | btn pry-btn"
              >
                {t("btnValidate")}
              </button>
            </div>
          </div>
        </>
      )}
      {!handleFilters ? (
        <button onClick={handleSearch} className="go-search | flex">
          <SearchLg />
        </button>
      ) : (
        <div
          style={
            mapOpen == 1 || mapOpen == "1" || mapOpen == true
              ? {
                  transition: "all 0.15s ease",
                  padding: "0px",
                }
              : { transition: "all 0.15s ease" }
          }
          className={`contains-btn-filters ${isStore ? "is-store" : ""}`}
        >
          <button
            onClick={() => handleFilters(true)}
            className="go-search show-filter | flex"
          >
            <Setting04 />
          </button>
          {mapOpen == 1 || mapOpen == "1" || mapOpen == true ? null : (
            <>
              <div className="separator"></div>
              <button
                onClick={() => {
                  if (locateUserOnMap) locateUserOnMap(true);
                }}
                className="btn arrow-localisation"
              >
                <img
                  style={{
                    transform: "translate(0px, 2.5px)",
                  }}
                  src={ArrowStoreListing}
                  alt="localisation"
                />
              </button>
            </>
          )}
        </div>
      )}

      {/* <div className="datepicker"></div> */}
    </div>
  );
}
